import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
  },
  tab: {
    color: '#2C1482',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 700,
    borderBottom: '2px solid #2C1482',
  },
}));
