import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    background: 'rgba(124, 131, 157, 0.09)',
    height: '100%',
    position: 'relative',
  },
  ellipseDownLeft: {
    position: 'absolute',
    bottom: '0',
    left: '0',
  },
  ellipseUpperRight: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  imageContainer: {
    width: '100%',
    textAlign: 'center',
    flex: 1,
    display: 'flex',
  },
  imageTemplate: {
    maxWidth: '395px',
    height: 'auto',
    objectFit: 'contain',
    width: '100%',
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
  logoContainer: {
    width: '100%',
    paddingTop: '24px',
  },
  innerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));
