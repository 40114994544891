import { configureStore } from '@reduxjs/toolkit';
import { fieldValuesSlice } from './fieldValues';
import { flowStrategySlice } from './flowStrategy';
import { creditConditionsSlice } from './creditConditions';
import { nodesFieldsSlice } from './nodesFields';
import { perfiladorSlice } from './perfilador';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const store = configureStore({
  reducer: {
    fieldValues: fieldValuesSlice.reducer,
    flowStrategy: flowStrategySlice.reducer,
    creditConditions: creditConditionsSlice.reducer,
    nodesFields: nodesFieldsSlice.reducer,
    perfilador: perfiladorSlice.reducer,
  },
  devTools: ENVIRONMENT === 'development',
});
