/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Stepper as StepperMaterial,
  Step,
  StepLabel,
  StepConnector,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

export const Stepper = ({ steps, activeStep, connectorClass }) => {
  const classes = useStyles();
  return (
    <StepperMaterial
      activeStep={activeStep}
      connector={<StepConnector className={connectorClass} />}
    >
      {
        steps.map(({ label, completed }) => (
          <Step key={label} completed={completed}>
            <StepLabel
              classes={{
                label: classes.label,
              }}
              StepIconProps={{
                classes: {
                  text: classes.stepLabelText,
                  active: classes.active,
                },
              }}
            >
              {label}

            </StepLabel>
          </Step>
        ))
      }
    </StepperMaterial>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  connectorClass: PropTypes.string,
};

Stepper.defaultProps = {
  connectorClass: '',
};
