import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import {
  FormControl,
  OutlinedInput,
} from '@material-ui/core';

import clsx from 'clsx';

import PropTypes from 'prop-types';

import useStyles from './useStyles';

const ERROR_MESSAGE_EMPTY = 'Rellena este campo';
const ERROR_LESS_THAN_4 = 'Completa 4 digitos';

const InputLastDigits = ({
  id,
  name,
  label,
  value,
  disabled,
  className,
  hide,
  required,
  onChange,
}) => {
  const inputRef = useRef(null);
  const classes = useStyles();

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value: valueTarget } = target;
    if (required && !valueTarget) target.setCustomValidity(ERROR_MESSAGE_EMPTY);
    else if (required && valueTarget.length < 4) target.setCustomValidity(ERROR_LESS_THAN_4);
    else target.setCustomValidity('');
    if (!/\D/.test(valueTarget)) onChange(event, valueTarget.slice(0, 4));
  }, [onChange, required]);

  useEffect(() => {
    if (!value && required) {
      inputRef.current.setCustomValidity(ERROR_MESSAGE_EMPTY);
    }
  }, [value, required]);

  return (
    <FormControl
      maxWidth={false}
      className={clsx(classes.containerCVV, className, {
        [classes.hide]: hide,
      })}
    >
      <label htmlFor={id} className={classes.checkBoxLabel}>{label}</label>
      <OutlinedInput
        onChange={handleOnChange}
        value={value}
        name={name}
        disabled={disabled}
        inputRef={inputRef}
        id={id}
        className={classes.input}
        variant="outlined"
        aria-describedby="my-helper-text"
      />
    </FormControl>
  );
};

InputLastDigits.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  hide: PropTypes.bool,
};

InputLastDigits.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  value: undefined,
  disabled: false,
  className: '',
  required: true,
  onChange: () => { },
  hide: false,
};

export default memo(React.forwardRef(InputLastDigits));
