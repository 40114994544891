import React, {
  useEffect, useContext, useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

// import ReactGA from 'react-ga';
import useStateMachine from './hooks/useStateMachine';

import { StepperContext } from '../../../../context/stepper';
import { LoaderPage } from './LoaderPage';
import {
  PERFILAMIENTO_NAME,
  PROFILING_COTIZATION,
  PROFILING_COMPLETED,
  PROFILING_EMPTY_MATRIZ,
  CREDIT_PROFILING_COTIZATION_STRATEGY,
  ENTITY_PERFILAMIENTO_INMOBILIARIA_ROUTE,
  PRECALIFICACION_CREDITO_HIPOTECARIO_STEPCONFIG,
  AGENTE_CLIENTS_ROUTE,
  APP_MAIN_ROUTE,
  PERFILAMIENTO_COTIZACION_NAME,
} from '../../../../constants';

import {
  getCurrentStep,
  getProcess,
  getPageTagStepsByLocalSteps,
  getTheCurrentStep,
  updateLocalStepsByStep,
  handleRedirectLogout,
  isUserAgent,
  getClientFirstName,
} from '../../../../utils';

import { getAndSetStrategyByName } from '../../../../utils/strategies.utils';
import * as processAPI from '../../../../api/process';
import { CreditCotizationAccount } from './CreditCotizationAccount';
import { CreditProfilingWait } from './CreditProfilingWait';
import useRoute from '../../../../hooks/useRoute';

import { useProfiling } from '../../../../hooks/useProfiling';
import {
  getLocalEntityId,
  getLocalEntityLandingURL,
  getPerfilamientoLeadData,
  getPerfilamientoLeadId,
} from '../../../../storage';
import { TabsProvider } from '../../../../context/tabs/tabsProvider';
import { getURLToPortal } from '../../../../utils/util-routes';
import { CreditProfilingRecuperation } from './CreditProfilingRecuperation';

export const CotizationInmobiliaria = ({ strategyName, productStrategy }) => {
  const { strategy, changeStrategy } = useProfiling();
  const [title, setTitle] = useState('');
  const [buttonFinalizarText, setButtonFinalizarText] = useState('Finalizar');
  const [stepperTags, setStepperTags] = useState({});
  const { updateCurrentStep } = useContext(StepperContext);
  const {
    navigate,
    navigateWithQueryParam,
  } = useRoute();
  const {
    STATES,
    currentState,
    goToProfilingCotization,
    goToLoaderPage,
    goToProfilingCompleted,
    goToProfilingEmptyMatriz,
  } = useStateMachine();

  const emptyMatrix = () => {
    setTitle('En este momento no podemos hacerte un oferta por que no eres sujeto a crédito. Te sugerimos intentar de nuevo en 90 días');
    return goToProfilingCompleted();
  };

  const onProfilingCompleted = () => {
    const leadId = getPerfilamientoLeadId();
    const firstName = getClientFirstName();
    const titleClient = `¡Gracias, ${firstName}! Un especialista de Chiplo se comunicará contigo hoy mismo para ayudarte a conseguir tu Crédito Hipotecario.`;
    const titleLead = `El cliente ${firstName} ha quedado registrado en Chiplo y listo para hacer su Calificación Hipotecaria.`;

    const titleToSet = leadId ? titleLead : titleClient;
    setTitle(titleToSet);
    return goToProfilingCompleted();
  };

  const getRedirectURL = () => {
    const { _id: leadId = '', processCreated = '' } = getPerfilamientoLeadData();
    if (leadId) {
      const portalURL = getURLToPortal();
      return `${portalURL}/flowchiplo/process/${processCreated}/stepconfig/${PRECALIFICACION_CREDITO_HIPOTECARIO_STEPCONFIG}`;
    }
    const ladingURL = getLocalEntityLandingURL() || APP_MAIN_ROUTE;
    return ladingURL;
  };

  const onFinish = async () => {
    const isAgentLogged = isUserAgent();
    if (isAgentLogged) {
      return navigate(AGENTE_CLIENTS_ROUTE, { replace: true });
    }
    const imobiliariaId = getLocalEntityId();
    const returnUrl = `${ENTITY_PERFILAMIENTO_INMOBILIARIA_ROUTE}${imobiliariaId}`;
    const redirectURL = getRedirectURL();
    return handleRedirectLogout(redirectURL, true, returnUrl);
  };

  // eslint-disable-next-line no-unused-vars
  const finishProfiling = (finishType) => {
    switch (finishType) {
      case PROFILING_COTIZATION:
        getAndSetStrategyByName(
          CREDIT_PROFILING_COTIZATION_STRATEGY,
          productStrategy,
          changeStrategy,
        );
        return goToProfilingCotization();
      case PROFILING_EMPTY_MATRIZ:
      default:
        return emptyMatrix();
    }
  };

  const createUpdateTag = useCallback((updateTag) => {
    const process = getProcess();
    if (!process) {
      const localStepPosition = getCurrentStep();
      const { steps } = updateLocalStepsByStep(localStepPosition);
      navigateWithQueryParam(steps[localStepPosition + 1].route, 'inmobiliaria');
    }
    return async () => {
      const { tags = {} } = await processAPI.getProcessById(process);
      const body = {
        [PERFILAMIENTO_NAME]: { ...tags[PERFILAMIENTO_NAME], ...updateTag },
      };
      await processAPI.createTag({
        tagName: PERFILAMIENTO_NAME,
        active: true,
        body: { ...tags, ...body },
        processId: process,
      });
    };
  }, [stepperTags]);

  const getProcessSteps = async () => {
    const processId = getProcess();
    const localStepPosition = getCurrentStep();

    if (!processId) {
      return getPageTagStepsByLocalSteps(
        PERFILAMIENTO_COTIZACION_NAME,
        localStepPosition,
      );
    }
    const process = await processAPI.getProcessById(processId);
    const { tags = {} } = process;
    const { redirectPath, stepPosition } = await getTheCurrentStep(
      processId,
      PERFILAMIENTO_COTIZACION_NAME,
    );
    const consultTag = tags[PERFILAMIENTO_COTIZACION_NAME];
    return {
      tags, redirectPath, stepPosition, consultTag, process,
    };
  };

  const selectPage = async () => {
    const {
      tags, redirectPath, stepPosition, consultTag,
    } = await getProcessSteps();
    const { currentPage = PROFILING_COTIZATION, completed } = consultTag;
    if (completed) return navigateWithQueryParam(redirectPath, 'inmobiliaria');
    setStepperTags(tags);
    updateCurrentStep(stepPosition);
    switch (currentPage) {
      case PROFILING_EMPTY_MATRIZ:
        setTitle('En este momento no podemos hacerte un oferta por que no eres sujeto a crédito. Te sugerimos intentar de nuevo en 90 días');
        return goToProfilingEmptyMatriz();
      case PROFILING_COMPLETED:
        setTitle('Gracias, información recibida. Analizamos tu solicitud de inmediato y regresamos contigo con los avances. Cualquier duda puedes escribirnos al 3335063714.');
        return goToProfilingCompleted();
      case PROFILING_COTIZATION:
        getAndSetStrategyByName(
          CREDIT_PROFILING_COTIZATION_STRATEGY,
          productStrategy,
          changeStrategy,
        );
        return goToProfilingCotization();
      default:
        return goToLoaderPage();
    }
  };

  useEffect(() => {
    selectPage();
  }, []);

  useEffect(() => {
    if (isUserAgent()) {
      setButtonFinalizarText('Finalizar');
    }
  }, []);

  useEffect(() => {
    if (strategy) return;
    getAndSetStrategyByName(strategyName, productStrategy, changeStrategy);
  }, [productStrategy, changeStrategy]);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {
        (
          () => {
            switch (currentState) {
              case STATES.LOADER_PAGE:
                return (
                  <LoaderPage />
                );
              case STATES.PROFILING_COMPLETED:
                return (
                  <CreditProfilingWait
                    titlePage={title}
                    buttonText={buttonFinalizarText}
                    onClick={onFinish}
                  />
                );
              case STATES.PROFILING_EMPTY_MATRIZ:
                return (
                  <CreditProfilingRecuperation />
                );
              case STATES.PROFILING_COTIZATION:
              default:
                return (
                  <TabsProvider>
                    <CreditCotizationAccount
                      createUpdateTag={createUpdateTag}
                      goTo={emptyMatrix}
                      goProfilingCompleted={onProfilingCompleted}
                    />
                  </TabsProvider>
                );
            }
          }
        )()
      }
    </>
  );
};

CotizationInmobiliaria.propTypes = {
  productStrategy: PropTypes.string,
  strategyName: PropTypes.string,
};

CotizationInmobiliaria.defaultProps = {
  strategyName: '',
  productStrategy: '',
};
