import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dropZoneContainer: {
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#fbfbfb',
    borderRadius: '4px',
    border: '1px solid #fbfbfb',
    cursor: 'pointer',
  },
  dropZone: {
    boxSizing: 'border-box',
    width: '100%',
    height: '130px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    boxShadow: 'none',
    backgroundColor: '#fbfbfb',
  },
  iconContainer: {
    marginLeft: '30px ',
    marginRight: '20px ',
  },
  label: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '600',
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    color: '#565656',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
    },
  },
  subTitle: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '600',
    fontSize: '0.875remrem',
    lineHeight: '1rem',
    color: '#2C1482',
  },
}));
