/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import useStyles from './useStyles';

const esPar = (numero) => numero % 2 === 0;

export const InfoSteps = ({
  data,
}) => {
  const classes = useStyles();
  return (
    <Box component="section" className={classes.container}>
      <Box component="div" className={classes.innerContainer}>
        <Box component="div" className={classes.steps}>
          {
            data.map(({
              title,
              body,
            }, index) => (
              <Box
                component="div"
                className={clsx(classes.step, {
                  [classes.stepEven]: esPar(index + 1),
                  [classes.stepOdd]: !esPar(index + 1),
                })}
              >
                <Box component="div" className={classes.stepInner}>
                  <Typography className={classes.title} variant="h2">{title}</Typography>
                  <Typography className={classes.body} variant="body1">{body}</Typography>
                </Box>
              </Box>
            ))
          }
        </Box>
      </Box>
    </Box>
  );
};

InfoSteps.propTypes = {
  data: PropTypes.array,
};

InfoSteps.defaultProps = {
  data: [],
};
