import React, {
  useState, useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container,
} from '@material-ui/core';
import * as nodeAPI from '../../../../../api/node';
import useLoading from '../../../../../hooks/useLoading';
import * as fieldValuesAPI from '../../../../../api/fieldValue';
import { getProcess } from '../../../../../utils/auth';

import useStyles from './useStyles';
import { GroupFields, Loader } from '../../../../../components';
import ErrorMessage from '../../../../../components/ErrorMessage';
import AlertSnack from '../../../../../components/AlertSnack';
import useError from '../../../../../hooks/useError';

import {
  COMPANY,
  CUSTOM_FLOW_CONFIG_TYPE, PERFILAMIENTO, PERFILAMIENTO_CREDITICIO,
} from '../../../../../constants';
import { getAllCompanyConfigs } from '../../../../../api/companyConfigs';
import { FormLayout } from '../../../layouts/FormLayout';
import { useMatrizProfilling } from '../../../hooks/useMatrizProfilling';
import { getNormalizedFlowNameByProcess } from '../../../../../utils/api/process';
import { get1DNodesFields, getNodeDocumentsByFieldsDocuments, obtenerFechaDeNacimiento } from '../../../../../utils/fields';
import { findFields } from '../../../../../utils/commons';
import { getMatrizBody } from '../../../../../utils/matriz';
import useRoute from '../../../../../hooks/useRoute';
import { useProfiling } from '../../../../../hooks/useProfiling';
import { useFieldValues } from '../../../hooks/useFieldValues';
import { fillAsalariadoFields } from '../../../../../utils/profiling';
import { FIELDS_SECTION_PERFILAMIENTO } from '../../../constants/fieldValues';

const getConsultPageConfigByFlow = async (normalizedFlowName, consultPageName) => {
  const companyConfigs = await getAllCompanyConfigs(COMPANY);
  const { payload } = companyConfigs.find(({ type: { name = '' } = {} }) => name === CUSTOM_FLOW_CONFIG_TYPE);
  const allPagesConfig = payload[normalizedFlowName][PERFILAMIENTO_CREDITICIO];
  const pageConfig = allPagesConfig[consultPageName];
  return pageConfig;
};

export const Profiling = ({ goTo, createUpdateTag }) => {
  const { strategy: profilingStrategy } = useProfiling();
  const [pageConfig, setPageConfig] = useState({
    title: '',
    subtitle: '',
    section: '',
    nodes: [],
    buttonTitle: '',
    separateDocsFields: true,
    titleDocsNode: 'Documentos a cargar',
  });

  const [nodeAutos, setNodeAutos] = useState([]);
  const [nodesWithFieldValues, setNodesWithFieldValues] = useState([]);
  const { isLoading, startLoading, endLoading } = useLoading();
  const matrizFields = useRef([]);
  const matrizDefaultFields = useRef([]);

  const {
    execMatrizProfilling,
    formatPerfilamientoFieldValue,
    savePerfilamientoValue,
    fetchMatrizFieldsConfigByFlowName,
    savePerfilamientoSharedFieldValues,
    updateClientConditionsValues,
    fetchLocalCreditData,
    getMatrizBody: getMatrizBodyFunc,
    getCatTasaFieldValues,
    getCreditPropertyFieldValues,
    handleOnPerfilamientoExists,
    handlOnPerfilamientoEmpty,
  } = useMatrizProfilling({
    company: COMPANY,
    fetchFieldsConfigsOnLoad: false,
  });
  const {
    storeLocalFieldValues,
    storeLocalFieldValueBySection,
    formatFromFieldValueModelToUpload,
    fillFieldValuesFromStoredData,
  } = useFieldValues({ company: COMPANY, customSectionName: FIELDS_SECTION_PERFILAMIENTO });
  const {
    navigateWithQueryParam,
  } = useRoute();

  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();

  const classes = useStyles();

  const compareValue = (value, parentValue) => {
    if (Array.isArray(value)) {
      const optionsChequed = value.filter(({ checked }) => checked);
      const valueNames = optionsChequed.map(({ name }) => name);
      return valueNames.includes(parentValue);
    }
    return parentValue === value;
  };

  const getFieldsCheckbox = (fields) => fields.filter(({ fieldType: { name = '' } = {} }) => name === 'lista' || name === 'checkbox');

  const getIdNodesFromFieldsCheckbox = (fieldsCheckbox, nodes) => fieldsCheckbox.reduce((acc, { config = {}, value = '' }) => {
    const { childrenNodes = [], defaultValue = '' } = config;
    const valueToCompare = value || defaultValue;
    const docsToCheck = nodes.filter(({ _id }) => childrenNodes.includes(_id));
    const docsToShow = docsToCheck
      .filter(({ config: { parentValue = '' } = {} }) => compareValue(valueToCompare, parentValue))
      .map(({ _id }) => _id);
    return [...acc, ...docsToShow];
  }, []);

  const clearNodeFieldsValue = (nodes, condition) => nodes.map((node) => {
    if (condition(node)) {
      const newFields = node.fields.map((field) => ({ ...field, value: '' }));
      return { ...node, fields: newFields };
    }
    return node;
  });

  const cleanFieldsInHiddenNodes = (nodes) => {
    if (!nodes || !nodes.length) return [];
    const onlyFields = get1DNodesFields(nodes);
    const fieldsCheckbox = getFieldsCheckbox(onlyFields);
    if (!fieldsCheckbox.length) return nodes;
    const idNodes = getIdNodesFromFieldsCheckbox(fieldsCheckbox, nodes);
    return clearNodeFieldsValue(
      nodes,
      (node) => node.config?.parentValue && !idNodes.includes(node._id),
    );
  };

  const filteredNewNodes = useMemo(() => {
    if (!nodesWithFieldValues || !nodesWithFieldValues.length) return [];
    const onlyFields = get1DNodesFields(nodesWithFieldValues);
    const fieldsCheckbox = getFieldsCheckbox(onlyFields);
    let nodesToReturn = nodesWithFieldValues;
    if (fieldsCheckbox.length) {
      const idNodes = getIdNodesFromFieldsCheckbox(fieldsCheckbox, nodesWithFieldValues);
      nodesToReturn = nodesWithFieldValues.filter(
        ({ _id, config = {} }) => !config?.parentValue || idNodes.includes(_id),
      );
    }
    const newNodes = nodesToReturn.map((node) => {
      const filteredFields = node.fields.filter(({ config: { hide = false } }) => !hide);
      return { ...node, fields: filteredFields };
    });
    if (!pageConfig?.separateDocsFields) return newNodes;
    return getNodeDocumentsByFieldsDocuments(newNodes, pageConfig?.titleDocsNode);
  }, [nodesWithFieldValues]);

  const getUnChekedNodesToEmptyValues = () => nodesWithFieldValues.map((node) => {
    if (!node?.config?.parentValue) return node;
    const foundNode = filteredNewNodes.find(({ _id }) => _id === node._id) || null;
    if (foundNode) return node;
    const { fields } = node;
    const newFields = fields.map((field) => {
      const { config: { defaultValue = '' } = {} } = field;
      return { ...field, value: defaultValue || '' };
    });
    return { ...node, fields: newFields };
  });

  const transformDataToUpload = (_process, filterNodes) => {
    const newFields = filterNodes.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).map(({
      _id, value,
    }) => ({
      field: _id,
      value,
      process: _process,
    }));
    return {
      fields: newFields,
    };
  };

  const execMatrix = async () => {
    const matrizBody = await getMatrizBody(matrizFields.current, matrizDefaultFields.current);
    const result = await execMatrizProfilling(matrizBody);
    return result;
  };

  const submitForm = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      startLoading();
      const processID = getProcess();
      const filterNodes = getUnChekedNodesToEmptyValues();
      const dataToUpload = transformDataToUpload(processID || '', filterNodes);
      await profilingStrategy?.handleSubmit({
        processID,
        fieldValues: dataToUpload?.fields,
        autoNodes: nodeAutos,
        execMatrix,
        fetchLocalCreditData,
        updateClientConditionsValues,
        savePerfilamientoValue,
        savePerfilamientoSharedFieldValues,
        createUpdateTag,
        goTo,
        getMatrizBody: getMatrizBodyFunc,
        execMatrizProfilling,
        formatPerfilamientoFieldValue,
        formatFromFieldValueModelToUpload,
        getCreditPropertyFieldValues,
        getCatTasaFieldValues,
        storeLocalFieldValues,
        navigateWithQueryParam,
        handleOnPerfilamientoExists,
        handlOnPerfilamientoEmpty,
      });
      return endLoading();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
      return endLoading();
    } finally {
      endLoading();
    }
  };

  const isNotCompleted = () => filteredNewNodes.some((node) => {
    const { fields = [] } = node;
    return fields.some(({ config = {}, value = '' }) => {
      const { required = false, hide = false } = config;
      if (!hide && !required) return false;
      if (hide || !required) return false;
      if (config?.childrenNodes && Array.isArray(value)) {
        return !value.some(({ checked }) => checked);
      }
      return value === '' || value === null || !value.length;
    });
  });

  const getFieldValues = async (fields) => {
    const processId = getProcess();
    const theFieldValues = await Promise.all(fields.map(async (field) => {
      if (!processId) return { ...field, value: '' };
      const fieldValue = await fieldValuesAPI.getFieldValueByProcessField(processId, field._id);
      return { ...field, value: fieldValue.value || '' };
    }));
    return theFieldValues;
  };

  const fetchNodesWithFields = async (idNodes) => {
    const nodesData = await Promise.all(idNodes.map(async (node, index) => {
      const nodeData = await nodeAPI.getNodeById(node);
      const { name = '', config } = nodeData;
      const nodeTitle = index === 0 ? '' : name;
      const fields = await nodeAPI.getFieldsByNodeId(node);
      return {
        _id: node, fieldsData: fields, name: nodeTitle, config,
      };
    }));
    return nodesData;
  };

  const convert1Darray = (fields) => fields.reduce((acc, curr) => {
    const { fieldsData } = curr;
    return [...acc, ...fieldsData];
  }, []);

  const changeHideChildrens = (field, fields) => {
    if (!field) return fields;
    const children = field?.config?.children || [];
    const updatedFields = fields.map((_field) => {
      if (children.includes(_field._id)) {
        const parentValue = field.value;
        const hide = !compareValue(parentValue, _field?.config?.parentValue);
        return {
          ..._field,
          config: {
            ..._field.config,
            hide,
            required: !hide
              ? _field?.config?.hideRequired
              : _field.config.required,
          },
        };
      }
      return _field;
    });
    return updatedFields;
  };

  const setDateFilled = async (name, value, newNodes) => {
    if (name === 'rfcField' && value) {
      const [{ value: dateValue }] = findFields([name], newNodes);
      try {
        startLoading();
        const newNN = await obtenerFechaDeNacimiento(dateValue, newNodes);
        setNodesWithFieldValues(newNN);
        endLoading();
      } catch (e) {
        setNodesWithFieldValues(newNodes);
        endLoading();
      }
    } else {
      setNodesWithFieldValues(newNodes);
    }
  };

  const handleChange = (event, value, position, nameEvent) => {
    const { name = '' } = event.target || {};
    const newName = nameEvent || name;
    const newNodes = nodesWithFieldValues.map((node) => {
      const newFields = node.fields.map(
        (field) => {
          if (field.name === newName) {
            const newFieldValue = { ...field, value };
            storeLocalFieldValueBySection(newFieldValue);
            return newFieldValue;
          }
          return field;
        },
      );
      const fieldEditted = newFields.find((field) => field.name === newName);
      const fieldsUnhide = changeHideChildrens(fieldEditted, newFields);
      return { ...node, fields: fieldsUnhide };
    });
    const nodesWithCleanedFields = cleanFieldsInHiddenNodes(newNodes);
    setNodesWithFieldValues(nodesWithCleanedFields);
    setDateFilled(name, value, nodesWithCleanedFields);
  };

  const formatNodesWithFieldValues = (_nodes, _fieldValues) => {
    const newNodes = _nodes.map((node) => {
      const newFields = node.fieldsData.map((_field) => {
        const { name: fieldValueName } = _field;
        const {
          _id, fieldCatalog = {}, config, value = '', fieldType,
        } = _fieldValues.find(({ name }) => name === fieldValueName);
        return {
          ..._field, _id, fieldCatalog, config, value, fieldType,
        };
      });
      return { ...node, fields: newFields };
    });
    return newNodes;
  };

  const fetchAutoNodes = async (autoExecutableNodes) => {
    const autoNodes = await Promise.all(
      autoExecutableNodes.map((nodeAutoExe) => nodeAPI.getNodeById(nodeAutoExe)),
    );
    setNodeAutos(autoNodes);
  };

  const fillFieldsWithValues = async (arrayFields) => {
    const fieldsWithValues = await getFieldValues(arrayFields);
    let filledFields = fillAsalariadoFields(fieldsWithValues);
    filledFields = await Promise.all(filledFields.map(fillFieldValuesFromStoredData));
    return filledFields;
  };

  const fetchData = async () => {
    startLoading();
    try {
      const normalizedFlowName = await getNormalizedFlowNameByProcess(getProcess());
      const _pageConfig = await getConsultPageConfigByFlow(
        normalizedFlowName,
        PERFILAMIENTO,
      );
      const {
        profillingFields,
        defaultProfillingFieldValues = [],
      } = await fetchMatrizFieldsConfigByFlowName();
      matrizFields.current = profillingFields;
      matrizDefaultFields.current = defaultProfillingFieldValues;
      const { nodes: idNodes, autonodes = [] } = _pageConfig;
      await fetchAutoNodes(autonodes);
      const nodesWithFields = await fetchNodesWithFields(idNodes);
      const fieldsFlat = convert1Darray(nodesWithFields);
      const fieldsWithValues = await fillFieldsWithValues(fieldsFlat);
      const newNodes = formatNodesWithFieldValues(nodesWithFields, fieldsWithValues);
      setPageConfig({ ...pageConfig, ..._pageConfig });
      setNodesWithFieldValues(newNodes);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to get the data');
      console.log(e);
    } finally {
      endLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <AlertSnack isOpen={isError} type="error" onClose={hideError}>
        <ErrorMessage errorType={errorType} errorMessage={errorMessage} />
      </AlertSnack>
      <Loader open={isLoading} />
      <FormLayout
        submit={submitForm}
        title={pageConfig.title}
        subTitle={pageConfig.subtitle}
        section={pageConfig.section}
        disabled={isNotCompleted()}
        className={profilingStrategy?.getTitleClassName(classes) || ''}
        titleContainerClass={classes.titleContainer}
        renderButton={profilingStrategy?.renderButton({
          classes,
          title: pageConfig.buttonTitle,
          disabled: isNotCompleted(),
        })}
      >
        <Container
          component="section"
          maxWidth="md"
          className={classes.container}
        >
          {
            filteredNewNodes.map(({ fields, name: titleNode }, index) => (
              <Container
                key={titleNode}
                component="section"
                maxWidth={false}
                disableGutters
                className={classes.containerFields}
              >
                <GroupFields
                  fields={fields}
                  title={titleNode}
                  position={index}
                  onChange={handleChange}
                  customTitleClass={classes.customFieldsTitle}
                />
              </Container>
            ))
          }
        </Container>
      </FormLayout>
    </>
  );
};

Profiling.propTypes = {
  goTo: PropTypes.func.isRequired,
  createUpdateTag: PropTypes.func.isRequired,
};
