import { convertirANumero } from './currency';

export const findInputInFormByFieldName = (formRef, fieldName) => {
  const input = formRef.querySelector(`[name="${fieldName}"]`);
  return input;
};

export const isInputValueGreaterThanAmount = (form, fieldName, amount) => {
  const input = findInputInFormByFieldName(form, fieldName);
  const { value = '' } = input;
  const valueNumber = convertirANumero(value);
  return valueNumber > amount;
};

export const reportFormCustomValidity = (form, fieldName, validityMessage) => {
  const input = findInputInFormByFieldName(form, fieldName);
  if (!input) return;
  input.focus();
  input.select();
  input.setCustomValidity(validityMessage);
  form.reportValidity();
};
