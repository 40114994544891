import React, { useState } from 'react';

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { LockOutlined, Menu } from '@material-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';

import useStyles from './useStyles';
import { Sidebar } from '../Sidebar';
import logoUrl from '../../../../assets/images/chiplo.svg';
import { LOGIN_ROUTE } from '../../../../constants';
import { Button } from '../../../../components';

export const HeaderBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const toggleSidebar = () => {
    setOpen(!open);
  };
  const sideBarOptions = [
    {
      label: 'Ingresar',
      type: 'element',
      icon: <LockOutlined style={{ color: 'white' }} />,
      action: async () => {
        navigate(LOGIN_ROUTE);
      },
    },
    {
      label: 'Cotizar',
      type: 'navHashLink',
      icon: <LockOutlined style={{ color: 'white' }} />,
      route: `${pathname}#cotizador`,
      action: async () => { },
    },
  ];
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Box className={classes.boxLogo}>
          <img
            className={classes.imgLogo}
            src={logoUrl}
            alt="imgLogo"
          />
        </Box>
        <Box className={classes.boxItems}>
          <Box className={classes.btnSidebar}>
            <IconButton onClick={toggleSidebar}>
              <Menu />
            </IconButton>
          </Box>
          <Box className={classes.boxButtons}>
            <Button
              button
              onClick={() => {
                navigate(LOGIN_ROUTE);
              }}
              variant="outlined"
              className={classes.btnSign}
            >
              Ingresar
            </Button>
          </Box>
        </Box>
        <Sidebar open={open} toggleSidebar={toggleSidebar} options={sideBarOptions} />
      </Toolbar>
    </AppBar>
  );
};
