import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#fff',
    marginBottom: '5px',
    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.11)',
    [theme.breakpoints.up('sm')]: {
      boxShadow: 'none',
      backgroundColor: '#f5f5f5',
    },
  },
  imgLogo: {
    width: '220px',
    [theme.breakpoints.down('md')]: {
      width: '150px',
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '110px',
      height: '90px',
    },
  },
  labelRoute: {
    color: '#000F8F',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.125rem',
    textDecoration: 'none',
    paddingRight: '10%',
    whiteSpace: 'nowrap',
    fontFamily: 'Open Sans',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row-reverse',
  },
  boxMenu: {
  },
  boxButtons: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  btnSign: {
    color: '#000F8F',
    borderRadius: '40px',
    borderColor: '#000F8F',
    margin: '0 5%',
    fontSize: '15px',
    fontWeight: 500,
    width: '170px',
    padding: '0.55rem 2.5rem',
    cursor: 'pointer',
    fontFamily: 'Open Sans',
  },
  btnRegister: {
    backgroundColor: '#000F8F',
    borderRadius: '6px',
    width: '170px',
    fontSize: '15px',
    color: '#FFFFFF',
    padding: '0.6875rem 1rem',
    textTransform: 'initial',
    fontFamily: 'Open Sans',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    textAlign: 'center',
  },
  boxLogo: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },

  },
  boxLogoMovil: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  btnSidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },

  },
  boxLogoNavigate: {
    cursor: 'pointer',
  },
}));
