import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  Legal,
} from '../pages';
import Landing from '../../landing';
import { Ipsum } from '../pages/Ipsum';
import { FAQPage } from '../pages/FAQPage';
import { RecoverProcess } from '../pages/RecoverProcess';
import { RecoverClientProcess } from '../pages/RecoverClientProcess';
import { AGENTE_LOGIN_ROUTE, ONLY_CLIENTS_ROUTE_TYPE } from '../../../constants';
import { ProtectedRoute } from '../../../components';
import { TabsProvider } from '../../../context/tabs/tabsProvider';
import { LandingProvider } from '../../../context/landing';

const ChiploRoutes = () => (
  <Routes>
    <Route
      path="/legal"
      element={(
        <Legal />
      )}
    />
    <Route
      path="/ipsum"
      element={(
        <Ipsum />
      )}
    />
    <Route
      path="/recuperacion/lead/:leadId"
      element={(
        <ProtectedRoute
          type={ONLY_CLIENTS_ROUTE_TYPE}
          redirectPath={AGENTE_LOGIN_ROUTE}
          additionalData={{ authProtected: false }}
        >
          <RecoverProcess />
        </ProtectedRoute>
      )}
    />
    <Route
      path="/recuperacion/perfilamiento"
      element={(
        <ProtectedRoute
          type={ONLY_CLIENTS_ROUTE_TYPE}
          redirectPath={AGENTE_LOGIN_ROUTE}
          additionalData={{ authProtected: false }}
        >
          <RecoverClientProcess />
        </ProtectedRoute>
      )}
    />
    <Route
      path="/faq"
      element={(
        <FAQPage />
      )}
    />
    <Route
      path="/"
      element={(
        <LandingProvider>
          <TabsProvider>
            <Landing />
          </TabsProvider>
        </LandingProvider>
      )}
    />
    <Route path="/*" element={<Navigate to="/" />} />
  </Routes>
);

export default ChiploRoutes;
