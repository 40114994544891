import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  tabs: {
    width: '100%',
  },
  tab: {
    flex: 1,
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: '400',
    backgroundColor: '#ECECEC',
    color: '#373737',
    paddingTop: '12px',
    textTransform: 'none',
    paddingBottom: '12px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '36px',
    },
  },
  selected: {
    backgroundColor: '#2C1482',
    color: '#FFF',
  },
}));
