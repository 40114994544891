import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  childContainer: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  helpIcon: {
    fontSize: '40px',
    color: '#176BCD',
    float: true,
  },
  boxContainer: {
    position: 'absolute',
    right: '0%',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: '0%',
    },
  },
  textContent: {
    paddingBottom: theme.spacing(1),
  },
}));
