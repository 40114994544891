import * as flowAPI from '../api/flow';
import * as productAPI from '../api/product';
import * as processAPI from '../api/process';
import { COMPANY, EMPLOYEE_TYPE_FIELD_NAME, SUELDO_MENSUAL_FIELD_NAME } from '../constants';
import { getIgresosNumber } from './credit';
import { convertirAMoneda } from './currency';

export const normalizeText = (text) => text
  .normalize('NFD')
  .replace(/\p{Diacritic}/gu, '')
  .toLowerCase()
  .replace(/ /g, '');

export const getFlow = async (flowId, token = '') => {
  const flowFound = await flowAPI.getFlowById(flowId, token);
  const normalizedName = normalizeText(flowFound.name);
  return { flow: flowFound, normalizedFlowName: normalizedName };
};

export const fetchProduct = async (normalizedFlowName) => {
  const newProduct = await productAPI
    .getFlowAndProductByCompanyIdAndProductName(COMPANY, normalizedFlowName);
  return newProduct;
};

export const createProcess = async (client, product, flowId) => {
  const dataToUpload = {
    flow: flowId,
    client,
    fields: [],
    product,
  };
  return processAPI.createProcess(dataToUpload, COMPANY);
};

export const createProcessWithOutProduct = async (client, flowId) => {
  const dataToUpload = {
    flow: flowId,
    client,
    fields: [],
  };
  return processAPI.createProcess(dataToUpload, COMPANY);
};

export const fillAsalariadoFields = (arrayFields) => {
  const ingresosNumber = getIgresosNumber();
  if (!ingresosNumber) return arrayFields;
  return arrayFields.map((field) => {
    const { name } = field;
    if (name === EMPLOYEE_TYPE_FIELD_NAME) {
      return {
        ...field,
        value: [{
          name: 'Asalariado',
          checked: true,
        }, {
          name: 'Independiente',
          checked: false,
        }, {
          name: 'Socio Accionista',
          checked: false,
        }],
      };
    }
    if (name === SUELDO_MENSUAL_FIELD_NAME) {
      return {
        ...field,
        value: convertirAMoneda(ingresosNumber),
      };
    }
    return field;
  });
};
