import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: '#f5f5f5',
  },
  innerContainer: {
    width: '95%',
    margin: '0 auto',
    paddingTop: '18px',
    paddingBottom: '18px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '120px',
      paddingBottom: '120px',
    },
  },
  steps: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  stepEven: {
    backgroundColor: '#E2E7F1',
    margin: '22px 4px',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
    },
  },
  stepOdd: {
    backgroundColor: '#FFF',
    margin: '18px 4px',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
    },
  },
  step: {
    padding: '48px 36px 10px 36px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      flex: 1,
      minHeight: '230px',
    },
  },
  stepInner: {},
  title: {
    color: '#06040A',
    fontFamily: 'Lato, sans-serif',
    fontSize: '24px',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      fontSize: '26px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '30px',
    },
  },
  body: {
    color: '#171D25',
    fontFamily: 'Lato, sans-serif',
    fontSize: '18px',
    fontWeight: '300',
    marginTop: '16px',
  },
}));
