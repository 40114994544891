import { createInstance } from './config';

const BASE_URL = '/googleApis';

// eslint-disable-next-line import/prefer-default-export
export const getPlaces = async (query) => {
  const axios = await createInstance();

  const url = `${BASE_URL}/places`;
  const queryParams = `query=${query}`;

  const { data } = await axios.get(`${url}?${queryParams}`);
  return data;
};

export const getAddressData = async (postalCode) => {
  const axios = await createInstance();

  const url = `${BASE_URL}/geocoding`;
  const queryParams = `postalCode=${postalCode}`;

  const { data } = await axios.get(`${url}?${queryParams}`);
  return data;
};
