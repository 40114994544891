/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const fieldValuesSlice = createSlice({
  name: 'fieldValues',
  initialState: {
    fieldValues: [],
    localFieldValues: [],
    localFieldValuesSections: {},
  },
  reducers: {
    setInitialFieldValues: (state) => {
      state.fieldValues = [];
      state.localFieldValues = [];
      state.localFieldValuesSections = {};
    },
    setFieldValues: (state, action) => {
      state.fieldValues = action.payload;
    },
    setLocalFieldValues: (state, action) => {
      state.localFieldValues = action.payload;
    },
    setLocalFieldValuesSections: (state, action) => {
      state.localFieldValuesSections = action.payload;
    },
    addNewLocalFieldValue: (state, action) => {
      state.localFieldValues = [...state.localFieldValues, action.payload];
    },
    addFieldValueLocalSection: (state, action) => {
      const {
        section, currentSection, field, value,
      } = action.payload;
      state.localFieldValuesSections = {
        ...state.localFieldValuesSections,
        [section]: [
          ...currentSection,
          { field, value },
        ],
      };
    },
    updateFieldValueOnLocalSection: (state, action) => {
      const {
        field, value, section, currentSection,
      } = action.payload;

      state.localFieldValuesSections = {
        ...state.localFieldValuesSections,
        [section]: currentSection.map(
          (fieldValue) => {
            if (fieldValue.field === field) {
              return {
                ...fieldValue,
                value,
              };
            }
            return fieldValue;
          },
        ),
      };
    },

    updateLocalFieldValueByFieldId: (state, action) => {
      const { field, value, name = '' } = action.payload;
      state.localFieldValues = state.localFieldValues.map((fieldValue) => {
        if (fieldValue.field === field) {
          fieldValue.value = value;
          fieldValue.name = fieldValue.name || name;
        }
        return fieldValue;
      });
    },
    updateFieldValueByFieldId: (state, action) => {
      const { field, value, name = '' } = action.payload;
      state.fieldValues = state.fieldValues.map((fieldValue) => {
        if (fieldValue.field === field) {
          fieldValue.value = value;
          fieldValue.name = fieldValue.name || name;
        }
        return fieldValue;
      });
    },
  },
});

export const {
  setFieldValues,
  setInitialFieldValues,
  setLocalFieldValues,
  setLocalFieldValuesSections,
  addNewLocalFieldValue,
  addFieldValueLocalSection,
  updateFieldValueByFieldId,
  updateLocalFieldValueByFieldId,
  updateFieldValueOnLocalSection,
} = fieldValuesSlice.actions;
