import { useEffect } from 'react';

const useSessionRewind = (props) => {
  useEffect(() => {
    const loadScript = () => {
      const w = window;
      w.SessionRewindConfig = props;
      const script = document.createElement('script');
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.src = 'https://rec.sessionrewind.com/srloader.js';

      const head = document.getElementsByTagName('head')[0];
      head.insertBefore(script, head.firstChild);
    };
    if (props.environment === 'prod') loadScript();
  }, []);

  return null; // O cualquier cosa que desees renderizar
};

export default useSessionRewind;
