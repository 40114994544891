/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import clsx from 'clsx';
import styles from './useStyles';

const InputCheckbox = ({
  label,
  value,
  options,
  onChange,
  name,
  className,
  disabled,
}) => {
  const classes = styles();
  const [state, setState] = React.useState({});

  const handleChange = (event) => {
    const { name: optionName, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [optionName]: checked,
    }));
    const formatValue = Array.isArray(value) ? value.map((valueItem) => {
      const { name: nameValueItem } = valueItem;
      if (nameValueItem === optionName) return { name: nameValueItem, checked };
      return valueItem;
    }) : options.map((option) => {
      const { name: nameOption } = option;
      if (nameOption === optionName) return { name: nameOption, checked };
      return { name: nameOption, checked: false };
    });
    onChange(event, formatValue);
  };

  const renderOptions = options.map((option) => (
    <FormControlLabel
      key={option.name}
      classes={{
        label: classes.controlLabel,
      }}
      disabled={disabled}
      control={(
        <Checkbox
          checked={state[option.name] || false}
          onChange={handleChange}
          name={option.name}
        />
      )}
      label={option.label || option.name}
    />
  ));

  useEffect(() => {
    if (value && Array.isArray(value)) {
      const updatedState = value.reduce((acc, option) => {
        acc[option.name] = option.checked;
        return acc;
      }, {});
      setState(updatedState);
    }
  }, [value]);

  return (
    <>
      <FormLabel component="legend" className={classes.label}>{label}</FormLabel>
      <FormGroup
        className={clsx(classes.container, {
          [className]: className,
        })}
      >
        {renderOptions}
      </FormGroup>
    </>
  );
};

InputCheckbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

InputCheckbox.defaultProps = {
  label: '',
  value: [],
  options: [],
  className: '',
  onChange: () => { },
  name: '',
  disabled: false,
};

export default memo(InputCheckbox);
