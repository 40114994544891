import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

export const CustomStepContent = ({
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {children}
      </div>
    </div>
  );
};

CustomStepContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

CustomStepContent.defaultProps = {
  children: '',
};
