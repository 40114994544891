/* eslint-disable arrow-body-style */
import React from 'react';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

export const SideBannerTemplate = ({ logoUrl, mainImageUrl }) => {
  const classes = useStyles();
  return (
    <Container
      component="section"
      maxWidth={false}
      className={classes.container}
    >
      <div className={classes.innerContainer}>
        <div
          id="logo-container"
          className={classes.logoContainer}
        >
          <img
            src={logoUrl}
            className={classes.logo}
            id="logo"
            alt="logo"
          />
        </div>
        <div
          id="image-container"
          className={classes.imageContainer}
        >
          <img
            src={mainImageUrl}
            className={classes.imageTemplate}
            id="image-template"
            alt="template"
          />
        </div>
      </div>
    </Container>
  );
};

SideBannerTemplate.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  mainImageUrl: PropTypes.string.isRequired,
};
