/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import {
  Box, Button, Container, Typography,
} from '@material-ui/core';

import { Banks } from '../../../../components/Banks';
import { SimpleModal } from '../../../../components/Modal';
import { Simulator } from '../../../../components/Simulator';
import { NormalLayout } from '../../layouts';

import { getConfigs } from '../../../../utils/configs';
import {
  getEnganche, getImporte,
  getPlazo, getPropietyValue, getYear,
} from '../../../../utils/credit';
import { getConsultPageConfigByFlow } from '../../../../utils/commons';
import * as googleAnalytics from '../../../../utils/googleAnalytics/events';
import { getAndSetStrategyByName } from '../../../../utils/strategies.utils';
import { replacingValuesOnString } from '../../utils/replacingValuesInString';
import { currencyFormatter, getMonthlyPaid, storeStepData } from '../../../../utils';

import { StepperContext } from '../../../../context/stepper';

import useError from '../../../../hooks/useError';
import useLoading from '../../../../hooks/useLoading';
import { useCotizationPreview } from '../../../../hooks/useCotizationPreview';
import useRoute from '../../../../hooks/useRoute';

import useStyles from './useStyles';

import { COTIZACION_PREVIEW_VIVIENDA, COTIZATION_PREVIEW_STRATEGY, CREDITO_HIPOTECARIO_VIVIENDA } from '../../../../constants';
import { getFlowName } from '../../../../utils/flows';

const BANCOS = {
  Afirme: 'AFORO 90%',
  Citibanamex: 'Hipoteca Perfiles Aforo Mayor 80',
  Banorte: 'Hipoteca Fuerte',
  Banregio: 'Hipoteca Naranja',
  HSBC: 'Hipoteca Premier',
  Santander: 'Hipoteca Santander',
  Scotiabank: 'Pagos Oportunos',
};

export const CotizacionPreview = ({ productStrategy }) => {
  const { cotizationPreviewStrategy, strategyLoaded, changeStrategy } = useCotizationPreview();
  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();
  const {
    navigateWithQueryParam,
  } = useRoute();
  const [banks, setBanks] = useState([]);
  const [showModalCredit, setShowModalCredit] = useState(false);

  const { updateStateStepper, state: { steps, current } } = useContext(StepperContext);

  const [pagesData, setPagesData] = useState({
    title: '',
    infoTitle: '',
    buttonTitle: '',
    buttonFooterTitle: '',
    subtitle: '',
  });
  const { isLoading, startLoading, endLoading } = useLoading();
  const classes = useStyles();

  const handleEditConditions = () => {
    window.location.reload();
  };

  const onSubmit = async () => {
    try {
      startLoading();
      updateStateStepper(true);
      googleAnalytics.cotizacionBancos();
      endLoading();
    } catch (e) {
      showError(e.message);
      endLoading();
    }
  };

  const sortByTasa = (bankss) => bankss.sort((bankA, bankB) => bankA.tasa - bankB.tasa);

  const fetchTasas = async () => {
    const tasas = await getConfigs('64d3ecd9067071a24e92550e');
    const bancos = await getConfigs('64c191e62432371dcd8f103b');
    const importe = getImporte();
    const enganche = getEnganche();
    const propietyValue = getPropietyValue();
    const year = getYear();
    const calcData = {
      banks: bancos,
      listBanks: BANCOS,
      tasas,
      year,
      importe,
      enganche,
      propietyValue,
    };
    const bancosValues = cotizationPreviewStrategy.getBanksValues(calcData);
    setBanks(sortByTasa(bancosValues));
  };

  const fetchData = async () => {
    try {
      startLoading();
      const flowName = getFlowName();
      const {
        title, infoTitle, footerText, buttonTitle, subtitle = '',
        buttonFooterTitle,
      } = await getConsultPageConfigByFlow(
        flowName,
        COTIZACION_PREVIEW_VIVIENDA,
        COTIZACION_PREVIEW_VIVIENDA,
      );
      await fetchTasas();
      setPagesData({
        title,
        infoTitle: cotizationPreviewStrategy.getInfoTitle({ infoTitle }),
        footerText,
        buttonTitle,
        buttonFooterTitle,
        subtitle,
      });
      endLoading();
    } catch (e) {
      endLoading();
    }
  };

  useEffect(() => {
    getAndSetStrategyByName(COTIZATION_PREVIEW_STRATEGY, productStrategy, changeStrategy);
  }, []);

  useEffect(() => {
    if (strategyLoaded) {
      fetchData();
    }
  }, [strategyLoaded]);

  useEffect(() => {
    if (steps[current].completed) {
      storeStepData({ stepRoute: steps[current + 1].route, currentStep: current + 1, steps });
      navigateWithQueryParam(steps[current + 1].route, 'inmobiliaria');
    }
  }, [steps, current]);

  return (
    <NormalLayout
      submit={onSubmit}
      isLoading={isLoading}
      isError={isError}
      hideError={hideError}
      errorType={errorType}
      errorMessage={errorMessage}
      data={pagesData}
      classesNames={{
        infoTitle: classes.infoTitle,
        container: classes.formContainer,
      }}
      renderButton={() => (
        <>
          <Typography className={classes.footerText}>{pagesData.footerText}</Typography>
          <Box
            component="div"
            className={classes.arrowContainer}
          >
            <ArrowDownwardIcon className={classes.arrow} fontSize="large" />
          </Box>
          <Container
            className={classes.buttonContainer}
          >
            <Button
              className={classes.button}
              type="submit"
            >
              {pagesData.buttonTitle}
            </Button>
          </Container>
          <Typography
            variant="body2"
            className={classes.buttonFooterTitle}
          >
            {pagesData.buttonFooterTitle}
          </Typography>
        </>
      )}
    >
      <div className={classes.container}>
        <SimpleModal
          onCancel={() => setShowModalCredit(false)}
          showModal={showModalCredit}
          title="Edita las condiciones de tu crédito"
        >
          <Simulator
            startLoading={startLoading}
            endLoading={endLoading}
            onSubmitClick={handleEditConditions}
          />
        </SimpleModal>
        <Container
          className={classes.FactorajeDataContainer}
          disableGutters
          maxWidth={false}
        >
          <div onClick={() => setShowModalCredit(true)} role="button" tabIndex={0} onKeyDown={() => setShowModalCredit(true)}>
            <Typography component="h2" className={classes.componentTitleSimulator}>Editar condiciones</Typography>
          </div>
          {
            cotizationPreviewStrategy?.renderSubtitle({ ...pagesData, classes })
          }
          <Box className={classes.containerBanks}>
            <Banks initialBanks={banks} />
          </Box>
        </Container>
      </div>
    </NormalLayout>
  );
};

CotizacionPreview.propTypes = {
  productStrategy: PropTypes.string,
};

CotizacionPreview.defaultProps = {
  productStrategy: '',
};
