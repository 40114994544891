import PropTypes from 'prop-types';
import { ContainerConditionAmmount } from '../ContainerConditionAmmount';

export const ConditionAmmountList = ({ items, customTitleClassName, customValueClassName }) => (
  <>
    {items.map((item) => (
      <ContainerConditionAmmount
        key={item.label}
        label={item.label}
        ammountNumber={item.ammountNumber}
        customTitleClassName={customTitleClassName}
        customValueClassName={customValueClassName}
      />
    ))}
  </>
);

ConditionAmmountList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      ammountNumber: PropTypes.number,
    }),
  ).isRequired,
  customTitleClassName: PropTypes.string,
  customValueClassName: PropTypes.string,
};

ConditionAmmountList.defaultProps = {
  customTitleClassName: '',
  customValueClassName: '',
};
