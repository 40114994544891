import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: theme.spacing(2),
    backgroundColor: '#F7FAFD',
    width: '100%',
  },
  imageContent: {
    display: 'flex',
    padding: '20px 0px',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    maxHeight: '24px',
    maxWidth: '140px',
  },
  dataContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  propContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  propTitle: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    color: '#2C2C2C',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.30rem',
      lineHeight: '1.6rem',
    },
  },
  propValue: {
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
    maxWidth: '150px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.30rem',
      lineHeight: '1.6rem',
      maxWidth: 'unset',
    },
  },
}));
