/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const nodesFieldsSlice = createSlice({
  name: 'nodesFields',
  initialState: {
    nodesFields: [],
    hiddenNodes: [],
    activeNode: null,
    nodesLoaded: false,
    nodesToShow: [],
    fieldsIndexed: {},
  },
  reducers: {
    setNodesFields: (state, action) => {
      state.nodesFields = action.payload?.nodesFields || [];
      state.fieldsIndexed = action.payload?.fieldsIndexed || {};
    },
    setHiddenNodes: (state, action) => {
      state.hiddenNodes = action.payload;
    },
    setActiveNode: (state, action) => {
      state.activeNode = action.payload;
    },
    setNodesToShow: (state, action) => {
      state.nodesToShow = action.payload;
    },
    setNodesLoaded: (state, action) => {
      state.nodesLoaded = action.payload;
    },
    updateNodeByIndex: (state, action) => {
      const { index, node } = action.payload;
      state.nodesFields[index] = node;
    },
    updateNodesToShowByIndex: (state, action) => {
      const { index, node } = action.payload;
      state.nodesToShow[index] = node;
    },
    addNodesToNodesFields: (state, action) => {
      state.nodesFields = [...state.nodesFields, ...action.payload];
    },
    removeNodeByIndex: (state, action) => {
      const { index } = action.payload;
      state.nodesFields.splice(index, 1);
    },
  },
});

export const {
  setNodesFields,
  setHiddenNodes,
  setNodesToShow,
  setActiveNode,
  setNodesLoaded,
  updateNodeByIndex,
  addNodesToNodesFields,
  updateNodesToShowByIndex,
} = nodesFieldsSlice.actions;
