const haveSubCategories = (option) => (
  option
  && option.children
  && option.children.length > 0
);

// eslint-disable-next-line import/prefer-default-export
export const optionsFormatter = (options = [], currentOptions = [], parentName = '') => {
  options.forEach((option, index) => {
    const { name, children } = option;

    const info = {
      id: `${parentName}_${name}_${index}`,
      name,
      value: parentName ? `${parentName} - ${name}` : name,
      disabled: true,
    };

    if (haveSubCategories(option)) {
      currentOptions.push(info);
      // eslint-disable-next-line no-unused-vars
      optionsFormatter(children, currentOptions, name);
    } else currentOptions.push({ ...info, disabled: false });
  });
};
