import {
  COTIZATION_PREVIEW_STRATEGY,
  CREATE_ACCOUNT_STRATEGY,
  CREDIT_PROFILING_COTIZATION_STRATEGY,
  CREDIT_PROFILING_STRATEGY,
} from '../constants';

import * as ProfilingStrategies from '../strategies/profilingStrategies';
import * as CreateAccountStrategies from '../strategies/createAccountStrategies';
import * as CreditProfilingCotizationStrategies from '../strategies/creditProfilingCotizationStrategies';
import * as CotizationPreviewStrategies from '../strategies/cotizationPreviewStrategies';

export const getStrategiesByName = (strategyName) => {
  switch (strategyName) {
    case CREDIT_PROFILING_STRATEGY:
      return ProfilingStrategies;
    case CREDIT_PROFILING_COTIZATION_STRATEGY:
      return CreditProfilingCotizationStrategies;
    case CREATE_ACCOUNT_STRATEGY:
      return CreateAccountStrategies;
    case COTIZATION_PREVIEW_STRATEGY:
      return CotizationPreviewStrategies;
    default:
      return null;
  }
};

export const getStrategyByType = (strategies, productStrategy) => strategies[productStrategy];

export const getAndSetStrategyByName = (strategyName, productStrategy, handleSetStrategy) => {
  const strategies = getStrategiesByName(strategyName);
  const firstStrategyKey = Object.keys(strategies)[0];

  const strategy = getStrategyByType(strategies, productStrategy);
  return strategy !== null && strategy !== undefined
    ? handleSetStrategy(strategy)
    : handleSetStrategy(strategies[firstStrategyKey]);
};
