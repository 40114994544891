import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  InputLabel: {
    fontSize: '16px',
  },

  Select: {
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #B9D4FF',
  },

  InputLabelPropsFocused: {
    color: '#7C839D !important',
  },

  // RedditTextField Reference
  InputPropsRoot: {
    border: '1px solid #FFFFFF',
    borderRadius: '10px !important',
    backgroundColor: '#FFFFFF !important',
    overflow: 'hidden',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },

  InputPropsDisabled: {
    // backgroundColor: '#e0e0e0 !important',
    borderColor: '#e0e0e0',
    '&:hover': {
      borderColor: '#e0e0e0',
    },
  },

  InputPropsFocused: {
    backgroundColor: '#fff !important',
    borderColor: theme.palette.primary.main,
  },
  error: {
    border: '1px solid #f44336',
  },
  hide: {
    display: 'none',
  },
}));
