/* eslint-disable react/forbid-prop-types */
import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Fab,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';

export const FloatingActionButton = ({
  options,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (func) => {
    setAnchorEl(null);
    func('Clickeado');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Fab
        size="small"
        onClick={handleClickListItem}
        color="primary"
        aria-label="add"
      >
        <Add />
      </Fab>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map(({
          label,
          id,
          func,
        }) => (
          <MenuItem
            key={id}
            onClick={() => handleMenuItemClick(func)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

FloatingActionButton.propTypes = {
  options: PropTypes.array,
};

FloatingActionButton.defaultProps = {
  options: [],
};
