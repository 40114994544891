/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import {
  Box, Grid, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import { getLogoAndImageTemplate } from '../../../../utils';
import { IMAGE_HEADER } from '../../../../constants/commonUrls';

export const AuthLayout = ({
  product,
  children,
}) => {
  const classes = useStyles();

  const {
    mainImageUrl,
  } = useMemo(() => getLogoAndImageTemplate(product), []);
  return (
    <Grid container className={classes.gridContainer}>
      <Grid item md={6} sm={0} xs={0} className={classes.gridItemLeft}>
        <Box className={classes.innerContainer}>
          <Box className={classes.information}>
            <Typography variant="h4" className={classes.title}>
              Bienvenido
            </Typography>
            <Typography variant="body1" className={classes.body}>
              Ingresa para obtener tu crédito hipotecario
            </Typography>
          </Box>
          <Box className={classes.imgContainer}>
            <img
              className={classes.imgMain}
              src={IMAGE_HEADER}
              alt="imgMain"
            />
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} sm={12} xs={12} className={classes.gridItemRight}>
        {children}
      </Grid>
    </Grid>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  product: PropTypes.string.isRequired,
};
