import { decryptData, encryptData } from './encryption';

const CREDIT_PROCESS_KEY = btoa('CREDIT_PROCESS_DATA');

export const storeCreditProcess = async (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(CREDIT_PROCESS_KEY, encryptedData);
};

export const removeCreditProcess = () => {
  window.localStorage.removeItem(CREDIT_PROCESS_KEY);
};

export const getCreditProcess = () => {
  const jsonDataEncrypted = window.localStorage.getItem(CREDIT_PROCESS_KEY);
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

export const saveExtraDataCreditProcess = (extraData) => {
  const creditData = getCreditProcess();
  storeCreditProcess({
    ...creditData,
    ...extraData,
  });
};

const getItem = (key) => {
  try {
    const creditProcess = getCreditProcess();
    return creditProcess[key];
  } catch {
    return undefined;
  }
};

export const getCurrentProcess = () => {
  const currentProcess = getItem('currentProcess');
  return currentProcess;
};

export const getProcesses = () => {
  const processes = getItem('processes');
  return processes;
};

export const getTitle = () => {
  const title = getItem('title');
  return title;
};
