import { decryptData, encryptData } from '../utils/encryption';

const AGENT_DATA_KEY = btoa('ENTITY_DATA');

export const storeEntityData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(AGENT_DATA_KEY, encryptedData);
};

export const removeLocalEntityData = () => {
  window.localStorage.removeItem(AGENT_DATA_KEY);
};

export const getLocalEntityData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(AGENT_DATA_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

export const storeExtraEntityData = (data) => {
  const currentData = getLocalEntityData();
  const newData = { ...currentData, ...data };
  storeEntityData(newData);
};

const getItem = (key) => {
  try {
    const agentData = getLocalEntityData();
    return agentData[key];
  } catch {
    return undefined;
  }
};

export const getLocalEntityId = () => getItem('_id');

export const getLocalEntityLandingURL = () => getItem('landingURL');

export const getLocalEntityName = () => getItem('name');
