import React, {
  useEffect, useRef, useState,
} from 'react';

import PropTypes from 'prop-types';
import { Container, useMediaQuery, Typography } from '@material-ui/core';

import { Loader, ProgressBar } from '../../../../../components';
import useLoading from '../../../../../hooks/useLoading';
import { useNodesFields } from '../../../../../hooks/useNodesFields';

import { getIsARecoverClient } from '../../../../../utils/clientData';
import {
  getProcess,
} from '../../../../../utils/auth';

import useStyles from './useStyles';

import { NodeContainer } from '../../NodeContainer';
import { InformationDialog } from '../../../../../components/InformationDialog';

export const ModalNodosChiplo = ({ onlyEmptyNodes, onFinish }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    isLoading: isLoadingGeneral,
    startLoading: startLoadingGeneral,
    endLoading: endLoadingGeneral,
  } = useLoading();
  const [openModalSendEmail, setOpenModalSendEmail] = useState(false);
  const formRef = useRef(null);

  const {
    nodesToShow,
    activeNode,
    isLastNode,
    nodesLoaded,
    fetchNodesAndFieldsByProcess,
    goNextActiveNode,
    loadNodesFields,
    sendDocumentEmail,
    saveNodeFieldValues,
    getDescriptionEmail,
    isActiveNodeDocumentNode,
    sendFieldValuesToBatch,
  } = useNodesFields({ applyModalDissabled: true, startLoading, endLoading });

  const sendEmail = async () => {
    startLoadingGeneral();
    await sendDocumentEmail();
    setOpenModalSendEmail(false);
    endLoadingGeneral();
  };

  const fetchData = async () => {
    startLoading();
    const processId = getProcess();
    const isARecoveredClient = getIsARecoverClient() || onlyEmptyNodes || false;
    const nodesAndFields = await fetchNodesAndFieldsByProcess(processId, isARecoveredClient);
    loadNodesFields(nodesAndFields, true);
    endLoading();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    startLoading();

    if (isLastNode) {
      await saveNodeFieldValues(activeNode, true);
      await sendFieldValuesToBatch();
      await onFinish();
    } else {
      saveNodeFieldValues(activeNode, true);
      goNextActiveNode();
    }
    endLoading();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    formRef
      ?.current
      ?.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
  }, [activeNode]);

  return (
    <Container
      disableGutters
      className={classes.mainContainer}
      maxWidth={false}
    >
      <Loader open={isLoading} />
      <Loader open={isLoadingGeneral} />
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>Completa lo siguiente</Typography>
        <Typography className={classes.subtitle}>{nodesToShow[activeNode]?.name}</Typography>
      </div>
      {
        nodesLoaded && (
          <div className={classes.progressContainer}>
            <ProgressBar
              currentProgress={(
            nodesToShow.length > 0
              ? activeNode + 1
              : activeNode
            )}
              totalProgress={nodesToShow.length}
            />
          </div>
        )
      }
      {
        isMobile && isActiveNodeDocumentNode() && (
          <Typography component="h2" className={classes.subtitle}>
            <button
              className={classes.buttonLink}
              onClick={() => setOpenModalSendEmail(true)}
              type="button"
            >
              ¿Quieres cargar los documentos desde tu computadora?
            </button>
          </Typography>
        )
      }
      <div ref={formRef} className={classes.scrollContainer}>
        <NodeContainer
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          startLoading={startLoading}
          endLoading={endLoading}
          showDocuments
        />
      </div>
      <InformationDialog
        showModal={openModalSendEmail}
        onSubmit={sendEmail}
        buttonTitle="Enviar"
        onClose={() => setOpenModalSendEmail(false)}
        descripcion={getDescriptionEmail()}
      />
    </Container>
  );
};

ModalNodosChiplo.propTypes = {
  onlyEmptyNodes: PropTypes.bool,
  onFinish: PropTypes.func,
};

ModalNodosChiplo.defaultProps = {
  onlyEmptyNodes: false,
  onFinish: () => {},
};
