import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './useStyles';
import { Button } from '../../../../components';

export const FormLayout = ({
  children,
  title,
  className,
  submit,
  buttonTitle,
  subTitle,
  section,
  hide,
  disabled,
  renderButton,
  renderHeader,
  fullHeight,
  formRef,
  titleContainerClass,
}) => {
  const classes = useStyles();
  return (
    <Container
      component="form"
      onSubmit={submit}
      method="post"
      autoComplete="off"
      maxWidth={false}
      className={clsx(classes.container, className, {
        [classes.hide]: hide,
        [classes.fullHeight]: fullHeight,
      })}
      ref={formRef}
    >
      {renderHeader() || (
        <Container
          component="div"
          maxWidth={false}
          className={clsx(classes.containerTitles, titleContainerClass)}
        >
          {title ? <Typography className={classes.title}>{title}</Typography> : ''}
          {subTitle ? <Typography className={classes.subTitle}>{subTitle}</Typography> : ''}
          {section ? <Typography className={classes.section}>{section}</Typography> : ''}
        </Container>
      )}

      {children}
      {renderButton() || (
        <div
          className={classes.buttonContainer}
        >
          <Button
            disabled={disabled}
            className={classes.button}
            type="submit"
          >
            {buttonTitle}
          </Button>
        </div>
      )}
    </Container>
  );
};

FormLayout.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  renderButton: PropTypes.func,
  renderHeader: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  submit: PropTypes.func,
  buttonTitle: PropTypes.string,
  subTitle: PropTypes.string,
  section: PropTypes.string,
  hide: PropTypes.bool,
  disabled: PropTypes.bool,
  fullHeight: PropTypes.bool,
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  titleContainerClass: PropTypes.string,
};

FormLayout.defaultProps = {
  title: '',
  className: '',
  submit: () => { },
  buttonTitle: 'Continuar',
  subTitle: '',
  section: '',
  hide: false,
  disabled: false,
  fullHeight: false,
  renderButton: () => { },
  renderHeader: () => { },
  formRef: null,
  titleContainerClass: '',
};
