import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formContainer: {},
  title: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
    marginTop: '40px',
    marginBottom: '26px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  viviendaInnerContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '750px',
    },
  },
  innerContainer: {
  },
  firtInputElement: {
    padding: '8px',
  },
  inputPrecio: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
    },
  },
  engancheInput: {
    width: '100%',
  },
  secondInputElement: {
  },
  precioContainer: {
    width: '100%',
    padding: '8px',
  },
  enganche: {
    textAlign: 'right',
    borderLeft: '1px solid #E9E9E9',
    paddingLeft: '5px',
  },
  invalidate: {
    color: 'red',
  },
  yearContainer: {
    padding: '8px',
    width: '100%',
  },
  thirdInputElement: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderTop: '3px solid #dedeeb',
    [theme.breakpoints.up('md')]: {
      borderTop: '0px',
    },
  },
  hidden: {
    display: 'none',
  },
  borderRight: {
    borderRight: '3px solid #dedeeb',
    [theme.breakpoints.up('md')]: {
      borderRight: '2px solid #dedeeb',
      borderLeft: '2px solid #dedeeb',
    },
  },
  element: {
    flex: 1,
    padding: '12px 0px 12px 0px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: '10px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  containerButton: {
    textAlign: 'center',
  },
  button: {
    marginTop: '70px',
    marginBottom: '50px',
    fontSize: '18px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000',
    backgroundColor: '#FFA500',
    padding: '20px 44px',
    textTransform: 'capitalize',
    borderRadius: '40px',
    '&:hover': {
      backgroundColor: '#FFA500',
      opacity: '0.6',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#FFA500',
      marginTop: '70px',
      marginBottom: '70px',
    },
  },
}));
