import React, { useEffect } from 'react';

import * as googleAnalytics from '../../../../utils/googleAnalytics/events';
import { LandingLayout } from '../../../landing/layouts/LandingLayout';
import { HeaderBar } from '../../../landing/components/HeaderBar';
import { FooterInfoImages } from '../../../../components/FooterInfoImages';
import { FAQ } from '../../../../components/FAQ';
import useStyles from './useStyles';
import useLoading from '../../../../hooks/useLoading';

import { LandingInfonavitData } from '../../../../data';
import { BankLabels } from '../../../../components/BankLabels';
import { Header } from '../../../../components/Header';
import { VideoSection } from '../../../../components/VideoSection';
import { Loader } from '../../../../components';
import { CalCredit } from '../../components/CalcCredit';

export const InfonavitLanding = () => {
  const { isLoading, startLoading, endLoading } = useLoading();
  const classes = useStyles();
  const {
    faqData,
    HeaderData,
    BankLabelsData,
    videoComponent,
    credito,
  } = LandingInfonavitData;
  const { title, questions } = faqData;
  useEffect(() => {
    googleAnalytics.visitaChiplo();
  }, []);

  return (
    <LandingLayout>
      <Loader open={isLoading} />
      <HeaderBar
        showLoginButton
        showSideBar
      />
      <Header
        title={HeaderData.title}
        subTitle={HeaderData.subTitle}
        buttonTitle={HeaderData.buttonTitle}
        image={HeaderData.image}
        buttonRoute={HeaderData.buttonRoute}
      />
      <CalCredit
        title={credito.title}
        subTitle={credito.subTitle}
        startLoading={startLoading}
        endLoading={endLoading}
        buttonTitle={credito.buttonTitle}
      />
      <VideoSection
        title={videoComponent.title}
        subTitle={videoComponent.subTitle}
        videoUrl={videoComponent.videoUrl}
      />
      <BankLabels title={BankLabelsData.title} images={BankLabelsData.images} />
      <div className={classes.container}>
        <FAQ questions={questions} title={title} />
      </div>
      <FooterInfoImages />
    </LandingLayout>
  );
};
