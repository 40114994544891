export const COTIZACION_CREDITO_HIPOTECARIO = 'COTIZACION_CREDITO_HIPOTECARIO';
export const COTIZACION_PREVIEW_VIVIENDA = 'COTIZACION_PREVIEW_VIVIENDA';
export const BIENES_ARRENDAMIENTO = 'BIENES_ARRENDAMIENTO';
export const CREDITO_HIPOTECARIO = 'creditohipotecario';
export const CREDITO_HIPOTECARIO_VIVIENDA = 'creditohipotecariovivienda';
export const CREDITO_HIPOTECARIO_LIQUIDEZ = 'creditohipotecarioliquidez';
export const CREDITO_HIPOTECARIO_INFONAVIT = 'creditohipotecarioinfonavit';
export const CREDITO_HIPOTECARIO_INMOBILIARIA = 'creditohipotecarioinmobiliaria';

export const COTIZATION = 'COTIZATION';
