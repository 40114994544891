import React from 'react';
import { Container, Typography } from '@material-ui/core';
import clsx from 'clsx';

import PropTypes from 'prop-types';

import useStyles from './useStyles';
import { Button } from '../Button';

export const Information = ({
  title, className, buttonText, onClick, customButtonClassName,
}) => {
  const classes = useStyles();
  return (
    <Container
      disableGutters
      component="main"
      maxWidth={false}
      className={classes.container}
    >
      <Typography component="h2" className={clsx(classes.title, className)}>{title}</Typography>
      { buttonText ? (
        <div className={classes.buttonContainer}>
          <Button
            className={clsx(classes.button, customButtonClassName)}
            fullWidth
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </div>
      ) : ''}
    </Container>
  );
};

Information.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  customButtonClassName: PropTypes.string,
};

Information.defaultProps = {
  className: '',
  buttonText: '',
  onClick: () => {},
  customButtonClassName: '',
};
