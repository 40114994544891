/* eslint-disable react/forbid-prop-types */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getProcess } from '../../utils';

import * as flowProcessAPI from '../../api/flowProcess';
import * as processAPI from '../../api/process';

import { STEP_STATUS_TERMINADO } from '../../constants';

const checkStepCompleted = async (processId, stepName) => {
  const { steps = [] } = await flowProcessAPI.getStepsByProcess(processId);
  const matchStep = steps.find(({ stepConfig }) => stepConfig?.name === stepName) || null;
  const isCompleted = matchStep?.status?.value === STEP_STATUS_TERMINADO || false;
  return isCompleted;
};

const updateCompleteTag = async (tagName, updateTag) => {
  const process = getProcess();
  const { tags = {} } = await processAPI.getProcessById(process);
  const body = {
    [tagName]: { ...tags[tagName], ...updateTag },
  };
  await processAPI.createTag({
    tagName,
    active: true,
    body: { ...tags, ...body },
    processId: process,
  });
};

export const StepConfigCompletedChecker = ({
  children, stepConfigName, tagName, updateTag, onStepConfigCompleted,
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const processId = getProcess();

  const checkAndSetStepComplete = async () => {
    const completed = await checkStepCompleted(processId, stepConfigName);
    setIsCompleted(completed);
    if (completed) {
      await updateCompleteTag(tagName, updateTag);
      onStepConfigCompleted();
    }
  };

  useEffect(() => {
    if (processId) checkAndSetStepComplete();
  }, [processId, stepConfigName]);

  return (!isCompleted && children) || null;
};

StepConfigCompletedChecker.propTypes = {
  children: PropTypes.node.isRequired,
  stepConfigName: PropTypes.string.isRequired,
  tagName: PropTypes.string.isRequired,
  updateTag: PropTypes.object,
  onStepConfigCompleted: PropTypes.func,
};

StepConfigCompletedChecker.defaultProps = {
  updateTag: {},
  onStepConfigCompleted: () => { },
};
