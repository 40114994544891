import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';

import { WallOfLovePage } from '../../../WallOfLove';

import useStyles from './useStyles';
import { WALL_OF_LOVE_CARROUSEL, WALL_OF_LOVE_CARROUSEL_MOBILE } from '../../../../constants';

export const WallOfLoveSection = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const variant = isMobile ? WALL_OF_LOVE_CARROUSEL_MOBILE : WALL_OF_LOVE_CARROUSEL;
  return (
    <div className={classes.container}>
      <Typography className={classes.title} component="h2">
        Lo que dicen nuestros clientes
      </Typography>
      <WallOfLovePage
        variant={variant}
      />
    </div>
  );
};
