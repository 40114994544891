const ERROR_ONE_UPPER_CASE = 'Necesita tener 1 mayúscula';
const ERROR_ONE_DIGIT = 'Necesita tener 1 dígito';
const ERROR_ONE_SPECIAL_SYMBOL = 'Necesita tener 1 caracter especial';

export const isValidPassword = ({
  oneUpperCaseLetter = false,
  oneDigit = false,
  oneSpecialSymbol = false,
  minimunLength = 1,
  showPasswordValidity = true,
  value = '',
}) => {
  if (!showPasswordValidity) return ({ valid: true, errorMessage: '' });
  if (oneUpperCaseLetter && !/[A-Z]/.test(value)) return { valid: false, errorMessage: ERROR_ONE_UPPER_CASE };
  if (oneDigit && !/[0-9]/.test(value)) return { valid: false, errorMessage: ERROR_ONE_DIGIT };
  if (oneSpecialSymbol && !/[^A-Za-z0-9]/.test(value)) return { valid: false, errorMessage: ERROR_ONE_SPECIAL_SYMBOL };
  if (value.length < minimunLength) return { valid: false, errorMessage: `Mínimo de ${minimunLength} caracteres` };
  return { valid: true, errorMessage: '' };
};
