/* eslint-disable max-len */
import * as compConfigAPI from '../../api/companyConfigs';

export const createPaletteConfiguration = (product) => {
  const { palette } = compConfigAPI.getCompanyAndType(product);
  return {
    ...palette,
  };
};

export const createLogoConfiguration = (product) => {
  const { logoUrl } = compConfigAPI.getCompanyAndType(product);
  return {
    logo: logoUrl,
  };
};
