import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isValidNss, nssValidator } from './validator';
import InputRejected from '../InputRejected';
import useEnabler from '../../../hooks/useEnabler';

const InputNSS = ({
  value,
  onChange,
  required,
  isEmpty,
  ...props
}) => {
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const handleOnChange = (event) => {
    const { target } = event;
    const { value: _value } = target;
    const isValid = isValidNss(_value);
    if (isValid) {
      target.setCustomValidity('');
      disableError();
    } else {
      target.setCustomValidity(nssValidator.errorMessages.validation);
      enableError();
    }
    onChange(event, _value);
  };

  return (
    <InputRejected
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isValid={isValidNss(value)}
      value={value}
      onChange={handleOnChange}
      isEmtpy={isEmpty}
      error={isError}
      helperText={isError ? nssValidator.errorMessages.validation : ''}
    />
  );
};

InputNSS.defaultProps = {
  type: nssValidator.type,
  error: false,
  errorMessage: '',
  required: false,
  autoComplete: 'off',
  value: '',
  onChange: () => {},
  isEmpty: undefined,
};

InputNSS.propTypes = {
  type: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isEmpty: PropTypes.bool,
};

export default memo(InputNSS);
