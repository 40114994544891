import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import useEnabler from '../../../hooks/useEnabler';

import InputRejected from '../InputRejected';

import phoneIsValid from './validator';
import { limitPhoneNumber } from './formatter';

const ERROR_MESSAGE = 'El número no es válido';

const InputPhone = ({
  value,
  onChange,
  isEmpty,
  ...props
}) => {
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value: _value } = target; // 3312139444
    const limitValue = limitPhoneNumber(_value);
    if (phoneIsValid(limitValue)) {
      target.setCustomValidity('');
      disableError();
    } else {
      target.setCustomValidity(ERROR_MESSAGE);
      enableError();
    }

    onChange(event, limitValue);
  }, [onChange, enableError, disableError]);

  return (
    <InputRejected
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      isValid={phoneIsValid(value)}
      onChange={handleOnChange}
      isEmpty={isEmpty}
      error={isError}
      helperText={isError ? ERROR_MESSAGE : ''}
    />
  );
};

InputPhone.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isEmpty: PropTypes.any,
};

InputPhone.defaultProps = {
  isEmpty: undefined,
};

export default memo(InputPhone);
