import React, {
  memo,
  useContext,
} from 'react';

import PropTypes from 'prop-types';

import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeContext } from '../context/Theme/themeContext';

const AppTheme = ({ children }) => {
  const { state: { theme } } = useContext(ThemeContext);
  return (
    <ThemeProvider theme={theme}>
      { children }
    </ThemeProvider>
  );
};

AppTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default memo(AppTheme);
