import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import {
  getEnganche,
  getImporte,
  getMontoGastosEscritura,
  getPercentageEnganche,
  getPlazo,
  getPropietyValue,
} from '../../../../../utils/credit';
import { convertirAMoneda } from '../../../../../utils/currency';

import useStyles from './useStyles';
import { selectPerfiladorGastosEscrituraPercentage } from '../../../../../store/perfilador';

export const ShowConditionsContainer = React.memo(({
  showPropietyValue,
  showEnganche,
  showImporte,
  showPlazo,
  showGastosEscritura,
  includeCents,
  children,
}) => {
  const classes = useStyles();
  const plazoNumber = getPlazo();
  const enganchePercentage = getPercentageEnganche();
  const gastosEscrituraPercentage = useSelector(selectPerfiladorGastosEscrituraPercentage);
  const plazoValue = typeof plazoNumber === 'number' ? `${plazoNumber} años` : plazoNumber;
  const conditions = [
    {
      id: 'propiety-value',
      label: 'Valor de propiedad',
      value: convertirAMoneda(getPropietyValue(), includeCents),
      show: showPropietyValue,
    },
    {
      id: 'importe',
      label: 'Monto del crédito',
      value: convertirAMoneda(getImporte(), includeCents),
      show: showImporte,
    },
    {
      id: 'enganche',
      label: `Enganche (${enganchePercentage}%)`,
      value: convertirAMoneda(getEnganche() || 0, includeCents),
      show: showEnganche,
    },
    {
      id: 'gastos-escritura',
      label: `Gastos notariales (${gastosEscrituraPercentage}%)`,
      value: convertirAMoneda(getMontoGastosEscritura() || 0, includeCents),
      show: showGastosEscritura,
    },
    {
      id: 'plazo',
      label: 'Plazo',
      value: plazoValue,
      show: showPlazo,
    },
  ];

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Detalles del crédito</Typography>
      <div className={classes.textsContainer}>
        <div className={classes.labelsContainer}>
          {conditions.map((condition) => (
            condition.show && (
              <Typography key={condition.id} className={classes.conditionTitle}>
                {condition.label}
              </Typography>
            )
          ))}
        </div>
        <div>
          {conditions.map((condition) => (
            condition.show && (
              <Typography key={condition.id} className={classes.subtitle}>
                {` ${condition.value}`}
              </Typography>
            )
          ))}
        </div>
      </div>

      {children}
    </div>
  );
});

ShowConditionsContainer.propTypes = {
  showPropietyValue: PropTypes.bool,
  showEnganche: PropTypes.bool,
  showImporte: PropTypes.bool,
  showPlazo: PropTypes.bool,
  showGastosEscritura: PropTypes.bool,
  children: PropTypes.node,
  includeCents: PropTypes.bool,
};

ShowConditionsContainer.defaultProps = {
  showPropietyValue: false,
  showEnganche: false,
  showImporte: false,
  showPlazo: false,
  showGastosEscritura: false,
  children: null,
  includeCents: false,
};
