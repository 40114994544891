/* eslint-disable import/no-extraneous-dependencies */
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';

import { AppRouter } from './router/AppRouter';

import { StepperProvider } from './context/stepper/stepperProvider';
import { UIProvider } from './context/ui/uiProvider';
import { AuthProvider } from './context/auth/authProvider';

import AppTheme from './themes';
import { ThemeProvider } from './context/Theme';
import { ClientProvider } from './context/client/clientProvider';
import useSessionRewind from './hooks/useSessionRewind';

import { store } from './store/store';
import { FlowStrategyProvider } from './context/flowStrategy';

const {
  REACT_APP_GOOGLE_ANALYTICS,
  REACT_APP_ENVIRONMENT,
  REACT_APP_SESSION_REWIND_API_KEY,
} = process.env;

ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS);

function App() {
  useSessionRewind({
    apiKey: REACT_APP_SESSION_REWIND_API_KEY,
    startRecording: true,
    environment: REACT_APP_ENVIRONMENT,
  });
  return (
    <Provider store={store}>
      <ThemeProvider>
        <AppTheme>
          <FlowStrategyProvider>
            <AuthProvider>
              <UIProvider>
                <StepperProvider>
                  <ClientProvider>
                    <BrowserRouter>
                      <AppRouter />
                    </BrowserRouter>
                  </ClientProvider>
                </StepperProvider>
              </UIProvider>
            </AuthProvider>
          </FlowStrategyProvider>
        </AppTheme>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
