import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    width: '100%',
    border: '1px solid white',
    borderRadius: '10px',
  },
  mainElement: {
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
  },
  firstElement: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
  },
  lastElement: {
    border: '1px solid transparent',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}));
