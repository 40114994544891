import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@material-ui/core';

import Input from '../Input';
import { ButtonCredit } from '../Simulator/ButtonCredit';
import { CurrencyLabel } from '../Simulator/CurrencyLabel';

import { convertirAMoneda } from '../../utils/currency';
import { isGreaterThanValue, isValidPercentage } from '../../utils/simulator.utils';

import styles from './useStyles';

import {
  CALC_MODE_CONDITIONS_EDITION,
  INPUT_NAME_DESEMBOLSO,
  INPUT_NAME_ENGANCHE,
  INPUT_SIMULATOR_MINIMUM_DESEMBOLSO_PERCENTAGE,
  INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
  MINIMUM_CREDIT_VALUE,
} from '../../constants';
import usePerfilador from '../../hooks/usePerfilador';

export const CreditConditionsSimulator = ({
  isVivienda,
  onSubmit,
  initialValues,
  buttonTitle,
  hidePlazo,
  useDesembolsoMode,
}) => {
  const classes = styles();
  const {
    inputValue: precio,
    enganche,
    plazo,
    inputNumber: precioNumber,
    engancheNumber,
    plazoNumber,
    percentageEnganche,
    pagoMensual,
    montoCredito,
    montoGastosEscritura,
    onChange,
    onChangeEnganche,
    onChangePlazo,
    // infonavit
    sueldoMensualBruto = null,
    montoMensual = null,
    engancheMinimoPercentage = INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
  } = usePerfilador({
    calcMode: CALC_MODE_CONDITIONS_EDITION,
    useDesembolsoMode,
    initialData: initialValues,
    engancheMinimoPercentage: INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const storageData = {
      montoMensual,
      sueldoMensualBruto,
      importe: montoCredito,
      meses: plazoNumber * 12,
      aforo: (montoCredito / precioNumber).toFixed(4),
      enganche: engancheNumber,
      year: plazoNumber,
      plazo,
      mensualidad: pagoMensual,
      propietyValue: precioNumber,
      montoGastosEscritura,
      percentageEnganche,
    };
    onSubmit({ storageData, event });
  };

  return (
    <Container
      component="form"
      onSubmit={handleSubmit}
      method="post"
      autoComplete="off"
      className={classes.formContainer}
      disableGutters
      maxWidth={false}
    >
      <Box
        className={clsx(classes.innerContainer, {
          [classes.viviendaInnerContainer]: isVivienda,
        })}
      >
        <Box className={classes.firtInputElement}>
          <Input
            className={classes.inputPrecio}
            label={initialValues.precio.label}
            type={initialValues.precio.type}
            name={initialValues.precio.name}
            value={precio}
            required={initialValues.precio.required}
            isEmpty={initialValues.precio.isEmpty}
            validations={[
              (inputValue) => isGreaterThanValue(
                inputValue,
                MINIMUM_CREDIT_VALUE,
                `Valor no puede ser menor a ${convertirAMoneda(
                  MINIMUM_CREDIT_VALUE,
                )}`,
              ),
            ]}
            onChange={onChange}
          />
        </Box>
        <Box className={classes.secondInputElement}>
          {Object.keys(initialValues).includes(INPUT_NAME_ENGANCHE) && (
            <Box className={classes.precioContainer}>
              <Input
                disabled={initialValues.enganche.disabled}
                className={classes.engancheInput}
                label={initialValues.enganche.label}
                type={initialValues.enganche.type}
                name={initialValues.enganche.name}
                value={enganche}
                required={initialValues.enganche.required}
                isEmpty={initialValues.enganche.isEmpty}
                onChange={onChangeEnganche}
                error={initialValues.enganche.error}
                validations={[
                  (inputValue) => isValidPercentage(
                    inputValue,
                    precioNumber,
                    engancheMinimoPercentage,
                    `Valor no puede ser menor a ${engancheMinimoPercentage}%`,
                  ),
                  (inputValue) => isGreaterThanValue(
                    precioNumber,
                    inputValue,
                    'Enganche no puede ser mayor al precio',
                  ),
                ]}
                endAdornment={(
                  <Typography
                    className={clsx(classes.enganche, {
                      [classes.invalidate]:
                      percentageEnganche
                      < engancheMinimoPercentage,
                    })}
                  >
                    {`${percentageEnganche}%`}
                  </Typography>
                )}
              />
            </Box>
          )}
          {(Object.keys(initialValues).includes(INPUT_NAME_DESEMBOLSO) && useDesembolsoMode) && (
            <Box className={classes.precioContainer}>
              <Input
                disabled={initialValues.desembolso.disabled}
                className={classes.engancheInput}
                label={initialValues.desembolso.label}
                type={initialValues.desembolso.type}
                name={initialValues.desembolso.name}
                value={enganche}
                required={initialValues.desembolso.required}
                isEmpty={initialValues.desembolso.isEmpty}
                onChange={onChangeEnganche}
                error={initialValues.desembolso.error}
                validations={[
                  (inputValue) => isValidPercentage(
                    inputValue,
                    initialValues.precio.value,
                    INPUT_SIMULATOR_MINIMUM_DESEMBOLSO_PERCENTAGE,
                    `Valor no puede ser menor a ${INPUT_SIMULATOR_MINIMUM_DESEMBOLSO_PERCENTAGE}%`,
                  ),
                  (inputValue) => isGreaterThanValue(
                    initialValues.precio.value,
                    inputValue,
                    'Enganche no puede ser mayor al precio',
                  ),
                ]}
                endAdornment={(
                  <Typography
                    className={clsx(classes.enganche, {
                      [classes.invalidate]:
                        percentageEnganche < INPUT_SIMULATOR_MINIMUM_DESEMBOLSO_PERCENTAGE,
                    })}
                  >
                    {`${percentageEnganche}%`}
                  </Typography>
                )}
              />
            </Box>
          )}
          {hidePlazo ? null : (
            <Box className={classes.yearContainer}>
              <Input
                className={classes.inputBorder}
                label={initialValues.year.label}
                type={initialValues.year.type}
                name={initialValues.year.name}
                value={plazo}
                required={initialValues.year.required}
                isEmpty={initialValues.year.isEmpty}
                onChange={onChangePlazo}
                fullWidth
                options={initialValues.year.options}
              />
            </Box>
          )}
        </Box>
        <Box
          className={clsx(classes.thirdInputElement, {
            [classes.hidden]: isVivienda,
          })}
        >
          <Box className={clsx(classes.element, classes.borderRight)}>
            <CurrencyLabel
              title={initialValues.prestamo.label}
              money={precio}
              format={initialValues.prestamo.format}
            />
          </Box>
          <Box className={classes.element}>
            <CurrencyLabel
              title={initialValues.monthlyPaid.label}
              money={pagoMensual}
              format={initialValues.monthlyPaid.format}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.containerButton}>
        <ButtonCredit
          className={classes.button}
          variant="contained"
          type="submit"
        >
          {buttonTitle}
        </ButtonCredit>
      </Box>
    </Container>
  );
};

CreditConditionsSimulator.propTypes = {
  onSubmit: PropTypes.func,
  isVivienda: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  hidePlazo: PropTypes.bool,
  useDesembolsoMode: PropTypes.bool,
};

CreditConditionsSimulator.defaultProps = {
  onSubmit: () => { },
  isVivienda: false,
  hidePlazo: false,
  useDesembolsoMode: false,
};
