import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Typography } from '@material-ui/core';

const ViviendaWhatsappContainer = ({ classes, buttonTitle, buttonFooterTitle }) => (
  <>
    <Typography className={classes.footerText}>
      ¿Tienes más dudas? Platica con uno de nuestros expertos inmobiliarios por
      {' '}
      <a
        target="_blank"
        style={{ color: '#2C1482' }}
        href="https://api.whatsapp.com/send?phone=523335063714&text="
        rel="noreferrer"
      >
        WhatsApp
      </a>
    </Typography>
    <Container
      className={classes.buttonContainer}
    >
      <Button
        className={classes.button}
        type="submit"
      >
        {buttonTitle}
      </Button>
    </Container>
    <Typography
      variant="body2"
      className={classes.buttonFooterTitle}
    >
      {buttonFooterTitle}
    </Typography>
  </>
);

ViviendaWhatsappContainer.propTypes = {
  classes: PropTypes.shape({
    buttonContainer: PropTypes.string,
    button: PropTypes.string,
    footerText: PropTypes.string,
    buttonFooterTitle: PropTypes.string,
  }),
  buttonTitle: PropTypes.string,
  buttonFooterTitle: PropTypes.string,
};

ViviendaWhatsappContainer.defaultProps = {
  classes: {
    buttonContainer: '',
    button: '',
    footerText: '',
    buttonFooterTitle: '',
  },
  buttonTitle: '',
  buttonFooterTitle: '',
};

export default ViviendaWhatsappContainer;
