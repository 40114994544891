import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
    width: '100%',
    overflowY: 'hidden',
  },
  innerContainer: {
    maxWidth: '600px',
    margin: '0 auto',
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  formTitle: {
    fontFamily: '"Open Sans"',
    fontSize: '1rem',
    fontWeight: 400,
    color: '#7C839D',
    marginTop: '16px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '4px',
    },
  },
  subTitle: {
    fontFamily: '"Open Sans"',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#000F8F',
    marginBottom: '26px',
    marginTop: '6px',
  },
  background: {
    background: '#F7F7F7',
  },
  title: {
    maxWidth: '420px',
  },
  buttonContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '360px',
    marginBottom: '30px',
    marginTop: '15px',
  },
  buttonContainerCustom: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px',
    margin: '0 auto',
    width: '100%',
    maxWidth: '360px',
    marginBottom: '30px',
    marginTop: '15px',
  },
  extraInputClassName: {
    borderColor: 'gray',
  },
}));
