import { decryptData, encryptData } from '../utils/encryption';

const AGENT_DATA_KEY = btoa('AGENT_DATA');

export const storeAgentData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(AGENT_DATA_KEY, encryptedData);
};

export const removeLocalAgentData = () => {
  window.localStorage.removeItem(AGENT_DATA_KEY);
};

export const getLocalAgentData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(AGENT_DATA_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

export const storeExtraAgentData = (data) => {
  const currentAgentData = getLocalAgentData();
  const newAgentData = { ...currentAgentData, ...data };
  storeAgentData(newAgentData);
};

const getItem = (key) => {
  try {
    const agentData = getLocalAgentData();
    return agentData[key];
  } catch {
    return undefined;
  }
};

export const getAgtPropietyValue = () => getItem('clientPropietyValue');
