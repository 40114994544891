import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import useStyles from './useStyles';
import useStateMachine from './hooks/useStateMachine';
import { useNodesFields } from '../../../../hooks/useNodesFields';
import { DocumentUpload, FormNodesDocuments, FormNodesFields } from './components';
import useArray from '../../../../hooks/useArray';
import useError from '../../../../hooks/useError';

export const NodeContainer = ({
  handleSubmit,
  isLoading,
  startLoading,
  endLoading,
  formRef,
  showDocuments,
}) => {
  const classes = useStyles();

  const {
    nodesToShow,
    activeNode,
    activeNodeFields,
    isLastNode,
    onChangeFieldValue,
    isActiveNodeNotComplete,
  } = useNodesFields({ applyModalDissabled: true, startLoading, endLoading });

  const {
    showError,
  } = useError();

  const {
    values,
    replace: replaceValues,
    updateByIndex: updateValueByIndex,
    removeByIndex: removeValueByIndex,
  } = useArray();

  const {
    STATES,
    currentState,
    goToListAllFields,
    goToUploadDocument,
    goToListDocuments,
  } = useStateMachine();

  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(-1);
  const [mainView, setMainView] = useState(null);

  const currentDocument = useMemo(
    () => activeNodeFields[currentDocumentIndex],
    [nodesToShow, activeNode, currentDocumentIndex],
  );

  const hasReversePhoto = useMemo(
    () => Boolean(currentDocument?.reversePhoto),
    [currentDocument],
  );

  const selectDocumentAndGoToUploadView = useCallback(
    (event, index, document) => {
      setCurrentDocumentIndex(index);
      replaceValues(document.value || []);
      goToUploadDocument();
    },
    [goToUploadDocument],
  );

  const getBackToMainView = useCallback(() => {
    switch (mainView) {
      case STATES.LIST_ALL_FIELDS:
        return goToListAllFields();
      case STATES.LIST_DOCUMENTS:
      default:
        return goToListDocuments();
    }
  }, []);

  const addDocumentValue = useCallback(
    async (newValues = []) => {
      const value = newValues.length === 1 ? newValues[0] : newValues;

      const newDocuments = cloneDeep(activeNodeFields);
      const newDocument = newDocuments[currentDocumentIndex];
      newDocument.value = value;
      if (newDocument.status) {
        delete newDocument.status;
      }
      replaceValues(value);
    },
    [
      values,
      activeNodeFields,
      currentDocumentIndex,
      goToListDocuments,
    ],
  );

  const onAddDocumentValue = async (event, value, fieldIndex, fieldName) => {
    startLoading();
    const newValues = [...values, ...value];
    onChangeFieldValue(event, newValues, fieldIndex, fieldName);
    await addDocumentValue(newValues);
    endLoading();
  };

  const removeValue = (index) => async () => {
    // NOTE: for reversePhoto flow
    try {
      startLoading();

      let newValues = [...values];
      if (currentDocument.reversePhoto) {
        newValues[index] = null;
        newValues = newValues.filter((newValue) => newValue !== null);
        updateValueByIndex(null, index);
      } else {
        newValues.splice(index, 1);
        removeValueByIndex(index);
      }
      await addDocumentValue(newValues);
      endLoading();
    } catch (e) {
      const type = e.message;
      const message = 'Error al guardar los documentos';

      showError(message, type);
      endLoading();
    }
  };

  useEffect(() => {
    if (showDocuments) {
      goToListDocuments();
      setMainView(STATES.LIST_DOCUMENTS);
      return;
    }
    goToListAllFields();
    setMainView(STATES.LIST_ALL_FIELDS);
  }, []);

  return (
    <div className={classes.main}>
      {(() => {
        switch (currentState) {
          case STATES.LIST_ALL_FIELDS:
            return <FormNodesFields />;
          case STATES.UPLOAD_DOCUMENT:
            return (
              <DocumentUpload
                field={currentDocument}
                values={values}
                reversePhoto={hasReversePhoto}
                isLoading={isLoading}
                onBack={getBackToMainView}
                onRemoveValue={removeValue}
                onChange={onAddDocumentValue}
                fieldIndex={currentDocumentIndex}
              />
            );
          case STATES.LIST_DOCUMENTS:
          default:
            return (
              <FormNodesDocuments
                formRef={formRef}
                fields={activeNodeFields}
                key={activeNode}
                buttonText={isLastNode ? 'Finalizar' : 'Continuar'}
                disabled={isActiveNodeNotComplete()}
                handleSubmit={handleSubmit}
                onChange={onChangeFieldValue}
                isLoading={isLoading}
                onDocumentEdit={selectDocumentAndGoToUploadView}
                onDocumentSelection={selectDocumentAndGoToUploadView}
              />
            );
        }
      })()}
    </div>
  );
};

NodeContainer.propTypes = {
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  startLoading: PropTypes.func,
  endLoading: PropTypes.func,
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  showDocuments: PropTypes.bool,
};

NodeContainer.defaultProps = {
  handleSubmit: () => { },
  isLoading: false,
  startLoading: () => { },
  endLoading: () => { },
  formRef: null,
  showDocuments: false,
};
