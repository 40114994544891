import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth: '650px',
    margin: '0 auto',
  },
  formContainer: {
    height: 'initial',
    [theme.breakpoints.down('md')]: {
      height: 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'initial',
    },
  },
  customFieldsTitle: {
    textAlign: 'center',
  },
  titleContainer: {
    maxWidth: '800px',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '25px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
    },
  },
  button: {
    background: '#FFA500',
    borderRadius: '60px',
    textTransform: 'none',
    fontWeight: 700,
    color: '#000',
    fontFamily: '"Montserrat", "sans-serif"',
    fontSize: '16px',
    lineHeight: '1.2rem',
    padding: '16px 40px',
    '&:hover': {
      background: '#FFA500',
      opacity: '0.6',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      padding: '16px 60px',
    },
  },
}));
