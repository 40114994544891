import { DEFAULT_DECIMALES_REDONDEO, INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE, MINIMUM_CREDIT_VALUE } from '../constants';
import { convertirAMoneda, redondearNumero } from './currency';

export const getValueMinimumErrorMessage = () => `Valor no puede ser menor a ${convertirAMoneda(
  MINIMUM_CREDIT_VALUE,
)}`;

export const isGreaterThanValue = (inputValue, limitValue, errorMessage) => {
  const isValid = inputValue > limitValue;
  return { isValid, errorMessage };
};

export const calcAmmountPercentage = (propietyValueNumber, engancheNumber) => {
  if (!propietyValueNumber || !engancheNumber) return 0;
  const percentage = parseInt(((engancheNumber / propietyValueNumber) * 100).toFixed(0), 10);
  return percentage;
};

export const isValidPercentage = (iValue, precio, limitValue, errorMessage) => {
  const isValid = parseInt(((iValue / precio) * 100).toFixed(0), 10) >= limitValue;
  return { isValid, errorMessage };
};

export const calcAmmountGastosEscritura = (valorPropiedad, porcentajeGastosEscritura = 5) => {
  const gastosEscritura = (porcentajeGastosEscritura / 100) * valorPropiedad;
  return gastosEscritura;
};

export const calculateEngancheFromDesembolso = (
  valorPropiedad,
  valorDesembolso,
  porcentajeGastosEscritura = 5,
) => {
  const resultGastosEscrituracion = calcAmmountGastosEscritura(
    valorPropiedad,
    porcentajeGastosEscritura,
  );
  const enganche = valorDesembolso - resultGastosEscrituracion;
  return {
    enganche,
    gastosEscritura: resultGastosEscrituracion,
  };
};

export const calcAmmountEnganche = (
  valorPropiedad,
  includeDecimals = true,
  engancheMinimoPercentage = INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
) => {
  const enganchePorcentaje = engancheMinimoPercentage * 0.01;
  const newEngancheValue = valorPropiedad * enganchePorcentaje;
  const engancheNumber = redondearNumero(newEngancheValue, DEFAULT_DECIMALES_REDONDEO);
  return {
    engancheNumber,
    enganche: convertirAMoneda(engancheNumber, includeDecimals),
  };
};
