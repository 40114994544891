import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    cursor: 'pointer',
    marginBottom: '16px',
  },
  title: {
    display: 'block',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#303030EB',
  },
  phone: {
    display: 'block',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#303030A1',
  },
  date: {
    display: 'block',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#303030A1',
  },
}));
