/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */

import React, {
  useMemo,
  createRef,
  useCallback,
  memo,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import Digit from '../Digit';
import useStyles from './useStyles';

let onKeyDownFlag = false;

const isDeleteKeyCode = (key) => {
  const DELETE_KEY_CODE = 8;
  return key === DELETE_KEY_CODE;
};

const haveControlKeyCode = (event) => {
  const ctrlKeyCode = event.ctrlKey || event.metaKey || event.altKey;
  return ctrlKeyCode;
};

const isEmpty = (value) => {
  const valueIsEmpty = value ?? '';
  return valueIsEmpty === '';
};

const PinDigit = ({
  values,
  onChange,
  onPaste,
  disabled,
}) => {
  const classes = useStyles();

  const references = useMemo(() => (
    values
      .map(() => createRef())
  ), [values.length]);

  const handleOnChange = (index) => (event, value) => {
    if (value.length > 1) {
      onChange(value);
      return;
    }
    const _values = [...values];
    _values[index] = value;
    const newValues = [...values];
    newValues[index] = value;
    onChange(newValues);
  };

  const handleOnKeyDown = (index, nextReference) => (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    const value = values[index];
    const nextValue = values[index + 1];

    if (
      nextReference
      && !isEmpty(value)
      && isEmpty(nextValue)
      && !isDeleteKeyCode(charCode)
      && !haveControlKeyCode(event)
    ) {
      // onKeyDownFlag = true;
      nextReference.focus();
    }
  };

  const handleOnKeyUp = (pevReference, nextReference) => (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      pevReference
      && isDeleteKeyCode(charCode)
      && !haveControlKeyCode(event)
    ) {
      pevReference.focus();
    }

    if (
      nextReference
      && !onKeyDownFlag
      && !isDeleteKeyCode(charCode)
      && !haveControlKeyCode(event)
    ) {
      nextReference.focus();
    }

    onKeyDownFlag = false;
  };

  const handleOnFocus = useCallback((event) => {
    const input = event.target;
    const { value } = input;
    const inputValueLength = value ? value.toString().length : 0;

    input.setSelectionRange(0, inputValueLength);
  }, []);

  const handleOnPaste = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    event.target.blur();

    const _clipboardData = event.clipboardData || window.clipboardData;
    const value = _clipboardData.getData('text');
    onPaste(event, value);
  }, [onPaste]);

  const getOtpCode = async () => {
    try {
      if ('OTPCredential' in window) {
        const abortController = new AbortController();
        const { signal } = abortController;
        setTimeout(() => {
          abortController.abort();
        }, 1 * 60 * 1000);
        const otp = await navigator.credentials
          .get({
            otp: { transport: ['sms'] },
            signal,
          });

        if (otp) {
          const { code } = otp;
          const digits = code.split('');
          onChange(digits);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getOtpCode();
  }, []);

  return (
    <div
      className={classes.digitContainer}
      onPaste={handleOnPaste}
    >
      {
        references.map((reference, index) => {
          const pevReference = references?.[index - 1]?.current;
          const nextReference = references?.[index + 1]?.current;

          return (
            <Digit
              key={index}
              ref={reference}
              value={values[index]}
              onChange={handleOnChange(index, nextReference)}
              onKeyDown={handleOnKeyDown(index, nextReference)}
              onKeyUp={handleOnKeyUp(pevReference, nextReference)}
              onFocus={handleOnFocus}
              required
              disabled={disabled}
              digitIndex={index}
            />
          );
        })
      }
    </div>
  );
};

PinDigit.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  disabled: PropTypes.bool,
};

PinDigit.defaultProps = {
  values: [],
  onChange: () => { },
  onPaste: () => { },
  disabled: false,
};

export default memo(PinDigit);
