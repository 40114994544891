import {
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { FLOW_INMOBILIARIA, FLOW_VIVIENDA } from '../constants';

const useRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const createRoute = (oldRoute, queryParam) => {
    const myParamValue = searchParams.get(queryParam) || '';
    return myParamValue ? `${oldRoute}?${queryParam}=${myParamValue}` : oldRoute;
  };

  const nextRoute = (oldRoute) => {
    const { pathname } = location;
    switch (pathname) {
      case FLOW_INMOBILIARIA:
        return `${FLOW_VIVIENDA}${oldRoute}`;
      default:
        return oldRoute;
    }
  };

  const navigateWithQueryParam = (oldRoute, queryParam) => {
    const newRoute = createRoute(oldRoute, queryParam);
    navigate(newRoute);
  };

  const navigateToNewQueryRoute = (oldRoute, queryParam, newRoute) => {
    const route = `${oldRoute}?${queryParam}=${newRoute}`;
    navigate(route);
  };

  return {
    createRoute,
    navigate,
    navigateWithQueryParam,
    nextRoute,
    navigateToNewQueryRoute,
    searchParams,
    location,
  };
};

export default useRoute;
