import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { MainLayout } from '../../main/layouts';
import { ProtectedRoute } from '../../../components';

import { CreditProfilingPage } from '../../main/pages/CreditProfilingPage';
import CreateAccountPage from '../../main/pages/CreateAccountPage';

import {
  AUTH_ROUTE_TYPE,
  COTIZATION_PREVIEW_STRATEGY,
  CREDITO_HIPOTECARIO_VIVIENDA,
  CREDIT_PROFILING_COTIZATION_STRATEGY,
  CREDIT_PROFILING_STRATEGY,
  VIVIENDA_STRATEGY,
} from '../../../constants';

import { CotizacionPreview } from '../../main/pages/CotizacionPreview';
import LandingVivienda from '../landing';
import { ProfilingProvider } from '../../../context/profiling';
import { CreateAccountProvider } from '../../../context/createAccount';
import { CotizationPreviewProvider } from '../../../context/cotizationPreview';
import { FlowStrategyContainer } from '../../flowStrategy';
import { simulatorStrategies } from '../../../strategies/simulatorStrategies';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  events: {
    sendUserInfo: 'userInfo',
  },
  dataLayerName: 'vivienda',
};
TagManager.initialize(tagManagerArgs);

const ViviendaRoutes = () => (
  <div style={{ overflowY: 'auto', height: '100%', background: '#F7FAFD' }}>
    <Routes>
      <Route
        path="/cotizacion"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/account"
            additionalData={{ authProtected: false }}
          >
            <MainLayout showStepperBar={false}>
              <FlowStrategyContainer
                strategies={simulatorStrategies}
                flowName={CREDITO_HIPOTECARIO_VIVIENDA}
              >
                <CotizationPreviewProvider>
                  <CotizacionPreview
                    strategyName={COTIZATION_PREVIEW_STRATEGY}
                    productStrategy={VIVIENDA_STRATEGY}
                  />
                </CotizationPreviewProvider>
              </FlowStrategyContainer>

            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/cuestionario_perfilamiento"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/main/consult"
            additionalData={{ authProtected: false }}
          >
            <MainLayout showStepperBar={false}>
              <ProfilingProvider>
                <CreditProfilingPage
                  strategyName={CREDIT_PROFILING_STRATEGY}
                  productStrategy={VIVIENDA_STRATEGY}
                />
              </ProfilingProvider>
            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/perfilamiento"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/main/consult"
            additionalData={{ authProtected: false }}
          >
            <MainLayout showStepperBar={false}>
              <FlowStrategyContainer
                strategies={simulatorStrategies}
                flowName={CREDITO_HIPOTECARIO_VIVIENDA}
              >
                <ProfilingProvider>
                  <CreditProfilingPage
                    strategyName={CREDIT_PROFILING_COTIZATION_STRATEGY}
                    productStrategy={VIVIENDA_STRATEGY}
                  />
                </ProfilingProvider>
              </FlowStrategyContainer>
            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/account"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/main/consult"
            additionalData={{ authProtected: false }}
          >
            <MainLayout showStepperBar={false}>
              <CreateAccountProvider>
                <CreateAccountPage productStrategy={VIVIENDA_STRATEGY} />
              </CreateAccountProvider>
            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route path="/" element={<LandingVivienda />} />
      <Route path="/*" element={<Navigate to="/vivienda" />} />
    </Routes>
  </div>
);

export default ViviendaRoutes;
