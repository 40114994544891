import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import { VideoPlayer } from '../Video';

export const VideoSection = ({
  title,
  subTitle,
  videoUrl,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.title} variant="h2" component="h2">{title}</Typography>
        <Typography className={classes.subTitle} variant="h3" component="h3">{subTitle}</Typography>
      </Box>
      <Box className={classes.videoContainer}>
        <VideoPlayer
          url={videoUrl}
        />
      </Box>
    </Box>
  );
};

VideoSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  videoUrl: PropTypes.string,
};

VideoSection.defaultProps = {
  title: '',
  subTitle: '',
  videoUrl: '',
};
