import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop: '8vh',
    paddingBottom: '5vh',
    overflowY: 'auto',
    height: 'calc(100% - 65px - 88px - 5px)',
    width: '100%',
    backgroundColor: '#F7FAFD',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 65px - 85.19px - 5px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 96.8px - 57px - 5px)',
    },
  },
  back: {
    position: 'absolute',
    top: '3%',
    left: '3%',
    cursor: 'pointer',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  button: {
    fontSize: '1.25rem',
    padding: '0.75rem 2.5rem',
  },
  title: {
    color: theme.palette.primary.main,
    maxWidth: '900px',
    margin: '0 auto',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      fontSize: '1.5rem',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  infoTitle: {
    color: theme.palette.secondary.main,
    maxWidth: '900px',
    margin: '10px auto',
    fontWeight: 600,
    lineHeight: '1.25rem',
    fontSize: '1.125rem',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  description: {
    maxWidth: '1100px',
    margin: '1rem auto',
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
    color: '#171D25C9',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },

  },
  formGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '80px 0',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
    },
  },
  checkBoxLabel: {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: '#7C839D',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
    },
  },
}));
