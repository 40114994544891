/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import useMediaScreen from '../../hooks/useMediaScreen';
import {
  LG_SCREEN, MD_SCREEN, SM_SCREEN, XL_SCREEN, XS_SCREEN,
} from '../../constants/screen';

const useStyles = makeStyles(() => ({
  carouselContainer: {
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  carouselWrapper: {
    display: 'inline-flex',
    willChange: 'transform',
  },
  carouselItem: {
    display: 'inline-block',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const Carousel = ({ items }) => {
  const { screenSize } = useMediaScreen();

  const getCarouselSpeed = () => {
    switch (screenSize) {
      case XL_SCREEN:
      case LG_SCREEN:
        return 0.65;
      case MD_SCREEN:
        return 0.55;

      case SM_SCREEN:
        return 0.35;
      case XS_SCREEN:
      default:
        return 0.35;
    }
  };

  const classes = useStyles();
  const wrapperRef = useRef(null);
  const scrollRef = useRef(0);

  useEffect(() => {
    const totalWidth = wrapperRef.current.scrollWidth;
    let animationFrameId;

    const scroll = () => {
      const speed = getCarouselSpeed();
      scrollRef.current -= speed;

      if (scrollRef.current <= -Math.floor(totalWidth / 2)) {
        scrollRef.current = 0;
      }

      wrapperRef.current.style.transform = `translateX(${Math.floor(scrollRef.current)}px)`; // Asegurar que el valor sea entero
      animationFrameId = requestAnimationFrame(scroll);
    };

    animationFrameId = requestAnimationFrame(scroll);

    return () => cancelAnimationFrame(animationFrameId);
  }, [items, screenSize]);

  return (
    <div className={classes.carouselContainer}>
      <div ref={wrapperRef} className={classes.carouselWrapper}>
        {[...items, ...items].map((item) => (
          <div className={classes.carouselItem}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  items: PropTypes.array,
};

Carousel.defaultProps = {
  items: [],
};
