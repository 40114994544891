import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    color: '#2C1482',
  },
  title: {
    color: '#2C1482',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '24px',
    textTransform: 'capitalize',
    margin: '0',
  },
  menuIcon: {
    color: '#2C1482',
    fontSize: '40px',
  },
}));
