/* eslint-disable react/forbid-prop-types */
import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import { Button, Container, Typography } from '@material-ui/core';
import useStyles from './useStyles';
import PinDigit from '../PinDigit';

const CODE_LENGTH = new Array(6).fill('');

export const SendCodeDialog = ({
  onCancel,
  onSubmit,
  phone,
  showModal,
  error,
  backPhone,
  title,
  useEditPhone,
  buttonText,
}) => {
  const [values, setValues] = useState(CODE_LENGTH);
  const classes = useStyles();

  const disabled = useMemo(() => values.some((value) => !value), [values]);

  const onChange = useCallback((value) => {
    setValues(value);
  }, [values]);

  const onPaste = useCallback((event, value) => {
    const paste = value.split('');
    let newValues = [...values];

    newValues = newValues.map((v, index) => paste[index] || '');
    setValues(newValues);
  }, [values]);

  const handleOnSubmit = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const code = values.join('');
    onSubmit(event, code);
  }, [values, onSubmit]);

  return (
    <Modal
      open={showModal}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
    >
      <div className={classes.mainContainer}>
        <Typography
          variant="h1"
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          className={classes.subTitle}
        >
          {`Ingresa el código que enviamos al ${phone}`}
        </Typography>
        {error.isError ? (
          <Typography
            variant="body1"
            component="p"
            className={classes.errorText}
            tabIndex={0}
            role="button"
          >
            {error.errorMessage}
          </Typography>
        ) : ''}
        <Container
          component="form"
          onSubmit={handleOnSubmit}
          method="post"
          autoComplete="off"
          maxWidth={false}
          className={classes.form}
        >
          <div
            className={classes.pinDigitContainer}
          >
            <PinDigit
              values={values}
              onChange={onChange}
              onPaste={onPaste}
            />
          </div>
          {useEditPhone ? (
            <Typography
              variant="body1"
              component="p"
              className={classes.text}
            >
              ¿Tu celular es incorrecto?
              <Typography
                variant="body1"
                component="span"
                className={classes.textSpan}
                onClick={backPhone}
                onKeyDown={backPhone}
                tabIndex={0}
                role="button"
              >
                Edítalo
              </Typography>
            </Typography>
          ) : ''}
          <div
            className={classes.buttonContainer}
          >
            <Button
              type="submit"
              className={classes.button}
              disabled={disabled}
            >
              {buttonText}
            </Button>
          </div>
        </Container>
      </div>
    </Modal>
  );
};

SendCodeDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  backPhone: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  useEditPhone: PropTypes.bool.isRequired,
};
