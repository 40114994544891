import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'calc(100% - 56px)',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 65px)',
    },
  },
  pdf: {
    width: '100%',
    height: '100%',
  },
}));
