import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '24px',
    boxSizing: 'border-box',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'baseline',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  imageContent: {
    minWidth: '140px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '180px',
    },
  },
  imageContainer: {
    maxWidth: '120px',
    height: '50px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 10px 0 0',
      maxWidth: '150px',
      height: '80px',
    },
  },
  image: {
    height: '100%',
    objectFit: 'contain',
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  productContainer: {
    flex: 1,
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px',
    },
  },
  productTitle: {
    fontSize: '1rem',
    fontFamily: 'roboto, sans-serif',
    color: '#000',
    lineHeight: '1.25rem',
    marginBottom: '3px',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      marginBottom: '8px',
    },
  },
  product: {
    fontSize: '1rem',
    fontFamily: 'roboto, sans-serif',
    color: '#000',
    lineHeight: '1.25rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  dataContainer: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'center',
    alignItems: 'baseline',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  monthly: {
    minWidth: '140px',
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '180px',
    },
  },
  mensualidad: {
    fontSize: '1rem',
    fontFamily: 'roboto, sans-serif',
    color: '#000',
    lineHeight: '1.25rem',
    marginBottom: '3px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      marginBottom: '8px',
    },
  },
  monthlyPaid: {
    fontSize: '1rem',
    fontFamily: 'roboto, sans-serif',
    color: '#000',
    lineHeight: '1.25rem',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  tasaContainer: {
    flex: 1,
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px',
    },
  },
  tasaInteres: {
    fontSize: '1rem',
    fontFamily: 'roboto, sans-serif',
    color: '#000',
    lineHeight: '1.25rem',
    marginBottom: '3px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      marginBottom: '8px',
    },
  },
  tasa: {
    fontSize: '1rem',
    fontFamily: 'roboto, sans-serif',
    color: '#000',
    lineHeight: '1.25rem',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  catContainer: {
    flex: 1,
    marginLeft: '0px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '10px',
    },
  },
  plazoContainer: {
    flex: 2,
    marginLeft: '0px',
  },
  catInteres: {
    fontSize: '1rem',
    fontFamily: 'roboto, sans-serif',
    color: '#000',
    lineHeight: '1.25rem',
    marginBottom: '3px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      marginBottom: '8px',
    },
  },
  cat: {
    fontSize: '1rem',
    fontFamily: 'roboto, sans-serif',
    color: '#000',
    lineHeight: '1.25rem',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  plazo: {
    fontSize: '13px',
    fontFamily: 'roboto, sans-serif',
  },
  containerDisabled: {
    backgroundColor: '#D9D9D94D',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
  },
  imageProductDisabled: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '140px',
  },
}));
