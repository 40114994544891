/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Grid, Box, Typography,
} from '@material-ui/core';

import useStyles from './useStyles';
import { staticLandingData } from '../../../../../data';

const { dataCreditSteps } = staticLandingData;

const { steps } = dataCreditSteps;

export const CreditSteps = () => {
  const classes = useStyles();
  return (
    <Box id="beneficios" component="section" className={classes.sectionContainer}>
      <Typography className={classes.Title} component="h2">
        {dataCreditSteps.title}
      </Typography>
      <Grid
        className={classes.gridContainer}
        container
        spacing={3}
      >
        {steps.map(({ title, subtitle, img }, index) => (
          <Grid key={index} item md={4} xs={12} sm={12}>
            <Box className={classes.Box}>
              <Typography className={classes.stepTitle} compontent="span">
                {title}
              </Typography>
              <Typography className={classes.stepSubtitle} variant="h6">
                {subtitle}
              </Typography>
              <Box className={classes.Img} component="img" src={img} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
