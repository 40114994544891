import { decryptData, encryptData } from './encryption';

const LEAD_KEY = btoa('LEAD_DATA');

export const storeLeadData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(LEAD_KEY, encryptedData);
};

export const removeLeadData = () => {
  window.localStorage.removeItem(LEAD_KEY);
};

export const getLeadData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(LEAD_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

const getItem = (key) => {
  try {
    const lead = getLeadData();
    return lead[key];
  } catch {
    return undefined;
  }
};

export const getLeadId = () => {
  const leadId = getItem('_id');
  return leadId;
};
