import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Help } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';

import useStyles from './useStyles';
import { InformationDialogContainer } from '../InformationDialogContainer';

export const HelpButton = ({ helpData }) => {
  const classes = useStyles();
  const [showInformationDialog, setShowInformationDialog] = useState(false);

  const childrenContent = () => {
    const { data, type } = helpData;
    if (type === 'list') {
      return (
        <ul>
          {data.map((dataDescription) => (
            <li key={dataDescription}>
              <Typography variant="h6" className={classes.textContent}>
                {dataDescription}
              </Typography>
            </li>
          ))}
        </ul>
      );
    }

    return data.map((dataDescription) => (
      <Typography
        variant="h6"
        className={classes.textContent}
      >
        {dataDescription}
      </Typography>
    ));
  };

  return (
    <Box className={classes.boxContainer}>
      <InformationDialogContainer
        showModal={showInformationDialog}
        onSubmit={() => setShowInformationDialog(false)}
        buttonTitle="Ok"
        title={helpData.title}
      >
        <Box component="div" className={classes.childContainer}>
          {childrenContent()}
        </Box>
      </InformationDialogContainer>
      <Help
        onClick={() => setShowInformationDialog(true)}
        className={classes.helpIcon}
      />
    </Box>
  );
};

HelpButton.propTypes = {
  helpData: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string),
  }),
};

HelpButton.defaultProps = {
  helpData: {
    title: '',
    type: '',
    data: [''],
  },
};
