import { useState, useCallback, useMemo } from 'react';

const useArray = (_values = []) => {
  const [values, setValues] = useState(_values);

  const add = useCallback((value) => {
    const newValues = [...values, value];
    setValues(newValues);
  }, [values]);

  const addByArray = useCallback((value = []) => {
    const newValues = [...value];
    setValues(newValues);
  }, [values]);

  const addMany = useCallback((value = []) => {
    const newValues = [...values, ...value];
    setValues(newValues);
  }, [values]);

  const replace = useCallback((value = [] || '') => {
    const newValues = Array.isArray(value) ? [...value] : [value];
    setValues(newValues);
  }, []);

  const updateByIndex = useCallback((value, index) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
  }, [values]);

  const removeByIndex = useCallback((index) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  }, [values]);

  const empty = useCallback(() => {
    setValues([]);
  }, []);

  const result = useMemo(
    () => (
      {
        values,
        add,
        addByArray,
        addMany,
        replace,
        updateByIndex,
        removeByIndex,
        empty,
      }
    ),
    [
      values,
      add,
      addByArray,
      addMany,
      replace,
      updateByIndex,
      removeByIndex,
      empty,
    ],
  );

  return result;
};

export default useArray;
