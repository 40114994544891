import React, { useState, useEffect } from 'react';
import { Information } from '../../../../../components';

export const WaitInformation = () => {
  const [title, setTitle] = useState('');
  useEffect(() => {
    setTitle('Estamos verificando tus Documentos, regresaremos contigo muy pronto.');
  }, []);

  return (
    <Information
      title={title}
    />
  );
};
