import React, { useMemo } from 'react';

import { Box } from '@material-ui/core';

import { getLogoAndImageTemplate } from '../../utils';

import useStyles from './useStyles';

export const LogoChiplo = () => {
  const classes = useStyles();
  const {
    logoUrl,
  } = useMemo(() => getLogoAndImageTemplate(), []);

  return (
    <Box className={classes.boxImage}>
      <img
        className={classes.imgLogo}
        src={logoUrl}
        alt="imgLogo"
      />
    </Box>
  );
};
