export const sanitizeString = (value) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  return value;
};

export const getFieldValuesInFieldsFormat = (fieldValuesData) => {
  const fieldsFormat = fieldValuesData.map(({ field, value }) => ({ _id: field, value }));
  return fieldsFormat;
};
