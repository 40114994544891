/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, {
  memo, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import isURL from 'is-url';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';

import useStyles from './useStyles';
import { getSignedUrl } from '../../../../../../api/s3';
import DeleteDialog from '../DeleteDialog';
import { Button, Loader } from '../../../../../../components';
import Header from '../Header';
import DocumentButton from '../DocumentButton';
import Input from '../../../../../../components/Input';
import { getFileUploadLabelBasedOnUploadFormats } from '../../../../../../utils/fields';

const FRONT_INDEX = 0; // Note: for reversePhoto flow
const BACK_INDEX = 1; // Note: for reversePhoto flow

export const DocumentUpload = memo(({
  field,
  values,
  reversePhoto,
  isLoading,
  onBack,
  onAddValue,
  onRemoveValue,
  onRemoveAllValues,
  isRejected,
  onChange,
  fieldIndex,
}) => {
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();

  const openFileInANewTab = (value) => async () => {
    if (value instanceof Blob) {
      const url = URL.createObjectURL(value);
      window.open(url, '_blank');
    } else if (isURL(value)) {
      const url = await getSignedUrl(value);
      window.open(url, '_blank');
    } else throw new Error('Invalid Value');
  };

  const openOrAddFile = (index) => (event) => {
    const value = values[index];
    if (value) return openFileInANewTab(value)();
    return onAddValue(event, index); // Note: for reversePhoto flow
  };

  const onDeleteAll = () => {
    onRemoveAllValues();
    setShowModal(false);
  };

  const isReady = useMemo(() => {
    // Note: for reversePhoto flow
    if (reversePhoto) return values[FRONT_INDEX] && values[BACK_INDEX];
    return values.length > 0;
  }, [values, reversePhoto]);

  const shouldShowInput = useMemo(() => {
    const hasNoValues = values.length === 0;
    const isMultiUploadEnabled = field?.config?.multiUpload;
    const isReversePhotoActive = reversePhoto;
    const canShowReversePhotoInput = isReversePhotoActive && values.length < 3;
    if (reversePhoto && values.length === 2) return false;
    return hasNoValues || isMultiUploadEnabled || canShowReversePhotoInput;
  }, [values.length, field?.config?.multiUpload, reversePhoto]);

  return (
    <div className={classes.container}>
      <DeleteDialog
        onCancel={() => setShowModal(false)}
        onDeleteAll={onDeleteAll}
        title="¿Estas seguro que quieres borrar todos los documentos?"
        showModal={showModal}
      />
      <Loader
        open={isLoading}
      />
      <div className={classes.header}>
        <Header
          title="Archivos de tu"
          description={field?.label}
          enableOnBack
          onBack={onBack}
          disabled={isLoading}
        />
      </div>
      { isRejected
        ? (
          <div className={classes.deleteIconContainer}>
            <IconButton
              size="small"
              className={classes.deleteIconButton}
              onClick={() => setShowModal(true)}
              disabled={isLoading}
            >
              <DeleteIcon
                className={classes.deleteIcon}
                color="primary"
              />
            </IconButton>
          </div>
        )
        : ''}
      <div className={classes.iconButtonContainer}>
        <div
          className={classes.documentContainer}
        >
          {
            reversePhoto
              ? (
                <>
                  <DocumentButton
                    text="Frontal"
                    enableOnClick={!isLoading}
                    onClick={openOrAddFile(FRONT_INDEX)}
                    isCompleted={Boolean(values[FRONT_INDEX])}
                    enableOnDelete={!isLoading && Boolean(values[FRONT_INDEX])}
                    onDelete={onRemoveValue(FRONT_INDEX)}
                  />
                  <DocumentButton
                    enableOnClick={!isLoading}
                    text="Reverso"
                    onClick={openOrAddFile(BACK_INDEX)}
                    isCompleted={Boolean(values[BACK_INDEX])}
                    enableOnDelete={!isLoading && Boolean(values[BACK_INDEX])}
                    onDelete={onRemoveValue(BACK_INDEX)}
                  />
                </>
              )
              : (
                values.map((value, index) => (
                  <DocumentButton
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    text={`Documento ${index + 1}`}
                    enableOnClick={!isLoading}
                    onClick={openFileInANewTab(value)}
                    isCompleted
                    enableOnDelete={!isLoading && Boolean(values[index])}
                    onDelete={onRemoveValue(index)}
                  />
                ))
              )
          }
        </div>
        {
          shouldShowInput && (
            <Input
              label={getFileUploadLabelBasedOnUploadFormats(field?.config?.uploadFormats || [])}
              type="document"
              value=""
              onChange={(event, value) => onChange(event, value, fieldIndex, field?.name)}
              hide={false}
              multiUpload={field?.config?.multiUpload || false}
              uploadFormats={field?.config?.uploadFormats || []}
              disableCompleteIcon
            />
          )
        }
      </div>
      <div>
        <Button
          fullWidth
          disabled={!isReady || isLoading}
          onClick={onBack}
        >
          Listo
        </Button>
      </div>
    </div>
  );
});

DocumentUpload.propTypes = {
  field: PropTypes.any,
  values: PropTypes.array.isRequired,
  reversePhoto: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onAddValue: PropTypes.func,
  onRemoveValue: PropTypes.func.isRequired,
  onRemoveAllValues: PropTypes.func.isRequired,
  isRejected: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  fieldIndex: PropTypes.number.isRequired,
};

DocumentUpload.defaultProps = {
  onAddValue: () => {},
  isRejected: false,
};
