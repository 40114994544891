import { useMemo } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  LG_SCREEN,
  MD_SCREEN,
  SM_SCREEN,
  XL_SCREEN,
  XS_SCREEN,
} from '../constants/screen';

const useMediaScreen = () => {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.only(XS_SCREEN));
  const isSm = useMediaQuery(theme.breakpoints.only(SM_SCREEN));
  const isMd = useMediaQuery(theme.breakpoints.only(MD_SCREEN));
  const isLg = useMediaQuery(theme.breakpoints.only(LG_SCREEN));
  const isXl = useMediaQuery(theme.breakpoints.only(XL_SCREEN));

  const screenSize = useMemo(() => {
    switch (true) {
      case isXs:
        return XS_SCREEN;
      case isSm:
        return SM_SCREEN;
      case isMd:
        return MD_SCREEN;
      case isLg:
        return LG_SCREEN;
      case isXl:
        return XL_SCREEN;
      default:
        return 'unknown';
    }
  }, [isXs, isSm, isMd, isLg, isXl]);

  return {
    screenSize,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
  };
};

export default useMediaScreen;
