import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  boxImage: {
    maxHeight: '100px',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '130px',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: '190px',
    },
  },
  imgLogo: {
    height: '100%',
    maxHeight: '100px',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '130px',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: '190px',
    },
  },
}));
