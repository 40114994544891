import { decryptData, encryptData } from './encryption';

const FLOW_KEY = btoa('FLOW_DATA');

export const storeFlowData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(FLOW_KEY, encryptedData);
};

export const removeFlowData = () => {
  window.localStorage.removeItem(FLOW_KEY);
};

export const getFlowData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(FLOW_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

const getItem = (key) => {
  try {
    const flowData = getFlowData();
    return flowData[key];
  } catch {
    return undefined;
  }
};

export const getFlowName = () => {
  const flowName = getItem('name');
  return flowName;
};

export const getFlowLabel = () => {
  const flowLabel = getItem('label');
  return flowLabel;
};

export const getSourceLabel = () => {
  const sourceLabel = getItem('sourceLabel');
  return sourceLabel;
};
