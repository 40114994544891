/* eslint-disable no-unused-vars */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginAgent } from '../pages/Login';
import { Clients } from '../pages/Clients';
import { Credits } from '../pages/Credits';
import { CreditProcess } from '../pages/CreditProcess';
import { AgentRegister } from '../pages/Register';
import { ProtectedRoute } from '../../../components';
import {
  AUTH_ROUTE_TYPE, AGENTE_LOGIN_ROUTE, ONLY_AGENTS_ROUTE_TYPE, LOGIN_ROUTE,
} from '../../../constants';
import CreateClient from '../pages/CreateClient';
import { TabsProvider } from '../../../context/tabs/tabsProvider';
import { PerfiladorPage } from '../pages/PerfiladorPage';

const AgentRoutes = () => (
  <Routes>
    <Route
      path="/login"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath="/agente/clients"
          additionalData={{ authProtected: false }}
        >
          <LoginAgent />
        </ProtectedRoute>
      )}
    />
    <Route
      path="/registro"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath="/agente/clients"
          additionalData={{ authProtected: false }}
        >
          <AgentRegister />
        </ProtectedRoute>
      )}
    />

    <Route
      path="/clients"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath={AGENTE_LOGIN_ROUTE}
          additionalData={{ authProtected: true }}
        >
          <ProtectedRoute
            type={ONLY_AGENTS_ROUTE_TYPE}
            redirectPath={LOGIN_ROUTE}
            additionalData={{ authProtected: true }}
          >
            <Clients />
          </ProtectedRoute>
        </ProtectedRoute>
      )}
    />
    <Route
      path="/clients/create"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath={AGENTE_LOGIN_ROUTE}
          additionalData={{ authProtected: true }}
        >
          <ProtectedRoute
            type={ONLY_AGENTS_ROUTE_TYPE}
            redirectPath={LOGIN_ROUTE}
            additionalData={{ authProtected: true }}
          >
            <CreateClient />
          </ProtectedRoute>
        </ProtectedRoute>
      )}
    />

    <Route
      path="/credits"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath={AGENTE_LOGIN_ROUTE}
          additionalData={{ authProtected: true }}
        >
          <ProtectedRoute
            type={ONLY_AGENTS_ROUTE_TYPE}
            redirectPath={LOGIN_ROUTE}
            additionalData={{ authProtected: true }}
          >
            <Credits />
          </ProtectedRoute>
        </ProtectedRoute>
      )}
    />

    <Route
      path="/perfilador"
      element={(
        <TabsProvider>
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath={AGENTE_LOGIN_ROUTE}
            additionalData={{ authProtected: true }}
          >
            <ProtectedRoute
              type={ONLY_AGENTS_ROUTE_TYPE}
              redirectPath={LOGIN_ROUTE}
              additionalData={{ authProtected: true }}
            >
              <PerfiladorPage />
            </ProtectedRoute>
          </ProtectedRoute>
        </TabsProvider>
      )}
    />

    <Route
      path="/credits/creditprocess"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath={AGENTE_LOGIN_ROUTE}
          additionalData={{ authProtected: true }}
        >
          <ProtectedRoute
            type={ONLY_AGENTS_ROUTE_TYPE}
            redirectPath={LOGIN_ROUTE}
            additionalData={{ authProtected: true }}
          >
            <CreditProcess />
          </ProtectedRoute>
        </ProtectedRoute>
      )}
    />

    <Route path="/*" element={<Navigate to="/agente/clients" />} />
  </Routes>
);

export default AgentRoutes;
