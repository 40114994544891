/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

export const ListItem = ({
  title,
  phone,
  date,
  onClick,
  lastNode,
  userData,
  lastBookElementRef,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      ref={lastNode ? lastBookElementRef : null}
      tabIndex="0"
    >
      <span className={classes.title}>{title}</span>
      <span className={classes.phone}>{phone}</span>
      <span className={classes.date}>{date}</span>
      <span className={classes.date}>{`Agente: ${userData.normalizedName.toUpperCase()}`}</span>
    </div>
  );
};

ListItem.propTypes = {
  title: PropTypes.string,
  phone: PropTypes.string,
  date: PropTypes.string,
  onClick: PropTypes.func,
  lastNode: PropTypes.bool,
  userData: PropTypes.object,
  lastBookElementRef: PropTypes.func,
};

ListItem.defaultProps = {
  title: '',
  phone: '',
  date: '',
  onClick: () => { },
  lastNode: false,
  userData: {},
  lastBookElementRef: () => { },
};
