import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    padding: '10px 24px',
    textAlign: 'center',
    backgroundColor: '#2C1482',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      padding: '10px 48px',
    },
  },
}));
