// !!REMOVE
/* eslint-disable no-unused-vars */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga';

import {
  Box, Container, Grid, Typography,
} from '@material-ui/core';

import {
  AGENTE_CLIENTS_ROUTE,
  AGENTE_LOGIN_ROUTE,
  AGENT_FIELDS_COMPANY_TYPE, COMPANY, LOGIN_PHONE_ID, REJECTED,
} from '../../../../constants';

import { FormLayout } from '../../../main/layouts/FormLayout';
import AlertSnack from '../../../../components/AlertSnack';
import ErrorMessage from '../../../../components/ErrorMessage';
import {
  AlertDialog, Button, GroupFields, Loader,
} from '../../../../components';
import { SideBannerTemplate } from '../../components/SideBannerTemplate';

import { ThemeContext } from '../../../../context/Theme/themeContext';
import { useProduct } from '../../../../hooks/useProduct';
import useLoading from '../../../../hooks/useLoading';
import useError from '../../../../hooks/useError';

import * as companyConfigsAPI from '../../../../api/companyConfigs';
import * as unprotectedAPI from '../../../../api/unprotected';
import * as usersAPI from '../../../../api/users';

import {
  AuthError, getLogoAndImageTemplate, logOutMain, storeAuth,
} from '../../../../utils';

import useStyles from './useStyles';
import { SendCodeDialog } from '../../../../components/SendCodeDialog';
import { InformationDialog } from '../../../../components/InformationDialog';
import { SimpleInputDialog } from '../../../../components/SimpleInputDialog';
import { getConfigs } from '../../../../utils/configs';
import { logout } from '../../../../api/auth';
import { SideBanner } from '../../components/SideBanner';

const DEFAULT_PRODUCT = 'creditohipotecario';
const PHONE = 'phone';
const EMAIL = 'email';
const FIRSTNAME = 'firstName';
const LASTNAME = 'lastName';
const SECOND_LASTNAME = 'secondLastName';

const INITIAL_ERROR = {
  isError: false,
  errorMessage: 'La verificacionestamal',
};
const INITIAL_SIMPLE_INPUT_DIALOG = {
  show: false,
  input: {},
  buttonTitle: 'Actualizar Numero',
  title: 'Ingresa tu numero de telefono',
  error: {
    isError: false,
    errorMessage: '',
  },
};

export const AgentRegister = () => {
  const [searchParams] = useSearchParams();
  const { loadTheme, state: { logo } } = useContext(ThemeContext);
  const { products } = useProduct();
  const product = products[0] || DEFAULT_PRODUCT;

  const [showModal, setShowModal] = useState(false);
  const [idRoles, setIdRoles] = useState(['']);
  const [phoneValue, setPhoneValue] = useState('');
  const [isErrorPhoneValue, setIsErrorPhoneValue] = useState(INITIAL_ERROR);
  const [showSendCodeDialog, setShowSendCodeDialog] = useState(false);
  const [simpleInputDialogData, setSimpleInputDialogData] = useState(INITIAL_SIMPLE_INPUT_DIALOG);
  const [showInformationDialog, setShowInformationDialog] = useState(false);

  const [sections, setSections] = useState([]);
  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();

  const {
    mainImageUrl,
  } = useMemo(() => getLogoAndImageTemplate(product), [product]);

  const navigate = useNavigate();

  const classes = useStyles();

  const isNotCompleted = () => sections.some((section) => {
    const { fields = [] } = section;
    return fields.some(({ config = {}, value = '', status = {} }) => {
      const { value: statusValue } = status;
      const { required = false, hide = false } = config;
      if (!hide && !required && statusValue === REJECTED) return true;
      if (hide || !required) return false;
      return value === '' || value === null || !value.length || statusValue === REJECTED;
    });
  });

  const handleChange = (event, value, position) => {
    const { name } = event.target;
    const newSections = sections.map((section, index) => {
      if (index !== position) return section;
      const newFields = section.fields.map((field) => (field.name === name ? { ...field, value, errorMessage: '' } : field));
      return { ...section, fields: newFields };
    });
    setSections(newSections);
  };

  const findFields = (fieldsNames) => sections.reduce((acc, curr) => {
    const { fields } = curr;
    const filterValues = fields.filter(({ name }) => fieldsNames.includes(name));
    return [...acc, ...filterValues];
  }, []);

  const transformDataToUpload = () => {
    const newFields = sections.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).map(({
      _id, config, value, fieldCatalog,
    }) => ({
      _id,
      config,
      value,
      fieldCatalog: {
        _id: fieldCatalog?._id || '',
      },
    }));
    return {
      company: COMPANY,
      fields: newFields,
    };
  };

  const generateUserDataFromFieldsData = (fieldsData) => {
    const dataObject = {};
    fieldsData.forEach((field) => {
      const { config, name, value } = field;
      const matchColumn = config?.matchColumn || name;
      dataObject[matchColumn] = value;
    });
    return dataObject;
  };

  const normalizeText = (text) => text
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();

  const buildUserObject = ({ fieldsData, roles, userData = {} }) => {
    const dataObject = generateUserDataFromFieldsData(fieldsData);

    const { firstName = '', lastName = '', phone = '' } = dataObject;
    const fullName = `${firstName} ${lastName}`;

    const normalizedName = normalizeText(fullName);
    const phoneComplete = `+521${phone}`;

    const userObject = {
      ...userData,
      ...dataObject,
      roles,
      normalizedName,
      phone: phoneComplete,
    };
    return userObject;
  };

  const createUser = async (fieldsData) => {
    const inmobiliaria = searchParams.get('inmobiliaria');

    const defaultNewUserData = {
      status: true,
      organization: COMPANY,
      organizationModel: 'Company',
      password: '',
      company: COMPANY,
      readSelf: true,
      phoneVerified: true,
      ...(inmobiliaria ? { entity: inmobiliaria } : { }),
    };
    const userData = buildUserObject({
      fieldsData,
      roles: idRoles,
      userData: defaultNewUserData,
    });
    const useResponse = await usersAPI.create(userData);
    return useResponse;
  };

  const generateCode = async (user, phone) => {
    await usersAPI.generatePhoneCode(user);
    setPhoneValue(phone);
    setShowSendCodeDialog(true);
  };

  const checkPhoneAlreadyExists = async (user) => {
    const existsResponse = await usersAPI.verifyPhoneAlreadyExists(user);
    const { exists = false } = existsResponse;
    return exists;
  };

  const getFullName = () => {
    const arrayFields = findFields([FIRSTNAME, LASTNAME, SECOND_LASTNAME]);

    const fullName = arrayFields.reduce((acc, curr) => {
      const { value } = curr;
      return acc ? `${acc} ${value}` : `${value}`;
    }, '');
    return fullName;
  };

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      if (isNotCompleted()) return;
      startLoading();
      const [{ value: userPhone }] = findFields(PHONE);
      const fullName = getFullName();

      const userObject = {
        company: COMPANY,
        phone: `+521${userPhone}`,
        fullName,
      };

      const phoneExists = await checkPhoneAlreadyExists(userObject);
      if (phoneExists) {
        endLoading();
        setShowInformationDialog(true);
        return;
      }
      setSimpleInputDialogData(INITIAL_SIMPLE_INPUT_DIALOG);
      await generateCode(userObject, userPhone);
      endLoading();
    } catch (e) {
      console.log('e: ', e);
      if (e.response) {
        if (e.response.status === 422) {
          setShowInformationDialog(true);
        } else if (e.response.status === 409) {
          setShowModal(true);
        } else {
          showError('Error:', e.message);
        }
      } else if (e.message) {
        showError(e.message, e.name || 'Error al crear el usuario');
      } else {
        showError('Error inesperado');
      }
      endLoading();
      if (e instanceof AuthError) setShowModal(true);
    }
  };

  const onSubmitPhone = async (event, value) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      startLoading();

      const userObject = {
        company: COMPANY,
        phone: `+521${value}`,
        fullName: getFullName(),
      };

      const phoneExist = await checkPhoneAlreadyExists(userObject);

      if (phoneExist) {
        setShowInformationDialog(true);
        endLoading();
        return;
      }

      setSimpleInputDialogData(INITIAL_SIMPLE_INPUT_DIALOG);
      await generateCode(userObject, value);
      endLoading();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
      if (e instanceof AuthError) setShowModal(true);
    }
  };

  const onLogout = async () => {
    await logOutMain();
    await logout();
  };

  const getValueFromFieldsByName = (fieldName) => {
    const [foundField] = findFields([fieldName]);
    const { value = '' } = foundField;
    return value;
  };

  const onSubmitCode = async (event, code) => {
    try {
      await onLogout();
      startLoading();
      setShowSendCodeDialog(false);
      const correctPhone = `+521${phoneValue}`;
      const email = getValueFromFieldsByName(EMAIL);

      const userObject = {
        phone: correctPhone,
        code,
        model: 'agentAssigned',
        modelId: COMPANY,
        roles: idRoles,
        email,
      };
      const { verified } = await usersAPI.verifyPhoneCode(correctPhone, code);
      if (verified) {
        const dataToUpload = transformDataToUpload();
        const { fields } = dataToUpload;
        await createUser(fields);
        const { user } = await usersAPI.loginByPhoneCode(userObject);

        storeAuth(user);
        endLoading();
        navigate(AGENTE_CLIENTS_ROUTE);
      } else {
        setShowSendCodeDialog(true);
        setIsErrorPhoneValue({
          isError: true,
          errorMessage: 'Error al verificar el numero',
        });
      }
      endLoading();
    } catch (e) {
      console.log('e: ', e);
      if (e.response) {
        if (e.response.status === 422) {
          setShowInformationDialog(true);
        } else if (e.response.status === 409) {
          setShowModal(true);
        } else {
          showError('Error:', e.message);
        }
      } else if (e.message) {
        showError(e.message, e.name || 'Error al crear el usuario');
      } else {
        showError('Error inesperado');
      }
      endLoading();
      if (e instanceof AuthError) setShowModal(true);
    }
  };

  const getClientsFields = async () => {
    const companyConfigs = await companyConfigsAPI.getAllCompanyConfigs(COMPANY);
    const companyConfig = companyConfigs.find(({ type: { name = '' } = {} }) => name === AGENT_FIELDS_COMPANY_TYPE);
    return companyConfig?.payload;
  };

  const getFieldsData = (fieldsIds) => fieldsIds.map((fieldId) => unprotectedAPI.getById(fieldId));

  const setDefaultValues = (fields) => fields.map((field) => {
    const { value = '', config = {} } = field;
    return !value && config?.defaultValue
      ? { ...field, value: config?.defaultValue }
      : { ...field, value };
  });

  const getFieldsStructure = async (payload) => {
    const fieldsData = await Promise.all(getFieldsData(payload.fields));
    const fieldsDataWithValues = setDefaultValues(fieldsData);

    const fieldsSubtitleData = payload?.fieldsSubtitle
      ? await Promise.all(getFieldsData(payload.fieldsSubtitle))
      : [];
    const fieldsSubtitleDataWithValues = setDefaultValues(fieldsSubtitleData);
    return [{
      fields: fieldsDataWithValues,
      title: payload.title,
    },
    {
      fields: fieldsSubtitleDataWithValues,
      title: payload?.subtitle || '',
    },
    ];
  };

  const fetchAgentRolesId = async () => {
    const payloadConfig = await getConfigs(LOGIN_PHONE_ID);
    const { agentRoles } = payloadConfig;
    setIdRoles(agentRoles);
  };

  const fetchData = async () => {
    try {
      startLoading();
      const payload = await getClientsFields();
      const fieldsData = await getFieldsStructure(payload);
      await fetchAgentRolesId();
      setSections(fieldsData);
      endLoading();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to fetch  Data');
      endLoading();
    }
  };

  const backPhone = () => {
    setShowSendCodeDialog(false);
    const [inputPhone] = findFields([PHONE]);
    setSimpleInputDialogData({ ...INITIAL_SIMPLE_INPUT_DIALOG, show: true, input: inputPhone });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const asyncLoadTheme = async () => {
    await loadTheme(products[0]);
  };

  useEffect(() => {
    asyncLoadTheme();
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container
      disableGutters
      component="div"
      maxWidth={false}
      className={classes.mainContainer}
    >
      <AlertSnack
        isOpen={isError}
        type="error"
        onClose={hideError}
      >
        <ErrorMessage
          errorType={errorType}
          errorMessage={errorMessage}
        />
      </AlertSnack>
      <AlertDialog
        onCancel={() => setShowModal(false)}
        onAccept={() => navigate(AGENTE_LOGIN_ROUTE)}
        title="Intentas registrate con un correo existente"
        showModal={showModal}
        onCancelText="Registrarse con otro correo"
        onAcceptText="Ir al login"
      />
      <SendCodeDialog
        onCancel={() => setShowSendCodeDialog(false)}
        backPhone={backPhone}
        onSubmit={onSubmitCode}
        phone={phoneValue}
        showModal={showSendCodeDialog}
        error={isErrorPhoneValue}
        title="Verifica tu celular"
        useEditPhone
        buttonText="Continuar"
      />
      <InformationDialog
        showModal={showInformationDialog}
        onSubmit={() => navigate(AGENTE_LOGIN_ROUTE)}
        buttonTitle="Ingresar a Chiplo"
        descripcion="El celular que estás ingresando con nosotros ya tiene una cuenta. Por favor ingresa a Chiplo con tu cuenta."
      />
      <SimpleInputDialog
        onCancel={() => setSimpleInputDialogData({ ...INITIAL_SIMPLE_INPUT_DIALOG, show: false })}
        onSubmit={onSubmitPhone}
        field={simpleInputDialogData.input}
        showModal={simpleInputDialogData.show}
        error={simpleInputDialogData.error}
        title={simpleInputDialogData.title}
        buttonTitle={simpleInputDialogData.buttonTitle}
      />
      <Loader open={isLoading} />
      <Grid container className={classes.gridContaienr}>
        <Box
          component={Grid}
          item
          md={4}
          sm={4}
          xs={12}
          className={classes.gridLogo}
        >
          <SideBannerTemplate
            logoUrl={logo}
            mainImageUrl={mainImageUrl}
          />
        </Box>
        <Grid item md={8} xs={12} sm={8} className={classes.gridItem}>
          <Box
            className={classes.sideBarContainer}
          >
            <SideBanner logoUrl={logo} mainImageUrl={mainImageUrl} />
          </Box>
          <FormLayout
            submit={onSubmit}
            fullHeight
            hide={!sections.length}
            renderButton={() => (
              <>
                <div
                  className={classes.buttonContainer}
                >
                  <Button
                    className={classes.button}
                    type="submit"
                  >
                    Continuar
                  </Button>
                </div>
                <Box className={classes.boxNotAccount}>
                  <Typography component="span" variant="h6" className={classes.notAccount}>
                    ¿Ya tienes una cuenta?
                  </Typography>
                  <Typography
                    component="span"
                    onClick={() => navigate('/agente/login')}
                    variant="h6"
                    className={classes.redirect}
                  >
                    ¡Ingresa!
                  </Typography>
                </Box>
              </>
            )}
          >
            <div
              className={classes.innerContainer}
            >
              {
                sections.map(({ fields, title }, index) => (
                  <GroupFields
                    fields={fields}
                    title={title}
                    position={index}
                    customTitleClass={classes.titleSection}
                    onChange={handleChange}
                  />
                ))
              }
            </div>
          </FormLayout>

        </Grid>
      </Grid>
    </Container>
  );
};
