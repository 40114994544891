/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './useStyles';
import Bank from './Bank';
import BankCheckbox from './BankCheckbox';

export const Banks = ({
  initialBanks,
  useCheckbox,
  onChangeCheckbox,
  showCat,
  showProductName,
  hideRejecteds,
}) => {
  const classes = styles();
  return (
    <div className={classes.container}>
      {
        initialBanks.map(({
          imageUrl,
          monthlyPaid,
          tasa,
          id,
          checked,
          cat,
          product,
          rejected = false,
          loanType = '',
          year = null,
          plazo = null,
          amortization = [],
        }, index) => {
          if (rejected && hideRejecteds) {
            return null;
          }
          return (useCheckbox ? (
            <div key={id} className={classes.bankCheckboxContainer}>
              <BankCheckbox
                imageUrl={imageUrl}
                id={id}
                monthlyPaid={monthlyPaid}
                tasa={tasa}
                firstElement={index === 0}
                lastElement={index === (initialBanks.length - 1)}
                checked={checked}
                onCheck={() => onChangeCheckbox(!checked, product)}
                cat={cat}
                product={product}
                disabled={rejected}
                amortization={amortization}
                loanType={loanType}
                year={year}
                plazo={plazo}
              />
            </div>
          ) : (
            <div
              key={id}
              className={clsx(classes.mainElement, {
                [classes.firstElement]: index === 0,
                [classes.lastElement]: index === (initialBanks.length - 1),
              })}
            >
              <Bank
                imageUrl={imageUrl}
                monthlyPaid={monthlyPaid}
                tasa={tasa}
                disabled={rejected}
                loanType={loanType}
                year={year}
                plazo={plazo}
                amortization={amortization}
                showCat={showCat}
                showProduct={showProductName}
                cat={cat}
                product={product || ''}
              />
            </div>
          ));
        })
      }
    </div>
  );
};

Banks.propTypes = {
  initialBanks: PropTypes.array,
  useCheckbox: PropTypes.bool,
  onChangeCheckbox: PropTypes.func,
  showCat: PropTypes.bool,
  hideRejecteds: PropTypes.bool,
  showProductName: PropTypes.bool,
};

Banks.defaultProps = {
  initialBanks: [],
  useCheckbox: false,
  onChangeCheckbox: () => { },
  showCat: false,
  hideRejecteds: false,
  showProductName: false,
};

export default memo(Banks);
