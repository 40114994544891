import { COFINANCIAMIENTO_FIELD_ID, COFINANCIAMIENTO_FIELD_NAME } from '../constants';
import { getFieldValuesInFieldsFormat } from '../containers/main/utils/fields';
import { getLocalFieldValuesData, storeFieldValuesData } from '../storage';

export const getLocalFieldValueByFieldId = (fieldId) => {
  try {
    const fieldValuesItem = getLocalFieldValuesData();
    const fieldValue = fieldValuesItem.find(({ field }) => (field === fieldId));
    return fieldValue;
  } catch {
    return undefined;
  }
};

export const getLocalFieldValueByFieldName = (fieldName) => {
  try {
    const fieldValuesItem = getLocalFieldValuesData();
    const fieldValue = fieldValuesItem.find(({ name }) => (name === fieldName));
    return fieldValue;
  } catch {
    return undefined;
  }
};

export const saveExtraDataFieldValues = (extraData) => {
  const fieldValuesData = getLocalFieldValuesData();
  storeFieldValuesData([
    ...fieldValuesData,
    ...extraData,
  ]);
};

export const getAndFormatLocalFieldValuesToUpload = (process) => {
  const fieldValuesData = getLocalFieldValuesData();
  const newFieldValues = fieldValuesData.map(({ field, value }) => ({ field, value, process }));
  return newFieldValues;
};

export const getLocalFieldValuesInFieldsFormat = () => {
  const fieldValuesData = getLocalFieldValuesData();
  return getFieldValuesInFieldsFormat(fieldValuesData);
};

export const formatSharedFieldValuesToFieldValues = (sharedFieldValues) => {
  const fieldValuesFormatted = sharedFieldValues.map(({ field, value }) => ({
    field: field._id,
    value,
    name: field.name,
  }));
  return fieldValuesFormatted;
};

export const updateOrAddFieldValueToArray = (fieldValues, { field, value, name = '' }) => {
  const index = fieldValues.findIndex((fv) => fv.field === field);
  const updatedField = { field, value, name };

  if (index !== -1) {
    const existing = fieldValues[index];
    updatedField.name = existing.name || name;
    return [
      ...fieldValues.slice(0, index),
      updatedField,
      ...fieldValues.slice(index + 1),
    ];
  }
  return [...fieldValues, updatedField];
};

export const indexFieldValuesByField = (fieldValues) => fieldValues.reduce((acc, curr) => {
  acc[curr.field] = curr;
  return acc;
}, {});

export const addProcessToFieldValues = (fieldValues, process = '') => {
  const newFieldValues = fieldValues.map((fv) => ({
    ...fv,
    process,
  }));
  return newFieldValues;
};

export const storeCofinanciamientoLocalFieldValue = (value) => {
  const fieldValue = {
    field: COFINANCIAMIENTO_FIELD_ID,
    value,
    name: COFINANCIAMIENTO_FIELD_NAME,
  };
  saveExtraDataFieldValues([fieldValue]);
};

export const formatFieldValueToStore = (field, value, process) => ({
  process,
  field: field._id,
  config: field.config,
  value,
});

export const formatFieldValueToLocal = (field) => ({
  field: field._id,
  config: field.config,
  name: field.name,
  value: field.value || '',
});
