export const CIEC = 'ciec';
export const SATWS_COMPLETED = 'SATWS_COMPLETED';
export const PRE_APROVED = 'pre-aprobado';
export const REJECTED_CIEC = 'rechazado';
export const DATOS_SOLICITANTE_LABEL = 'Datos del solicitante';
export const CONSULTA_BURO_LABEL = 'Consulta buró y SAT';
export const OFERTA_CREDITO_LABEL = 'Oferta crédito';
export const FORMALIZATION_LABEL = 'Formalización';
export const NORMALIZED_FORMALIZACION_STEP_NAME = 'formalizacion';
export const UPLOAD_LABEL = 'Consulta buró y SAT';
export const OFFER_FACTORAJE_LABEL = 'Oferta factoraje';
export const FACTORAJE_LABEL = 'Oferta factoraje';
export const COTIZATION_LABEL = 'cotization label';

export const DATOS_SOLICITANTE_NAME = 'data';
export const CONSULTA_BURO_NAME = 'consult';
export const OFERTA_CREDITO_NAME = 'offer';
export const PERFILAMIENTO_CREDITICIO_NAME = 'perfilamiento';
export const PERFILAMIENTO_COTIZACION_NAME = 'perfilamientocotizacion';
export const FORMALIZATION_NAME = 'formalization';
export const FACTORAJE_PAGE_NAME = 'offerfactoraje';
export const UPLOAD_NAME = 'upload';
export const OFFER_FACTORAJE_NAME = 'offerfactoraje';
export const COTIZATION_NAME = 'cotizacion';
export const CREATE_ACCOUNT_NAME = 'account';
export const PERFILAMIENTO_NAME = 'perfilamiento';
export const FORMALIZATION_MODAL = 'FORMALIZATION_MODAL';
export const APPLICATION_NAME = 'aplicacion';

export const DATOS_SOLICITANTE_ROUTE = '/';
export const CONSULTA_BURO_ROUTE = '/main/consult';
export const OFERTA_CREDITO_ROUTE = '/main/offer';
export const FORMALIZATION_ROUTE = '/main/formalization';
export const UPLOAD_ROUTE = '/main/upload';
export const OFFER_FACTORAJE_ROUTE = '/main/offerfactoraje';
export const FACTORAJE_ROUTE = '/main/factoraje';
export const COTIZATION_ROUTE = '/main/cotization';

export const COTIZATION_PAGE = {
  label: COTIZATION_LABEL,
  completed: false,
  route: COTIZATION_ROUTE,
  name: COTIZATION_NAME,
};

export const FACTORAJE = {
  label: FACTORAJE_LABEL,
  completed: false,
  route: FACTORAJE_ROUTE,
  name: FACTORAJE_PAGE_NAME,
};

export const DATOS_SOLICITANTE = {
  completed: true,
  route: DATOS_SOLICITANTE_ROUTE,
  label: DATOS_SOLICITANTE_LABEL,
  name: DATOS_SOLICITANTE_NAME,
};

export const CONSULTA_BURO = {
  completed: false,
  route: CONSULTA_BURO_ROUTE,
  label: CONSULTA_BURO_LABEL,
  name: CONSULTA_BURO_NAME,
};

export const UPLOAD = {
  label: UPLOAD_LABEL,
  completed: false,
  route: UPLOAD_ROUTE,
  name: UPLOAD_NAME,
};

export const OFERTA_CREDITO = {
  completed: false,
  route: OFERTA_CREDITO_ROUTE,
  label: OFERTA_CREDITO_LABEL,
  name: OFERTA_CREDITO_NAME,
};

export const OFERTA_FACTORAJE = {
  completed: false,
  route: OFFER_FACTORAJE_ROUTE,
  label: OFFER_FACTORAJE_LABEL,
  name: OFFER_FACTORAJE_NAME,
};

export const FORMALIZATION = {
  completed: false,
  route: FORMALIZATION_ROUTE,
  label: FORMALIZATION_LABEL,
  name: FORMALIZATION_NAME,
};
