import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  submitButton: {
    padding: theme.spacing(1.5, 1),
    textTransform: 'initial',
    fontSize: '18px',
    fontFamily: 'Open Sans',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000',
    backgroundColor: '#FFA500',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '414px',
    minHeight: '58px',
    '&:hover': {
      backgroundColor: '#FFA500',
      opacity: '0.6',
    },
  },
  overLay: {
    zIndex: 9999,
  },
}));
