import {
  createLead, notificationAnalystLead, notifyLead, updateLeadById,
} from '../../api/leads';

import { getTagsStepsByLocalSteps } from '../tags';

import { COMPANY } from '../../constants';

const getBaseUrlFront = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return 'http://localhost:3000';
    default:
      return window.location.hostname;
  }
};

const notify = async (_id) => {
  const baseUrl = getBaseUrlFront();
  const url = `${baseUrl}/account?client=${_id}#cotizador`;
  await notifyLead(COMPANY, _id, { url });
};

export const createLeadAndNotify = async (body, sendWhatsapp = false) => {
  const createdLead = await createLead(body);
  await notificationAnalystLead(COMPANY, createdLead._id);
  if (sendWhatsapp) await notify(createdLead._id);
  return createdLead;
};

export const updateLeadWithTagSteps = async (leadData) => {
  const tags = getTagsStepsByLocalSteps();
  const body = { ...leadData, tags };
  return updateLeadById(leadData._id, body);
};
