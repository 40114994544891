import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  InputLabel: {
    color: '#000000',
    fontFamily: 'Open sans, sans-serif',
    fontWeight: '700',
    fontSize: '1rem',
    display: 'block',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  },

  Select: {
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #000',
  },

  InputLabelPropsFocused: {
    color: '#7C839D !important',
  },

  // RedditTextField Reference
  InputPropsRoot: {
    padding: '13px 12px',
    border: '1px solid #FFFFFF',
    textAlign: 'left',
    borderRadius: '4px !important',
    backgroundColor: '#FFFFFF !important',
    overflow: 'hidden',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },

  InputPropsDisabled: {
    backgroundColor: '#e0e0e0 !important',
    borderColor: '#e0e0e0',
    '&:hover': {
      borderColor: '#e0e0e0',
    },
  },

  InputPropsFocused: {
    backgroundColor: '#fff !important',
    borderColor: theme.palette.primary.main,
  },
  error: {
    border: '1px solid #f44336',
  },
  hide: {
    display: 'none',
  },
}));
