import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    borderLeft: '1px solid #bdbdbd',
    marginLeft: '12px',
    paddingLeft: '24px',
    paddingRight: '8px',
  },
  innerContainer: {
    width: '100',
    display: 'flex',
    flexDirection: 'column',
  },
}));
