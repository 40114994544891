/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/forbid-prop-types */

import React, {
  memo,
  useRef,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/es';
import DateMomentUtils from '@date-io/moment';

import useEnabler from '../../../hooks/useEnabler';

import useStyles from './useStyles';
import CustomToolbar from '../../CustomToolbar';

const LOCALE = 'es';
const ERROR_MESSAGE = 'La fecha no está en el formato correcto:';
const ERROR_MESSAGE_EMPTY = 'Rellena este campo';
moment.locale(LOCALE);
const FORMAT = 'DD/MM/YYYY';

const convertingDate = (valueDate, oldFormat, newFormat = FORMAT) => {
  const parsedDate = moment(valueDate, oldFormat);
  const formattedDate = parsedDate.format(newFormat);
  return formattedDate;
};

const InputBaseDatePicker = ({
  id,
  name,
  label,
  format,
  value,
  required,
  isEmpty,
  disabled,
  className,
  fullWidth,
  onChange,
  onKeyDown,
  rejections,
}) => {
  const dateInputFormat = FORMAT;
  const classes = useStyles();
  const inputRef = useRef();
  const [date, setDate] = useState(null);

  const {
    isEnabled: isRejected,
    enable: enableRejected,
  } = useEnabler();

  const {
    isEnabled: isError,
    enable: enableError,
  } = useEnabler();

  let errorMessage = value ? `${ERROR_MESSAGE} ${dateInputFormat}` : ERROR_MESSAGE_EMPTY;
  errorMessage = isError ? errorMessage : '';
  errorMessage = isRejected ? `${rejections.reason}-${rejections.comments}` : errorMessage;

  useEffect(() => {
    if (rejections) {
      enableRejected();
    }
  }, [enableRejected, rejections]);

  useEffect(() => {
    if (isEmpty) {
      enableError();
    }
  }, [enableError, isEmpty]);

  const formatDate = (_value) => {
    if (!_value) return null;
    const dateParsed = Date.parse(_value);
    if (Number.isNaN(dateParsed)) {
      return moment(_value, dateInputFormat);
    }
    return moment(_value);
  };

  const handleDateChange = (event) => {
    setDate(formatDate(event));
    const formattedDate = dateInputFormat && event ? moment(event).format(format) : event;
    // setDate(formattedDate);
    onChange({ target: { value: event, name } }, formattedDate);
  };

  useEffect(() => {
    const newValue = convertingDate(value, format, 'MM/DD/YYYY');
    if (value && (date !== newValue)) {
      setDate(formatDate(newValue));
    }
  }, [value]);

  useEffect(() => {
    if (!inputRef.current) return;

    const [input] = inputRef.current.getElementsByTagName('input');
    if (isError) input.setCustomValidity(errorMessage);
    else input.setCustomValidity('');
  }, [isError]);
  // GALJ950602HMC
  return (
    <MuiPickersUtilsProvider utils={DateMomentUtils} locale={LOCALE}>
      <KeyboardDatePicker
        id={id}
        name={name}
        label={label}
        placeholder="DD/MM/AAAA"
        value={date}
        ToolbarComponent={(props) => <CustomToolbar {...props} />}
        required={required}
        disabled={disabled}
        format={dateInputFormat}
        className={clsx(classes.textField, className)}
        fullWidth={fullWidth}
        variant="inline"
        inputVariant="filled"
        InputLabelProps={{
          classes: {
            root: classes.InputLabelPropsRoot,
            focused: classes.InputLabelPropsFocused,
          },
        }}
        InputProps={{
          ref: inputRef,
          classes: {
            root: classes.InputPropsRoot,
            focused: classes.InputPropsFocused,
            input: classes.InputPropsInput,
            disabled: classes.InputPropsDisabled,
            error: classes.InputPropsError,
          },
          disableUnderline: true,
        }}
        onChange={handleDateChange}
        onKeyDown={onKeyDown}
        error={isError || isRejected}
        helperText={errorMessage}
        autoOk
      />
    </MuiPickersUtilsProvider>
  );
};

InputBaseDatePicker.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  format: PropTypes.string,
  required: PropTypes.bool,
  isEmpty: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  onKeyDown: PropTypes.func,
  rejections: PropTypes.any,
  onChange: PropTypes.func,
};

InputBaseDatePicker.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  value: undefined,
  required: false,
  isEmpty: undefined,
  format: 'DD/MM/YYYY',
  disabled: false,
  className: '',
  fullWidth: true,
  onChange: () => { },
  onKeyDown: () => { },
  rejections: null,
};

export default memo(InputBaseDatePicker);
