import React, { useEffect } from 'react';

import * as googleAnalytics from '../../../../utils/googleAnalytics/events';
import { LandingLayout } from '../../../landing/layouts/LandingLayout';
import { HeaderBar } from '../../../landing/components/HeaderBar';
import { FooterInfoImages } from '../../../../components/FooterInfoImages';
import { LabelInfo } from '../../../../components/LabelInfo';
import { FAQ } from '../../../../components/FAQ';
import useStyles from './useStyles';

import infoStepsJson from '../../../../data/Faq.json';

export const FAQPage = () => {
  const classes = useStyles();
  const { title, questions } = infoStepsJson;
  useEffect(() => {
    googleAnalytics.visitaChiplo();
  }, []);

  return (
    <LandingLayout>
      <HeaderBar
        navigateOnLogoClick
      />
      <LabelInfo label="Preguntas frecuentes" />
      <div className={classes.container}>
        <FAQ questions={questions} title={title} />
      </div>
      <FooterInfoImages />
    </LandingLayout>
  );
};
