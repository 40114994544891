import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    margin: '2rem auto 2rem auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '0',
    },
  },
  bestOptionContainer: {
    left: '11px',
    top: '179px',
    border: '2px solid #2C1482',
    borderRadius: '15px',

    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    backgroundColor: '#F7FAFD',
    maxWidth: '100%',
    margin: 'auto',

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleBestOption: {
    fontFamily: 'Open Sans',
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '2rem',
    color: theme.palette.primary.main,
  },
}));
