/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as googleAnalytics from '../../utils/googleAnalytics/events';
import useStyles from './useStyles';
import * as NodeApi from '../../api/node';
import { getProcess } from '../../utils/auth';

export const DownloadButton = ({
  title,
  buttonTitle,
  setIsLoading,
  nodeAutoExe,
}) => {
  const classes = useStyles();

  const AutoNodeCreateContract = async () => {
    const process = getProcess();
    const bodyBatch = {
      nodeConfig: nodeAutoExe[0],
      process,
    };
    const bodyDigitalTemplate = {
      nodeConfig: nodeAutoExe[1],
      process,
    };
    await NodeApi.autoExecuteNode(bodyBatch);
    return NodeApi.autoExecuteNode(bodyDigitalTemplate);
  };
  const onDownload = async () => {
    setIsLoading(true);
    const urlAutoFilled = await AutoNodeCreateContract();
    googleAnalytics.descargaSolicitud();
    window.open(urlAutoFilled.signedURL, '_blank');
    setIsLoading(false);
  };
  return (
    <div className={classes.container}>
      {title ? (
        <Typography
          variant="h2"
          className={classes.mensualidad}
        >
          {title}
        </Typography>
      ) : ''}
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          startIcon={<CloudDownloadIcon fontSize="large" className={classes.icon} />}
          className={classes.button}
          onClick={onDownload}
        >
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
};

DownloadButton.propTypes = {
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  setIsLoading: PropTypes.func,
  nodeAutoExe: PropTypes.array,
};

DownloadButton.defaultProps = {
  title: '',
  buttonTitle: '',
  setIsLoading: () => { },
  nodeAutoExe: [],
};
