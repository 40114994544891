import { INIT_CLIENT_REDUCER, SELECT_PROCESS_CLIENT_REDUCER } from '../../constants';

export const clientReducer = (state, action) => {
  switch (action.type) {
    case INIT_CLIENT_REDUCER:
      return {
        ...state,
        processes: action.payload.processes,
        title: action.payload.title,
        currentProcess: '',
      };
    case SELECT_PROCESS_CLIENT_REDUCER:
      return {
        ...state,
        currentProcess: action.payload,
      };
    default:
      return { ...state };
  }
};
