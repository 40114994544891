/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import useStylesInputBase from '../InputBase/useStyles';

const InputAutocomplete = ({
  id,
  name,
  label,
  value,
  valueInput,
  options,
  filterOptions,
  filterSelectedOptions,
  required,
  disabled,
  loading,
  className,
  fullWidth,
  onChange,
  onChangeInput,
}) => {
  const classesInputBase = useStylesInputBase();

  return (
    <Autocomplete
      value={value}
      options={options}
      filterOptions={filterOptions}
      onChange={onChange}
      autoComplete
      includeInputInList
      filterSelectedOptions={filterSelectedOptions}
      renderInput={(params) => {
        params.InputLabelProps.classes = {};
        params.InputLabelProps.classes.root = classesInputBase.InputLabelPropsRoot;
        params.InputLabelProps.classes.focused = classesInputBase.InputLabelPropsFocused;

        params.InputProps.classes = {};
        params.InputProps.classes.root = classesInputBase.InputPropsRoot;
        params.InputProps.classes.focused = classesInputBase.InputPropsFocused;
        params.InputProps.classes.input = classesInputBase.InputPropsInput;
        params.InputProps.classes.disabled = classesInputBase.InputPropsDisabled;
        params.InputProps.classes.error = classesInputBase.InputPropsError;

        params.InputProps.disableUnderline = true;

        return (
          <TextField
            {...params}
            id={id}
            name={name}
            label={label}
            type="text"
            required={required}
            disabled={disabled}
            className={className}
            fullWidth={fullWidth}
            variant="filled"
            value={valueInput}
            onChange={onChangeInput}
          />
        );
      }}
      disabled={disabled}
      loading={loading}
    />
  );
};

InputAutocomplete.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  valueInput: PropTypes.any,
  options: PropTypes.array,
  filterOptions: PropTypes.func,
  filterSelectedOptions: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeInput: PropTypes.func,
};

InputAutocomplete.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  value: undefined,
  valueInput: undefined,
  options: [],
  filterOptions: (x) => x,
  filterSelectedOptions: true,
  required: false,
  disabled: false,
  loading: false,
  className: '',
  fullWidth: true,
  onChange: () => {},
  onChangeInput: () => {},
};

export default memo(InputAutocomplete);
