import { storeExtraPerfiladorData } from '../storage';
import { convertirANumero } from './currency';

export const storeIngresosData = (value) => {
  storeExtraPerfiladorData({
    ingresos: convertirANumero(value),
  });
};

export const storePerfiladorValuePropiety = (value) => {
  storeExtraPerfiladorData({
    valuePropNumber: convertirANumero(value),
  });
};
