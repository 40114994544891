const PRODUCT_KEY = btoa('PRODUCT_DATA');

export const storeProductData = (data) => {
  window.localStorage.setItem(PRODUCT_KEY, JSON.stringify(data));
};

export const getProductData = () => {
  const jsonData = window.localStorage.getItem(PRODUCT_KEY);
  return JSON.parse(jsonData);
};

export const removeProductData = () => {
  window.localStorage.removeItem(PRODUCT_KEY);
};

export const getProductItem = (key) => {
  try {
    const themeData = getProductData();
    return themeData[key];
  } catch {
    return undefined;
  }
};
