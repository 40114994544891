import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Checkbox } from '@material-ui/core';
import styles from './useStyles';

const CheckboxItem = ({
  firstElement,
  lastElement,
  checked,
  onCheck,
  label,
}) => {
  const classes = styles();
  return (
    <div className={classes.container}>
      <div className={classes.containerCheckbox}>
        <Checkbox
          className={classes.checkbox}
          checked={checked}
          onChange={onCheck}
        />
      </div>
      <div className={clsx(classes.mainElement, {
        [classes.firstElement]: firstElement,
        [classes.lastElement]: lastElement,
      })}
      >
        <span className={classes.label}>{label}</span>
      </div>
    </div>
  );
};

CheckboxItem.propTypes = {
  firstElement: PropTypes.bool,
  lastElement: PropTypes.bool,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  label: PropTypes.string,
};

CheckboxItem.defaultProps = {
  firstElement: false,
  lastElement: false,
  checked: false,
  onCheck: () => { },
  label: '',
};

export default memo(CheckboxItem);
