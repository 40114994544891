import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './useStyles';
import { Button } from '../../../../components';

export const FormLayout = ({
  children,
  title,
  className,
  submit,
  buttonTitle,
  subTitle,
  hide,
  disabled,
  fullHeight,
  formRef,
  titleContainerClass,
}) => {
  const classes = useStyles();
  return (
    <Container
      component="form"
      onSubmit={submit}
      method="post"
      autoComplete="off"
      maxWidth={false}
      className={clsx(classes.container, className, {
        [classes.hide]: hide,
        [classes.fullHeight]: fullHeight,
      })}
      ref={formRef}
    >
      {(title || subTitle) ? (
        <Container
          component="div"
          maxWidth={false}
          className={clsx(classes.containerTitles, titleContainerClass)}
        >
          {title ? <Typography className={classes.title}>{title}</Typography> : ''}
          {subTitle ? <Typography className={classes.subTitle}>{subTitle}</Typography> : ''}
        </Container>
      ) : ''}

      {children}
      <div
        className={classes.buttonContainer}
      >
        <Button
          disabled={disabled}
          className={classes.button}
          type="submit"
        >
          {buttonTitle}
        </Button>
      </div>
    </Container>
  );
};

FormLayout.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  submit: PropTypes.func,
  buttonTitle: PropTypes.string,
  subTitle: PropTypes.string,
  hide: PropTypes.bool,
  disabled: PropTypes.bool,
  fullHeight: PropTypes.bool,
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  titleContainerClass: PropTypes.string,
};

FormLayout.defaultProps = {
  title: '',
  className: '',
  submit: () => { },
  buttonTitle: 'Continuar',
  subTitle: '',
  hide: false,
  disabled: false,
  fullHeight: false,
  formRef: null,
  titleContainerClass: '',
};
