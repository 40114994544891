import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  sliderContainer: {
    maxWidth: '1000px',
    margin: '0 auto',
    width: '80%',
  },
  sliderValue: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '1.5rem',
    lineHeight: '2.5rem',
    margin: '0px 0px 20px 0px',
  },
  secSliderValue: {
    textAlign: 'center',
    color: '#A259FF',
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    margin: '8rem 0px 4rem 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
  },
  sliderMarkLabel: {
    display: 'none',
  },
  alternsliderMarkLabel: {
    textAlign: 'center',
    color: '#707174',
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    marginTop: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.75rem',
    },
  },
  thumb: {
    height: 48,
    width: 48,
    backgroundColor: theme.palette.tertiary.main,
    marginTop: -20,
    marginLeft: -24,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  alternthumb: {
    height: 48,
    width: 48,
    backgroundColor: '#A259FF',
    marginTop: -20,
    marginLeft: -24,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 10,
    borderRadius: 4,
  },
  alterntrack: {
    height: 10,
    borderRadius: 4,
  },
  rail: {
    height: 10,
    borderRadius: 4,
  },
  alternrail: {
    height: 10,
    borderRadius: 4,
  },
  marksContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  markLabel: {
    textAlign: 'center',
    color: '#707174',
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    marginTop: '0rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '1rem',
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
  },
}));
