import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { getLogoAndImageTemplate } from '../../utils';

import useStyles from './useStyles';

export const CustomLogo = ({ customUrl, isLoading }) => {
  const classes = useStyles();
  const {
    logoUrl,
  } = useMemo(() => getLogoAndImageTemplate(), []);

  return (
    isLoading ? null : (
      <Box className={classes.boxImage}>
        <img
          className={classes.imgLogo}
          src={customUrl || logoUrl}
          alt="imgLogo"
        />
      </Box>
    )
  );
};

CustomLogo.propTypes = {
  customUrl: PropTypes.string,
  isLoading: PropTypes.bool,
};

CustomLogo.defaultProps = {
  customUrl: '',
  isLoading: false,
};
