import TagManager from 'react-gtm-module';
import { ViviendaFormHeader } from '../components/ViviendaFormHeader';
import { ViviendaFormSubmitButton } from '../components/ViviendaFormSubmitButton';

const baseStrategy = {
  createTagLayer: () => {},
  getTitleClassName: () => '',
  renderHeader: () => {},
  renderButton: () => {},
};

const creditoHipotecarioStrategy = {
  ...baseStrategy,
};

const viviendaStrategy = {
  ...baseStrategy,
  getTitleClassName: (classes) => classes.formContainer,
  createTagLayer: ({ clientId }) => {
    TagManager.dataLayer({
      dataLayer: {
        clientId,
        page: 'CreateAccountPage',
      },
      dataLayerName: 'vivienda',
    });
  },
  renderHeader: ({ classes, title = '', subtitle = '' }) => () => (
    <ViviendaFormHeader classes={classes} title={title} subtitle={subtitle} />
  ),
  renderButton: ({ classes, buttonTitle, disabled }) => () => (
    <ViviendaFormSubmitButton classes={classes} buttonTitle={buttonTitle} disabled={disabled} />
  ),
};

const infonavitStrategy = {
  ...baseStrategy,
  getTitleClassName: (classes) => classes.formContainer,
  createTagLayer: ({ clientId }) => {
    TagManager.dataLayer({
      dataLayer: {
        clientId,
        page: 'CreateAccountPage',
      },
      dataLayerName: 'infonavit',
    });
  },
  renderHeader: ({ classes, title = '', subtitle = '' }) => () => (
    <ViviendaFormHeader classes={classes} title={title} subtitle={subtitle} />
  ),
  renderButton: ({ classes, buttonTitle, disabled }) => () => (
    <ViviendaFormSubmitButton classes={classes} buttonTitle={buttonTitle} disabled={disabled} />
  ),
};

const liquidezStrategy = {
  ...baseStrategy,
  getTitleClassName: (classes) => classes.formContainer,
  createTagLayer: ({ clientId }) => {
    TagManager.dataLayer({
      dataLayer: {
        clientId,
        page: 'CreateAccountPage',
      },
      dataLayerName: 'liquidez',
    });
  },
  renderHeader: ({ classes, title = '', subtitle = '' }) => () => (
    <ViviendaFormHeader classes={classes} title={title} subtitle={subtitle} />
  ),
  renderButton: ({ classes, buttonTitle, disabled }) => () => (
    <ViviendaFormSubmitButton classes={classes} buttonTitle={buttonTitle} disabled={disabled} />
  ),
};

export {
  creditoHipotecarioStrategy,
  viviendaStrategy,
  infonavitStrategy,
  liquidezStrategy,
};
