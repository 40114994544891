import IframeResizer from 'iframe-resizer-react';

import React from 'react';
import PropTypes from 'prop-types';

import { WALL_OF_LOVE_CARROUSEL, WALL_OF_LOVE_CARROUSEL_MOBILE, WALL_OF_LOVE_TESTIMONIAL } from '../../constants';

const { REACT_APP_URL_TESTIMONIAL, REACT_APP_URL_TESTIMONIAL_CARROUSEL } = process.env;
export const WallOfLovePage = ({ variant }) => {
  switch (variant) {
    case WALL_OF_LOVE_CARROUSEL:
      return (
        <IframeResizer
          src={REACT_APP_URL_TESTIMONIAL}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            maxHeight: '550px',
          }}
        />
      );
    case WALL_OF_LOVE_CARROUSEL_MOBILE:
      return (
        <IframeResizer
          src={REACT_APP_URL_TESTIMONIAL_CARROUSEL}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      );
    case WALL_OF_LOVE_TESTIMONIAL:
    default:
      return (
        <IframeResizer
          src={REACT_APP_URL_TESTIMONIAL}
          style={{
            width: '1px',
            minWidth: '100%',
            minHeight: 'calc(100% - 65px)',
            maxHeight: 'calc(100% - 65px)',
            height: '100%',
            border: 'none',
          }}
        />
      );
  }
};

WallOfLovePage.propTypes = {
  variant: PropTypes.string,
};
WallOfLovePage.defaultProps = {
  variant: WALL_OF_LOVE_TESTIMONIAL,
};
