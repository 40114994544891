import { createInstance } from './config';

const BASE_URL = '/sharedFieldValues';

export const getPhoneByValueAndField = async (phone, field, token = '') => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.get(`${BASE_URL}/phone/${phone}/field/${field}`);
  return data;
};

export const saveSharedFieldValuesData = async (body, token = '') => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}`, body);
  return data;
};

export const updateSharedFieldValuesData = async (shareFiledValueId, body, token = '') => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.put(`${BASE_URL}/${shareFiledValueId}`, body);
  return data;
};

export const getSharedFieldValuesDataByUserId = async (ownerId, token = '') => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.get(`${BASE_URL}/ownerId/${ownerId}`);
  return data;
};
