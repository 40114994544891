import { useReducer, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { UI_UPDATE_ALERT } from './constants';
import { UIContext } from './uiContext';
import { uiReducer } from './uiReducer';

const UI_INITIAL_STATE = {
  alert: {
    open: false,
    message: '',
    type: '',
  },
};

export const UIProvider = ({ children }) => {
  const [state, dispatch] = useReducer(uiReducer, UI_INITIAL_STATE);

  const updateAlert = useCallback((currentStep) => {
    dispatch({ type: UI_UPDATE_ALERT, payload: currentStep });
  }, []);

  const value = useMemo(
    () => ({
      state,
      updateAlert,
    }),
    [
      state,
      updateAlert,
    ],
  );

  return (
    <UIContext.Provider
      value={value}
    >
      { children }
    </UIContext.Provider>
  );
};

UIProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
