import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

export const TabPanel = ({
  children,
  value,
  index,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index ? (
        children
      ) : ''}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
};
