import { useState, useCallback, useMemo } from 'react';
import { COTIZACION_CREDITO_HIPOTECARIO, LOADER_PAGE } from '../../../../../constants';

export const STATES = {
  COTIZACION_CREDITO_HIPOTECARIO,
  LOADER_PAGE,
};

const useStateMachine = (_currentState = STATES.LOADER_PAGE) => {
  const [currentState, setCurrentState] = useState(_currentState);

  const goToCotization = useCallback(() => {
    setCurrentState(STATES.COTIZACION_ARRENDAMIENTO);
  }, []);

  const goToLoaderPage = useCallback(() => {
    setCurrentState(STATES.LOADER_PAGE);
  }, []);

  const result = useMemo(
    () => (
      {
        STATES,
        currentState,
        goToCotization,
        goToLoaderPage,
      }
    ),
    [
      currentState,
      goToCotization,
      goToLoaderPage,
    ],
  );

  return result;
};

export default useStateMachine;
