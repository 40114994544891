import { decryptData, encryptData } from '../utils/encryption';

const LEAD_KEY = btoa('LEAD_INMOBILIARIA_DATA');

export const storePerfilamientoLeadData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(LEAD_KEY, encryptedData);
};

export const removePerfilamientoLeadData = () => {
  window.localStorage.removeItem(LEAD_KEY);
};

export const getPerfilamientoLeadData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(LEAD_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

export const storeExtraPerfimalientoLeadData = (data) => {
  const currentAgentData = getPerfilamientoLeadData();
  const newAgentData = { ...currentAgentData, ...data };
  storePerfilamientoLeadData(newAgentData);
};

const getItem = (key) => {
  try {
    const lead = getPerfilamientoLeadData();
    return lead[key];
  } catch {
    return undefined;
  }
};

export const getPerfilamientoLeadId = () => {
  const leadId = getItem('_id');
  return leadId;
};

export const getPerfilamientoLeadExtraData = () => {
  const extraData = getItem('extraData');
  return extraData;
};

export const getPerfilamiendoLeadAgentAssigned = () => {
  const extraData = getPerfilamientoLeadExtraData();
  const { agentId = '' } = extraData || {};
  return agentId;
};
