import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  title: {
    width: '100%',
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
  subtitleConditions: {
    color: theme.palette.primary.main,
    fontSize: '1.25rem',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  textConditions: {
    marginTop: '25px',
    marginBottom: '25px',
  },
  inputContainer: {
    marginBottom: '25px',
    width: '100%',
  },
  btn: {
    fontSize: '1.10rem',
    fontWeight: '700',
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '25px',
    marginTop: '25px',
  },
  input: {
    border: '1px solid #b8d3ff',
    borderRadius: '10px',
  },
  grid: {
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px !important',
      paddingRight: '30px !important',
    },
  },
  gridRight: {
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px !important',
      paddingRight: '30px !important',
      borderLeft: '1px solid #7C839D',
    },
  },
}));
