import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import useStyles from './useStyles';
import { CreditSimulator } from '../../components/CreditSimulator';
import { Slider } from '../../../../components/Slider';

export const CalCredit = ({
  startLoading,
  endLoading,
  agentId,
  buttonTitle,
}) => {
  const [sliderValue, setSliderValue] = useState(3000000);
  const classes = useStyles();
  const onChangeSlider = (event, value) => {
    setSliderValue(+value);
  };
  return (
    <div id="cotizador" className={classes.container}>
      <div className={classes.innerContatiner}>
        <Typography
          className={classes.title}
        >
          Calcula tu préstamo.
        </Typography>
        <Typography
          className={classes.subTitle}
        >
          ¿Cuánto necesitas?
        </Typography>
        <Box className={classes.sliderContainer}>
          <Slider
            defaultValue={3000000}
            lowestValue={100000}
            highestValue={3000000}
            onChange={onChangeSlider}
            name="Calcula tu Crédito Cash"
          />
        </Box>
        <Box className={classes.containerCredit}>
          <CreditSimulator
            startLoading={startLoading}
            endLoading={endLoading}
            agentId={agentId}
            prestamoValue={sliderValue}
            buttonTitle={buttonTitle}
          />
        </Box>
      </div>
    </div>
  );
};

CalCredit.propTypes = {
  startLoading: PropTypes.func,
  endLoading: PropTypes.func,
  agentId: PropTypes.string,
  buttonTitle: PropTypes.string,
};

CalCredit.defaultProps = {
  startLoading: () => { },
  endLoading: () => { },
  agentId: '',
  buttonTitle: '',
};
