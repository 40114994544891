import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  Backdrop: {
    zIndex: 9999,
    color: '#fff',
    height: '100%',
    position: 'fixed',
  },
  percetage: {
    marginLeft: '10px',
  },
}));
