import React, { useContext } from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import useStyles from './useStyles';
import { WhatsappButton } from '../../../../components/WhatsappButton';
import { FAQ_ROUTE } from '../../../../constants';
import { ButtonCustom } from '../../../../components';
import { LandingContext } from '../../../../context/landing';

export const FooterInfoImages = () => {
  const { landingData } = useContext(LandingContext);

  const {
    FooterImages: {
      title,
      body,
      buttonTitle,
      showButtonFAQ,
      faqButtonTitle,
    },
  } = landingData;
  const classes = useStyles();

  const navigate = useNavigate();

  const handleFAQClick = () => {
    navigate(FAQ_ROUTE);
  };

  return (
    <Box component="section" className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box
          className={clsx(classes.item, classes.itemBackground)}
        >
          <Box className={clsx(
            classes.innerItem,
            classes.marginContainer,
            classes.displayFlexbox,
          )}
          >
            <Typography variant="h2" component="h2" className={classes.title}>{title}</Typography>
            <Typography variant="body1" component="p" className={classes.body}>{body}</Typography>
            <Box className={classes.containerButton}>
              {showButtonFAQ && (
                <ButtonCustom
                  onClick={handleFAQClick}
                >
                  {faqButtonTitle}
                </ButtonCustom>
              )}
              <WhatsappButton>{buttonTitle}</WhatsappButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
