import { useContext } from 'react';
import { CotizationPreviewContext } from '../context/cotizationPreview';

export const useCotizationPreview = () => {
  const { strategy: cotizationPreviewStrategy, strategyLoaded, changeStrategy } = useContext(
    CotizationPreviewContext,
  );
  return {
    cotizationPreviewStrategy,
    strategyLoaded,
    changeStrategy,
  };
};
