import { isNumber } from '../InputNumber/validator';
import { removeCurrencyFormatter } from '../InputCurrency/formatter';

export const isValidateFormat = (_value, typeValidation) => {
  const valueWithoutCurrency = removeCurrencyFormatter(_value);
  if ((_value && typeValidation !== 'currency') || valueWithoutCurrency) {
    if ((typeValidation === 'number' && isNumber(_value))
      || typeValidation === 'string'
      || (typeValidation === 'currency' && isNumber(valueWithoutCurrency))) {
      return 1;
    }
    return 2;
  }
  return 0;
};
