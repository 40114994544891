import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh', // Full viewport height
    backgroundColor:
      theme.palette.background[props.backgroundColorKey]
      || theme.palette.background.default,
  }),
  contentWrapper: (props) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundColor:
      theme.palette.background[props.backgroundColorKey]
      || theme.palette.background.default,
  }),
}));
