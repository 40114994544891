/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  Typography,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import useStyles from './useStyles';
import { FloatingActionButton } from '../../../../components/FloatingActionButton';
import { Sidebar } from '../SideBar';
import { LogoChiplo } from '../../../../components/LogoChiplo';

export const HeaderBar = ({
  title,
  options,
  back,
  showSideMenu,
  sideBarOptions,
  displayLogo,
}) => {
  const [open, setOpen] = useState(false);
  const toggleSidebar = () => {
    setOpen(!open);
  };
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {showSideMenu ? (
        <IconButton onClick={toggleSidebar}>
          <Menu className={classes.menuIcon} />
        </IconButton>
      ) : (
        <IconButton
          aria-label="back"
          onClick={back}
        >
          <ArrowBack className={classes.icon} />
        </IconButton>
      )}
      {displayLogo
        ? (<LogoChiplo />)
        : (
          <Typography
            variant="h1"
            className={classes.title}
          >
            {title}
          </Typography>
        )}
      {options.length ? <FloatingActionButton options={options} /> : <div />}
      <Sidebar
        open={open}
        toggleSidebar={toggleSidebar}
        options={sideBarOptions}
      />
    </div>
  );
};

HeaderBar.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  back: PropTypes.func,
  showSideMenu: PropTypes.bool,
  sideBarOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.node,
      action: PropTypes.func,
    }),
  ),
  displayLogo: PropTypes.bool,
};

HeaderBar.defaultProps = {
  title: '',
  options: [],
  back: () => { },
  showSideMenu: false,
  sideBarOptions: [],
  displayLogo: false,
};
