import PropTypes from 'prop-types';

import { CreateStrategyProvider } from '../createStrategy';
import { CotizationPreviewContext } from './cotizationPreviewContext';

export const CotizationPreviewProvider = CreateStrategyProvider(CotizationPreviewContext);

CotizationPreviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
