import { getTempEncriptedPassword } from '../../../utils/clientData';

export const generateUserDataFromFieldsData = (fieldsData) => {
  const dataObject = {};
  fieldsData.forEach((field) => {
    const { config, name, value } = field;
    const matchColumn = config?.matchColumn || name;
    dataObject[matchColumn] = value;
  });
  return dataObject;
};

export const generateTMPPasswordField = () => {
  const value = getTempEncriptedPassword();
  const passwordField = {
    config: {
      matchColumn: 'password',
    },
    fieldCatalog: {
      _id: '62cdd611d13d1da220dd8f46',
    },
    _id: '62cce546d13d1da220c7f6df',
    value,
  };
  return passwordField;
};
