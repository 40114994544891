import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LandingContext } from './landingContext';
import { CHIPLO_INMOBILIARIA_ID, CIFRAS_INMOBILIARIA_ID } from '../../constants';
import { DataLandingChiplo, DataLandingCifras } from '../../data';
import { Loader } from '../../components';

export const LandingProvider = ({ children, inmobiliariaId }) => {
  const [landingData, setLandingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const value = useMemo(
    () => ({
      landingData,
      inmobiliariaId: inmobiliariaId || CHIPLO_INMOBILIARIA_ID,
    }),
    [inmobiliariaId, landingData],
  );

  const setLandingDataByInmobiliariaId = () => {
    switch (inmobiliariaId) {
      case CIFRAS_INMOBILIARIA_ID:
        return DataLandingCifras;
      case CHIPLO_INMOBILIARIA_ID:
      default:
        return DataLandingChiplo;
    }
  };

  useEffect(() => {
    const data = setLandingDataByInmobiliariaId();
    setLandingData(data);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loader open={isLoading} />;
  }

  return (
    <LandingContext.Provider value={value}>
      {children}
    </LandingContext.Provider>
  );
};

LandingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  inmobiliariaId: PropTypes.string,
};

LandingProvider.defaultProps = {
  inmobiliariaId: '',
};
