/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';

import Modal from '@material-ui/core/Modal';
import { Button, Typography } from '@material-ui/core';
import useStyles from './useStyles';

export const InformationDialogContainer = ({
  onSubmit,
  title,
  showModal,
  children,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={showModal}
      onClose={onSubmit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
    >
      <div className={classes.mainContainer}>
        <div className={classes.closeButtonContainer}>
          <Button
            className={classes.closeButton}
            onClick={onSubmit}
          >
            <Close />
          </Button>
        </div>
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.innerContainer}>

          <div className={classes.contentContainer}>
            <div className={classes.scrollContainer}>{children}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

InformationDialogContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
