import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '15px',
    paddingLeft: '15px',
    paddingRight: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '30px',
    },
  },
  tabsContainer: {
    maxWidth: '1000px',
    width: '100%',
  },
}));
