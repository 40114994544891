/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
  },
  searchContainer: {
    margin: '26px 0 12px 0',
  },
  innerContainer: {
    margin: '0 auto',
    width: '95%',
  },
  clients: {},
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '20px',
  },
  title: {
    color: '#2C1482',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '24px',
    textTransform: 'capitalize',
    margin: '0',
  },
}));
