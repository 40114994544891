import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  innerContainer: {
    boxSizing: 'border-box',
    margin: '40px 0px 0px 0px',
    paddingLeft: '19px',
    paddingRight: '16px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: '68px auto 0px auto',
      width: '95%',
    },
  },
  containerQuestion: {},
  header: {},
  title: {
    color: '#06040A',
    fontSize: '40px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '600',
    letterSpacing: '0.4px',
    margin: '0px 0px 24px 0px',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 0px 48px 0px',
      fontSize: '40px',
    },
  },
  questions: {},
  question: {
    borderTop: '3px solid black',
  },
  label: {
    color: '#06040A',
    fontSize: '24px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    lineHeight: '32px',
    margin: '12px 0px 16px 0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      margin: '38px 0px 14px 0px',
    },
  },
  body: {
    color: '#06040A',
    fontSize: '20px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '300',
    margin: '16px 0px 24px 0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      margin: '14px 0px 60px 0px',
    },
  },
  containerLink: {
    margin: '30px 0px 70px 0px',
    [theme.breakpoints.up('sm')]: {
      margin: '60px 0px 100px 0px',
    },
  },
  navLink: {
    color: '#2C1482',
    fontSize: '24px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    lineHeight: '32px',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
  },
}));
