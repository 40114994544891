import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  fullWidth: {
    width: ' 100%',
  },
  icon: {
    alignItems: 'center',
  },
}));
