import React, { useContext, useMemo, useRef } from 'react';

import PropTypes from 'prop-types';
import {
  Box, Container, Divider, Grid, Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { Button } from '../../../../components';

import {
  CALC_MODE_PRECIO_INMUEBLE,
  MINIMUM_CREDIT_VALUE,
  PERFILADOR_VARIANTE_LANDING,
} from '../../../../constants';
import { ConditionAmmountList } from '../ConditionsAmmountList';
import { PerfiladorInput } from '../PerfiladorInput';
import { FooterTextPerfilador } from '../FooterTextPerfilador';
import { TabsContext } from '../../../../context/tabs/tabsContext';
import usePerfilador from '../../../../hooks/usePerfilador';

import useStyles from './useStyles';
import { getValueMinimumErrorMessage, isGreaterThanValue } from '../../../../utils/simulator.utils';
import { reportFormCustomValidity } from '../../../../utils';

const FIELD_PROPIETY_NAME = 'propietyField';

export const PerfiladorPrecioInmueble = ({
  onSubmit,
  buttonTitle,
  showFooter,
}) => {
  const formRef = useRef();
  const classes = useStyles();
  const {
    inputValue: propietyValue,
    inputNumber: valuePropNumber,
    engancheNumber = 0,
    plazoNumber,
    pagoMensual = 0,
    montoCredito = 0,
    percentageEnganche = 0,
    ingresosRecomendados = 0,
    montoGastosEscritura = 0,
    onChange,
  } = usePerfilador({
    calcMode: CALC_MODE_PRECIO_INMUEBLE,
    useDesembolsoMode: true,
  });

  const { tabsExtraClasses, tabsVariant } = useContext(TabsContext);

  const inputData = {
    label: '',
    value: '',
    name: FIELD_PROPIETY_NAME,
    placeHolder: '$1,000,000',
    required: true,
    type: 'precio',
    disabled: false,
    hideLabel: true,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { isValid } = isGreaterThanValue(valuePropNumber, MINIMUM_CREDIT_VALUE);
    if (!isValid) {
      return reportFormCustomValidity(
        formRef.current,
        FIELD_PROPIETY_NAME,
        getValueMinimumErrorMessage(),
      );
    }
    const storageData = {
      importe: montoCredito,
      meses: plazoNumber * 12,
      aforo: (montoCredito / valuePropNumber).toFixed(4),
      enganche: engancheNumber,
      year: plazoNumber,
      plazo: `${plazoNumber} años`,
      mensualidad: pagoMensual,
      propietyValue: valuePropNumber,
      propietyValueCurrency: propietyValue,
      montoGastosEscritura,
      percentageEnganche,
    };
    return onSubmit({
      storageData,
      event,
    });
  };

  const conditionsAmmountData = [
    {
      label: 'Pago mensual:',
      ammountNumber: pagoMensual,
    },
    {
      label: 'Monto del crédito:',
      ammountNumber: montoCredito,
    },
    {
      label: 'Ingresos mensuales:',
      ammountNumber: ingresosRecomendados,
    },
  ];

  const inputLabel = useMemo(() => {
    switch (tabsVariant) {
      case PERFILADOR_VARIANTE_LANDING:
        return 'Valor aproximado de tu propiedad';
      default:
        return 'Valor de la propiedad:';
    }
  }, [tabsVariant]);

  return (
    <Container
      component="form"
      onSubmit={handleSubmit}
      ref={formRef}
      disableGutters
      autoComplete="off"
    >
      <Grid
        container
        spacing={2}
        className={clsx(classes.container, {
          [tabsExtraClasses?.gridContainer]: tabsExtraClasses?.gridContainer,
        })}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className={clsx(classes.grid, {
            [tabsExtraClasses?.gridLeft]: tabsExtraClasses?.gridLeft,
          })}
        >
          <PerfiladorInput
            titleLabel={inputLabel}
            disabled={inputData.disabled}
            hideLabel={inputData.hideLabel}
            label={inputData.label}
            placeHolder={inputData.placeHolder}
            required={inputData.required}
            type={inputData.type}
            value={propietyValue}
            onChange={onChange}
            isEmpty={valuePropNumber <= 0}
            name={FIELD_PROPIETY_NAME}
            classes={{
              input: clsx(classes.input, {
                [tabsExtraClasses?.input]: tabsExtraClasses.input,
              }),
              title: clsx(classes.title, {
                [tabsExtraClasses?.title]: tabsExtraClasses.title,
              }),
            }}
            validations={[
              (inputValue) => isGreaterThanValue(
                inputValue,
                MINIMUM_CREDIT_VALUE,
                getValueMinimumErrorMessage(),
              ),
            ]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className={clsx(classes.gridRight, {
            [tabsExtraClasses?.gridRight]: tabsExtraClasses.gridRight,
          })}
        >
          <Divider
            orientation="vertical"
            flexItem
          />
          <Box className={classes.textConditions}>
            <Typography
              className={clsx(classes.subtitleConditions, {
                [tabsExtraClasses?.subtitleConditions]:
                  tabsExtraClasses.subtitleConditions,
              })}
            >
              Condiciones estimadas
            </Typography>
          </Box>
          <ConditionAmmountList
            items={conditionsAmmountData}
            customTitleClassName={tabsExtraClasses?.titleClassName}
            customValueClassName={tabsExtraClasses?.valueClassName}
          />
        </Grid>
      </Grid>
      <Box className={classes.btnContainer}>
        <Button
          className={clsx(classes.btn, {
            [tabsExtraClasses?.btn]: tabsExtraClasses.btn,
          })}
          type="submit"
        >
          {buttonTitle}
        </Button>
      </Box>
      {showFooter && <FooterTextPerfilador />}
    </Container>
  );
};

PerfiladorPrecioInmueble.propTypes = {
  onSubmit: PropTypes.func,
  buttonTitle: PropTypes.string,
  showFooter: PropTypes.bool,
};

PerfiladorPrecioInmueble.defaultProps = {
  onSubmit: () => {},
  buttonTitle: '',
  showFooter: true,
};
