import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Information } from '../../../../../components';

export const CreditProfilingWait = ({
  titlePage,
}) => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('home/credits');
  };
  return (
    <Information
      title={titlePage}
      onClick={navigateToHome}
    />
  );
};

CreditProfilingWait.propTypes = {
  titlePage: PropTypes.string,
};

CreditProfilingWait.defaultProps = {
  titlePage: '',
};
