/* eslint-disable no-param-reassign */
import moment from 'moment';
import { convertirAMoneda } from '../../../utils/currency';

const getValueByName = (fieldValuesMap, name) => {
  const sourceFieldValue = fieldValuesMap[name];
  if (!sourceFieldValue) return '';
  const { value } = sourceFieldValue;
  return value;
};

const getValueByNames = (fieldValuesMap, names) => {
  const values = names.map((name) => {
    const value = getValueByName(fieldValuesMap, name);
    return { name, value };
  });
  if (!values.length) return '';
  return values;
};

const numberWithCommas = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const removeSigns = (value) => value.replace(/[,$%]/g, '');

export const excludeWordsFromText = (text, wordsToExclude) => {
  const escapedWords = wordsToExclude.map((word) => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
  const regexPattern = new RegExp(escapedWords.join('|'), 'g');
  const cleanedText = text.replace(regexPattern, '');
  return cleanedText;
};

export const getAgeByBirthDate = (format, fieldName, fieldValuesMap, includeYearsText = false) => {
  const dataValue = getValueByName(fieldValuesMap, fieldName);
  if (dataValue) {
    const fechaNacimiento = moment(dataValue, format);
    const fechaActual = moment();
    const yearsDiff = fechaActual.diff(fechaNacimiento, 'years');
    const age = includeYearsText ? `${yearsDiff} Años` : `${yearsDiff}`;
    return age;
  }
  return 'No se pudo calcular la edad';
};

export const getAgeAndTerm = (fieldNameArray, fieldValuesMap) => {
  const values = getValueByNames(fieldValuesMap, fieldNameArray);
  const total = values.reduce((acc, val) => {
    const { name, value } = val;
    const valueSanitize = value.toLowerCase();
    if (name === 'plazo') {
      const formatValue = valueSanitize.replace(' meses', '').replace(' mes', '');
      const monthsToYears = Number(formatValue) / 12;
      acc = Number(acc) + Number(monthsToYears);
    } else {
      const formatValue = valueSanitize.replace(' años', '').replace(' año', '');
      acc = Number(acc) + Number(formatValue);
    }
    return acc;
  }, 0);
  return `${total} Años`;
};

export const getRPI = (fieldNameArray, fieldValuesMap) => {
  const values = getValueByNames(fieldValuesMap, fieldNameArray);
  if (values.length < 2) return 0;
  const total = Number(removeSigns(values[0].value)) / Number(removeSigns(values[1].value));
  return `${total}`;
};

export const getTotalSumOfFields = (fieldNameArray, wordsToExclude, fieldValuesMap) => {
  const values = getValueByNames(fieldValuesMap, fieldNameArray);
  const total = values.reduce((acc, val) => acc + Number(removeSigns(val.value)), 0);
  return excludeWordsFromText(`$${numberWithCommas(total)}`, wordsToExclude);
};

export const calcYearsAndMonthsDifference = (fieldName, fieldValuesMap, elementToExtract = '', includeYearsText = false) => {
  const inputDate = getValueByName(fieldValuesMap, fieldName);
  if (!inputDate) return '';
  const currentDate = moment();
  const parsedDate = moment(inputDate, 'll');

  const years = currentDate.diff(parsedDate, 'years');
  parsedDate.add(years, 'years');

  const months = currentDate.diff(parsedDate, 'months');
  const totalDifference = `${years} años, ${months} meses`;
  const yearsResult = includeYearsText ? `${years} años` : `${years}`;
  const monthsResult = includeYearsText ? `${months} meses` : `${months}`;

  switch (elementToExtract) {
    case 'years':
      return yearsResult;
    case 'months':
      return monthsResult;
    default:
      return totalDifference;
  }
};

export const multiply = (fieldName, fieldValuesMap, multiplicator) => {
  const value = getValueByName(fieldValuesMap, fieldName);
  const valueNumber = Number(removeSigns(value));
  const total = valueNumber * multiplicator;
  return total.toFixed(2);
};

export const calcAmmountFromPercentage = (fieldNamePercentage, fieldName, fieldValuesMap) => {
  const valuePercentage = getValueByName(fieldValuesMap, fieldNamePercentage);
  const valueNumber = Number(removeSigns(valuePercentage));
  const total = valueNumber / 100;
  const resultString = multiply(fieldName, fieldValuesMap, total);
  const numberResult = Number(resultString);
  const result = convertirAMoneda(numberResult, false);
  return result;
};
