import { Button, Container } from '@material-ui/core';

import * as googleAnalytics from '../utils/googleAnalytics/events';
import { executeAutoNodes } from '../utils/api/nodes.utils';
import { saveManyFieldValues } from '../utils/api/fieldValues.utils';

import {
  COMPANY, PERFILAMIENTO_EXIST, PROFILING_COTIZATION, PROFILING_EMPTY_MATRIZ,
} from '../constants';

import * as processAPI from '../api/process';
import { saveExtraDataFieldValues } from '../utils/fieldValues';

const baseStrategy = {
  getTitleClassName: () => '',
  renderButton: () => {},
  handleSubmit: () => {},
};

const creditoHipotecarioStrategy = {
  ...baseStrategy,
  handleSubmit: async ({
    processID,
    fieldValues,
    autoNodes,
    execMatrix,
    updateClientConditionsValues,
    savePerfilamientoValue,
    savePerfilamientoSharedFieldValues,
    createUpdateTag,
    goTo,
  }) => {
    await saveManyFieldValues(fieldValues);
    await executeAutoNodes(autoNodes, processID);
    const { matrizStatus, values, newConditions } = await execMatrix();
    if (newConditions) {
      await updateClientConditionsValues(newConditions, processID);
    }
    await savePerfilamientoValue(values, processID);
    await savePerfilamientoSharedFieldValues(values, processID);
    googleAnalytics.matrizPerfilamiento(matrizStatus);
    if (matrizStatus === PERFILAMIENTO_EXIST && values) {
      await createUpdateTag({ currentPage: PROFILING_COTIZATION })();
      await processAPI.setTasaBroker(processID, COMPANY);
      googleAnalytics.perfilamientoCrediticio();
      goTo(PROFILING_COTIZATION);
    } else {
      await createUpdateTag({ currentPage: PROFILING_EMPTY_MATRIZ })();
      googleAnalytics.perfilamientoCrediticio();
      goTo(PROFILING_EMPTY_MATRIZ);
    }
  },
};

const viviendaStrategy = {
  ...baseStrategy,
  getTitleClassName: (classes) => classes.formContainer,
  renderButton: ({ classes, title, disabled }) => () => (
    <Container
      className={classes.buttonContainer}
    >
      <Button
        className={classes.button}
        type="submit"
        disabled={disabled}
      >
        {title}
      </Button>
    </Container>
  ),
  handleSubmit: async ({
    fieldValues,
    createUpdateTag,
  }) => {
    saveExtraDataFieldValues(fieldValues);
    await createUpdateTag();
  },
};

const infonavitStrategy = {
  ...baseStrategy,
  getTitleClassName: (classes) => classes.formContainer,
  renderButton: ({ classes, title, disabled }) => () => (
    <Container
      className={classes.buttonContainer}
    >
      <Button
        className={classes.button}
        type="submit"
        disabled={disabled}
      >
        {title}
      </Button>
    </Container>
  ),
  handleSubmit: async ({
    fieldValues,
    createUpdateTag,
  }) => {
    saveExtraDataFieldValues(fieldValues);
    await createUpdateTag();
  },
};

const liquidezStrategy = {
  ...baseStrategy,
  getTitleClassName: (classes) => classes.formContainer,
  renderButton: ({ classes, title, disabled }) => () => (
    <Container
      className={classes.buttonContainer}
    >
      <Button
        className={classes.button}
        type="submit"
        disabled={disabled}
      >
        {title}
      </Button>
    </Container>
  ),
  handleSubmit: async ({
    fieldValues,
    createUpdateTag,
  }) => {
    saveExtraDataFieldValues(fieldValues);
    await createUpdateTag();
  },
};

const inmobiliariaStrategy = {
  ...viviendaStrategy,
  handleSubmit: async ({
    fieldValues,
    getMatrizBody,
    fetchLocalCreditData,
    execMatrizProfilling,
    formatPerfilamientoFieldValue,
    formatFromFieldValueModelToUpload,
    getCreditPropertyFieldValues,
    updateClientConditionsValues,
    getCatTasaFieldValues,
    storeLocalFieldValues,
    navigateWithQueryParam,
    handleOnPerfilamientoExists,
    handlOnPerfilamientoEmpty,
  }) => {
    saveExtraDataFieldValues(fieldValues);
    fetchLocalCreditData();
    const matrizBody = await getMatrizBody();
    const { fieldValues: matrizFieldValues } = matrizBody;
    const { values, matrizStatus, newConditions } = await execMatrizProfilling(matrizBody);

    const perfilamientoFieldValue = formatPerfilamientoFieldValue(values);
    const formattedFieldValues = formatFromFieldValueModelToUpload(matrizFieldValues, true);
    const creditInfoFieldValues = getCreditPropertyFieldValues();
    const leadFieldValuesToSave = [
      ...fieldValues,
      ...formattedFieldValues,
      ...creditInfoFieldValues,
      perfilamientoFieldValue,
    ];
    if (newConditions) {
      await updateClientConditionsValues(newConditions);
    }
    googleAnalytics.matrizPerfilamiento(matrizStatus);
    if (matrizStatus === PERFILAMIENTO_EXIST && values) {
      const catAndTasasFieldValues = await getCatTasaFieldValues(values);
      storeLocalFieldValues([...leadFieldValuesToSave, ...catAndTasasFieldValues]);
      await handleOnPerfilamientoExists({ navigate: navigateWithQueryParam });
    } else {
      storeLocalFieldValues([...leadFieldValuesToSave]);
      await handlOnPerfilamientoEmpty({ navigate: navigateWithQueryParam });
    }
  },
};

export {
  creditoHipotecarioStrategy,
  viviendaStrategy,
  infonavitStrategy,
  liquidezStrategy,
  inmobiliariaStrategy,
};
