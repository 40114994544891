import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    background: '#F7FAFD',
    height: 'calc(100% - 65px - 88px - 5px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 65px - 85.19px - 5px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 96.8px - 57px - 5px)',
    },
  },
}));
