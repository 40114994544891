import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import useStyles from './useStyles';
import { CreditSimulator } from '../CreditSimulator';
import { TabsComponent } from '../../../../components/TabsComponent';
import { TabsContext } from '../../../../context/tabs/tabsContext';
import { INFONAVIT_APOYO_INFONAVIT_TYPE, INFONAVIT_COFINAVIT_TYPE, INFONAVIT_FOVISSSTE_TYPE } from '../../../../constants';

const data = [
  {
    label: 'Apoyo Infonavit',
    value: INFONAVIT_APOYO_INFONAVIT_TYPE,
    disabled: false,
  },
  {
    label: 'COFINAVIT',
    value: INFONAVIT_COFINAVIT_TYPE,
    disabled: false,
  },
  {
    label: 'FOVISSSTE',
    value: INFONAVIT_FOVISSSTE_TYPE,
    disabled: false,
  },
];

export const CalCredit = ({
  startLoading,
  endLoading,
  title,
  subTitle,
  agentId,
  buttonTitle,
}) => {
  const {
    initTabs,
    updateActiveTab,
    state: { tabs, active },
  } = useContext(TabsContext);
  const classes = useStyles();
  useEffect(() => {
    initTabs({
      tabs: data,
      default: data[0].value,
    });
  }, []);
  return (
    <div id="cotizador" className={classes.container}>
      <div className={classes.innerContatiner}>
        <Typography
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography
          className={classes.subTitle}
        >
          {subTitle}
        </Typography>
        {tabs.length ? (
          <TabsComponent
            data={tabs}
            onChange={(e, value) => updateActiveTab(value)}
            defaultValue={active}
          />
        ) : ''}
        {tabs.length ? (
          <Box className={classes.containerCredit}>
            <CreditSimulator
              startLoading={startLoading}
              endLoading={endLoading}
              agentId={agentId}
              buttonTitle={buttonTitle}
            />
          </Box>
        ) : ''}
      </div>
    </div>
  );
};

CalCredit.propTypes = {
  startLoading: PropTypes.func,
  endLoading: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  agentId: PropTypes.string,
  buttonTitle: PropTypes.string,
};

CalCredit.defaultProps = {
  startLoading: () => { },
  endLoading: () => { },
  title: '',
  subTitle: '',
  agentId: '',
  buttonTitle: '',
};
