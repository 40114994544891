import React from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

// import { TabsRounded } from '../../../../components/TabsRounded';

import useStyles from './useStyles';
import { HeaderBar } from '../../components/HeaderBar';
import { AGENTE_CLIENTS_CREATE_ROUTE, AGENTE_PERFILADOR_ROUTE } from '../../../../constants';
import { removeLocalAgentData, storeExtraAgentData } from '../../../../storage';
import { Perfilador } from '../../../perfilador';
import { PageTitle } from '../../components/PageTitle';

export const PerfiladorPage = ({ buttonTitle }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const back = () => {
    removeLocalAgentData();
    navigate('/agente/clients');
  };

  const onChangeActiveTab = (value) => {
    storeExtraAgentData({
      activeTab: value,
    });
  };

  const onSubmit = ({ storageData, event }) => {
    event.preventDefault();
    event.stopPropagation();
    const { propietyValueCurrency } = storageData;
    storeExtraAgentData({ clientPropietyValue: propietyValueCurrency });
    navigate(AGENTE_CLIENTS_CREATE_ROUTE, {
      state: {
        from: AGENTE_PERFILADOR_ROUTE,
      },
    });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerContainer}>
        <HeaderBar
          displayLogo
          back={back}
          options={[
            {
              label: 'Agregar cliente nuevo',
              id: 'sendLink',
              func: () => navigate(AGENTE_CLIENTS_CREATE_ROUTE),
            },
          ]}
        />
      </div>
      <PageTitle title="Perfilador" />
      <Perfilador
        buttonTitle={buttonTitle}
        onSubmit={onSubmit}
        handleChangeActiveTab={onChangeActiveTab}
        showFooter
      />
    </div>

  );
};

PerfiladorPage.propTypes = {
  buttonTitle: PropTypes.string,
};

PerfiladorPage.defaultProps = {
  buttonTitle: 'Continuar con Registro de Cliente',
};
