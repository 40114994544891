// http://localhost:1337/api/clients/user/docupass
// /api/clients/company/:companyId/email/:email
import { AuthError } from '../utils';
import { createInstance, createInstanceNoToken } from './config';

const BASE_URL = '/clients';

export const saveFieldsData = async (body) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.post(`${BASE_URL}/user/docupass`, body);
  if (!Object.keys(data).length) throw new AuthError();
  return data;
};

export const getClientByCompanyAndEmail = async (company, email) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/company/${company}/email/${email}`);
  return data;
};

export const getClientById = async (clientId, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.get(`${BASE_URL}/${clientId}`);
  return data;
};

export const saveClientExtraData = async (id, body) => {
  const axios = createInstance();
  const { data } = await axios.put(`${BASE_URL}/${id}/extradata`, body);
  return data;
};

export const getClientsAgentAssigned = async (company, query, page = 1) => {
  const axios = createInstance();
  const url = `${BASE_URL}/docupass/agentAssigned/company/${company}/page/${page}/sort${query ? `?search=${query}` : ''}`;
  const { data } = await axios.get(url);
  return data;
};

export const getClientsAgentAssignedPageSize = async (company, pageSize = 25) => {
  const axios = createInstance();
  const url = `${BASE_URL}/docupass/agentAssigned/company/${company}/pageSize/${pageSize}/count`;
  const { data } = await axios.get(url);
  return data;
};

export const getClientsEntityAssigned = async (company, entity, query, page = 1) => {
  const axios = createInstance();
  const url = `${BASE_URL}/docupass/company/${company}/entity/${entity}/page/${page}/sort${query ? `?search=${query}` : ''}`;
  const { data } = await axios.get(url);
  return data;
};

export const getClientsEntityAssignedPageSize = async (company, entity, pageSize = 25) => {
  const axios = createInstance();
  const url = `${BASE_URL}/docupass/company/${company}/entity/${entity}/pageSize/${pageSize}/count`;
  const { data } = await axios.get(url);
  return data;
};

export const getAgentData = async (agentId) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/agent/${agentId}`);
  return data;
};

export const updateClientData = async (id, body, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.put(`${BASE_URL}/${id}`, body);
  return data;
};
