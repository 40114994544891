/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TabsContext } from '../../../../context/tabs/tabsContext';
import { TabsRounded } from '../../../../components/TabsRounded';
import { TabsSeparated } from '../../../../components/TabsSeparated';
import useStyles from './useStyles';
import { PERFILADOR_VARIANTE_COTIZADOR_INMOBILIARIAS, PERFILADOR_VARIANTE_COTIZATION, PERFILADOR_VARIANTE_LANDING } from '../../../../constants';

const renderTabs = (variant, tabs, active, onChangeActiveTab) => {
  switch (variant) {
    case PERFILADOR_VARIANTE_LANDING:
    case PERFILADOR_VARIANTE_COTIZATION:
      return (
        <TabsSeparated
          data={tabs}
          onChange={(e, value) => onChangeActiveTab(value)}
          defaultValue={active}
        />
      );
    case PERFILADOR_VARIANTE_COTIZADOR_INMOBILIARIAS:
    default:
      return (
        <TabsRounded
          data={tabs}
          onChange={(e, value) => onChangeActiveTab(value)}
          defaultValue={active}
        />
      );
  }
};

export const PerfiladorHeaderTabs = ({ handleChangeActiveTab }) => {
  const classes = useStyles();
  const {
    tabsVariant,
    updateActiveTab,
    state: { tabs, active },
    tabsExtraClasses,
  } = useContext(TabsContext);

  const onChangeActiveTab = (value) => {
    updateActiveTab(value);
    handleChangeActiveTab(value);
  };

  return (
    <div className={clsx(classes.container, tabsExtraClasses?.container)}>
      {tabs.length > 0 && renderTabs(tabsVariant, tabs, active, onChangeActiveTab)}
    </div>
  );
};

PerfiladorHeaderTabs.defaultProps = {
  handleChangeActiveTab: () => {},
};

PerfiladorHeaderTabs.propTypes = {
  handleChangeActiveTab: PropTypes.func,
};
