import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  toolbarContainer: {},
  toolbarTitle: {
    color: '#FFF',
    fontFamily: 'Open sans, sans-serif',
    fontWeight: '600',
    fontSize: '16px',
    marginRight: '8px',
  },
  toolbarButton: {
    backgroundColor: '#FFA500',
    color: '#000',
    boxShadow: '0px 4px 4px 0px #000',
    borderRadius: '3px',
    padding: '0px 6px',
    '&:hover': {
      backgroundColor: '#FFA500',
      opacity: '0.6',
    },
    '& > span > h6': {
      color: '#000',
      textAlign: 'center',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '14px',
      fontWeight: 700,
    },
  },
}));
