export const CHECK_DATA = 'checkData';
export const CHECK_BURO = 'checkBuro';
export const DOWNLOAD_DATA = 'downloadData';

export const CIEC_MISSING = 'CIEC_MISSING';
export const BURO_MISSING = 'BURO_MISSING';
export const BURO_CIEC_COMPLETED = 'BURO_CIEC_COMPLETED';

export const PRODUCT_ID_MEGA_FACTORAJE = '62fda7f351b9a70127d9d0da';
export const PRODUCT_ID_MEGA_CREDITO_SIMPLE = '62cf3debd13d1da22002c8b3';
export const PRODUCT_ID_MEGA_ARRENDAMIENTO = '63ff9d4aaec05f2340cbdade';
export const PRODUCT_ID_MEGA_CASH_CUSTOM = '6408f469ca14277cd938615d';

export const PRODUCT_FACTORAJE_MEGA_CLICK = 'factoraje';
export const PRODUCT_CREDITO_SIMPLE_MEGA_CLICK = 'creditopersonamoral';
