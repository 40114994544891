import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  item: {
    flex: 1,
    width: '100%',
  },
  itemBackground: {
    background: 'linear-gradient(122deg, #2D1583 2.78%, rgba(50, 24, 141, 0.90) 36.46%, rgba(100, 61, 238, 0.85) 98.15%)',
  },
  marginContainer: {
    margin: '0 auto',
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
  },
  innerItem: {
    color: 'white',
    height: '100%',
  },
  displayFlexbox: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  title: {
    fontSize: '32px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    letterSpacing: '0.32px',
    textAlign: 'center',
    marginTop: '35px',
    marginBottom: '35px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '26px',
      marginTop: '35px',
      fontSize: '48px',
      letterSpacing: '0.48px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '35px',
      marginTop: '35px',
      fontSize: '64px',
      letterSpacing: '0.64px',
    },
  },
  body: {
    fontSize: '20px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    letterSpacing: '0.2px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      letterSpacing: '0.28px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '36px',
      letterSpacing: '0.36px',
    },
  },
  correo: {
    fontSize: '24px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    letterSpacing: '0.24px',
    textAlign: 'center',
    marginTop: '35px',
    marginBottom: '35px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '28px',
      marginBottom: '0px',
      fontSize: '28px',
      letterSpacing: '0.28px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '35px',
      marginBottom: '0px',
      fontSize: '36px',
      letterSpacing: '0.36px',
    },
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    background: '#ecedef',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'inline-block',
    objectPosition: 'bottom',
  },
  containerButton: {
    textAlign: 'center',
    marginTop: '70px',
    marginBottom: '50px',
    [theme.breakpoints.up('md')]: {
      marginTop: '70px',
      marginBottom: '70px',
    },
  },
}));
