import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as fieldsAPI from '../../../api/fields';
import * as fieldValuesAPI from '../../../api/fieldValue';

import {
  addNewLocalFieldValue,
  setLocalFieldValues,
  setLocalFieldValuesSections,
  updateLocalFieldValueByFieldId,
  updateFieldValueOnLocalSection,
  addFieldValueLocalSection,
} from '../../../store/fieldValues';

import {
  getLocalFieldValueByFieldId,
  getLocalFieldValueByFieldName,
  saveExtraDataFieldValues,
  updateOrAddFieldValueToArray,
} from '../../../utils/fieldValues';
import {
  getFieldValuesSectionsData,
  getLocalFieldValuesData,
  removeFieldValuesData,
  storeFieldValuesData,
  storeFieldValuesSectionsData,
} from '../../../storage';

export const useFieldValues = ({
  company,
  process,
  customSectionName = null,
}) => {
  const dispatch = useDispatch();
  const {
    localFieldValues,
    localFieldValuesSections,
  } = useSelector((state) => state.fieldValues);

  const addLocalFieldValue = (fieldValue) => {
    dispatch(addNewLocalFieldValue(fieldValue));
    saveExtraDataFieldValues([fieldValue]);
  };

  const updateLocalFieldValue = (newFieldValue) => {
    dispatch(updateLocalFieldValueByFieldId(newFieldValue));
  };

  const combineFVWithLocalFieldValues = (newFieldValues) => newFieldValues.reduce(
    (acc, newFieldValue) => updateOrAddFieldValueToArray(acc, newFieldValue),
    [...localFieldValues],
  );

  const storeLocalFieldValues = (newFieldValues, combine = true) => {
    const newLocalFieldValues = combine
      ? combineFVWithLocalFieldValues(newFieldValues)
      : newFieldValues;

    dispatch(setLocalFieldValues(newLocalFieldValues));
    storeFieldValuesData(newLocalFieldValues);
    return newLocalFieldValues;
  };

  const storeLocalFieldValueBySection = (newFieldValue, section = customSectionName) => {
    const { _id: field, value } = newFieldValue;
    const existsFieldValue = localFieldValuesSections[section]?.find(
      (fieldValue) => fieldValue?.field === field,
    );
    const currentSection = localFieldValuesSections[section] || [];

    const payload = {
      field, value, section, currentSection,
    };
    if (!existsFieldValue) {
      return dispatch(addFieldValueLocalSection(payload));
    }
    return dispatch(updateFieldValueOnLocalSection(payload));
  };

  const storeManyLocalFieldValues = (newFieldValues) => {
    if (customSectionName) {
      newFieldValues.forEach(
        (newFieldValue) => storeLocalFieldValueBySection(newFieldValue, customSectionName),
      );
    }
  };

  const cleanLocalFieldValues = () => {
    dispatch(setLocalFieldValues([]));
    removeFieldValuesData();
  };

  const formatFromFieldValueModelToUpload = (fieldValuesToFormat, includeFieldName = false) => {
    const newFieldValues = fieldValuesToFormat.map(({ field, value }) => {
      const { _id, name } = field;
      if (includeFieldName) return { field: _id, value, name };
      return {
        field: _id,
        value,
      };
    });
    return newFieldValues;
  };

  const formatFieldValuesToUpload = (fieldValuesToFormat, processId = '') => {
    const newFieldValues = fieldValuesToFormat.map(({ field, value }) => ({
      field,
      value,
      process: processId || process,
    }));
    return newFieldValues;
  };

  const getLocalFieldValueByName = (fieldName) => {
    const fieldValue = getLocalFieldValueByFieldName(fieldName) || {};
    return fieldValue?.value || '';
  };

  const getLocalFieldValueById = (fieldId) => {
    const fieldValue = getLocalFieldValueByFieldId(fieldId);
    return fieldValue?.value || '';
  };

  const getFieldValueByName = async (fieldName) => {
    if (!process) return getLocalFieldValueByName(fieldName);
    const { _id } = await fieldsAPI.getFieldByCompanyAndName(company, fieldName);
    const { value } = await fieldValuesAPI.getFieldValueByProcessField(process, _id);
    return value;
  };

  const getFieldValueById = async (fieldId) => {
    if (!process) return getLocalFieldValueById(fieldId);
    const { value } = await fieldValuesAPI.getFieldValueByProcessField(process, fieldId);
    return value;
  };

  const getFieldValueByIdAndSectionName = (
    fieldId,
    sectionName,
    sectionsData = localFieldValuesSections,
  ) => {
    const sectionFieldValues = sectionsData[sectionName] || [];
    const { value = '' } = sectionFieldValues.find(({ field: _id }) => _id.toString() === fieldId.toString()) || {};
    return value;
  };

  const getFieldValue = async (_id, storedLocalSections) => {
    if (customSectionName) {
      return getFieldValueByIdAndSectionName(_id, customSectionName, storedLocalSections);
    }
    return getFieldValueById(_id);
  };

  const updateFieldValue = async (field, storedLocalSections) => {
    const { _id, value: currentFieldValue = '' } = field;
    const fieldValue = await getFieldValue(_id, storedLocalSections);
    return { ...field, value: fieldValue || currentFieldValue };
  };

  const fillFieldValuesFromStoredData = async (input) => {
    const fields = Array.isArray(input) ? input : [input];
    const storedLocalSections = getFieldValuesSectionsData() || {};

    if (process) {
      return input;
    }

    const updatedFields = await Promise.all(
      fields.map((field) => updateFieldValue(field, storedLocalSections)),
    );

    return Array.isArray(input) ? updatedFields : updatedFields[0];
  };

  useEffect(() => {
    if (!Object.keys(localFieldValuesSections).length) return;
    storeFieldValuesSectionsData(localFieldValuesSections);
  }, [localFieldValuesSections]);

  useEffect(() => {
    const storedFieldValues = getLocalFieldValuesData() || [];
    dispatch(setLocalFieldValues(storedFieldValues));
  }, []);

  useEffect(() => {
    const storedLocalSections = getFieldValuesSectionsData() || {};
    dispatch(setLocalFieldValuesSections(storedLocalSections));
  }, []);

  return {
    localFieldValues,
    localFieldValuesSections,
    getFieldValueByName,
    getFieldValueById,
    addLocalFieldValue,
    storeLocalFieldValues,
    updateLocalFieldValue,
    cleanLocalFieldValues,
    fillFieldValuesFromStoredData,
    formatFromFieldValueModelToUpload,
    formatFieldValuesToUpload,
    storeLocalFieldValueBySection,
    storeManyLocalFieldValues,
  };
};
