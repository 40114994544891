import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {},
  mainContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    border: '2px solid transparent',
    borderRadius: '15px',
    boxShadow: 24,
    width: '100%',
    maxWidth: '360px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '500px',
    },
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    lineHeight: '1.5rem',
    color: '#2C1482',
    marginTop: '16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
    },
  },
  text: {
    textAlign: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.85rem',
    color: '#4F41F2',
    marginTop: '16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  textSpan: {
    fontSize: '1rem',
    display: 'inline-block',
    marginLeft: '8px',
    cursor: 'pointer',
    fontWeight: '600',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.125rem',
    },
  },
  errorText: {
    textAlign: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.85rem',
    color: 'red',
    marginTop: '8px',
    marginBottom: '8px',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  subTitle: {
    fontSize: '1rem',
    fontWeight: 400,
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    lineHeight: '1.5rem',
    color: '#2C1482',
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxWidth: '360px',
    margin: '0 auto',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  pinDigitContainer: {
    marginTop: '40px',
    marginBottom: '40px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '60px',
      marginBottom: '60px',
    },
  },
  button: {
    backgroundColor: '#2C1482',
    fontSize: '0.85rem',
    fontWeight: 700,
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    lineHeight: '1.25rem',
    padding: '10px 24px',
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '16px',
    marginBottom: '16px',
  },
}));
