import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import InputNumber from '../InputNumber';
import useEnabler from '../../../hooks/useEnabler';

import clabeIsValid from './validator';
import clabeFormatter from './formatter';

const ERROR_MESSAGE = 'CLABE interbancaria incompleta o no válida';

const InputCLABE = ({ onChange, ...props }) => {
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value } = target; // 032180000118359719

    const newValue = clabeFormatter(value);

    if (clabeIsValid(newValue)) {
      target.setCustomValidity('');
      disableError();
    } else {
      target.setCustomValidity(ERROR_MESSAGE);
      enableError();
    }

    onChange(event, value);
  }, [onChange, enableError, disableError]);

  return (
    <InputNumber
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isFloat={false}
      onChange={handleOnChange}
      error={isError}
      helperText={isError ? ERROR_MESSAGE : ''}
    />
  );
};

InputCLABE.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default memo(InputCLABE);
