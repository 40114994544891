import React, {
  useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { FormLayout } from '../../layouts';
import { Button, GroupFields, Loader } from '../../../../components';

import useLoading from '../../../../hooks/useLoading';
import useError from '../../../../hooks/useError';
import { useProduct } from '../../../../hooks/useProduct';
import * as unprotectedAPI from '../../../../api/unprotected';
import * as companyConfigsAPI from '../../../../api/companyConfigs';

import useStyles from './useStyles';
import {
  AGENT_CLIENT_COMPANY_TYPE,
  AGENT_CREATE_CLIENT_ACCOUNT,
  COMPANY, REJECTED,
} from '../../../../constants';
import { getConsultPageConfigByFlow } from '../../../../utils/commons';
import { HeaderBar } from '../../components/HeaderBar';
import { getUserId } from '../../../../utils';
import { createLeadAndNotify } from '../../../../utils/api/leads.utils';
import { SimpleModal } from '../../../../components/Modal';
import { generateSharedFieldValues } from '../../../../utils/clients';
import { saveSharedFieldValuesData } from '../../../../api/sharedFieldValues';

const PHONE = 'phone';
const OWNER_MODEL = 'Lead';

const CreateClient = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [sections, setSections] = useState([]);
  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    showError,
  } = useError();
  const { getDefaultProduct, getFlowType } = useProduct();
  const currentProduct = getDefaultProduct();

  const [pageConfig, setPageConfig] = useState({
    title: 'Crea tu cuenta para continuar con tu aplicación',
    agentSectionTitle: '',
  });

  const classes = useStyles();

  const handleChange = (event, value, position) => {
    const { name } = event.target;
    const newSections = sections.map((section, index) => {
      if (index !== position) return section;
      const newFields = section.fields.map((field) => (field.name === name ? { ...field, value, errorMessage: '' } : field));
      return { ...section, fields: newFields };
    });
    setSections(newSections);
  };

  const isNotCompleted = () => sections.some((section) => {
    const { fields = [] } = section;
    return fields.some(({ config = {}, value = '', status = {} }) => {
      const { value: statusValue } = status;
      const { required = false, hide = false } = config;
      if (!hide && !required && statusValue === REJECTED) return true;
      if (hide || !required) return false;
      return value === '' || value === null || !value.length || statusValue === REJECTED;
    });
  });

  const getFieldsData = (fieldsIds) => fieldsIds.map((fieldId) => unprotectedAPI.getById(fieldId));
  const setDefaultValues = (fields) => fields.map((field) => {
    const { value = '', config = {} } = field;
    return !value && config?.defaultValue
      ? { ...field, value: config?.defaultValue }
      : { ...field, value };
  });

  const getFieldsStructure = async (payload) => {
    const fieldsData = await Promise.all(getFieldsData(payload.fields));
    const fieldsDataWithValues = setDefaultValues(fieldsData);
    return [{
      fields: fieldsDataWithValues,
      title: payload.title,
    },
    ];
  };

  const getCorrectPhone = (phoneText, addPrefix) => (addPrefix ? `+521${phoneText}` : phoneText);

  const transformDataToUpload = (filterFields, addPrefix = false) => {
    const newFields = sections.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).filter(({ name }) => !filterFields.includes(name)).map(({
      value, name: fieldName,
    }) => ({
      fieldName,
      value: fieldName === PHONE ? getCorrectPhone(value, addPrefix) : value,
    })).reduce((acc, { fieldName, value }) => ({
      ...acc,
      [fieldName]: value,
    }), {});
    const extraData = { propiedadValor: newFields.propiedadvalor, agentId: getUserId() };
    const firstName = `${newFields.firstName} ${newFields.secondName}`;
    const clientObject = {
      ...newFields, firstName, source: 'Agente Inmobiliario', extraData, company: COMPANY,
    };
    return clientObject;
  };

  const getClientsFields = async () => {
    const companyConfigs = await companyConfigsAPI.getAllCompanyConfigs(COMPANY);
    const companyConfig = companyConfigs.find(({ type: { name = '' } = {} }) => name === AGENT_CLIENT_COMPANY_TYPE);
    const flowType = getFlowType(currentProduct.name);
    if (companyConfig && companyConfig.payload) {
      return companyConfig.payload.flows.find((_flow) => _flow.flow === flowType);
    }
    return {};
  };

  const fetchData = async () => {
    try {
      startLoading();
      const payload = await getClientsFields();
      const fieldsData = await getFieldsStructure(payload);
      const _pageConfig = await getConsultPageConfigByFlow(
        currentProduct.flowName,
        AGENT_CREATE_CLIENT_ACCOUNT,
        AGENT_CREATE_CLIENT_ACCOUNT,
      );
      setPageConfig(_pageConfig);
      setSections(fieldsData);
      endLoading();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to fetch  Data');
      endLoading();
    }
  };

  const createClient = async (dataToUpload) => {
    const { _id } = await createLeadAndNotify(dataToUpload, true);
    return _id;
  };

  const saveSharedFieldValues = async (fields, clientId, ownerModel) => {
    const dataSharedFieldValues = generateSharedFieldValues(fields, clientId, ownerModel);
    await Promise.all(
      dataSharedFieldValues.map(
        async (itemSharedFieldValue) => saveSharedFieldValuesData(itemSharedFieldValue),
      ),
    );
  };

  const transformDataShared = (filterFields) => {
    const newFields = sections.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).filter(({ name }) => !filterFields.includes(name)).map(({
      _id, value,
    }) => ({
      _id,
      value,
    }));
    return newFields;
  };

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      startLoading();
      const newClientData = transformDataToUpload([], true);
      const temporalClientId = await createClient(newClientData);
      const sharedValues = transformDataShared([]);
      await saveSharedFieldValues(sharedValues, temporalClientId, OWNER_MODEL);
      setShowModal(true);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
    } finally {
      endLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const back = () => {
    navigate('/inmobiliaria/clients');
  };

  return (
    <div className={classes.mainContainer}>
      <Loader
        open={isLoading}
      />
      <SimpleModal
        showModal={showModal}
        onCancel={back}
        title="Liga de acceso enviada al cliente por WhatsApp"
      >
        <Box className={classes.buttonContainer}>
          <Button onClick={back} fullWidth>Aceptar</Button>
        </Box>
      </SimpleModal>
      <div className={classes.background}>
        <HeaderBar
          title={pageConfig.navbarTitle}
          back={back}
        />
      </div>
      <FormLayout
        submit={onSubmit}
        disabled={isNotCompleted()}
        titleContainerClass={classes.title}
        buttonTitle={pageConfig.buttonTitle}
      >
        <div
          className={classes.innerContainer}
        >
          <Typography variant="h2" className={classes.formTitle}>
            Completa los siguientes
          </Typography>
          <Typography variant="h3" className={classes.subTitle}>
            Datos
          </Typography>
          {
            sections.map(({ fields, title }, index) => (
              <GroupFields
                fields={fields}
                title={title}
                position={index}
                onChange={handleChange}
              />
            ))
          }
        </div>
      </FormLayout>
    </div>
  );
};

export default CreateClient;
