import React from 'react';
import { Container } from '@material-ui/core';
import { Loader } from '../../../../../components';

import useStyles from './useStyles';

export const LoaderPage = () => {
  const classes = useStyles();
  return (
    <Container
      disableGutters
      component="main"
      maxWidth={false}
      className={classes.container}
    >
      <Loader open />
    </Container>
  );
};
