import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sectionContainer: {
    background: '#E2E7F1',
  },
  tagTitle: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: '2rem',
    lineHeight: '3.125rem',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      paddingTop: '50px',
      paddingBottom: '40px',
    },
  },
  bankContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0px auto',
    width: '95%',
    paddingBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '30px',
      justifyContent: 'space-between',
    },
  },
  imgContainer: {
    margin: '20px 15px 20px 15px',
    maxWidth: '120px',
    height: '50px',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 16px',
      maxWidth: '150px',
      height: '80px',
    },
  },
  imgBanks: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));
