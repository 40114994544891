import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  title: {
    /*  */
    width: '322px',
    height: '24px',
    left: '41px',
    top: '202px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '27px',
    color: '#444345',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    left: '11px',
    top: '179px',
    border: '2px solid #E6E6E9',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    maxWidth: '600px',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(4),
    },
  },
  conditionTitle: {
    textAlign: 'left',
    color: '#2C2C2C',
    fontFamily: '"Montserrat", "sans-serif"',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: '500',
  },
  subtitle: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: '"Montserrat", "sans-serif"',
    color: '#2C2C2C',
    fontWeight: '500',
    textAlign: 'right',
  },
  textsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelsContainer: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '5rem',
    },
  },
}));
