import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  boxImage: {
    maxWidth: '100px',
  },
  imgLogo: {
    width: '100%',
  },
}));
