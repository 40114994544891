import React from 'react';

import { Box, Typography } from '@material-ui/core';
import useStyles from './useStyles';

import { staticLandingData } from '../../../../data';

export const InfoTag = () => {
  const classes = useStyles();

  const { dataInfoTag } = staticLandingData;

  return (
    <Box component="section" className={classes.sectionContainer}>
      <Typography className={classes.tagTitle} component="h2">
        {dataInfoTag.title}
      </Typography>
      <Box className={classes.bankContainer}>
        {
          dataInfoTag.blankImages.map((image) => (
            <Box className={classes.imgContainer}>
              <Box className={classes.imgBanks} component="img" src={image} />
            </Box>
          ))
        }
      </Box>
    </Box>
  );
};
