import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    background: '#F7FAFD',
    height: 'calc(100% - 130px)',
    flexDirection: 'column',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  buttonInformation: {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.25rem',
  },
  buttonContainer: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '2.5rem',
    lineHeight: '3rem',
    maxWidth: '1100px',
    marginBottom: '40px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
      lineHeight: '2rem',

    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
}));
