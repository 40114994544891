/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as googleAnalytics from '../../utils/googleAnalytics/events';
import useStyles from './useStyles';
import { WHATS_ICON_LOGO } from '../../constants/commonUrls';

export const WhatsappFloat = ({
  classNames,
}) => {
  const classes = useStyles();
  const whatsappLogo = WHATS_ICON_LOGO;
  const onClick = () => {
    googleAnalytics.botonWhatsapp();
  };
  return (
    <div className={classes.whatsappLogo} onClick={onClick}>
      <a
        target="_blank"
        className={clsx(classes.Float, classNames)}
        href="https://api.whatsapp.com/send?phone=523335063714&text="
        rel="noreferrer"
      >
        <img
          src={whatsappLogo}
          className={classes.imageLogo}
          alt="whatsapp logo"
        />
        <i className={classes.myFloat} />
      </a>
    </div>
  );
};

WhatsappFloat.propTypes = {
  classNames: PropTypes.string,
};

WhatsappFloat.defaultProps = {
  classNames: '',
};
