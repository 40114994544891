import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  innerContainer: {
    maxWidth: '1100px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  logoColumn: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: 200,
    marginRight: theme.spacing(4),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  logosContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: theme.spacing(2),
  },
  logoImageMovil: {
    maxHeight: '65px',
    width: 'auto',
    display: 'block',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoImageR: {
    maxHeight: '50px',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  promoText: {
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    letterSpacing: '0.32px',
  },
  bodyText: {
    marginBottom: theme.spacing(4),
    fontSize: '1.25rem',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    letterSpacing: '0.2px',
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      width: 'auto',
    },
  },
}));

export default useStyles;
