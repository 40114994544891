import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingBottom: '80px',
    background: 'white',
    maxWidth: '1600px',

    [theme.breakpoints.up('sm')]: {
      background: 'transparent',
      paddingBottom: '50px',
    },
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0px 20px 0px 32px',
      width: 'calc(100% - 52px)',
    },
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      width: '100%',
    },
  },
  item: {
    flex: 1,
    width: '100%',
  },
  itemRight: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  marginContainer: {
    margin: '0 auto',
    width: '95%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  innerItem: {
    color: 'white',
    height: '100%',
  },
  title: {
    color: '#06040A',
    fontSize: '44px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    letterSpacing: '0.36px',
    margin: '40px 0px 36px 0px',
    maxWidth: '750px',
    [theme.breakpoints.up('sm')]: {
      margin: '20px 0px 36px 0px',
      fontSize: '42px',
      lineHeight: '64px',
      letterSpacing: '0.32px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '42',
      lineHeight: '50px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '60px',
      lineHeight: '72px',
    },
  },
  subtitleContainer: {
    paddingLeft: '32px',
    borderLeft: '6px solid #2C1482',
  },
  subTitle: {
    color: '#06040A',
    fontSize: '24px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    letterSpacing: '0.2px',
    lineHeight: '32px',
    margin: '24px 0px 24px 0px',
    maxWidth: '650px',
  },
  button: {
    fontSize: '22px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '500',
    letterSpacing: '0.2px',
    textTransform: 'none',
    lineHeight: '30px',
    color: '#FFF',
    background: '#2C1482',
    borderRadius: '5px',
    padding: '10px 20px',
    width: '100%',
    maxWidth: '90%',
    '&:hover': {
      background: '#2C1482',
      opacity: '0.6',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '24px 40px',
      maxWidth: '414px',
    },
  },
  imageContainer: {
    boxSizing: 'border-box',
    width: '95%',
    height: '100%',
    marginTop: '50px',
    maxWidth: '450px',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 40px)',
      marginTop: '0px',
      marginLeft: '40px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'inline-block',
    objectPosition: 'bottom',
  },
}));
