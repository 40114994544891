/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import { ListItem } from '../ListItem';

export const ListData = ({
  title,
  clients,
  onClick,
  lastBookElementRef,
}) => {
  const classes = useStyles();
  return (
    <>
      <h2 className={classes.title}>{title}</h2>
      {
        clients.map(({
          name,
          date,
          phone,
          processesData,
          lastNode,
        }) => (
          <ListItem
            title={name}
            phone={phone}
            date={date}
            onClick={() => onClick(processesData, name)}
            lastNode={lastNode}
            lastBookElementRef={lastBookElementRef}
          />
        ))
      }
    </>
  );
};

ListData.propTypes = {
  title: PropTypes.string,
  clients: PropTypes.array,
  onClick: PropTypes.func,
  lastBookElementRef: PropTypes.func,
};

ListData.defaultProps = {
  title: '',
  clients: [],
  onClick: () => { },
  lastBookElementRef: () => { },
};
