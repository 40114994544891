import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  label: {
    fontWeight: '600 !important',
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  stepLabelText: {
    visibility: 'hidden',
  },
  active: {
    color: theme.palette.tertiary.main,
  },
}));
