import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '../../../../components';
import AlertSnack from '../../../../components/AlertSnack';
import ErrorMessage from '../../../../components/ErrorMessage';

import useError from '../../../../hooks/useError';
import useLoading from '../../../../hooks/useLoading';

import { StepperContext } from '../../../../context/stepper';
import { ThemeContext } from '../../../../context/Theme/themeContext';
import { AuthContext } from '../../../../context/auth';

import {
  PERFILAMIENTO_REDIRECTION,
  getActualStep,
  getFlowTypeHandler,
  getStepperTags,
  saveExtraDataAuth,
  storeStepData,
} from '../../../../utils';
import { storeClientExtraData } from '../../../../utils/clientData';
import { loginToken } from '../../../../utils/api/auth.utils';

export const RecoverClientProcess = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const process = queryParams.get('process');

  const navigate = useNavigate();

  const { initTimeoutToken } = useContext(AuthContext);
  const { initStepper } = useContext(StepperContext);
  const { loadTheme } = useContext(ThemeContext);

  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();

  const setTags = async (processId, normalizedFlowName) => {
    const stepperInitialization = await getStepperTags(normalizedFlowName, process);
    initStepper(stepperInitialization);
    const { steps } = stepperInitialization;
    const actualStep = getActualStep(steps);
    initStepper({ ...stepperInitialization, current: actualStep.currentStep });
    storeStepData({ ...actualStep, steps });
    return actualStep;
  };

  const loginByTokenFlow = async () => {
    try {
      if (!queryParams) return false;
      startLoading();
      await loginToken(token);
      const { normalizedFlowName } = await getFlowTypeHandler(process);
      await setTags(process, normalizedFlowName);
      saveExtraDataAuth({ process });
      await loadTheme();
      initTimeoutToken();
      storeClientExtraData({ isRecoverClient: true });
      return navigate(PERFILAMIENTO_REDIRECTION);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to loginByTokenFlow');
      return false;
    }
  };

  const handleLoginByToken = async () => {
    startLoading();
    await loginByTokenFlow();
    endLoading();
  };

  useEffect(() => {
    handleLoginByToken();
  }, [token]);

  return (
    <>
      <AlertSnack
        isOpen={isError}
        type="error"
        onClose={hideError}
      >
        <ErrorMessage
          errorType={errorType}
          errorMessage={errorMessage}
        />
      </AlertSnack>
      <Loader open={isLoading} />
    </>
  );
};
