import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import { InputBase, InputAdornment, IconButton } from '@material-ui/core';
import useStyles from './useStyles';

const ENTER_KEY = 13;

const Search = ({
  onEnter,
  placeholder,
  value,
  disabled,
  name,
}) => {
  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState('');
  const [hasSearchBeenTriggered, setHasSearchBeenTriggered] = useState(false);

  const onKeyup = (event) => {
    const { keyCode } = event;
    if (keyCode === ENTER_KEY) {
      onEnter(event, currentValue);
      setHasSearchBeenTriggered(true);
    }
  };

  const onSearch = (event) => {
    onEnter(event, currentValue);
    setHasSearchBeenTriggered(true);
  };

  const onCleanSearch = (event) => {
    setCurrentValue('');
    setHasSearchBeenTriggered(false);
    onEnter(event, '');
  };

  const handleOnChange = (event) => {
    const {
      target: { value: targetValue },
    } = event;
    setCurrentValue(targetValue);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={classes.root}>
      <InputBase
        onKeyUp={onKeyup}
        name={name}
        placeholder={placeholder}
        classes={{
          root: classes.inputContainer,
          input: classes.input,
        }}
        inputProps={{ 'aria-label': 'Buscar cliente' }}
        startAdornment={(
          <InputAdornment position="start">
            <IconButton
              aria-label="Buscar cliente"
              className={classes.iconButton}
              onClick={onSearch}
            >
              <SearchIcon className={classes.icon} />
            </IconButton>
          </InputAdornment>
        )}
        endAdornment={
          hasSearchBeenTriggered ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="Buscar cliente"
                className={classes.iconButton}
                onClick={onCleanSearch}
              >
                <Close className={classes.icon} />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        value={currentValue}
        onChange={handleOnChange}
        disabled={disabled}
      />
    </div>
  );
};

Search.propTypes = {
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

Search.defaultProps = {
  onEnter: () => { },
  placeholder: '',
  value: '',
  disabled: false,
  name: '',
};

export default Search;
