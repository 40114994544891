import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBar: {
    marginBottom: '5px',
  },
  boxStep: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  boxStepMovil: {
    padding: '5%',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      padding: '3%',
      display: 'flex',

    },
    [theme.breakpoints.up('md')]: {
      padding: '3%',
      display: 'none',
    },

  },
  titleContainer: {
    flex: 1,
    paddingLeft: '10px',
  },
  containerButton: {
    justifyContent: 'right',
  },
  title: {
    color: '#434445',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },

  },
  subtitle: {
    color: '#7B7474',
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
    },

  },
  stepCircle: {
    width: '20px',
    height: '20px',
    background: '#FF5F00',
    borderRadius: '50%',
    marginTop: '15px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '5px',

    },
  },
  stepperContainer: {
    flex: 1,
  },
  line: {
    visibility: 'hidden',
  },
  backButton: {
    padding: '10px 18px',
    backgroundColor: '#FFF',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
    margin: '0px 14px',
    visibility: 'hidden',
  },
  exitButton: {
    padding: '10px 18px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
  },
}));
