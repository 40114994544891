import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  linearProgress: {
    backgroundColor: '#c6c9d4',
  },

  typography: {
    textAlign: 'center',
  },
}));
