import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { PERFILADOR_VARIANTE_LANDING } from '../../../../constants';
import { Perfilador } from '../../../perfilador';
import {
  getActualStep, getConfigTags, handleLogout, storeStepData,
} from '../../../../utils';
import * as entitiesAPI from '../../../../api/entity';
import { storeCredit } from '../../../../utils/credit';
import { setInitialFieldValues } from '../../../../store/fieldValues';

import useStyles from './useStyles';
import useRoute from '../../../../hooks/useRoute';
import { useProduct } from '../../../../hooks/useProduct';
import { ThemeContext } from '../../../../context/Theme/themeContext';
import { StepperContext } from '../../../../context/stepper';
import { storeFlowData } from '../../../../utils/flows';
import { storeEntityData } from '../../../../storage';
import { LandingContext } from '../../../../context/landing';

export const CalCredit = ({
  startLoading,
  endLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { inmobiliariaId } = useContext(LandingContext);

  const { loadTheme } = useContext(ThemeContext);
  const { initStepper } = useContext(StepperContext);
  const {
    navigateToNewQueryRoute,
  } = useRoute();

  const { getProductByRoute } = useProduct();

  const fetchChiploInmobiliariaId = async () => {
    const dataInmobiliaria = await entitiesAPI.getEntityById(inmobiliariaId);
    return dataInmobiliaria;
  };

  const handleSubmit = async ({
    storageData,
    event,
  }) => {
    event.preventDefault();
    event.stopPropagation();

    await handleLogout();
    dispatch(setInitialFieldValues());
    startLoading();
    storeCredit(storageData);
    const inmobiliariaData = await fetchChiploInmobiliariaId();

    const productByRoute = getProductByRoute('/inmobiliaria');
    const { name, firstRoute } = productByRoute;
    await loadTheme(name);
    const tags = await getConfigTags(name);
    const stepperObject = { steps: tags, current: 0 };
    initStepper(stepperObject);
    const { steps } = stepperObject;
    const actualStep = getActualStep(steps);
    storeFlowData(productByRoute);
    storeStepData({ ...actualStep, steps });
    storeEntityData(inmobiliariaData);

    navigateToNewQueryRoute(firstRoute, 'inmobiliaria', inmobiliariaId);
    endLoading();
  };

  return (
    <div id="cotizador" className={classes.container}>
      <Typography
        className={classes.title}
      >
        Cotiza tu crédito
      </Typography>
      <Perfilador
        buttonTitle="Cotiza tu crédito"
        onSubmit={handleSubmit}
        variant={PERFILADOR_VARIANTE_LANDING}
      />
    </div>
  );
};

CalCredit.propTypes = {
  startLoading: PropTypes.func,
  endLoading: PropTypes.func,
};

CalCredit.defaultProps = {
  startLoading: () => { },
  endLoading: () => { },
};
