/* eslint-disable arrow-body-style */
import React from 'react';
import { Container, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './useStyles';

export const SideBanner = ({ logoUrl, mainImageUrl }) => {
  const classes = useStyles();
  return (
    <Container
      component="section"
      maxWidth={false}
      className={classes.container}
    >
      <Box className={classes.boxImage}>
        <img
          className={classes.imgLogo}
          src={logoUrl}
          alt="imgLogoMega"
        />
      </Box>
      <Box className={classes.imageContainer}>
        <img
          className={classes.image}
          src={mainImageUrl}
          alt="imgLogoMega"
        />
      </Box>
    </Container>
  );
};

SideBanner.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  mainImageUrl: PropTypes.string.isRequired,
};
