import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ProtectedRoute } from '../../../components';
import { MainLayout } from '../../main/layouts';
import { FlowStrategyContainer } from '../../flowStrategy';

import { InfonavitLanding } from '../pages';
import CreateAccountPage from '../../main/pages/CreateAccountPage';
import { CotizacionPreview } from '../../main/pages/CotizacionPreview';
import { CreditProfilingPage } from '../../main/pages/CreditProfilingPage';

import { TabsProvider } from '../../../context/tabs/tabsProvider';
import { ProfilingProvider } from '../../../context/profiling';
import { CreateAccountProvider } from '../../../context/createAccount';

import { simulatorStrategies } from '../../../strategies/simulatorStrategies';
import { CotizationPreviewProvider } from '../../../context/cotizationPreview';

import {
  AUTH_ROUTE_TYPE,
  CREDIT_PROFILING_COTIZATION_STRATEGY,
  CREDIT_PROFILING_STRATEGY,
  INFONAVIT_STRATEGY,
  COTIZATION_PREVIEW_STRATEGY,
  CREDITO_HIPOTECARIO_INFONAVIT,
} from '../../../constants';

const InfonavitRoutes = () => (
  <div style={{ overflowY: 'auto', height: '100%', background: '#F7FAFD' }}>
    <Routes>
      <Route
        path="/cotizacion"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/account"
            additionalData={{ authProtected: false }}
          >
            <MainLayout showStepperBar={false}>
              {/* TODO: refactorizar  a contenedor con todas las estrategias de la app */}
              <FlowStrategyContainer
                strategies={simulatorStrategies}
                flowName={CREDITO_HIPOTECARIO_INFONAVIT}
              >
                <CotizationPreviewProvider>
                  <CotizacionPreview
                    strategyName={COTIZATION_PREVIEW_STRATEGY}
                    productStrategy={INFONAVIT_STRATEGY}
                  />
                </CotizationPreviewProvider>
              </FlowStrategyContainer>
            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/cuestionario_perfilamiento"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/main/consult"
            additionalData={{ authProtected: false }}
          >
            <MainLayout showStepperBar={false}>
              <ProfilingProvider>
                <CreditProfilingPage
                  strategyName={CREDIT_PROFILING_STRATEGY}
                  productStrategy={INFONAVIT_STRATEGY}
                />
              </ProfilingProvider>
            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/perfilamiento"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/main/consult"
            additionalData={{ authProtected: false }}
          >
            <MainLayout showStepperBar={false}>
              <FlowStrategyContainer
                strategies={simulatorStrategies}
                flowName={CREDITO_HIPOTECARIO_INFONAVIT}
              >
                <ProfilingProvider>
                  <CreditProfilingPage
                    strategyName={CREDIT_PROFILING_COTIZATION_STRATEGY}
                    productStrategy={INFONAVIT_STRATEGY}
                  />
                </ProfilingProvider>
              </FlowStrategyContainer>
            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/account"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/main/consult"
            additionalData={{ authProtected: false }}
          >
            <MainLayout showStepperBar={false}>
              <CreateAccountProvider>
                <CreateAccountPage productStrategy={INFONAVIT_STRATEGY} />
              </CreateAccountProvider>
            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/"
        element={(
          <TabsProvider>
            <InfonavitLanding />
          </TabsProvider>
        )}
      />
      <Route
        path="/*"
        element={<Navigate to="/infonavit" />}
      />
    </Routes>
  </div>
);

export default InfonavitRoutes;
