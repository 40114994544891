/* eslint-disable max-len */
import { Button, Container, Typography } from '@material-ui/core';

import { WhatsappFloat } from '../components';
import ViviendaWhatsappContainer from '../components/ViviendaWhatsappContainer';
import { convertStringToFloat, currencyFormatter } from '../utils';
import { calculateAmortizacion, calculateCreditoInfonavit } from '../utils/banksCalculations.utils';
import {
  getEnganche,
  getImporte,
  getMontoCreditoInfonavit,
  getPlazo,
  getPropietyValue,
  getSaldoSubcuentaInfonavit,
  getSueldoMensualBruto,
  getTipoPrestamoInfonavit,
} from '../utils/credit';
import { convertirANumero, getMonthlyPaid } from '../utils/currency';
import { createLayerTagManager } from '../utils/tagManager';
import { INFONAVIT_COFINAVIT_TYPE } from '../constants';
import { replacingValuesOnString } from '../containers/main/utils/replacingValuesInString';
import { ShowConditionsContainer } from '../containers/main/components/components/ShowConditionsContainer';

const baseStrategy = {
  // poner los bank values
  getBanksValues: async ({
    bankMerge,
    importe,
    month,
    getFieldValueById,
  }) => {
    const { tasa: tasaId } = bankMerge;
    const valueTasa = await getFieldValueById(tasaId);
    const newTasa = valueTasa * 0.01;
    const pagoMensual = getMonthlyPaid({
      importe,
      plazoMensual: month,
      tasa: newTasa,
    });
    const tasaMensual = newTasa / 12;
    const { amortizacion } = calculateAmortizacion('', new Date(), month, pagoMensual, tasaMensual, importe, 70000);
    return {
      ...bankMerge,
      tasa: convertStringToFloat(valueTasa),
      monthlyPaid: pagoMensual,
      amortization: amortizacion,
    };
  },
  realFlowName: 'creditohipotecario',
  createLayerTag: () => { },
  getInfoTitle: () => '',
  getTitleClassName: (classes) => ({ header: classes.header, container: classes.container }),
  renderButton: () => { },
  renderFooterWhatsapp: ({ classes }) => (
    <WhatsappFloat classNames={classes.whatss} />
  ),
  renderSubtitle: ({ classes, subtitle }) => subtitle && (
    <Typography component="h2" className={classes.title}>{subtitle}</Typography>
  ),
};

const creditoHipotecarioStrategy = {
  ...baseStrategy,
  renderButton:
    ({
      classes, buttonTitle, buttonFooterTitle, disabled,
    }) => () => (
      <Container className={classes.buttonInmobiliariaContainer}>
        <Button
          className={classes.button}
          type="submit"
          disabled={disabled}
        >
          {buttonTitle}
        </Button>
        <Typography
          variant="body2"
          className={classes.buttonFooterTitle}
        >
          {buttonFooterTitle}
        </Typography>
      </Container>
    ),
  renderSubtitle: ({
    showPropietyValue = false,
    showEnganche = false,
    showImporte = false,
    showPlazo = false,
    showGastosEscritura = false,
    includeCents = false,
    children = null,
  }) => (
    <ShowConditionsContainer
      showPropietyValue={showPropietyValue}
      showEnganche={showEnganche}
      showImporte={showImporte}
      showPlazo={showPlazo}
      showGastosEscritura={showGastosEscritura}
      includeCents={includeCents}
    >
      {children}
    </ShowConditionsContainer>
  ),
};

const viviendaStrategy = {
  ...baseStrategy,
  realFlowName: 'creditohipotecariovivienda',
  createLayerTag: (dataLayer) => createLayerTagManager(dataLayer, 'vivienda'),
  getTitleClassName: (classes) => ({
    header: classes.header,
    container: classes.formContainer,
  }),
  getInfoTitle: ({ subtitle = '' }) => subtitle,
  renderButton:
    ({ classes, buttonTitle, buttonFooterTitle }) => () => (
      <ViviendaWhatsappContainer
        classes={classes}
        buttonTitle={buttonTitle}
        buttonFooterTitle={buttonFooterTitle}
      />
    ),
  renderFooterWhatsapp: ({ classes }) => (
    <WhatsappFloat classNames={classes.whatss} />
  ),
};

const infonavitStrategy = {
  ...baseStrategy,
  realFlowName: 'creditohipotecarioinfonavit',
  createLayerTag: (dataLayer) => createLayerTagManager(dataLayer, 'infonavit'),
  getInfoTitle: ({ infoTitle = '' }) => {
    if (!infoTitle) return null;
    const tipoPrestamoInfonavit = getTipoPrestamoInfonavit() || INFONAVIT_COFINAVIT_TYPE;
    const textInfoTitle = infoTitle[tipoPrestamoInfonavit];
    if (!textInfoTitle) return null;
    const engancheNumber = getEnganche();
    const engancheString = engancheNumber ? currencyFormatter(engancheNumber) : '$0.00';
    return replacingValuesOnString(textInfoTitle, {
      importe: currencyFormatter(getImporte()),
      propietyValue: currencyFormatter(getPropietyValue()),
      enganche: engancheString,
      plazo: getPlazo(),
    });
  },
  getTitleClassName: (classes) => ({
    header: classes.header,
    container: classes.formContainer,
  }),
  renderButton:
    ({ classes, buttonTitle, buttonFooterTitle }) => () => (
      <ViviendaWhatsappContainer
        classes={classes}
        buttonTitle={buttonTitle}
        buttonFooterTitle={buttonFooterTitle}
      />
    ),
  renderFooterWhatsapp: ({ classes }) => (
    <WhatsappFloat classNames={classes.whatss} />
  ),
  renderSubtitle: ({ classes, subtitle }) => {
    const tipoPrestamoInfonavit = getTipoPrestamoInfonavit();
    if (!subtitle || !Object.keys(subtitle).length) return null;
    const productSubtitle = subtitle[tipoPrestamoInfonavit];
    if (!productSubtitle) return null;
    const { text, subtitleLink } = productSubtitle;
    return (
      <div className={classes.subtContainer}>
        <Typography component="h2" className={classes.subtitle}>
          {`${text} `}
          {subtitleLink && (
            <a className={classes.subtitleLink} href={subtitleLink?.url} target="_blank" rel="noreferrer">
              {subtitleLink?.text}
            </a>
          )}
        </Typography>
      </div>
    );
  },
  getBanksValues: async ({
    bankMerge,
    enganche,
    month,
    propiedadvalor,
    getFieldValueById,
  }) => {
    const { tasa: tasaId } = bankMerge;
    const propietyValue = convertirANumero(propiedadvalor);
    const valueTasa = await getFieldValueById(tasaId);
    const tipoPrestamoInfonavit = getTipoPrestamoInfonavit();
    const saldoSubcuentaInfonavit = getSaldoSubcuentaInfonavit();
    const montoCreditoInfonavit = getMontoCreditoInfonavit();
    const sueldoMensualBruto = getSueldoMensualBruto();
    const year = month / 12;
    const {
      pagoMensual,
      amortizacion,
      duracionPlazo,
    } = calculateCreditoInfonavit({
      propietyValue,
      tasa: valueTasa * 0.01,
      years: year,
      tipoPrestamoInfonavit,
      enganche,
      saldoSubcuentaInfonavit,
      montoCreditoInfonavit,
      sueldoMensualBruto,
    });
    const plazo = (duracionPlazo !== null || undefined) && duracionPlazo < year
      ? duracionPlazo
      : null;
    return {
      ...bankMerge,
      tasa: convertStringToFloat(valueTasa),
      monthlyPaid: pagoMensual,
      amortization: amortizacion,
      plazo,
    };
  },
};

const liquidezStrategy = {
  ...baseStrategy,
  realFlowName: 'creditohipotecarioliquidez',
  createLayerTag: (dataLayer) => createLayerTagManager(dataLayer, 'liquidez'),
  getTitleClassName: (classes) => ({
    header: classes.header,
    container: classes.formContainer,
  }),
  renderButton:
    ({ classes, buttonTitle, buttonFooterTitle }) => () => (
      <ViviendaWhatsappContainer
        classes={classes}
        buttonTitle={buttonTitle}
        buttonFooterTitle={buttonFooterTitle}
      />
    ),
  renderFooterWhatsapp: ({ classes }) => (
    <WhatsappFloat classNames={classes.whatss} />
  ),
};

const inmobiliariaStrategy = {
  ...baseStrategy,
  realFlowName: 'creditohipotecarioinmobiliaria',
  renderButton:
    ({
      classes, buttonTitle, buttonFooterTitle, disabled,
    }) => () => (
      <Container className={classes.buttonInmobiliariaContainer}>
        <Button
          className={classes.button}
          type="submit"
          disabled={disabled}
        >
          {buttonTitle}
        </Button>
        <Typography
          variant="body2"
          className={classes.buttonFooterTitle}
        >
          {buttonFooterTitle}
        </Typography>
      </Container>
    ),
  getTitleClassName: (classes) => ({
    header: classes.header,
    container: classes.inmobiliariaContainer,
  }),
  renderFooterWhatsapp: () => {},
  renderSubtitle: ({
    showPropietyValue = false,
    showEnganche = false,
    showImporte = false,
    showPlazo = false,
    showGastosEscritura = false,
    includeCents = false,
    children = null,
  }) => (
    <ShowConditionsContainer
      showPropietyValue={showPropietyValue}
      showEnganche={showEnganche}
      showImporte={showImporte}
      showPlazo={showPlazo}
      showGastosEscritura={showGastosEscritura}
      includeCents={includeCents}
    >
      {children}
    </ShowConditionsContainer>
  ),
  getBanksValues: async ({
    bankMerge, importe, month, getFieldValueById,
  }) => {
    const { tasa: tasaId } = bankMerge;
    const valueTasa = await getFieldValueById(tasaId);
    const newTasa = valueTasa * 0.01;
    const pagoMensual = getMonthlyPaid({
      importe,
      plazoMensual: month,
      tasa: newTasa,
    });
    return {
      ...bankMerge,
      tasa: convertStringToFloat(valueTasa),
      monthlyPaid: pagoMensual,
    };
  },
};

export {
  creditoHipotecarioStrategy,
  viviendaStrategy,
  infonavitStrategy,
  liquidezStrategy,
  inmobiliariaStrategy,
};
