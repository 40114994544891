/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { TabsContext } from '../../../../context/tabs/tabsContext';
import { PerfiladorPrecioInmueble } from '../PerfiladorPrecioInmueble';
import { PerfiladorIngresosMensuales } from '../PerfiladorIngresosMensuales';
import { TabPanel } from '../../../../components/TabPanel';
import useStyles from './useStyles';

export const PerfiladorBodyTabs = ({
  buttonTitle, onSubmit, showFooter,
}) => {
  const { state: { tabs, active }, tabsExtraClasses } = useContext(TabsContext);

  const classes = useStyles();

  return (
    <div className={clsx({
      [classes.container]: true,
      [tabsExtraClasses?.container]: tabsExtraClasses?.container,
    })}
    >
      {tabs.length > 0 && (
        <div className={classes.tabsContainer}>
          <TabPanel
            value={active}
            index={tabs[0].value}
          >
            <PerfiladorPrecioInmueble
              onSubmit={onSubmit}
              buttonTitle={buttonTitle}
              showFooter={showFooter}
            />
          </TabPanel>
          <TabPanel
            value={active}
            index={tabs[1].value}
          >
            <PerfiladorIngresosMensuales
              onSubmit={onSubmit}
              buttonTitle={buttonTitle}
              showFooter={showFooter}
            />
          </TabPanel>
        </div>
      )}
    </div>
  );
};

PerfiladorBodyTabs.propTypes = {
  buttonTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  showFooter: PropTypes.bool,
};

PerfiladorBodyTabs.defaultProps = {
  buttonTitle: '',
  onSubmit: () => {},
  showFooter: false,
};
