// /api/fields/company/:company/name/:fieldName
// /api/fields/company/:company/name/:fieldName
import { createInstance } from './config';

const BASE_URL = '/fields';

export const getFieldsById = async (id) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/${id}`);
  return data;
};

export const getFieldByCompanyAndName = async (company, fieldName) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/company/${company}/name/${fieldName}`);
  return data;
};
