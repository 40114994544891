import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  footerText: {
    width: '100%',
    color: theme.palette.primary.main,
    fontSize: '0.85rem',
    marginTop: '20px',
    marginBottom: '20px',
    textAlign: 'justify',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
}));
