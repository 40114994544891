/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import { Button, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './useStyles';

export const InformationDialog = ({
  onSubmit,
  descripcion,
  buttonTitle,
  showModal,
  onClose,
  showCloseButton,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    if (showCloseButton) {
      onClose();
    }
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
    >
      <div className={classes.mainContainer}>
        {
          showCloseButton && (
          <div className={classes.closeButtonContainer}>
            <Button
              className={classes.closeButton}
              onClick={onClose}
            >
              <Close />
            </Button>
          </div>
          )
        }
        <div className={classes.innerContainer}>
          <Typography
            variant="h1"
            className={classes.title}
          >
            {descripcion}
          </Typography>
          <div
            className={classes.buttonContainer}
          >
            <Button
              type="submit"
              className={classes.button}
              onClick={onSubmit}
            >
              {buttonTitle}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

InformationDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  descripcion: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool,
};

InformationDialog.defaultProps = {
  showCloseButton: false,
};
