import React, {
  useState, useEffect, useContext,
} from 'react';

import PropTypes from 'prop-types';

import * as googleAnalytics from '../../../../../utils/googleAnalytics/events';
import { StepperContext } from '../../../../../context/stepper';
import useError from '../../../../../hooks/useError';
import useLoading from '../../../../../hooks/useLoading';
import { getCurrentStep, storeStepData } from '../../../../../utils/stepper';

import * as leadsAPI from '../../../../../api/leads';
import * as clientAPI from '../../../../../api/clients';
import * as processAPI from '../../../../../api/process';
import * as fieldValuesAPI from '../../../../../api/fieldValue';

import {
  COMPANY,
  CREDIT_PROFILING_COTIZATION,
  FINISH_TYPE_CREDIT_PROFILLING_VIVIENDA,
  PROPUESTA_PERSONALIZADA_TIMELINE_TAG,
} from '../../../../../constants';

import {
  getEmail,
  getProcess,
  createTags,
  getAuth,
  storeAuth,
  addNextProcess,
  findActualStepByHighestStepCompleted,
  sortStepsArrayByStepPosition,
  setCustomDefaultTagsInProcess,
  updateLocalStepsByStep,
} from '../../../../../utils';

import { getFlowName } from '../../../../../utils/flows';
import { getLeadData } from '../../../../../utils/leads';
import { finishStepByProcess } from '../../../../../utils/steps';
import { getFieldValuesInFieldsFormat } from '../../../utils/fields';
import { updateLeadWithTagSteps } from '../../../../../utils/api/leads.utils';
import { createProcessWithOutProduct, getFlow } from '../../../../../utils/profiling';
import { convertirAMoneda } from '../../../../../utils/currency';

import useRoute from '../../../../../hooks/useRoute';
import { useProduct } from '../../../../../hooks/useProduct';

import { saveAllLocalFieldValues, saveLocalPerfilamientoSharedFieldValue } from '../../../shared/CreditProfiling';
import {
  createClientAccountFromLocalData, loginByEmailAndPassword, saveLeadSharedFieldValues,
} from '../../../shared/CreateClientAccount';

import { useProfiling } from '../../../../../hooks/useProfiling';
import { executeAutoNodes } from '../../../../../utils/api/nodes.utils';
import BaseCreditProfilingCotization from '../BaseCreditProfilingCotization';
import useFetchPageData from '../../../../../hooks/useFetchPageData';
import useCreditProfilingCotization from '../hooks/useCreditProfilingCotization';
import { completeTimelineTagByTagName } from '../../../../../api/timeline';

const NORMAL_FINISH_TYPE = 'NORMAL';

export const CreditProfilingCotizationV2 = ({ goTo, createUpdateTag }) => {
  const { strategy: profilingStrategy } = useProfiling();
  const { getDefaultProduct } = useProduct();

  const defaultProduct = getDefaultProduct();
  const flowName = getFlowName() || defaultProduct.name;
  const {
    autoNodes,
    startNotifyResults,
    fetchAutoNodesToNotifyResults,
    fetchAutoNodes,
  } = useCreditProfilingCotization();

  const { pageData, fetchPageData, setPageData } = useFetchPageData({
    pageName: CREDIT_PROFILING_COTIZATION,
    flowName,
    autoFetch: false,
  });

  const {
    showError,
  } = useError();
  const {
    navigateWithQueryParam,
    searchParams,
  } = useRoute();

  const { initStepper, state: { steps, current } } = useContext(StepperContext);

  const [show, setShow] = useState(false);

  const { isLoading, startLoading, endLoading } = useLoading();
  const [checkSendEmail, setCheckSendEmail] = useState(false);

  const saveExtraDataInStorage = (extraData) => {
    const authData = getAuth();
    storeAuth({
      ...authData,
      ...extraData,
    });
  };
  const getActualStep = (tagsArray) => {
    const tagFound = tagsArray.find(({ completed }) => !completed);
    const tagIndex = tagsArray.findIndex(({ completed }) => !completed);
    return { stepRoute: tagFound.route, currentStep: tagIndex };
  };

  const getBankFieldValuesFormatted = (monthlyPayment, product, tasa, process = '') => [
    {
      field: '64e3bfa365dc58ed7b91ab1c',
      value: monthlyPayment,
      process,
    },
    {
      field: '64f8c40e0a5ab5c025d5a5bb',
      value: product,
      process,
    },
    {
      field: '64f8c3d10a5ab5c025d59636',
      value: tasa,
      process,
    },
  ];

  const saveFieldValue = async (value, product, tasa, process, token = '') => {
    const bankFieldValues = getBankFieldValuesFormatted(value, product, tasa, process);
    await Promise.all(
      bankFieldValues.map(async (fieldValue) => fieldValuesAPI.saveFieldValue(fieldValue, token)),
    );
  };

  const createFinishTagGeneral = async (processId, id) => {
    const tagBody = {
      banco: `Banco elegido: ${id}`,
    };
    await completeTimelineTagByTagName(
      processId,
      COMPANY,
      {
        tagName: PROPUESTA_PERSONALIZADA_TIMELINE_TAG,
        tagBody,
      },
    );
  };

  const execNormalFinish = async ({ bestOption }) => {
    const oldProcess = getProcess();
    const client = await clientAPI.getClientByCompanyAndEmail(COMPANY, getEmail());
    const {
      flow, mensualidad, producto, tasa,
    } = bestOption;
    await finishStepByProcess('precalificacion');
    const { normalizedFlowName: normalizedName } = await getFlow(flow);
    const { process } = await createProcessWithOutProduct(client, flow);
    await saveFieldValue(convertirAMoneda(mensualidad), producto, `${tasa}`, process._id);
    const tags = await createTags(process._id, normalizedName);
    const tagsArray = Object.keys(tags)
      .map((key) => tags[key]).filter(({ name }) => name);
    const actualStep = getActualStep(tagsArray);
    saveExtraDataInStorage({ process: process._id, flow });
    await executeAutoNodes(autoNodes, process._id);
    await startNotifyResults(checkSendEmail, process._id);

    storeStepData({ ...actualStep, steps: tagsArray });
    await createFinishTagGeneral(process._id, flow);
    await addNextProcess(oldProcess, process._id);
    await addNextProcess(process._id, oldProcess);
    googleAnalytics.eleccionProducto(producto);
    await processAPI.setTasaBroker(process._id, COMPANY);
    window.location.reload();
  };

  const saveInmobiliaria = async (value, process, token) => {
    fieldValuesAPI.saveFieldValue({
      field: '6567e60c1f07e5a5c2288d88',
      value,
      process,
    }, token);
  };

  const execFinishCreateAccountAndProcess = async ({ bestOption }) => {
    const {
      flow, mensualidad, producto, tasa,
    } = bestOption;
    const leadData = getLeadData();
    const { _id: leadId } = leadData;
    const banksFieldValues = getBankFieldValuesFormatted(convertirAMoneda(mensualidad), producto, `${tasa}`);
    const fieldValuesInFieldsFormat = getFieldValuesInFieldsFormat(banksFieldValues);
    await saveLeadSharedFieldValues(fieldValuesInFieldsFormat, leadId);
    await updateLeadWithTagSteps(leadData);
    const { client, password } = await createClientAccountFromLocalData();
    const { email } = client;
    const user = await loginByEmailAndPassword(email, password);
    const { token } = user;
    const { process } = await createProcessWithOutProduct(client, flow);
    const inmobiliaria = searchParams.get('inmobiliaria');
    if (inmobiliaria) await saveInmobiliaria(inmobiliaria, process._id, token);
    const bodyLead = {
      ...leadData,
      processCreated: process._id,
    };
    await leadsAPI.updateLeadById(leadId, bodyLead);
    await saveAllLocalFieldValues(process._id, token);
    await saveFieldValue(convertirAMoneda(mensualidad), producto, `${tasa}`, process._id, token);
    await saveLocalPerfilamientoSharedFieldValue(client._id, process._id, token);
    await executeAutoNodes(autoNodes, process._id, token);
    await startNotifyResults(checkSendEmail, process._id, token);

    const localStepPosition = getCurrentStep();
    updateLocalStepsByStep(localStepPosition);

    if (pageData?.tagNameConfig) {
      await setCustomDefaultTagsInProcess(
        pageData.tagNameConfig,
        process._id,
        token,
      );
    }

    const { normalizedFlowName: normalizedName } = await getFlow(flow, token);

    saveExtraDataInStorage({ process: process._id, flow, ...user });

    const tags = await createTags(process._id, normalizedName, false, token);

    const tagsArray = Object.keys(tags)
      .map((key) => tags[key]).filter(({ name }) => name);

    await createFinishTagGeneral(flow);
    const actualStep = findActualStepByHighestStepCompleted(tagsArray);
    const sortedSteps = sortStepsArrayByStepPosition(tagsArray);
    storeStepData({ ...actualStep, steps: sortedSteps });
    const payloadSteps = { current: actualStep.currentStep, steps: sortedSteps };
    googleAnalytics.eleccionProducto(producto);
    const dataLayer = {
      product: producto,
      page: 'CreditProfilingCotization',
    };
    profilingStrategy.createLayerTag(dataLayer);
    initStepper(payloadSteps);
    navigateWithQueryParam(actualStep.stepRoute, 'inmobiliaria');
  };

  const execFinishByType = async (event) => {
    switch (pageData.finishType) {
      case FINISH_TYPE_CREDIT_PROFILLING_VIVIENDA:
        return execFinishCreateAccountAndProcess(event);
      case NORMAL_FINISH_TYPE:
      default:
        return execNormalFinish(event);
    }
  };

  const onSubmit = async (event) => {
    try {
      startLoading();
      await execFinishByType(event);
      endLoading();
    } catch (e) {
      showError(e.message);
      endLoading();
    }
  };

  const onChangeCheckSendEmail = () => {
    setCheckSendEmail(!checkSendEmail);
  };

  const fetchData = async () => {
    try {
      startLoading();
      const _pageConfig = await fetchPageData();

      const { autonodes = [], autoNodesToNotifyResults = [] } = _pageConfig;
      await fetchAutoNodes(autonodes);
      await fetchAutoNodesToNotifyResults(autoNodesToNotifyResults);

      setPageData((prevPageData) => ({
        ..._pageConfig,
        ...prevPageData,
        title: prevPageData?.title || _pageConfig?.title,
        infoTitle: prevPageData?.subtitle ? '' : profilingStrategy.getInfoTitle(_pageConfig),
        buttonTitle: _pageConfig?.buttonTitle,
        subtitle: prevPageData?.subtitle || _pageConfig?.subtitle || '',
        componentTitle: prevPageData?.subtitle ? '' : prevPageData?.componentTitle || _pageConfig?.componentTitle,
        finishType: _pageConfig?.finishType || pageData.finishType,
        buttonFooterTitle: _pageConfig?.buttonFooterTitle || '',
        tagNameConfig: _pageConfig?.tagNameConfig || pageData.tagNameConfig,
        titleBestOption: _pageConfig?.titleBestOption || '',
        banksComparativeTitle: _pageConfig?.banksComparativeTitle || '',
        checkSendEmailText: _pageConfig?.checkSendEmailText || '',
      }));
      setShow(true);
      endLoading();
    } catch (e) {
      endLoading();
    }
  };

  useEffect(() => {
    if (steps[current].completed) {
      storeStepData({ stepRoute: steps[current + 1].route, currentStep: current + 1, steps });
      navigateWithQueryParam(steps[current + 1].route, 'inmobiliaria');
    }
  }, [steps, current]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <BaseCreditProfilingCotization
      isLoading={isLoading}
      handleFinishPage={onSubmit}
      goTo={goTo}
      pageData={pageData}
      disabled={false}
      autoNodes={autoNodes}
      showComponent={show}
      profilingStrategy={profilingStrategy}
      createUpdateTag={createUpdateTag}
      checkSendEmail={checkSendEmail}
      onChangeCheckSendEmail={onChangeCheckSendEmail}
    />
  );
};

CreditProfilingCotizationV2.propTypes = {
  goTo: PropTypes.func.isRequired,
  createUpdateTag: PropTypes.func.isRequired,
};
