/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import * as fieldValuesAPI from '../api/fieldValue';
import * as fieldAPI from '../api/fields';

import {
  COMPANY,
} from '../constants';
import { saveManyFieldValues } from './api/fieldValues.utils';
import { getProcess } from './auth';
import { addProcessToFieldValues } from './fieldValues';

const getPlazo = async () => {
  const processId = getProcess();
  const { value: plazo } = await fieldValuesAPI.getFieldValueByProcessField(processId, '64b71fa1cbf8225d4573b07f');
  const meses = plazo.split(' ')[0];
  return meses;
};
const getFieldvalues = async (profillingFields) => {
  const processId = getProcess();
  const values = await Promise.all(profillingFields.map((profilingId) => fieldValuesAPI.getFieldValueByProcessField(processId, profilingId)));
  const newValues = values
    .filter((value) => value)
    .map(({ field, value }) => ({
      value,
      field: {
        _id: field._id,
        config: field.config,
        name: field.name,
        fieldType: field.fieldType,
      },
    }));
  return newValues;
};

const getDefaultMatrizValues = async (matrizDefaultValues) => {
  const fieldValues = await Promise.all(matrizDefaultValues.map(async ({ field, value }) => {
    const fieldData = await fieldAPI.getFieldsById(field);
    return {
      value,
      field: {
        _id: fieldData._id,
        config: fieldData.config,
        name: fieldData.name,
        fieldType: fieldData.fieldType,
      },
    };
  }));
  return fieldValues;
};

const transformSection = (sections) => sections.reduce((acc, curr) => {
  const { fields } = curr;
  const newFields = fields.map(({ _id, value }) => ({
    value,
    field: {
      _id,
    },
  }));
  return [...acc, ...newFields];
}, []);

const saveDefaultFieldValues = async (defaultFieldValues = []) => {
  if (!defaultFieldValues || !defaultFieldValues?.length) return;
  const processId = getProcess();
  const fieldValuesToSave = addProcessToFieldValues(defaultFieldValues, processId);
  await saveManyFieldValues(fieldValuesToSave);
};

export const getMatrizBody = async (profillingFields, matrizDefaultFields = []) => {
  const fieldValues = await getFieldvalues(profillingFields);
  await saveDefaultFieldValues(matrizDefaultFields);
  const defaultFieldValues = matrizDefaultFields.length > 0 ? await getDefaultMatrizValues(matrizDefaultFields) : [];
  const plazo = await getPlazo();
  const body = {
    company: COMPANY,
    plazo,
    fieldValues: [...fieldValues, ...defaultFieldValues],
  };
  return body;
};
