export const isNumber = (data) => !Number.isNaN(Number(data));

export const isPadNumeric = (charCode) => {
  const PAD_ZERO_CHAR_CODE = 96; // 0 on numeric pad
  const PAD_NINE_CHAR_CODE = 105; // 9 on numeric pad

  return charCode >= PAD_ZERO_CHAR_CODE && charCode <= PAD_NINE_CHAR_CODE;
};

export const isValidDigitNumber = (charCode, ctrlCode, value) => {
  const strValue = value ? value.toString() : '';

  const ENTER_CHAR_CODE = 13; // ENTER
  const DELETE_CHAR_CODE = 8; // BACKSPACE
  const CLEAR_KEY_CODE = 12; // DELETE

  const POINT_CHAR_CODE = 190; // .
  const ZERO_CHAR_CODE = 48; // 0
  const NINE_CHAR_CODE = 57; // 9

  if (isPadNumeric(charCode)) return true;
  if (charCode === ENTER_CHAR_CODE) return true;
  if (charCode === DELETE_CHAR_CODE || charCode === CLEAR_KEY_CODE) return true;
  if (ctrlCode) return true;
  if (charCode === POINT_CHAR_CODE) return !strValue.includes('.');
  return charCode >= ZERO_CHAR_CODE && charCode <= NINE_CHAR_CODE; // 0 ... 9
};
