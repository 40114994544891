import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
  },
  title: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '40px',
    color: '#2C1482',
    textTransform: 'capitalize',
    margin: '0',
    marginBottom: '12px',
  },
}));
