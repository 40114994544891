/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import useStyles from './useStyles';

export const BankLabels = ({
  title,
  images,
}) => {
  const classes = useStyles();

  return (
    <Box component="section" className={classes.sectionContainer}>
      <Typography className={classes.tagTitle} component="h2">
        {title}
      </Typography>
      <Box className={classes.bankContainer}>
        {
          images.map((image) => (
            <Box className={classes.imgContainer}>
              <Box className={classes.imgBanks} component="img" src={image} />
            </Box>
          ))
        }
      </Box>
    </Box>
  );
};

BankLabels.propTypes = {
  title: PropTypes.string,
  images: PropTypes.array,
};

BankLabels.defaultProps = {
  title: '',
  images: [],
};
