import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: '16px',
      },
    },
  },
  iconButtonContainer: {
    position: 'relative',
    flex: 1,
  },

  totalHeigh: {
    height: '100%',
  },

  documentContainer: {
    overflow: 'auto',
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    boxShadow: '0px 10px 15px #ECECEC',
    marginBottom: '20px',
  },

  deleteIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteIcon: {
    fontSize: '1.25rem',
  },

  iconButton: {
    position: 'absolute',
    bottom: '50%',
    left: '0',
    backgroundColor: '#FFF',
  },
}));
