import React from 'react';
import PropTypes from 'prop-types';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import useStyles from './useStyles';

export const Loader = ({ open, onClick, text }) => {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.Backdrop}
      open={open}
      onClick={onClick}
    >
      <CircularProgress color="inherit" />
      {text && (
        <Typography className={classes.percetage} variant="h6">{text}</Typography>
      )}
    </Backdrop>
  );
};

Loader.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

Loader.defaultProps = {
  open: false,
  onClick: () => { },
  text: '',
};
