import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import InputRejected from '../InputRejected';

import isValidDigit from './validator';
import { currencyFormatter } from './formatter';

const InputCurrency = ({
  value,
  onChange,
  isEmpty,
  onKeyDown,
  isFloat,
  ...props
}) => {
  const handleOnChange = useCallback((event) => {
    const { value: _value } = event.target;
    // eslint-disable-next-line no-param-reassign
    event.target.value = _value;
    onChange(event, currencyFormatter(_value, isFloat));
  }, [onChange, isFloat]);

  const handleOnKeyDown = useCallback((event) => {
    const charCode = event.which ? event.which : event.keyCode;
    const ctrlCode = event.ctrlKey ? event.ctrlKey : event.metaKey;
    const { value: _value } = event.target;

    if (!isValidDigit(charCode, ctrlCode, _value)) event.preventDefault();
    onKeyDown(event);
  }, []);

  return (
    <InputRejected
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={currencyFormatter(value, isFloat)}
      onChange={handleOnChange}
      typeValidation="currency"
      isEmpty={isEmpty}
      onKeyDown={handleOnKeyDown}
    />
  );
};

InputCurrency.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  isFloat: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  isEmpty: PropTypes.any,
};

InputCurrency.defaultProps = {
  isFloat: true,
  isEmpty: undefined,
};

export default memo(InputCurrency);
