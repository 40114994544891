import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';

import useStyles from './useStyles';
import { convertirAMoneda } from '../../../../utils/currency';

export const ContainerConditionAmmount = ({
  label,
  ammountNumber,
  customTitleClassName,
  customValueClassName,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.conditionContainer}>
      <Typography
        className={clsx(classes.conditionText, {
          [customTitleClassName]: customTitleClassName,
        })}
      >
        {label}
      </Typography>
      <Typography
        className={clsx(classes.ammount, {
          [customValueClassName]: customValueClassName,
        })}
      >
        {`${convertirAMoneda(ammountNumber)} MXN`}
      </Typography>
    </Box>
  );
};

ContainerConditionAmmount.propTypes = {
  label: PropTypes.string,
  ammountNumber: PropTypes.number,
  customTitleClassName: PropTypes.string,
  customValueClassName: PropTypes.string,
};

ContainerConditionAmmount.defaultProps = {
  label: '',
  ammountNumber: 0,
  customTitleClassName: '',
  customValueClassName: '',
};
