import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
  },
  header: {
    width: '85%',
    margin: '0 auto',
    marginTop: '30px',
  },
  title: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '600',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      fontSize: '4rem',
      lineHeight: '4.5rem',
    },
  },
  subTitle: {
    marginTop: '20px',
    marginBottom: '50px',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '600',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
      fontSize: '2rem',
      marginBottom: '50px',
      lineHeight: '2.5rem',
    },
  },
  videoContainer: {
    boxSizing: 'border-box',
    width: '100%',
    height: '360px',
    maxWidth: '640px',
    margin: '0 auto',
    marginTop: '60px',
    marginBottom: '60px',
    paddingRight: '8px',
    paddingLeft: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px',
    },
  },
}));
