import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    overflowY: 'auto',
    height: 'calc(100% - 65px)',
    width: '100%',
    backgroundColor: '#F7FAFD',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 65px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 61px)',
    },
  },
  innerContainer: {
    paddingTop: '8vh',
    paddingBottom: '5vh',
    width: '90%',
    margin: '0 auto',
    height: '100%',
  },
  header: {
    maxWidth: '700px',
    margin: '0 auto',
  },
  subTitle: {
    color: theme.palette.secondary.main,
    maxWidth: '900px',
    margin: '20px auto 50px auto',
    fontWeight: 600,
    lineHeight: '1.25rem',
    fontSize: '1.125rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  title: {
    color: '#2C1482',
    maxWidth: '900px',
    margin: '0 auto',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  main: {
    backgroundColor: 'transparent',
  },
}));
