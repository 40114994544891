import { useCallback, useMemo, useState } from 'react';

export const STATES = {
  LIST_DOCUMENTS: 'LIST_DOCUMENTS',
  PREVIEW_DOCUMENTS: 'PREVIEW_DOCUMENTS',
  UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
  LIST_ALL_FIELDS: 'LIST_ALL_FIELDS',
  LOADER_PAGE: 'LOADER_PAGE',
};

const useStateMachine = (_currentState = STATES.LIST_DOCUMENTS) => {
  const [currentState, setCurrentState] = useState(_currentState);

  const goToListDocuments = useCallback(() => {
    setCurrentState(STATES.LIST_DOCUMENTS);
  }, []);

  const goToUploadDocument = useCallback(() => {
    setCurrentState(STATES.UPLOAD_DOCUMENT);
  }, []);

  const goToListAllFields = useCallback(() => {
    setCurrentState(STATES.LIST_ALL_FIELDS);
  }, []);

  const goToLoaderPage = useCallback(() => {
    setCurrentState(STATES.LOADER_PAGE);
  }, []);

  const goToPreviewPage = useCallback(() => {
    setCurrentState(STATES.PREVIEW_DOCUMENTS);
  });

  const result = useMemo(
    () => (
      {
        STATES,
        currentState,
        goToListDocuments,
        goToUploadDocument,
        goToListAllFields,
        goToLoaderPage,
        goToPreviewPage,
      }
    ),
    [
      currentState,
      goToListDocuments,
      goToUploadDocument,
      goToListAllFields,
      goToLoaderPage,
      goToLoaderPage,
      goToPreviewPage,
    ],
  );

  return result;
};

export default useStateMachine;
