import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  ConsultPage, FormalizationPage,
} from '../pages';
import { MainLayout } from '../layouts';
import { ProtectedRoute } from '../../../components';
import { getSteps } from '../../../utils';
import {
  APPLICATION_NAME,
  CONSULTA_BURO_NAME,
  CREDITO_HIPOTECARIO,
  CREDITO_HIPOTECARIO_STRATEGY,
  CREDIT_PROFILING_STRATEGY,
  FORMALIZATION_NAME,
  LOGIN_ROUTE, MAIN_ROUTE_TYPE,
  PERFILAMIENTO_CREDITICIO_NAME,

} from '../../../constants';
import { CreditProfilingPage } from '../pages/CreditProfilingPage';
import { ProfilingProvider } from '../../../context/profiling';
import { FlowStrategyContainer } from '../../flowStrategy';
import { simulatorStrategies } from '../../../strategies/simulatorStrategies';
import { ApplicationPage } from '../pages/Application';
import { TabsProvider } from '../../../context/tabs/tabsProvider';

const findCurrentStep = (foundStepName) => {
  const steps = getSteps();
  if (!steps) return null;
  return steps.findIndex(({ name }) => name === foundStepName);
};

const MainRoutes = () => (
  <MainLayout>
    <Routes>
      <Route
        path="/consult"
        element={(
          <ProtectedRoute
            type={MAIN_ROUTE_TYPE}
            redirectPath="/main/perfilamiento"
            additionalData={{ step: findCurrentStep(CONSULTA_BURO_NAME), path: '/consult' }}
          >
            <ConsultPage />
          </ProtectedRoute>
        )}
      />
      <Route
        path="/perfilamiento"
        element={(
          <ProtectedRoute
            type={MAIN_ROUTE_TYPE}
            redirectPath="/main/aplicacion"
            additionalData={{ step: findCurrentStep(PERFILAMIENTO_CREDITICIO_NAME), path: '/perfilamiento' }}
          >
            <FlowStrategyContainer
              strategies={simulatorStrategies}
              flowName={CREDITO_HIPOTECARIO}
            >
              <TabsProvider>
                <ProfilingProvider>
                  <CreditProfilingPage
                    strategyName={CREDIT_PROFILING_STRATEGY}
                    productStrategy={CREDITO_HIPOTECARIO_STRATEGY}
                  />
                </ProfilingProvider>
              </TabsProvider>
            </FlowStrategyContainer>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/aplicacion"
        element={(
          <ProtectedRoute
            type={MAIN_ROUTE_TYPE}
            redirectPath="/main/formalization"
            additionalData={{ step: findCurrentStep(APPLICATION_NAME), path: '/aplicacion' }}
          >
            <ApplicationPage />
          </ProtectedRoute>
        )}
      />

      <Route
        path="/formalization"
        element={(
          <ProtectedRoute
            type={MAIN_ROUTE_TYPE}
            redirectPath={LOGIN_ROUTE}
            additionalData={{ step: findCurrentStep(FORMALIZATION_NAME), path: '/formalization' }}
          >
            <FormalizationPage />
          </ProtectedRoute>
        )}
      />
      <Route path="/*" element={<Navigate to="/main/consult" />} />
    </Routes>
  </MainLayout>
);

export default MainRoutes;
