import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import useStyles from './useStyles';
import { CreditSimulator } from '../../../../../components/CreditSimulator';

export const CalCredit = ({
  startLoading,
  endLoading,
  agentId,
  buttonTitle,
}) => {
  const classes = useStyles();
  return (
    <div id="cotizador" className={classes.container}>
      <div className={classes.innerContatiner}>
        <Typography
          className={classes.title}
        >
          Cotiza tu crédito
        </Typography>
        <Typography
          className={classes.subTitle}
        >
          En 2 minutos sabrás cuánto dinero te prestarían todos los bancos.
        </Typography>
        <Box className={classes.containerCredit}>
          <CreditSimulator
            startLoading={startLoading}
            endLoading={endLoading}
            agentId={agentId}
            buttonTitle={buttonTitle}
          />
        </Box>
      </div>
    </div>
  );
};

CalCredit.propTypes = {
  startLoading: PropTypes.func,
  endLoading: PropTypes.func,
  agentId: PropTypes.string,
  buttonTitle: PropTypes.string,
};

CalCredit.defaultProps = {
  startLoading: () => { },
  endLoading: () => { },
  agentId: '',
  buttonTitle: '',
};
