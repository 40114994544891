/* eslint-disable max-len */
import React, {
  useContext, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container, Button,
} from '@material-ui/core';

import * as googleAnalytics from '../../../../utils/googleAnalytics/events';
import styles from './useStyles';
import Input from '../../../../components/Input';
import {
  convertirANumero, getMonthlyPaid,
} from '../../../../utils/currency';
import { storeCredit } from '../../../../utils/credit';
import { logOutMain } from '../../../../utils/logout';
import { logout } from '../../../../api/auth';
import { StepperContext } from '../../../../context/stepper';
import { useProduct } from '../../../../hooks/useProduct';
import { getConfigTags } from '../../../../utils/tags';
import { storeAuth, storeStepData } from '../../../../utils';
import { ThemeContext } from '../../../../context/Theme/themeContext';
import { storeFlowData } from '../../../../utils/flows';
import { FLOW_INMOBILIARIA, FLOW_VIVIENDA } from '../../../../constants';
import useRoute from '../../../../hooks/useRoute';

const LIMIT_VALUE = 1200000;
const reasonOptions = [
  {
    id: 'Automovil',
    name: 'Automovil',
    value: 'Automovil',
    disabled: false,
  },
  {
    id: 'Remodelacion de casa',
    name: 'Remodelacion de casa',
    value: 'Remodelacion de casa',
    disabled: false,
  },
  {
    id: 'Otro',
    name: 'Otro',
    value: 'Otro',
    disabled: false,
  },
];
const monthlyOptions = [
  {
    id: 5,
    name: '5 años',
    value: '5 años',
    disabled: false,
  },
  {
    id: 10,
    name: '10 años',
    value: '10 años',
    disabled: false,
  },
  {
    id: 15,
    name: '15 años',
    value: '15 años',
    disabled: false,
  },
  {
    id: 20,
    name: '20 años',
    value: '20 años',
    disabled: false,
  },
];

const INITIAL_VALUES = {
  year: {
    label: '¿A Cuántos años?',
    type: 'select_simple',
    name: 'year',
    required: true,
    value: '20 años',
    isEmpty: false,
    options: monthlyOptions,
  },
  precio: {
    label: 'Precio de la propiedad',
    type: 'precio',
    name: 'precio',
    required: true,
    isEmpty: true,
    value: 0,
    error: {
      isValid: false,
      errorMessage: '',
    },
  },
  reason: {
    label: '¿Para qué necesitas tu cédito?',
    type: 'select_simple',
    name: 'reason',
    required: true,
    value: 'Automovil',
    isEmpty: false,
    options: reasonOptions,
  },
};
const TASAS = {
  5: 9.48,
  10: 9.48,
  15: 9.48,
  20: 9.48,
};

export const CreditSimulator = ({
  startLoading, endLoading, agentId, buttonTitle,
  prestamoValue,
}) => {
  const { getProductByRoute } = useProduct();
  const {
    navigateWithQueryParam,
    location,
  } = useRoute();

  const { pathname } = location;
  const isVivienda = useMemo(() => [FLOW_VIVIENDA, FLOW_INMOBILIARIA].includes(pathname), [pathname]);

  const [values, setValues] = useState(INITIAL_VALUES);
  const { initStepper } = useContext(StepperContext);
  const { loadTheme } = useContext(ThemeContext);

  const classes = styles();

  const isGreaterThanValue = (inputValue, limitValue, errorMessage) => {
    const isValid = inputValue > limitValue;
    return { isValid, errorMessage };
  };

  const handleChange = (event, value) => {
    const { target } = event;
    const { name } = target;
    if (name === 'precio') {
      const convertedValue = convertirANumero(value);
      setValues({
        ...values,
        [name]: {
          ...values[name],
          value: convertedValue,
          isEmpty: !value,
        },
      });
    } else {
      setValues({
        ...values,
        [name]: {
          ...values[name],
          value: !['year', 'reason'].includes(name) ? convertirANumero(value) : value,
          isEmpty: !value,
        },
      });
    }
  };

  const getActualStep = (stepperTags) => {
    const actualIndexStep = stepperTags.findIndex(({ completed }) => !completed);
    return { stepRoute: stepperTags[actualIndexStep].route, currentStep: actualIndexStep };
  };

  const onLogout = async () => {
    await logOutMain();
    await logout();
  };
  /*
  Aforomo maximo = prestamo/valor de la casa

  prestamos = valor de la casa - enganche
  */

  const storeAgentId = () => {
    if (agentId) {
      storeAuth({ agentId });
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    await onLogout();
    startLoading();
    const { id: year } = monthlyOptions.find(({ value }) => value === values.year.value);
    const { id: reason } = reasonOptions.find(({ value }) => value === values.reason.value);
    const monthlyPay = getMonthlyPaid({
      importe: prestamoValue,
      plazoMensual: year * 12,
      tasa: TASAS[year] * 0.01,
    });
    storeCredit({
      importe: prestamoValue,
      meses: year * 12,
      aforo: (prestamoValue / values.precio.value).toFixed(4),
      enganche: values.precio.value - prestamoValue,
      year,
      plazo: `${year} años`,
      reason,
      mensualidad: +monthlyPay.toFixed(2),
      propietyValue: values.precio.value,
    });
    storeAgentId();
    const productByRoute = getProductByRoute(pathname);
    const { name, firstRoute } = productByRoute;
    await loadTheme(name);
    const tags = await getConfigTags(name);
    const stepperObject = { steps: tags, current: 0 };
    initStepper(stepperObject);
    const { steps } = stepperObject;
    const actualStep = getActualStep(steps);
    storeFlowData(productByRoute);
    storeStepData({ ...actualStep, steps });
    googleAnalytics.cotizacion();
    navigateWithQueryParam(firstRoute, 'inmobiliaria');
    endLoading();
  };

  return (
    <Container
      component="form"
      onSubmit={onSubmit}
      method="post"
      autoComplete="off"
      className={classes.formContainer}
      disableGutters
      maxWidth={false}
    >
      <Box className={clsx(classes.innerContainer, {
        [classes.viviendaInnerContainer]: isVivienda,
      })}
      >
        <Box className={classes.firtInputElement}>
          <Input
            className={classes.inputBorder}
            label={values.year.label}
            type={values.year.type}
            name={values.year.name}
            value={values.year.value}
            required={values.year.required}
            isEmpty={values.year.isEmpty}
            onChange={handleChange}
            fullWidth
            options={values.year.options}
          />
        </Box>
        <Box className={classes.secondInputElement}>
          <Box className={classes.precioContainer}>
            <Input
              className={classes.inputPrecio}
              label={values.precio.label}
              type={values.precio.type}
              name={values.precio.name}
              value={values.precio.value}
              required={values.precio.required}
              isEmpty={values.precio.isEmpty}
              validations={[
                (inputValue) => isGreaterThanValue(inputValue, LIMIT_VALUE, 'Valor no puede ser menor a $1,200,000'),
              ]}
              onChange={handleChange}
            />
          </Box>
          <Box className={classes.yearContainer}>
            <Input
              className={classes.inputBorder}
              label={values.reason.label}
              type={values.reason.type}
              name={values.reason.name}
              value={values.reason.value}
              required={values.reason.required}
              isEmpty={values.reason.isEmpty}
              onChange={handleChange}
              fullWidth
              options={values.reason.options}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.containerButton}>
        <Button
          className={classes.button}
          variant="contained"
          type="submit"
        >
          {buttonTitle}
        </Button>
      </Box>
    </Container>
  );
};

CreditSimulator.propTypes = {
  startLoading: PropTypes.func,
  endLoading: PropTypes.func,
  agentId: PropTypes.string,
  buttonTitle: PropTypes.string,
  prestamoValue: PropTypes.number,
};

CreditSimulator.defaultProps = {
  startLoading: () => { },
  endLoading: () => { },
  prestamoValue: 0,
  agentId: '',
  buttonTitle: '',
};
