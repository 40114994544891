/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useCallback } from 'react';
import {
  AppBar, Box, Toolbar, Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import useStyles from './useStyles';
import { Stepper } from '../Stepper';
import { Button } from '../Button';
import { StepperContext } from '../../context/stepper';
import { storeStepData } from '../../utils/stepper';
import { logout } from '../../api/auth';
import { handleLogoutRediection } from '../../utils/logout';

export const StepperBar = () => {
  const { state: { steps, current } } = useContext(StepperContext);
  const navigate = useNavigate();

  const classes = useStyles();

  const returnStep = useCallback(() => navigate(steps[current - 1].route), [current, steps]);

  const exitAndSave = useCallback(async () => {
    const response = await logout();
    if (response) {
      await handleLogoutRediection();
    }
  }, [steps]);

  return (
    <AppBar position="static" className={classes.appBar} color="unset">
      {
        steps.length ? (
          <>
            <Box className={classes.boxStep}>
              <Toolbar>
                <div className={classes.stepperContainer}>
                  <Stepper
                    steps={steps}
                    activeStep={current}
                    connectorClass={classes.line}
                  />
                </div>
                <div className={classes.containerButton}>
                  <Button className={classes.backButton} onClick={returnStep}>
                    Regresar
                  </Button>
                </div>
              </Toolbar>
            </Box>
            <Box className={classes.boxStepMovil}>
              <Box className={classes.stepCircle} />
              <Box className={classes.titleContainer}>
                <Typography variant="h6" className={classes.title}>
                  {`Paso ${current + 1} de ${steps.length}`}
                </Typography>
                <Typography variant="h6" className={classes.subtitle}>
                  {steps[current].label}
                </Typography>
              </Box>
            </Box>
          </>
        ) : ''
      }
    </AppBar>
  );
};
