import {
  Box, Container, Typography,
} from '@material-ui/core';
import React from 'react';

import useStyles from './useStyles';

import { staticLandingData } from '../../../../data';

const { dataFooter, dataSocial } = staticLandingData;

export const Footer = () => {
  const classes = useStyles();

  const { externalLinks } = dataFooter;

  return (
    <Container disableGutters maxWidth={false} className={classes.mainContainer}>
      <Container disableGutters maxWidth={false} className={classes.container}>
        <Box className={classes.boxContact}>
          <Box className={classes.boxContactItem}>
            {
              externalLinks.map(({ label, path }) => (
                <a className={classes.aExternalLinks} target="_blank" href={path} rel="noreferrer">
                  {label}
                </a>
              ))
            }
          </Box>
        </Box>
        <Box className={classes.boxSocialContact}>
          <Box className={classes.boxSocial}>
            <Typography className={classes.textFollowus}>{dataSocial.title}</Typography>
            <Box>
              {dataSocial.socialNetwork.map(({ img, url, alt }) => (
                <a className={classes.aImg} target="_blank" href={url} rel="noreferrer">
                  <img className={classes.imgSocial} src={img} alt={alt} />
                </a>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};
