import React from 'react';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';

import { FormLayout } from '../../../../layouts/FormLayout';

import useStyles from './useStyles';
import { GroupFields } from '../../../../../../components';

export const FormNodesFields = ({
  handleSubmit,
  formRef,
  disabled,
  fields,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <FormLayout
      submit={handleSubmit}
      className={classes.form}
      disabled={disabled}
      formRef={formRef}
    >
      <Container
        component="section"
        maxWidth="md"
        className={classes.container}
      >
        <GroupFields
          fields={fields}
          onChange={onChange}
          customGridClass={classes.gridItem}
        />
      </Container>
    </FormLayout>
  );
};

FormNodesFields.propTypes = {
  handleSubmit: PropTypes.func,
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.any,
  onChange: PropTypes.func,
};

FormNodesFields.defaultProps = {
  handleSubmit: () => { },
  formRef: null,
  disabled: false,
  fields: [],
  onChange: () => { },
};
