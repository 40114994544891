/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, {
  useState, useEffect, memo, useRef,
} from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@material-ui/core';
import { generateValueEmpty } from '../Input/InputTable/utils';
import useStyles from './useStyles';
import { CustomField } from '../CustomField';

const TableFields = ({
  fieldValues,
  addNewRow,
  handleOnChangeField,
  setFieldsEmpty,
  edit,
}) => {
  const formRef = useRef(null);

  const classes = useStyles();
  const [fields, setFields] = useState([]);
  const [deleteInfo, setDeleteInfo] = useState(false);

  useEffect(() => {
    const fieldsUnhide = fieldValues.filter(({ hide = false }) => !hide);
    setFields(fieldsUnhide);
  }, [fieldValues]);

  useEffect(() => {
    if (deleteInfo) {
      setFields(generateValueEmpty(fields));
      setFieldsEmpty(generateValueEmpty(fieldValues));
      setDeleteInfo(false);
    }
  }, [deleteInfo]);

  const handleOnChange = (event, field, index, value) => {
    handleOnChangeField(field, index, value);
  };

  const areValidFields = () => {
    let isValid = true;
    fields.map((field) => {
      if (field.required && field.value === '') {
        isValid = false;
        field.error = true;
      }
    });
    if (!isValid) setFields([...fields]);
    return isValid;
  };

  const onClickAccept = () => {
    if (areValidFields()) {
      addNewRow(fields);
      setDeleteInfo(true);
    }
  };

  const isNotCompleted = () => fields.some(({ required = false, value = '', hide = false }) => {
    if (!hide && !required) return false;
    if (hide || !required) return false;
    return value === '' || value === null || !value.length;
  });

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = formRef.current;
    const isValidForm = form.checkValidity();
    if (isValidForm) {
      onClickAccept();
      return;
    }
    form.reportValidity();
  };

  if (Object.keys(fields).length === 0) return null;
  return (
    <Box className={classes.fieldsContainer}>
      <form ref={formRef} autoComplete="off">
        {fields.map((field, index) => {
          const {
            id,
            name,
            label,
            type,
            options,
            format,
            value,
            error = false,
            errorMessage = '',
            required = false,
          } = field;
          return (
            <div className={classes.root} key={id}>
              <CustomField
                key={id}
                type={type}
                label={label}
                name={name}
                value={value}
                className={classes.fieldClass}
                // eslint-disable-next-line no-shadow
                onChange={(event, value) => handleOnChange(event, field, index, value)}
                error={error}
                errorMessage={errorMessage}
                format={format}
                options={options}
                required={required}
              />
            </div>
          );
        })}
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.button}
            onClick={onSubmit}
            disabled={isNotCompleted()}
            variant="outlined"
          >
            {edit ? 'Editar' : 'Agregar'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

TableFields.propTypes = {
  fieldValues: PropTypes.arrayOf(PropTypes.string),
  addNewRow: PropTypes.func,
  edit: PropTypes.bool,
  handleOnChangeField: PropTypes.func,
  setFieldsEmpty: PropTypes.func,
};

TableFields.defaultProps = {
  addNewRow: () => { },
  edit: false,
  fieldValues: [],
  handleOnChangeField: () => { },
  setFieldsEmpty: () => { },
};

export default memo(TableFields);
