import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '80px',
    paddingRight: '80px',
    marginTop: '80px',
    paddingBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },

  },
  title: {
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '20px',
    fontFamily: 'Open Sans',
  },
  footerLogo: {
    height: '102px',
  },
  subtitle: {
    maxWidth: '202px',
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 400,
    marginBottom: '20px',
    fontFamily: 'Open Sans',
  },
  boxLogo: {
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },

  },
  boxContact: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      order: 1,
      maxWidth: '200px',
      paddingBottom: '10%',
    },
  },
  boxContactItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnRegister: {
    backgroundColor: '#000F8F',
    color: '#FFFFFF',
    borderRadius: '6px',
    marginBottom: '20px',
    padding: '0.55rem 2.5rem',
    fontSize: '15px',
    textTransform: 'initial',
    fontFamily: 'Open Sans',
    whiteSpace: 'nowrap',

  },
  btnLogin: {
    fontSize: '15px',
    border: '1px solid',
    borderColor: '#000F8F',
    padding: '0.55rem 2.5rem',
    textTransform: 'initial',
    fontFamily: 'Open Sans',

  },
  boxSocial: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxBtns: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  boxSocialContact: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  textFollowus: {
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: 'Open Sans',
  },
  imgSocial: {
    paddingLeft: '25px',
    [theme.breakpoints.down('md')]: {
      width: '30px',
    },
  },
  containerExternalLinks: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingLeft: '80px',
    paddingRight: '80px',
    paddingBottom: '20px',
  },
  aExternalLinks: {
    fontSize: '15px',
    color: '#000F8F',
    fontWeight: 600,
  },
}));
