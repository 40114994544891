/* eslint-disable max-len */
import React, { useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import useStyles from './useStyles';
import { StepperVertical } from '../../../../components/StepperVertical';
import { ClientContext } from '../../../../context/client/clientContext';
import { HeaderBar } from '../../components/HeaderBar';

export const CreditProcess = () => {
  const navigate = useNavigate();
  const {
    state: {
      processes,
      currentProcess,
      title,
    },
  } = useContext(ClientContext);
  const classes = useStyles();

  const processData = useMemo(() => processes.find(({ _id }) => _id === currentProcess), [processes, currentProcess]);
  const subTitle = useMemo(() => (processData ? `${processData.name}: ` : ''), [processData]);
  const activeStep = useMemo(() => (processData ? processData.tags.reduce((acc, curr) => (curr.completed ? acc + 1 : acc), 0) : []), [processData]);

  const back = () => {
    navigate('/inmobiliaria/credits');
  };
  return (
    <Container
      component="main"
      className={classes.container}
      disableGutters
      maxWidth={false}
    >
      <Container
        component="div"
        className={classes.innerContainer}
        disableGutters
        maxWidth={false}
      >
        <HeaderBar
          title={title}
          back={back}
          options={[]}
        />
        <Typography
          variant="h2"
          className={classes.subTitle}
        >
          {subTitle}
        </Typography>
        <main
          className={classes.main}
        >
          <StepperVertical
            steps={processData.tags}
            activeStep={activeStep}
            connectorClass={classes.line}
          />
        </main>
      </Container>
    </Container>
  );
};
