import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientContext } from '../../../../context/client/clientContext';
import useStyles from './useStyles';
import TabsCredits from '../../../../data/TabsCredit.json';
import { HeaderBar } from '../../components/HeaderBar';
import { TabsChiplo } from '../../../../components/TabsChiplo';
import { TabPanelCredito } from '../../../../components/TabsChiplo/TabPanelCredito';

export const Credits = () => {
  const [tabValue, setTabValue] = useState(TabsCredits[0].value);
  const navigate = useNavigate();
  const {
    state: {
      title,
    },
  } = useContext(ClientContext);
  const classes = useStyles();
  const handleChange = (newValue) => {
    setTabValue(newValue);
  };
  const back = () => {
    navigate('/agente/clients');
  };
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <HeaderBar
          title={title}
          back={back}
        />
        <div className={classes.tabs}>
          <TabsChiplo
            handleChange={handleChange}
            tabs={TabsCredits}
            initialValue={TabsCredits[0].value}
          />
          <div
            className={classes.tabPanelCredito}
            style={{ display: tabValue === 'credits' ? 'initial' : 'none' }}
          >
            <TabPanelCredito tabValue={tabValue} />
          </div>
        </div>
      </div>
    </div>
  );
};
