import { useReducer, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { ClientContext } from './clientContext';
import { clientReducer } from './clientReducer';
import { INIT_CLIENT_REDUCER, SELECT_PROCESS_CLIENT_REDUCER } from '../../constants';
import { getCurrentProcess, getProcesses, getTitle } from '../../utils/creditsProcess';

const CLIENT_INITIAL_STATE = {
  currentProcess: '',
  processes: [],
  title: '',
};

const INIT = () => {
  const processes = getProcesses() || [];
  const title = getTitle() || '';
  const currentProcess = getCurrentProcess() || '';
  return {
    currentProcess,
    processes,
    title,
  };
};

export const ClientProvider = ({ children }) => {
  const [state, dispatch] = useReducer(clientReducer, CLIENT_INITIAL_STATE, INIT);

  const initClient = useCallback((payload) => {
    dispatch({ type: INIT_CLIENT_REDUCER, payload });
  }, []);

  const selectProcessClient = useCallback((payload) => {
    dispatch({ type: SELECT_PROCESS_CLIENT_REDUCER, payload });
  }, []);

  const value = useMemo(
    () => ({
      state,
      initClient,
      selectProcessClient,
    }),
    [
      state,
      initClient,
      selectProcessClient,
    ],
  );

  return (
    <ClientContext.Provider
      value={value}
    >
      {children}
    </ClientContext.Provider>
  );
};

ClientProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
