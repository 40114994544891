import { AGENT_VIEWER_ROLE_ID } from '../constants/roles';
import { decryptData, encryptData } from './encryption';

const AUTH_KEY = btoa('AUTH_DATA');

export const storeAuth = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(AUTH_KEY, encryptedData);
};

export const storeAgentId = (agentId) => {
  if (agentId) {
    storeAuth({ agentId });
  }
};

export const removeAuth = () => {
  window.localStorage.removeItem(AUTH_KEY);
};

export const getAuth = () => {
  const jsonDataEncrypted = window.localStorage.getItem(AUTH_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

const getItem = (key) => {
  try {
    const auth = getAuth();
    return auth[key];
  } catch {
    return undefined;
  }
};

export const saveExtraDataAuth = (extraData) => {
  const authData = getAuth();
  storeAuth({
    ...authData,
    ...extraData,
  });
};

export const getToken = () => {
  const token = getItem('token');
  return token;
};

export const getFlow = () => {
  const flow = getItem('flow');
  return flow;
};

export const isAuth = () => {
  const token = getItem('token');
  return !!token;
};

export const getEmail = () => {
  const email = getItem('email');
  return email;
};

export const getSocialReason = () => {
  const socialReason = getItem('socialReason');
  return socialReason;
};

export const getProfile = () => {
  const profile = getItem('profile');
  return profile;
};

export const getProcess = () => {
  const process = getItem('process');
  return process;
};

export const getRfcField = () => {
  const rfcField = getItem('rfcField');
  return rfcField;
};

export const getFirstName = () => {
  const firstName = getItem('firstName');
  return firstName;
};

export const getClientFirstName = () => {
  const firstName = getItem('clientFirstName');
  return firstName;
};

export const getFullName = () => {
  const firtName = getItem('firstName');
  const lastName = getItem('lastName');
  return `${firtName} ${lastName}`;
};

export const getUserId = () => {
  const userId = getItem('_id');
  return userId;
};

export const getAgentId = () => {
  const agentId = getItem('agentId');
  return agentId;
};

export const getOrganizationId = () => {
  const agentId = getItem('organization');
  return agentId;
};

export const getRoles = () => {
  const roles = getItem('roles');
  return roles;
};

export const isUserAgent = () => {
  const roles = getRoles();
  if (!roles || !roles.length) return false;
  const isAgent = roles.some(({ _id }) => _id === AGENT_VIEWER_ROLE_ID);
  return isAgent;
};
