import { v4 as uuidv4 } from 'uuid';
import * as S3Api from './s3';
import { getProcess } from '../utils/auth';
import { COMPANY } from '../constants';

export const uploadFileToS3 = async (file) => {
  const processId = getProcess();
  const fileName = `${processId ? `${processId}` : COMPANY}/${uuidv4()}.${file.type.split('/').pop()}`;
  const { url } = await S3Api.uploadToS3(file, fileName);
  return { url };
};

export const uploadFileToS3Fad = async (file) => {
  const fileName = `${uuidv4()}.${file.type.split('/').pop()}`;
  const fileMetaData = {
    fileType: file.type,
    fileName,
  };
  const s3Reponse = await S3Api.signFileFad(fileMetaData);
  const { signedUrl, url } = s3Reponse;
  await S3Api.uploadToS3(signedUrl, file);
  return { signedUrl, url };
};
