import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    background: '#F7F7F7',
    width: '100%',
    padding: '0 0 2vh 0',
    overflowY: 'auto',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  fullHeight: {
    height: '100%',
  },
  hide: {
    display: 'none',
  },
  containerTitles: {
    marginBottom: '4vh',
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  subTitle: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: 600,
    lineHeight: '1.25rem',
    fontSize: '1.125rem',
  },
  buttonContainer: {
    textAlign: 'center',
    margin: '2rem auto',
    paddingBottom: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  button: {
    fontSize: '1.25rem',
    padding: '0.75rem 2.5rem',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'initial',
    },
  },
}));
