// api/flowprocess/process/62cf3df87bb75923c06c4731/steps/active
import { createInstance } from './config';

const BASE_URL = '/flowprocess';

export const getFlowProcessById = async (id, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.get(`${BASE_URL}/process/${id}`);
  return data;
};

export const finishStep = async (id, step) => {
  const axios = createInstance();
  const { data } = await axios.put(`${BASE_URL}/${id}/steps/${step}/finish`);
  return data;
};

export const getStepsByProcess = async (process) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/process/${process}/steps/active`);
  return data;
};

export const getStepsActiveByProcess = async (process) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/process/${process}/steps/active`);
  return data;
};

export const getFlowProcessByProcess = async (process) => {
  try {
    const axios = createInstance();
    const { data } = await axios.get(`${BASE_URL}/process/${process}`);
    return data;
  } catch (err) {
    return { status: err.response.status, message: err.response.data };
  }
};

export const getNodesAndFieldsByProcess = async (processId) => {
  try {
    const axios = createInstance();
    const { data } = await axios.get(`${BASE_URL}/process/${processId}/nodesAndFields`);
    return data;
  } catch (err) {
    return { status: err.response.status, message: err.response.data };
  }
};

export const getOnlyEmptyNodesAndFieldsByProcess = async (processId) => {
  try {
    const axios = createInstance();
    const { data } = await axios.get(`${BASE_URL}/process/${processId}/nodesAndFields?onlyEmptyNodes=true`);
    return data;
  } catch (err) {
    return { status: err.response.status, message: err.response.data };
  }
};
