import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { convertirANumero, getMonthlyPaid } from '../utils/currency';
import {
  setEngancheValue,
  setMonthlyPay, setPrestamoValue, setValues as setValuesDispatch, setYears,
} from '../store/creditConditions';
import { calcAmmountGastosEscritura } from '../utils/simulator.utils';
import { INPUT_NAME_DESEMBOLSO, INPUT_NAME_ENGANCHE, INPUT_SIMULATOR_MINIMUN_ENGANCHE_DECIMAL } from '../constants';

export const useSimulator = ({
  initialData = {},
  tasas,
  TASA,
  useDesembolsoMode = false,
}) => {
  const dispatch = useDispatch();
  const {
    prestamoValue,
    engancheValue,
    monthlyPay,
    years,
  } = useSelector((state) => state.creditConditions);
  const [values, setValues] = useState(initialData);

  const {
    precio, enganche, desembolso, year,
  } = useMemo(() => ({
    precio: values.precio || {},
    enganche: values.enganche || {},
    year: values.year || {},
    desembolso: values.desembolso || {},
  }), [values]);

  const gastosEscritura = useMemo(() => {
    if (!useDesembolsoMode) return 0;
    return calcAmmountGastosEscritura(precio.value);
  }, [precio, useDesembolsoMode]);

  useEffect(() => {
    setValues(initialData);
  }, [initialData]);

  useEffect(() => {
    dispatch(setValuesDispatch(values));
    if (precio?.value && engancheValue) {
      dispatch(setPrestamoValue(precio.value - engancheValue));
    } else {
      dispatch(setPrestamoValue(0));
    }
  }, [values, engancheValue, useDesembolsoMode]);

  useEffect(() => {
    dispatch(setEngancheValue(enganche.value));
  }, [precio, desembolso, useDesembolsoMode]);

  const percentage = useMemo(() => {
    const precioValue = precio?.value;
    const montoDesembolso = engancheValue;
    if (!precioValue || !montoDesembolso || (typeof precioValue !== 'number' || typeof montoDesembolso !== 'number')) return 0;
    return parseInt(((montoDesembolso / precioValue) * 100).toFixed(0), 10);
  }, [precio, engancheValue, desembolso, useDesembolsoMode]);

  useEffect(() => {
    if (!year?.options || !year.options.length) {
      dispatch(setYears(''));
    } else {
      const { id } = year.options.find(({ value }) => value === year.value) || { id: '' };
      dispatch(setYears(id));
    }
  }, [year]);

  useEffect(() => {
    if (!precio.value || !tasas) {
      dispatch(setMonthlyPay(0));
    } else {
      const tasaCurrent = (tasas[years] * 0.01) || TASA;
      const paid = getMonthlyPaid({
        importe: prestamoValue,
        plazoMensual: years * 12,
        tasa: tasaCurrent,
      });
      dispatch(setMonthlyPay(+paid.toFixed(2)));
    }
  }, [years, precio, prestamoValue, tasas]);

  const handleChange = (event, value) => {
    const { target } = event;
    const { name } = target;
    if (name === 'precio') {
      const convertedValue = convertirANumero(value);
      const newDesembolso = (convertedValue * INPUT_SIMULATOR_MINIMUN_ENGANCHE_DECIMAL)?.toFixed(2);
      const newEnganche = newDesembolso;
      const newValues = useDesembolsoMode
        ? {
          enganche: {
            ...values.enganche,
            value: convertirANumero(newEnganche),
            isEmpty: !newEnganche,
          },
        }
        : {
          enganche: {
            ...values.enganche,
            value: convertirANumero(newEnganche),
            isEmpty: !newEnganche,
          },
        };
      setValues({
        ...values,
        ...newValues,
        [name]: {
          ...values[name],
          value: convertedValue,
          isEmpty: !value,
        },
      });
    } else {
      setValues({
        ...values,
        [name]: {
          ...values[name],
          value: name !== 'year' ? convertirANumero(value) : value,
          isEmpty: !value,
          ...(name === INPUT_NAME_ENGANCHE || name === INPUT_NAME_DESEMBOLSO
            ? { focus: true }
            : {}),
        },
      });
    }
  };
  return {
    prestamoValue,
    percentage,
    monthlyPay,
    engancheValue,
    montoGastosEscritura: gastosEscritura,
    currentYear: years,
    values,
    handleChange,
  };
};
