/* eslint-disable no-plusplus */
const clabeWeights = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];

const getClabeControlDigit = (clabe) => clabe.substr(17, 18);

const getClabeKey = (clabe) => clabe.substr(0, 17);

const clabeIsValid = (value) => {
  const clabe = value ? value.toString() : '';
  let sum = 0;
  const key = getClabeKey(clabe).split('');
  const control = parseInt(getClabeControlDigit(clabe), 10);

  if (Number.isNaN(control)) return false;

  for (let i = 0; i < key.length; i++) {
    const intElement = parseInt(key[i], 10);
    if (Number.isNaN(intElement)) return false;
    const mult = (intElement * clabeWeights[i]) % 10;
    sum += mult;
  }

  sum = (10 - (sum % 10)) % 10;

  return control === sum;
};

export default clabeIsValid;
