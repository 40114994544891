import * as companyConfigsAPI from '../../api/companyConfigs';
import { COMPANY, COMPANY_TYPE } from '../../constants';

export const getClientsFields = async (flowName) => {
  const companyConfigs = await companyConfigsAPI.getAllCompanyConfigs(COMPANY);
  const companyConfig = companyConfigs.find(({ type: { name = '' } = {} }) => name === COMPANY_TYPE);

  if (companyConfig && companyConfig.payload) {
    return companyConfig.payload.flows.find((_flow) => _flow.flow === flowName);
  }
  return {};
};
