import React from 'react';
import PropTypes from 'prop-types';

import { MenuAppBar, StepperBar } from '../../../../components';
import useStyles from './useStyles';

export const MainLayout = ({
  showStepperBar = true,
  navigateOnLogoClick,
  backgroundColorKey,
  children,
  displayAuthUser = true,
}) => {
  const classes = useStyles({ backgroundColorKey });

  return (
    <div className={classes.root}>
      <MenuAppBar
        navigateOnLogoClick={navigateOnLogoClick}
        displayAuthUser={displayAuthUser}
      />
      {showStepperBar && <StepperBar />}
      <div className={classes.contentWrapper}>{children}</div>

    </div>
  );
};

MainLayout.propTypes = {
  showStepperBar: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  navigateOnLogoClick: PropTypes.bool,
  displayAuthUser: PropTypes.bool,
  backgroundColorKey: PropTypes.string,

};

MainLayout.defaultProps = {
  showStepperBar: true,
  navigateOnLogoClick: false,
  displayAuthUser: true,
  backgroundColorKey: 'default',
};
