/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import {
  Container,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './useStyles';

import { Button, Loader } from '../../../../components';
import AlertSnack from '../../../../components/AlertSnack';
import ErrorMessage from '../../../../components/ErrorMessage';

export const NormalLayout = ({
  children, data, submit, disabled, isLoading,
  isError, hideError, errorType, errorMessage,
  show, renderButton,
  classesNames,
}) => {
  const {
    title = '',
    infoTitle = '',
    buttonTitle = '',
  } = data;
  const classes = useStyles();
  const onSubmit = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    submit(event, value);
  };
  return (
    <>
      <AlertSnack
        isOpen={isError}
        type="error"
        onClose={hideError}
      >
        <ErrorMessage
          errorType={errorType}
          errorMessage={errorMessage}
        />
      </AlertSnack>
      <Loader open={isLoading} />
      {show ? (
        <Container
          component="form"
          onSubmit={onSubmit}
          method="post"
          autoComplete="off"
          className={clsx(classes.container, {
            [classesNames.container]: classesNames?.container,
          })}
          disableGutters
          maxWidth={false}
        >
          <Container
            component="div"
            className={classes.gridItem}
            disableGutters
            maxWidth={false}
          >
            <Container
              component="div"
              className={clsx(classes.header, {
                [classesNames?.header]: classesNames?.header,
              })}
              disableGutters
              maxWidth={false}
            >
              {title ? (
                <Typography
                  component="h2"
                  className={clsx(classes.title, {
                    [classesNames?.title]: classesNames?.title,
                  })}
                >{title}
                </Typography>
              ) : ''}
              {infoTitle ? (
                <Typography
                  component="p"
                  className={clsx(classes.infoTitle, {
                    [classesNames?.infoTitle]: classesNames?.infoTitle,
                  })}
                >{infoTitle}
                </Typography>
              ) : ''}
            </Container>
            {children}
          </Container>
          {renderButton() || (
            <Container
              className={classes.buttonContainer}
            >
              <Button
                className={classes.button}
                type="submit"
                disabled={disabled}
              >
                {buttonTitle}
              </Button>
            </Container>
          )}
        </Container>
      ) : ''}
    </>
  );
};

NormalLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  classesNames: PropTypes.shape({
    title: PropTypes.string,
    infoTitle: PropTypes.string,
    header: PropTypes.string,
    container: PropTypes.string,
  }),
  data: PropTypes.shape({
    title: PropTypes.string,
    infoTitle: PropTypes.string,
    buttonTitle: PropTypes.string,
  }),
  submit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  show: PropTypes.bool,
  isError: PropTypes.bool,
  hideError: PropTypes.func,
  errorType: PropTypes.string,
  errorMessage: PropTypes.string,
  renderButton: PropTypes.func,
};

NormalLayout.defaultProps = {
  disabled: false,
  isLoading: false,
  isError: false,
  hideError: () => { },
  errorType: '',
  errorMessage: '',
  data: {},
  show: true,
  renderButton: () => { },
  classesNames: {
    title: '',
    infoTitle: '',
    header: '',
    container: '',
  },
};
