import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import useStyles from './useStyles';

export const ProgressBar = memo(({ currentProgress, totalProgress }) => {
  const classes = useStyles();

  const progress = useMemo(() => (
    ((currentProgress * 100) / totalProgress) || 0
  ), [currentProgress, totalProgress]);

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          className={classes.linearProgress}
          variant="determinate"
          value={progress}
        />
      </Box>
      <Box minWidth={75}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.typography}
        >
          { `${currentProgress} de ${totalProgress}` }
        </Typography>
      </Box>
    </Box>
  );
});

ProgressBar.propTypes = {
  currentProgress: PropTypes.number,
  totalProgress: PropTypes.number,
};

ProgressBar.defaultProps = {
  currentProgress: 0,
  totalProgress: 0,
};
