import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  containerCredit: {
    width: '100%',
    marginTop: '40px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '60px',
    },
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerContainer: {
    width: '96%',
  },
  childContainer: {
    maxWidth: '800px',
    width: '95%',
  },
  tabsContainer: {
  },
  boxImage: {
    maxWidth: '100px',
    marginBottom: '10px',
  },
  imgLogo: {
    width: '100%',
  },
}));
