import { useState, useCallback, useMemo } from 'react';
import {
  PERFILAMIENTO, PROFILING_MODAL, PROFILING_COTIZATION,
  PROFILING_UPLOADING, PROFILING_COMPLETED, PROFILING_EMPTY_MATRIZ,
  PERFILAMIENTO_MODAL_COMPLETE,
} from '../../../../../constants';

export const STATES = {
  PROFILING: PERFILAMIENTO,
  PROFILING_MODAL,
  PROFILING_COTIZATION,
  PROFILING_UPLOADING,
  PROFILING_COMPLETED,
  PROFILING_EMPTY_MATRIZ,
  PERFILAMIENTO_MODAL_COMPLETE,
  LOADER_PAGE: 'LOADER_PAGE',
};

const useStateMachine = (_currentState = STATES.LOADER_PAGE) => {
  const [currentState, setCurrentState] = useState(_currentState);

  const goToProfiling = useCallback(() => {
    setCurrentState(STATES.PROFILING);
  }, []);
  const goToProfilingModal = useCallback(() => {
    setCurrentState(STATES.PROFILING_MODAL);
  }, []);
  const goToProfilingEmptyMatriz = useCallback(() => {
    setCurrentState(STATES.PROFILING_EMPTY_MATRIZ);
  }, []);
  const goToProfilingCotization = useCallback(() => {
    setCurrentState(STATES.PROFILING_COTIZATION);
  }, []);
  const goToProfilingCompleted = useCallback(() => {
    setCurrentState(STATES.PROFILING_COMPLETED);
  }, []);
  const goToProfilingUploading = useCallback(() => {
    setCurrentState(STATES.PROFILING_UPLOADING);
  }, []);
  const goToLoaderPage = useCallback(() => {
    setCurrentState(STATES.LOADER_PAGE);
  }, []);

  const result = useMemo(
    () => (
      {
        STATES,
        currentState,
        goToProfiling,
        goToProfilingModal,
        goToProfilingCotization,
        goToProfilingUploading,
        goToLoaderPage,
        goToProfilingCompleted,
        goToProfilingEmptyMatriz,
      }
    ),
    [
      currentState,
      goToProfiling,
      goToProfilingModal,
      goToProfilingCotization,
      goToProfilingUploading,
      goToLoaderPage,
      goToProfilingCompleted,
      goToProfilingEmptyMatriz,
    ],
  );

  return result;
};

export default useStateMachine;
