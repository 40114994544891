import React, { useReducer, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createTheme } from '@material-ui/core/styles';

import { LOAD_THEME, UPDATE_LOGO, UPDATE_THEME } from './constants';
import { ThemeContext } from './themeContext';
import { themeReducer } from './themeReducer';
import { init, storeThemeData } from '../../utils/theme';
import { DefaultTheme } from '../../themes/DefaultTheme';
import { createLogoConfiguration, createPaletteConfiguration } from './utils';

const DEFAULT_THEME = {
  theme: DefaultTheme,
  logo: {},
};

export const ThemeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(themeReducer, DEFAULT_THEME, init);

  const updateTheme = useCallback((payload) => {
    dispatch({ type: UPDATE_THEME, payload });
  }, []);

  const updateLogo = useCallback((payload) => {
    dispatch({ type: UPDATE_LOGO, payload });
  }, []);

  const loadTheme = useCallback((product) => {
    const logoTheme = createLogoConfiguration(product);
    const newTheme = createPaletteConfiguration(product);
    storeThemeData({ theme: newTheme, ...logoTheme });
    dispatch({ type: LOAD_THEME, payload: { ...logoTheme, theme: newTheme } });
  }, [createLogoConfiguration, createPaletteConfiguration]);

  const value = useMemo(() => ({
    state: {
      ...state,
      theme: createTheme(state.theme),
    },
    updateTheme,
    updateLogo,
    loadTheme,
  }), [state, updateTheme, updateLogo, loadTheme]);

  return (
    <ThemeContext.Provider
      value={value}
    >
      { children }
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
