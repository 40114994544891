import * as fieldValueAPI from '../../../api/fieldValue';
import * as sharedFieldValuesAPI from '../../../api/sharedFieldValues';

import { generateSharedFieldValues } from '../../../utils/clients';
import { getAndFormatLocalFieldValuesToUpload } from '../../../utils/fieldValues';
import { getClientHaveAgent, getClientPropertyStage } from '../../../utils/credit';

import { PERFILAMIENTO_ID_FIELD } from '../constants/fields';

export const saveCreditValues = async (process, token) => {
  const clientPropertyStage = getClientPropertyStage();
  const clientHaveAgent = getClientHaveAgent();
  await Promise.all([
    fieldValueAPI.saveFieldValue({
      field: '6515ede55ab415f23cb38c55',
      value: clientPropertyStage,
      process,
    }, token),
    fieldValueAPI.saveFieldValue({
      field: '65396fb7a97ccfb0db57040a',
      value: clientHaveAgent,
      process,
    }, token),
  ]);
};

export const saveAllLocalFieldValues = async (process, token) => {
  const fieldValuesToUpload = getAndFormatLocalFieldValuesToUpload(process);
  await Promise.all(
    fieldValuesToUpload.map(
      async (itemFieldValue) => fieldValueAPI.saveFieldValue(itemFieldValue, token),
    ),
  );
};

export const saveLocalPerfilamientoSharedFieldValue = async (clientId, process, token) => {
  const fieldValues = getAndFormatLocalFieldValuesToUpload(process);
  const perfilamientoFieldValue = fieldValues.find(({ field = '' }) => (field === PERFILAMIENTO_ID_FIELD)) || {};
  const perfilamientoField = {
    _id: perfilamientoFieldValue?.field || '',
    value: perfilamientoFieldValue?.value || '',
  };
  const [sharedFieldValues] = generateSharedFieldValues([perfilamientoField], clientId);
  return sharedFieldValuesAPI.saveSharedFieldValuesData(sharedFieldValues, token);
};
