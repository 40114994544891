import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  checkBoxLabel: {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: '#7C839D',
    marginLeft: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      lineHeight: '1rem',
    },
  },
  container: {
    margin: '30px auto',
    paddingLeft: '49px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
  Checkbox: {
    transform: 'scale(1.6)',
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(1.1)',

    },
  },
}));
