import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    border: '1px solid black',
    borderRadius: '20px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    width: '100%',
    padding: '10px',
  },
  innerContainer: {
    height: '200px',
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
  title: {
    color: '#000',
    fontFamily: 'Lato, sans-serif',
    fontSize: '1rem',
    fontWeight: '700',
    whiteSpace: 'nowrap',
  },
  money: {
    color: '#000',
    fontFamily: 'Lato, sans-serif',
    fontSize: '1rem',
    fontWeight: '700',
    display: 'inline',
    textAlign: 'left',
  },
  currency: {
    color: '#000',
    fontFamily: 'Lato, sans-serif',
    fontSize: '0.75rem',
    fontWeight: '700',
    display: 'inline-block',
    marginLeft: '4px',
  },
  moneyFormat: {
    textAlign: 'left',
  },
  element: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
  },
}));
