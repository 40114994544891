/* eslint-disable react/forbid-prop-types */
import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import { Button, Container, Typography } from '@material-ui/core';
import useStyles from './useStyles';
import Input from '../Input';

export const SimpleInputDialog = ({
  onCancel,
  onSubmit,
  field,
  showModal,
  error,
  title,
  buttonTitle,
}) => {
  const [values, setValues] = useState('');
  const classes = useStyles();

  const onChange = useCallback((event, value) => {
    setValues(value);
  }, [values]);

  const handleOnSubmit = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit(event, values, field._id);
  }, [values, onSubmit, field]);

  return (
    <Modal
      open={showModal}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
    >
      <div className={classes.mainContainer}>
        <Typography
          variant="h1"
          className={classes.title}
        >
          {title}
        </Typography>
        {error.isError ? (
          <Typography
            variant="body1"
            component="p"
            className={classes.errorText}
            onClick={onCancel}
            onKeyDown={onCancel}
            tabIndex={0}
            role="button"
          >
            {error.errorMessage}
          </Typography>
        ) : ''}
        <Container
          component="form"
          onSubmit={handleOnSubmit}
          method="post"
          autoComplete="off"
          maxWidth={false}
          className={classes.form}
        >
          <div
            className={classes.pinDigitContainer}
          >
            <Input
              label={field.label}
              type={field?.fieldType?.name}
              name={field.name}
              value={values}
              headers={field?.config?.data || []}
              required={Boolean(field?.config?.required)}
              onChange={onChange}
              disabled={field?.config?.disabled}
              hide={Boolean(field?.config?.hide)}
              isEmpty={field?.isEmpty}
              errorMessage={field?.errorMessage}
              defaultValue={field?.config?.defaultValue}
              rejections={field?.status?.payload?.rejections
                && field?.status?.payload?.rejections[0]}
              status={field?.status?.value}
              format={field?.config?.format}
            />
          </div>
          <div
            className={classes.buttonContainer}
          >
            <Button
              type="submit"
              className={classes.button}
            >
              {buttonTitle}
            </Button>
          </div>
        </Container>
      </div>
    </Modal>
  );
};

SimpleInputDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
};
