import { UI_UPDATE_ALERT } from './constants';

export const uiReducer = (state, action) => {
  switch (action.type) {
    case UI_UPDATE_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    default:
      return { ...state };
  }
};
