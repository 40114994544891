/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './useStyles';
import Input from '../../../../../components/Input';
import { CofinavitData } from '../../../data';
import Simulator, {
  FRAME_CLASSES, FRAME_TYPES, isGreaterThanValue,
} from '../../Simulator';
import { determineMontoPrestamo } from '../../../../../utils/banksCalculations.utils';

import { INFONAVIT_COFINAVIT_TYPE, INFONAVIT_COTIZACION_BUTTON_TEXT } from '../../../../../constants';

const {
  LIMIT_VALUE,
  TASA,
  INITIAL_VALUES,
  TASAS,
} = CofinavitData;

export const CofinavitSimulator = ({
  onSubmit,
  isVivienda,
  buttonTitle = INFONAVIT_COTIZACION_BUTTON_TEXT,
  initialValues = INITIAL_VALUES,
}) => {
  const classes = styles();

  const handleSubmit = ({
    monthlyPay,
    currentYear,
    values,
    event,
  }) => {
    const saldoSubcuentaInfonavit = values.saldoSubcuentaInfonavit.value;
    const montoCreditoInfonavit = values.montoCreditoInfonavit.value;

    const propietyValue = values.precio.value;
    const { enganche, montoPrestamo } = determineMontoPrestamo(
      INFONAVIT_COFINAVIT_TYPE,
      propietyValue,
      0,
      montoCreditoInfonavit,
      saldoSubcuentaInfonavit,
    );
    const aforo = (montoPrestamo / values.precio.value).toFixed(4);

    const storageData = {
      saldoSubcuentaInfonavit,
      montoCreditoInfonavit,
      importe: montoPrestamo,
      meses: currentYear * 12,
      aforo,
      year: currentYear,
      plazo: `${currentYear} años`,
      mensualidad: monthlyPay,
      propietyValue: values.precio.value,
      enganche,
    };
    onSubmit({
      storageData,
      event,
    });
  };

  return (
    <Simulator
      initialData={initialValues}
      tasas={TASAS}
      TASA={TASA}
      onSubmit={handleSubmit}
    >
      {
        ({
          handleChange,
          values,
        }) => (
          <>
            <Simulator.InnerContainer isVivienda={isVivienda}>
              <Simulator.Frame variant={FRAME_TYPES.FRAME} frameClass={FRAME_CLASSES.FIRST_INPUT_ELEMENT}>
                <Input
                  label={values.precio.label}
                  type={values.precio.type}
                  name={values.precio.name}
                  value={values.precio.value}
                  required={values.precio.required}
                  isEmpty={values.precio.isEmpty}
                  validations={[
                    (inputValue) => isGreaterThanValue(inputValue, LIMIT_VALUE, 'Valor no puede ser menor a $1,200,000'),
                  ]}
                  onChange={handleChange}
                />
              </Simulator.Frame>
              <Simulator.Frame variant={FRAME_TYPES.FRAME} frameClass={FRAME_CLASSES.SECOND_INPUT_ELEMENT}>
                <Input
                  label={values.year.label}
                  type={values.year.type}
                  name={values.year.name}
                  value={values.year.value}
                  required={values.year.required}
                  isEmpty={values.year.isEmpty}
                  onChange={handleChange}
                  fullWidth
                  options={values.year.options}
                />
              </Simulator.Frame>
              <Simulator.Frame variant={FRAME_TYPES.FRAME} frameClass={FRAME_CLASSES.SECOND_INPUT_ELEMENT}>
                <Input
                  className={classes.inputElement}
                  classNameLabel={classes.blueColor}
                  label={values.saldoSubcuentaInfonavit.label}
                  type={values.saldoSubcuentaInfonavit.type}
                  name={values.saldoSubcuentaInfonavit.name}
                  value={values.saldoSubcuentaInfonavit.value}
                  required={values.saldoSubcuentaInfonavit.required}
                  isEmpty={values.saldoSubcuentaInfonavit.isEmpty}
                  onChange={handleChange}
                />
              </Simulator.Frame>
              <Simulator.Frame variant={FRAME_TYPES.FRAME} frameClass={FRAME_CLASSES.FIRST_INPUT_ELEMENT}>
                <Input
                  className={classes.inputElement}
                  classNameLabel={classes.blueColor}
                  label={values.montoCreditoInfonavit.label}
                  type={values.montoCreditoInfonavit.type}
                  name={values.montoCreditoInfonavit.name}
                  value={values.montoCreditoInfonavit.value}
                  required={values.montoCreditoInfonavit.required}
                  isEmpty={values.montoCreditoInfonavit.isEmpty}
                  onChange={handleChange}
                />
              </Simulator.Frame>
            </Simulator.InnerContainer>
            <Simulator.Button buttonTitle={buttonTitle} />
          </>
        )
      }
    </Simulator>
  );
};
CofinavitSimulator.propTypes = {
  onSubmit: PropTypes.func,
  isVivienda: PropTypes.bool,
  buttonTitle: PropTypes.string,
  initialValues: PropTypes.object,
};

CofinavitSimulator.defaultProps = {
  onSubmit: () => { },
  isVivienda: false,
  buttonTitle: INFONAVIT_COTIZACION_BUTTON_TEXT,
  initialValues: INITIAL_VALUES,
};
