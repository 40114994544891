import React, {
  useEffect, useContext, useCallback, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import * as processAPI from '../../../../api/process';

import { StepperContext } from '../../../../context/stepper';
import { WaitInformation } from './WaitInformation';
import { LoaderPage } from './LoaderPage';
import { ModalNodosChiplo } from '../CreditProfilingPage/CreditProfilingModalChiplo';

import useStateMachine from './hooks/useStateMachine';

import { asyncTimeOut } from '../../../../utils/asyncTimeout';
import { getProcess, getSteps, getTheCurrentStep } from '../../../../utils';

import {
  FORMALIZATION_MODAL, FORMALIZATION_NAME, NORMALIZED_FORMALIZACION_STEP_NAME, WAIT_INFORMATION,
} from '../../../../constants';
import { finishStepByProcess } from '../../../../utils/steps';

export const FormalizationPage = () => {
  const navigate = useNavigate();
  const [stepperTags, setStepperTags] = useState({});
  const { updateCurrentStep } = useContext(StepperContext);
  const {
    STATES,
    currentState,
    goToFormalizationModal,
    goToWaitInformation,
    goToLoaderPage,
  } = useStateMachine();

  const createUpdateTag = useCallback((updateTag) => {
    const process = getProcess();
    return async () => {
      const { tags = {} } = await processAPI.getProcessById(process);
      const body = {
        [FORMALIZATION_NAME]: { ...tags[FORMALIZATION_NAME], ...updateTag },
      };

      await processAPI.createTag({
        tagName: FORMALIZATION_NAME,
        active: true,
        body: { ...tags, ...body },
        processId: process,
      });
    };
  }, [stepperTags]);

  const getProcessSteps = async () => {
    const processId = getProcess();

    const process = await processAPI.getProcessById(processId);
    const { tags = {} } = process;
    const { redirectPath, stepPosition } = await getTheCurrentStep(
      processId,
      FORMALIZATION_NAME,
    );
    const consultTag = tags[FORMALIZATION_NAME];
    return {
      tags, redirectPath, stepPosition, consultTag, process,
    };
  };

  const onFinishNodos = async () => {
    const createTag = createUpdateTag({ currentPage: WAIT_INFORMATION });
    await createTag();
    await finishStepByProcess(NORMALIZED_FORMALIZACION_STEP_NAME);
    goToWaitInformation();
  };

  const selectPage = async () => {
    await asyncTimeOut(3000);
    const {
      tags, redirectPath, stepPosition, consultTag = {},
    } = await getProcessSteps();
    const { currentPage = FORMALIZATION_MODAL, completed } = consultTag;

    if (completed) {
      return navigate(redirectPath);
    }
    setStepperTags(tags);

    updateCurrentStep(stepPosition);
    switch (currentPage) {
      case WAIT_INFORMATION:
        return goToWaitInformation();
      case FORMALIZATION_MODAL:
        return goToFormalizationModal();
      default:
        return goToLoaderPage();
    }
  };

  const findCurrentStep = () => {
    const steps = getSteps();
    return steps.findIndex(({ name }) => name === FORMALIZATION_NAME);
  };

  useEffect(() => {
    const actualStep = findCurrentStep();
    updateCurrentStep(actualStep);
    selectPage();
  }, []);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {
        (
          () => {
            switch (currentState) {
              case STATES.LOADER_PAGE:
                return (
                  <LoaderPage />
                );
              case STATES.WAIT_INFORMATION:
                return (
                  <WaitInformation />
                );
              case STATES.FORMALIZATION_MODAL:
              default:
                return (
                  <ModalNodosChiplo
                    onFinish={onFinishNodos}
                    onlyEmptyNodes
                  />
                );
            }
          }
        )()
      }
    </>
  );
};
