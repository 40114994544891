/* eslint-disable max-len */
import React, { useEffect, useState, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import { Loader, MenuAppBar } from '../../../../components';
import useLoading from '../../../../hooks/useLoading';
import useStyles from './useStyles';
import { StepperVertical } from '../../../../components/StepperVertical';
import { getExpirationTime, getFlowTypeHandler } from '../../../../utils';
import { getProcessById } from '../../../../api/process';
import { getClientById } from '../../../../api/clients';

export const Ipsum = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [stepTags, setStepTags] = useState([]);
  const [pageData, setPageData] = useState({});
  const { isLoading, startLoading, endLoading } = useLoading();
  const classes = useStyles();

  const {
    title = '',
    subTitle = '',
  } = pageData;

  const activeStep = useMemo(() => stepTags.reduce((acc, curr) => (curr.completed ? acc + 1 : acc), 0), [stepTags]);

  const setTagsFromProcess = (tags) => {
    const formattedTags = Object.keys(tags)
      .filter((keyTag) => tags[keyTag].show)
      .sort((a, b) => tags[a].position - tags[b].position)
      .map((keyTag) => ({
        label: tags[keyTag].label,
        completed: tags[keyTag].completed,
        extraInfo: tags[keyTag].sort.map((field) => tags[keyTag][field] || '').filter((value) => value),
      }));
    setStepTags(formattedTags);
  };

  const setDataPage = async (processId, client, token) => {
    const { name } = await getClientById(client, token);
    const { flow } = await getFlowTypeHandler(processId, token);
    setPageData({
      title: name,
      subTitle: flow.name,
    });
  };

  const mergeTags = (tagsToMerge, oldTags, nextTags) => Object.keys(nextTags)
    .map((keyTag) => (tagsToMerge.includes(keyTag) ? { [keyTag]: oldTags[keyTag] } : { [keyTag]: nextTags[keyTag] }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const setTagsFrom2Process = async (process, token) => {
    const nextProcess = await getProcessById(process.otherProcess, token);
    const mergedTags = mergeTags(['iniciatramite', 'consultaburo'], process.tags, nextProcess.tags);
    setTagsFromProcess(mergedTags);
  };

  const fetchData = async () => {
    try {
      startLoading();
      const processId = searchParams.get('process');
      const token = searchParams.get('token');
      const expTime = getExpirationTime(token);
      if (!processId && !expTime) return navigate('/');
      const process = await getProcessById(processId, token);
      if (!process?.otherProcess) setTagsFromProcess(process.tags);
      else {
        await setTagsFrom2Process(process, token);
      }
      await setDataPage(processId, process.client, token);
      return endLoading();
    } catch (e) {
      return navigate('/');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <MenuAppBar>
      <Loader
        open={isLoading}
      />
      <Container
        component="main"
        className={classes.container}
        disableGutters
        maxWidth={false}
      >
        <Container
          component="div"
          className={classes.innerContainer}
          disableGutters
          maxWidth={false}
        >
          <div className={classes.header}>
            <Typography
              component="h2"
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography
              component="p"
              className={classes.subTitle}
            >
              {subTitle}
            </Typography>
          </div>
          <main
            className={classes.main}
          >
            <StepperVertical
              steps={stepTags}
              activeStep={activeStep}
              connectorClass={classes.line}
            />
          </main>
        </Container>
      </Container>
    </MenuAppBar>
  );
};
