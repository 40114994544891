/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%',
    maxWidth: '370px',
    [theme.breakpoints.up('xl')]: {
      width: '80%',
      maxWidth: '600px',
    },
  },
  customBorderInput: {
    borderColor: 'gray',
  },
  boxImage: {
    maxWidth: '280px',
    margin: '0 auto',
  },
  imgLogo: {
    paddingTop: '10%',
    width: '100%',
  },
  inputContainer: {
    maxWidth: '370px',
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      width: '80%',
      maxWidth: '700px',
    },
  },
  inputFieldContainer: {
    width: '100%',
  },
  labelInput: {
    lineHeight: '24px',
    paddingTop: '5%',
    paddingBottom: '5%',
    fontWeight: 700,
    fontSize: '1rem',
  },
  buttonContainer: {
    maxWidth: '370px',
    width: '100%',
    margin: '0 auto',
    marginBottom: '10%',
    [theme.breakpoints.up('xl')]: {
      width: '80%',
      maxWidth: '700px',
    },
  },
  btnLogin: {
    fontSize: '1rem',
    padding: '0.75rem 5rem',
  },
  boxNotAccount: {
    textAlign: 'center',
  },
  notAccount: {
    paddingRight: '5px',
    color: '#A5A8AB',
    fontWeight: 600,
    fontSize: '1rem',
  },
  redirect: {
    fontWeight: 600,
    fontSize: '1rem',
    color: '#176BCD',
    cursor: 'pointer',
  },
}));
