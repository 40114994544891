/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './useStyles';
import { TabsContext } from '../../context/tabs/tabsContext';

export const TabsSeparated = ({
  data,
  defaultValue,
  onChange,
}) => {
  const classes = useStyles();
  const {
    tabsExtraClasses,
  } = useContext(TabsContext);
  const [value, setValue] = useState(defaultValue);
  const onChangeTabs = (event, newValue) => {
    setValue(newValue);
    onChange(event, newValue);
  };
  return (
    <Box className={classes.container}>
      <Tabs
        value={value}
        onChange={onChangeTabs}
        className={clsx(classes.tabs, {
          [tabsExtraClasses?.tabsContainer]: tabsExtraClasses?.tabsContainer,
        })}
        variant="fullWidth"
      >
        {
          data.map(({
            label,
            value: tabValue,
            disabled,
          }, index) => (
            <Tab
              className={clsx(classes.tab, {
                [classes.firstTab]: index === 0,
                [classes.lastTab]: index === data.length - 1,
                [tabsExtraClasses?.tab]: tabsExtraClasses?.tab,
              })}
              label={label}
              value={tabValue}
              disabled={disabled}
              classes={{
                selected: clsx(classes.selected, {
                  [tabsExtraClasses?.selected]: tabsExtraClasses?.selected,
                }),
              }}
            />
          ))
        }
      </Tabs>
    </Box>
  );
};

TabsSeparated.propTypes = {
  data: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

TabsSeparated.defaultProps = {
  data: [],
  defaultValue: '',
  onChange: () => { },
};
