import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '../../../../components';
import AlertSnack from '../../../../components/AlertSnack';
import ErrorMessage from '../../../../components/ErrorMessage';

import * as leadsAPI from '../../../../api/leads';
import * as sharedFieldValuesAPI from '../../../../api/sharedFieldValues';

import useError from '../../../../hooks/useError';
import useLoading from '../../../../hooks/useLoading';
import { useProduct } from '../../../../hooks/useProduct';
import { useFieldValues } from '../../../main/hooks/useFieldValues';
import { StepperContext } from '../../../../context/stepper';

import { storeLeadData } from '../../../../utils/leads';
import { storeFlowData } from '../../../../utils/flows';
import { logOutMain, storeStepData } from '../../../../utils';
import { getFirstPathSegment } from '../../../../utils/util-routes';
import { formatSharedFieldValuesToFieldValues } from '../../../../utils/fieldValues';
import { buildCreditObjectFromFieldValues, storeCredit } from '../../../../utils/credit';

import { COMPANY, LOGIN_ROUTE } from '../../../../constants';

export const RecoverProcess = () => {
  const { initStepper } = useContext(StepperContext);
  const navigate = useNavigate();
  const { leadId } = useParams();

  const { getProductByRoute } = useProduct();
  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();
  const { isLoading, startLoading, endLoading } = useLoading();
  const { storeLocalFieldValues } = useFieldValues({ company: COMPANY });

  const storeStepsData = (stepsData) => {
    const { steps, currentStep } = stepsData;
    const stepperObject = { steps, current: currentStep };
    initStepper(stepperObject);
    storeStepData(stepsData);
  };

  const handlePocessNotExists = async (leadData, stepsData) => {
    await logOutMain();
    const { stepRoute } = stepsData;
    const leadSharedFieldValues = await sharedFieldValuesAPI
      .getSharedFieldValuesDataByUserId(leadId);
    const fieldValues = formatSharedFieldValuesToFieldValues(leadSharedFieldValues);
    const creditDataObject = buildCreditObjectFromFieldValues(fieldValues);
    const productPath = getFirstPathSegment(stepRoute);
    const productByRoute = getProductByRoute(productPath);
    storeLeadData(leadData);
    storeCredit(creditDataObject);
    storeFlowData(productByRoute);
    storeLocalFieldValues(fieldValues, false);
    storeStepsData(stepsData);
    navigate(stepRoute, { replace: true });
  };

  const handleProcessExists = () => {
    navigate(LOGIN_ROUTE);
  };

  const fetchLeadStepsData = async () => {
    try {
      startLoading();
      const leadData = await leadsAPI.getLeadById(leadId);
      const {
        stepsData,
        hasCreatedProcess = false,
      } = await leadsAPI.recoverLeadProcessSteps(leadId);
      if (hasCreatedProcess) handleProcessExists();
      else handlePocessNotExists(leadData, stepsData);
      endLoading();
    } catch (e) {
      endLoading();
      showError(e.message, e.name || 'Error trying to fetch lead steps data');
    }
  };

  useEffect(() => {
    fetchLeadStepsData();
  }, [leadId]);
  return (
    <div>
      <AlertSnack
        isOpen={isError}
        type="error"
        onClose={hideError}
      >
        <ErrorMessage
          errorType={errorType}
          errorMessage={errorMessage}
        />
      </AlertSnack>
      <Loader
        open={isLoading}
      />
    </div>

  );
};
