/* eslint-disable no-unused-vars */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginEntity } from '../pages/Login';
import { Clients } from '../pages/Clients';
import { Credits } from '../pages/Credits';
import { CreditProcess } from '../pages/CreditProcess';
import { ProtectedRoute, WhatsappFloat } from '../../../components';
import {
  AUTH_ROUTE_TYPE,
  CREDITO_HIPOTECARIO_INMOBILIARIA,
  CREDIT_PROFILING_COTIZATION_STRATEGY,
  CREDIT_PROFILING_STRATEGY,
  ENTITY_LOGIN_ROUTE,
  INMOBILIARIA_STRATEGY,
  ONLY_AGENTS_AND_NOT_AUTH_CLIENTS,
} from '../../../constants';
import CreateClient from '../pages/CreateClient';
import { TabsProvider } from '../../../context/tabs/tabsProvider';
import { InmobiliariaLanding } from '../../Chiplo/pages';
import { MainLayout } from '../../main/layouts';
import { ProfilingProvider } from '../../../context/profiling';
import { CreditProfilingPage } from '../../main/pages/CreditProfilingPage';
import { FlowStrategyContainer } from '../../flowStrategy';
import { simulatorStrategies } from '../../../strategies/simulatorStrategies';
import { CotizationInmobiliaria } from '../../main/pages/CotizationInmobiliaria';

const EntityRoutes = () => (
  <Routes>
    <Route
      path="/login"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath="/inmobiliaria/clients"
          additionalData={{ authProtected: false }}
        >
          <LoginEntity />
        </ProtectedRoute>
      )}
    />

    <Route
      path="/clients"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath={ENTITY_LOGIN_ROUTE}
          additionalData={{ authProtected: true }}
        >
          <Clients />
        </ProtectedRoute>
      )}
    />
    <Route
      path="/clients/create"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath={ENTITY_LOGIN_ROUTE}
          additionalData={{ authProtected: true }}
        >
          <CreateClient />
        </ProtectedRoute>
      )}
    />

    <Route
      path="/credits"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath={ENTITY_LOGIN_ROUTE}
          additionalData={{ authProtected: true }}
        >
          <Credits />
        </ProtectedRoute>
      )}
    />

    <Route
      path="/credits/creditprocess"
      element={(
        <ProtectedRoute
          type={AUTH_ROUTE_TYPE}
          redirectPath={ENTITY_LOGIN_ROUTE}
          additionalData={{ authProtected: true }}
        >
          <CreditProcess />
        </ProtectedRoute>
      )}
    />

    <Route
      path="/perfilamiento"
      element={(
        <TabsProvider>
          <InmobiliariaLanding />
          <WhatsappFloat />
        </TabsProvider>
      )}
    />

    <Route
      path="/cuestionario_perfilamiento"
      element={(
        <ProtectedRoute
          type={ONLY_AGENTS_AND_NOT_AUTH_CLIENTS}
          redirectPath="/main/consult"
        >
          <MainLayout showStepperBar={false}>
            <ProfilingProvider>
              <CreditProfilingPage
                strategyName={CREDIT_PROFILING_STRATEGY}
                productStrategy={INMOBILIARIA_STRATEGY}
              />
              <WhatsappFloat />
            </ProfilingProvider>
          </MainLayout>
        </ProtectedRoute>
        )}
    />

    <Route
      path="/cotizacion"
      element={(
        <ProtectedRoute
          type={ONLY_AGENTS_AND_NOT_AUTH_CLIENTS}
          redirectPath="/main/consult"
        >
          <MainLayout showStepperBar={false}>
            <FlowStrategyContainer
              strategies={simulatorStrategies}
              flowName={CREDITO_HIPOTECARIO_INMOBILIARIA}
            >
              <ProfilingProvider>
                <CotizationInmobiliaria
                  strategyName={CREDIT_PROFILING_COTIZATION_STRATEGY}
                  productStrategy={INMOBILIARIA_STRATEGY}
                />
                <WhatsappFloat />
              </ProfilingProvider>
            </FlowStrategyContainer>
          </MainLayout>
        </ProtectedRoute>
        )}
    />

    <Route path="/*" element={<Navigate to="/inmobiliaria/clients" />} />
  </Routes>
);

export default EntityRoutes;
