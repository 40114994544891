export const FORMAT_DATE = 'date';
export const FORMAT_PERCENTAGE = 'percentage';
export const FORMAT_NUMBER = 'number';
export const FORMAT_CURRENCY = 'currency';

export const DEFAULT_FORMAT_FILES_TO_UPLOAD = {
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
};

export const FILE_TYPE_LABELS = {
  pdf: 'un PDF',
  png: 'una imagen',
  jpeg: 'una imagen',
  jpg: 'una imagen',
};

export const textFormats = {
  UPPER: 'upperCase',
  LOWER: 'lowerCase',
  NUMBER: 'number',
};
