import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  containerCheck: {
    textAlign: 'center',
    marginTop: '25px',
    marginBottom: '25px',
    padding: '16px',
  },
  label: {
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'Open Sans',
    color: '#2C1482',
  },
  companyLink: {
    textDecoration: 'underline',
    color: '#2C1482',
  },
}));
