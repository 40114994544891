/* eslint-disable react/forbid-prop-types */
// PerfiladorInput.js
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Input from '../../../../components/Input';

export const PerfiladorInput = ({
  classes,
  isEmpty,
  name,
  onChange,
  titleLabel,
  validations,
  value,
  disabled,
  hideLabel,
  label,
  placeHolder,
  required,
  type,
  options,
}) => (
  <Box className={classes?.inputContainer}>
    <Typography className={classes?.title}>{titleLabel}</Typography>
    <Input
      className={classes?.input}
      disabled={disabled}
      hideLabel={hideLabel}
      isEmpty={isEmpty}
      label={label}
      name={name}
      onChange={onChange}
      placeHolder={placeHolder}
      required={required}
      type={type}
      validations={validations}
      value={value}
      options={options}
    />
  </Box>
);

PerfiladorInput.propTypes = {
  classes: PropTypes.object,
  isEmpty: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  titleLabel: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.func),
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  options: PropTypes.array,
};

PerfiladorInput.defaultProps = {
  isEmpty: false,
  name: '',
  titleLabel: '',
  validations: [],
  disabled: false,
  hideLabel: false,
  label: '',
  placeHolder: '',
  required: false,
  type: 'text',
  classes: {},
  options: [],
};
