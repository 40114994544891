import jwtDecode from 'jwt-decode';
import { storeAuth } from '../auth';
import * as authAPI from '../../api/auth';

export const loginToken = async (token) => {
  const { validator: { email = '' } = {} } = jwtDecode(token);
  const { user } = await authAPI.loginByToken(email, token);
  storeAuth(user);
  return user;
};
