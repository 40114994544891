import { FORMAT_CURRENCY, FORMAT_PERCENTAGE } from '../constants/format';

export const currencyFormatter = (value, defaultValue = '', suffix = '') => {
  if (value === 0) return `$${value.toLocaleString('en-US')}`;
  if (!value || typeof value !== 'number') return defaultValue;
  return `$${value.toLocaleString('en-US')}${suffix ? ` ${suffix}` : ''}`;
};

export const formater = (value, format) => {
  switch (format) {
    case FORMAT_CURRENCY:
      return currencyFormatter(value, 0, '');
    case FORMAT_PERCENTAGE:
      return `${value}%`;
    default:
      return value;
  }
};

export const convertStringToFloat = (numeroString) => {
  const numeroFloat = parseFloat(numeroString);
  if (Number.isNaN(numeroFloat)) {
    return 0;
  }
  return numeroFloat;
};
