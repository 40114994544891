import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './useStyles';
import { Carousel } from '../Carousel';

export const CarouselSlider = ({
  images,
  classNames,
}) => {
  const classes = useStyles();

  const newItems = images.map((image) => (
    <Box
      key={image}
      className={clsx(classes.imgContainer, {
        [classNames?.imgContainer]: classNames.imgContainer,
      })}
    >
      <Box
        className={clsx(classes.img, {
          [classNames?.img]: classNames.img,
        })}
        component="img"
        src={image}
      />
    </Box>
  ));

  return (
    (newItems && newItems.length > 0) && (
    <Carousel
      items={newItems}
    />
    )
  );
};

CarouselSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  classNames: PropTypes.shape({
    img: PropTypes.string,
    imgContainer: PropTypes.string,
  }),
};

CarouselSlider.defaultProps = {
  images: [],
  classNames: {},
};
