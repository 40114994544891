import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './useStyles';
import { staticLandingData } from '../../../../data';
import { BoxRounded } from '../../../../components/BoxRounded';

import { CarouselSlider } from '../../../../components/CarouselSlider';

export const BanksCarrousel = () => {
  const classes = useStyles();
  const { dataInfoTag } = staticLandingData;

  return (
    <Box component="section" className={classes.sectionContainer}>
      <Typography className={classes.tagTitle} component="h2">
        {dataInfoTag.title}
      </Typography>
      <BoxRounded
        title={dataInfoTag.subtitle}
      >
        <CarouselSlider
          images={dataInfoTag.images}
        />

      </BoxRounded>
    </Box>
  );
};
