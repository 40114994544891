import { useState, useCallback, useMemo } from 'react';
import {
  CONSULT_CREDIT_BUSSINESS,
  CONSULT_CREDIT_SHAREHOLDER,
  SET_DATA_LEGAL_REPRESENTATIVES,
  SET_DATA_SHAREHOLDER,
  WAIT_INFORMATION,
  LOADER_PAGE,
  CONSULT_INVOICE_BUSSINESS,
  CONSULT_PATRIMONIAL_RELATIONSHIP,
  UPLOAD_PDF,
  BURO_REJECTED,
  WAIT_CONSULT,
} from '../../../constants';

export const STATES = {
  CONSULT_CREDIT_BUSSINESS,
  CONSULT_CREDIT_SHAREHOLDER,
  CONSULT_INVOICE_BUSSINESS,
  CONSULT_PATRIMONIAL_RELATIONSHIP,
  SET_DATA_LEGAL_REPRESENTATIVES,
  SET_DATA_SHAREHOLDER,
  WAIT_INFORMATION,
  LOADER_PAGE,
  WAIT_CONSULT,
  BURO_REJECTED,
  UPLOAD_PDF,
};

const useStateMachine = (_currentState = STATES.LOADER_PAGE) => {
  const [currentState, setCurrentState] = useState(_currentState);

  const goToConsultCreditBussiness = useCallback(() => {
    setCurrentState(STATES.CONSULT_CREDIT_BUSSINESS);
  }, []);

  const goToConsultBuroRejected = useCallback(() => {
    setCurrentState(STATES.BURO_REJECTED);
  }, []);

  const goToUploadPdf = useCallback(() => {
    setCurrentState(STATES.UPLOAD_PDF);
  }, []);

  const goToWaitConsult = useCallback(() => {
    setCurrentState(STATES.WAIT_CONSULT);
  }, []);

  const goToConsultCreditShareholder = useCallback(() => {
    setCurrentState(STATES.CONSULT_CREDIT_SHAREHOLDER);
  }, []);

  const goToConsultInvoiceBussiness = useCallback(() => {
    setCurrentState(STATES.CONSULT_INVOICE_BUSSINESS);
  });

  const goToSetDataShareholder = useCallback(() => {
    setCurrentState(STATES.SET_DATA_SHAREHOLDER);
  }, []);

  const goToWaitInformation = useCallback(() => {
    setCurrentState(STATES.WAIT_INFORMATION);
  }, []);

  const goToLoaderPage = useCallback(() => {
    setCurrentState(STATES.LOADER_PAGE);
  }, []);

  const goToConsultPatrimonialRelationShip = useCallback(() => {
    setCurrentState(STATES.CONSULT_PATRIMONIAL_RELATIONSHIP);
  }, []);

  const goToSetDataLegalRepresentatives = useCallback(() => {
    setCurrentState(STATES.SET_DATA_LEGAL_REPRESENTATIVES);
  }, []);

  const result = useMemo(
    () => (
      {
        STATES,
        currentState,
        goToConsultCreditBussiness,
        goToConsultCreditShareholder,
        goToConsultInvoiceBussiness,
        goToConsultPatrimonialRelationShip,
        goToSetDataShareholder,
        goToWaitInformation,
        goToLoaderPage,
        goToUploadPdf,
        goToWaitConsult,
        goToConsultBuroRejected,
        goToSetDataLegalRepresentatives,
      }
    ),
    [
      currentState,
      goToConsultCreditBussiness,
      goToConsultCreditShareholder,
      goToConsultInvoiceBussiness,
      goToConsultPatrimonialRelationShip,
      goToSetDataShareholder,
      goToWaitInformation,
      goToLoaderPage,
      goToUploadPdf,
      goToWaitConsult,
      goToConsultBuroRejected,
      goToSetDataLegalRepresentatives,
    ],
  );

  return result;
};

export default useStateMachine;
