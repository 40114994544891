import { getLogoAndImageTemplate } from '../utils/themeStyles';
import { createInstanceNoToken, createInstance } from './config';

const BASE_URL = '/companyConfigs/company';

export const getCompany = async (id) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.get(`${BASE_URL}/${id}/modal`);
  return data;
};

export const getCompanyConfigByType = async (company, type) => {
  try {
    const axios = createInstance();
    const { data } = await axios.get(`${BASE_URL}/${company}/type/${type}`);
    return { ...data };
  } catch (e) {
    return null;
  }
};

export const getAllCompanyConfigs = async (id) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.get(`${BASE_URL}/${id}`);
  return data;
};

export const getCompanyAndType = (product) => getLogoAndImageTemplate(product);
