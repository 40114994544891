import * as fieldValueAPI from '../api/fieldValue';

import { CreditConditionsSimulator } from '../components/CreditConditionsSimulator';
import { ApoyoInfonavitSimulator } from '../containers/Infonavit/components/InfonavitSimulators/ApoyoInfonavitSimulator';

import {
  getAforo,
  getImporte,
  getMensualInfonavit,
  getMensualidad,
  getMeses,
  getMontoCreditoInfonavit,
  getPropietyValue,
  getSaldoSubcuentaInfonavit,
  getSueldoMensualBruto,
  getTipoPrestamoInfonavit,
} from '../utils/credit';
import { currencyFormatter, getProcess } from '../utils';

import {
  INFONAVIT_APOYO_INFONAVIT_TYPE,
  INFONAVIT_COFINAVIT_TYPE,
  INFONAVIT_FOVISSSTE_TYPE,
  SIMULATOR_EDIT_BUTTON_TEXT,
  IMPORTE_FIELD_ID,
  AFORO_FIELD_ID,
  PROPIEDAD_VALOR_FIELD_ID,
  CREDITO_HIPOTECARIO_INT_VALUES,
} from '../constants';
import { ApoyoInfonavitData, CofinavitData, FovisssteData } from '../containers/Infonavit/data';
import { FovisssteSimulator } from '../containers/Infonavit/components/InfonavitSimulators/FovisssteSimulator';
import { CofinavitSimulator } from '../containers/Infonavit/components/InfonavitSimulators/CofinavitSimulator';

const baseStrategy = {
  getInitialValues: () => CREDITO_HIPOTECARIO_INT_VALUES,
  getCreditValues: async () => ({
    propietyValue: currencyFormatter(getPropietyValue()),
    monthlyPaid: currencyFormatter(getMensualidad()),
    importe: currencyFormatter(getImporte()),
    meses: `${getMeses()} meses`,
    aforo: getAforo(),
  }),
  renderSimulator: ({ initialValues, onSubmit }) => (
    <CreditConditionsSimulator
      onSubmit={onSubmit}
      initialValues={initialValues}
      buttonTitle={SIMULATOR_EDIT_BUTTON_TEXT}
      isVivienda
      hidePlazo
      useDesembolsoMode
    />
  ),
};

const creditoHipotecarioStrategy = {
  ...baseStrategy,
  getCreditValues: async () => {
    const process = getProcess();
    const [
      propietyValue,
      monthlyPaid,
      importe,
      meses,
      aforo,
    ] = await Promise.all([
      fieldValueAPI.getFieldValueByProcessField(
        process,
        PROPIEDAD_VALOR_FIELD_ID,
      ),
      fieldValueAPI.getFieldValueByProcessField(
        process,
        '64e3bfa365dc58ed7b91ab1c',
      ),
      fieldValueAPI.getFieldValueByProcessField(
        process,
        IMPORTE_FIELD_ID,
      ),
      fieldValueAPI.getFieldValueByProcessField(
        process,
        '64b71fa1cbf8225d4573b07f',
      ),
      fieldValueAPI.getFieldValueByProcessField(
        process,
        AFORO_FIELD_ID,
      ),
    ]);
    return {
      propietyValue: propietyValue.value,
      monthlyPaid: monthlyPaid.value,
      importe: importe.value,
      meses: meses.value,
      aforo: aforo.value,
    };
  },
};

const viviendaStrategy = {
  ...baseStrategy,
  renderSimulator: ({ initialValues, onSubmit }) => (
    <CreditConditionsSimulator
      onSubmit={onSubmit}
      isVivienda
      initialValues={initialValues}
      buttonTitle={SIMULATOR_EDIT_BUTTON_TEXT}
    />
  ),
};
const infonavitStrategy = {
  getInitialValues: () => {
    const tipoInfonavit = getTipoPrestamoInfonavit();
    switch (tipoInfonavit) {
      case INFONAVIT_COFINAVIT_TYPE:
        return CofinavitData.INITIAL_VALUES;
      case INFONAVIT_FOVISSSTE_TYPE:
        return FovisssteData.INITIAL_VALUES;
      case INFONAVIT_APOYO_INFONAVIT_TYPE:
      default:
        return ApoyoInfonavitData.INITIAL_VALUES;
    }
  },
  getCreditValues: async () => ({
    propietyValue: currencyFormatter(getPropietyValue()),
    monthlyPaid: currencyFormatter(getMensualidad()),
    importe: currencyFormatter(getImporte()),
    meses: `${getMeses()} meses`,
    aforo: getAforo(),
    montoCreditoInfonavit: currencyFormatter(getMontoCreditoInfonavit() || 0),
    saldoSubcuentaInfonavit: currencyFormatter(getSaldoSubcuentaInfonavit() || 0),
    sueldoMensualBruto: currencyFormatter(getSueldoMensualBruto() || 0),
    montoMensualInfonavit: currencyFormatter(getMensualInfonavit() || 0),
  }),
  renderSimulator: ({ initialValues, onSubmit }) => {
    const tipoInfonavit = getTipoPrestamoInfonavit();
    switch (tipoInfonavit) {
      case INFONAVIT_COFINAVIT_TYPE:
        return (
          <CofinavitSimulator
            onSubmit={onSubmit}
            isVivienda={false}
            initialValues={initialValues}
            buttonTitle={SIMULATOR_EDIT_BUTTON_TEXT}
          />
        );
      case INFONAVIT_FOVISSSTE_TYPE:
        return (
          <FovisssteSimulator
            onSubmit={onSubmit}
            isVivienda={false}
            initialValues={initialValues}
            buttonTitle={SIMULATOR_EDIT_BUTTON_TEXT}
          />
        );
      case INFONAVIT_APOYO_INFONAVIT_TYPE:
      default:
        return (
          <ApoyoInfonavitSimulator
            onSubmit={onSubmit}
            isVivienda={false}
            initialValues={initialValues}
            buttonTitle={SIMULATOR_EDIT_BUTTON_TEXT}
          />
        );
    }
  },
};

const liquidezStrategy = {
  ...baseStrategy,
};

const inmobiliariaStrategy = {
  ...baseStrategy,
  renderSimulator: ({ initialValues, onSubmit }) => (
    <CreditConditionsSimulator
      onSubmit={onSubmit}
      initialValues={initialValues}
      buttonTitle={SIMULATOR_EDIT_BUTTON_TEXT}
      isVivienda
      useDesembolsoMode
    />
  ),
};

export const simulatorStrategies = {
  creditohipotecario: creditoHipotecarioStrategy,
  creditohipotecariovivienda: viviendaStrategy,
  creditohipotecarioinfonavit: infonavitStrategy,
  creditohipotecarioliquidez: liquidezStrategy,
  creditohipotecarioinmobiliaria: inmobiliariaStrategy,
};
