import { useState, useCallback, useMemo } from 'react';
import {
  APPLICATION_COMPLETED,
  PERFILAMIENTO_MODAL_COMPLETE,
  PROFILING_COMPLETED,
  PROFILING_MODAL,
} from '../../../../../constants';

export const STATES = {
  APPLICATION_COMPLETED,
  PROFILING_MODAL,
  PERFILAMIENTO_MODAL_COMPLETE,
  PROFILING_COMPLETED,
  LOADER_PAGE: 'LOADER_PAGE',
};

const useStateMachine = (_currentState = STATES.LOADER_PAGE) => {
  const [currentState, setCurrentState] = useState(_currentState);

  const goToProfilingModal = useCallback(() => {
    setCurrentState(STATES.PROFILING_MODAL);
  }, []);

  const goToProfilingCompleted = useCallback(() => {
    setCurrentState(STATES.PROFILING_COMPLETED);
  }, []);

  const goToLoaderPage = useCallback(() => {
    setCurrentState(STATES.LOADER_PAGE);
  }, []);

  const result = useMemo(
    () => (
      {
        STATES,
        currentState,
        goToProfilingModal,
        goToLoaderPage,
        goToProfilingCompleted,
      }
    ),
    [
      currentState,
      goToProfilingModal,
      goToLoaderPage,
      goToProfilingCompleted,
    ],
  );

  return result;
};

export default useStateMachine;
