export const isInputEmpty = (data) => !data || data.length < 1;

export const isNumber = (data) => !Number.isNaN(Number(data));

export const validateRegex = (text, reg) => reg.test(text);

export const getInputLabel = (label, required) => {
  if (!label) return null;
  if (!required) return label;
  return `${label}*`;
};
