import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import useStyles from './useStyles';
import infoStepsJson from '../../../../../data/Landing.json';

export const FAQ = () => {
  const classes = useStyles();
  const {
    FAQ: {
      title,
      questions,
    },
  } = infoStepsJson;
  return (
    <Box variant="section" className={classes.container}>
      <Box variant="div" className={classes.innerContainer}>
        <Box variant="div" className={classes.containerQuestion}>
          <Box variant="div" className={classes.header}>
            <Typography variant="h2" className={classes.title}>{title}</Typography>
          </Box>
          <Box variant="div" className={classes.questions}>
            {
              questions.map(({
                label,
                body,
              }) => (
                <Box variant="div" className={classes.question}>
                  <Typography variant="h2" className={classes.label}>{label}</Typography>
                  <Typography variant="h2" className={classes.body}>{body}</Typography>
                </Box>
              ))
            }
          </Box>
        </Box>
        <Box variant="div" className={classes.containerLink}>
          <NavLink
            to="/faq"
            className={classes.navLink}
          >
            Ir a preguntas frecuentes
          </NavLink>
        </Box>
      </Box>
    </Box>
  );
};
