import PropTypes from 'prop-types';

import { CreateStrategyProvider } from '../createStrategy';
import { FlowStrategyContext } from './flowStrategyContext';

export const FlowStrategyProvider = CreateStrategyProvider(FlowStrategyContext);

FlowStrategyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
