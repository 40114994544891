import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  header: {},
  headerTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    maxWidth: '800px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  headerSubTitle: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: 600,
    lineHeight: '1.25rem',
    fontSize: '1.125rem',
    margin: '15px auto 40px auto',
    [theme.breakpoints.up('sm')]: {
      margin: '30px auto 80px auto',
      maxWidth: '700px',
    },
  },
  innerContainer: {
    maxWidth: '600px',
    margin: '0 auto',
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  formContainer: {
    height: 'initial',
    overflowY: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'initial',
    },
  },
  title: {
    maxWidth: '630px',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '25px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
    },
  },
  button: {
    background: '#FFA500',
    borderRadius: '60px',
    textTransform: 'none',
    fontWeight: 700,
    color: '#000',
    fontFamily: '"Montserrat", "sans-serif"',
    fontSize: '16px',
    lineHeight: '1.2rem',
    padding: '16px 40px',
    '&:hover': {
      background: '#FFA500',
      opacity: '0.6',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      padding: '16px 60px',
    },
  },
}));
