// tabsProvider.js
import React, {
  useCallback, useMemo, useReducer, useState,
} from 'react';
import PropTypes from 'prop-types';
import { tabsReducer } from './tabsReducer';
import { INITIAL_TABS, UDATE_ACTIVE_TAB } from './constants';
import { TabsContext } from './tabsContext';
import { perfiladorCotizationStyles, perfiladorInmobiliariaStyles, perfiladorLandingStyles } from '../../containers/perfilador/styles';
import { PERFILADOR_VARIANTE_COTIZADOR_INMOBILIARIAS, PERFILADOR_VARIANTE_COTIZATION, PERFILADOR_VARIANTE_LANDING } from '../../constants';

const TABS_INITIAL_STATE = {
  tabs: [],
  active: '',
};

export const TabsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tabsReducer, TABS_INITIAL_STATE);
  const [tabsVariant, setTabsVariant] = useState('');
  const [tabsExtraClasses, setTabsExtraClasses] = useState({});

  const classesPerfiladorLanding = perfiladorLandingStyles();
  const classesPerfiladorInmobiliaria = perfiladorInmobiliariaStyles();
  const classesPerfiladorCotization = perfiladorCotizationStyles();

  const getExtraClasses = (variantType) => {
    switch (variantType) {
      case PERFILADOR_VARIANTE_LANDING:
        return classesPerfiladorLanding;
      case PERFILADOR_VARIANTE_COTIZADOR_INMOBILIARIAS:
        return classesPerfiladorInmobiliaria;
      case PERFILADOR_VARIANTE_COTIZATION:
        return classesPerfiladorCotization;
      default:
        return () => ({});
    }
  };

  const initTabs = useCallback((payload) => {
    const { variant = '' } = payload;
    const extraClasses = getExtraClasses(variant);
    setTabsVariant(variant);
    setTabsExtraClasses(extraClasses);
    dispatch({ type: INITIAL_TABS, payload });
  }, []);

  const updateActiveTab = useCallback((currentTab) => {
    dispatch({ type: UDATE_ACTIVE_TAB, payload: currentTab });
  }, []);

  const value = useMemo(() => ({
    state,
    tabsVariant,
    initTabs,
    setTabsVariant,
    tabsExtraClasses,
    setTabsExtraClasses,
    updateActiveTab,
  }), [
    state,
    tabsVariant,
    initTabs,
    setTabsVariant,
    tabsExtraClasses,
    setTabsExtraClasses,
    updateActiveTab,
  ]);

  return (
    <TabsContext.Provider value={value}>
      {children}
    </TabsContext.Provider>
  );
};

TabsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
