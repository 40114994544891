import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formContainer: {},
  viviendaInnerContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '750px',
    },
  },
  innerContainer: {
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
      width: '95%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  },
  firtInputElement: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
      margin: '0px 20px 0px 20px',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
  inputPrecio: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
    },
  },
  montos: {
    color: '#206BDF',
  },
  engancheInput: {
    width: '100%',
  },
  secondInputElement: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    margin: '60px 0px 60px 0px',
    [theme.breakpoints.up('md')]: {
      margin: '0px 0px 0px 0px',
      flex: 2,
      alignItems: 'flex-end',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
  precioContainer: {
    flex: 1,
    marginRight: '10px',
  },
  enganche: {
    textAlign: 'right',
    borderLeft: '1px solid #E9E9E9',
    paddingLeft: '5px',
  },
  invalidate: {
    color: 'red',
  },
  yearContainer: {
    flex: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: '10px',
      marginRight: '20px',
    },
  },
  thirdInputElement: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    borderTop: '3px solid #dedeeb',
    [theme.breakpoints.up('md')]: {
      borderTop: '0px',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
  hidden: {
    display: 'none',
  },
  borderRight: {
    borderRight: '3px solid #dedeeb',
    [theme.breakpoints.up('md')]: {
      borderRight: '2px solid #dedeeb',
      borderLeft: '2px solid #dedeeb',
    },
  },
  inputElement: {
    width: '100%',
  },
  element: {
    flex: 1,
    padding: '0px 0px 0px 0px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.up('md')]: {
      marginTop: '10px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  containerButton: {
    textAlign: 'center',
  },
  button: {
    marginTop: '70px',
    marginBottom: '50px',
    fontSize: '18px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000',
    backgroundColor: '#FFA500',
    padding: '20px 44px',
    textTransform: 'capitalize',
    borderRadius: '40px',
    '&:hover': {
      backgroundColor: '#FFA500',
      opacity: '0.6',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#FFA500',
      marginTop: '70px',
      marginBottom: '70px',
    },
  },
}));
