/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import useStyles from './useStyles';

export const FAQ = ({
  questions,
  title,
}) => {
  const classes = useStyles();
  return (
    <Box variant="section" className={classes.container}>
      <Box variant="div" className={classes.innerContainer}>
        <Box variant="div" className={classes.containerQuestion}>
          <Box variant="div" className={classes.header}>
            <Typography variant="h2" className={classes.title}>{title}</Typography>
          </Box>
          <Box variant="div" className={classes.questions}>
            {
              questions.map(({
                label,
                body,
              }) => (
                <Box variant="div" className={classes.question}>
                  <Typography variant="h2" className={classes.label}>{label}</Typography>
                  <Typography variant="h2" className={classes.body}>{body}</Typography>
                </Box>
              ))
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

FAQ.propTypes = {
  questions: PropTypes.array,
  title: PropTypes.string,
};

FAQ.defaultProps = {
  questions: [],
  title: '',
};
