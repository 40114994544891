import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSearchParams } from 'react-router-dom';
import { Loader } from '../../components';
import { getExpirationTime, handleRedirectLogout } from '../../utils';
import { LOGIN_ROUTE } from '../../constants';

export const CheckTokenUrl = ({
  children,
}) => {
  const [useLoginByToken, setUseLoginByToken] = useState(true);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const logout = async (redirection) => {
    try {
      await handleRedirectLogout(redirection);
    } catch (e) {
      setUseLoginByToken(false);
    }
  };

  const getRedirection = () => {
    const process = searchParams.get('process');
    if (!token || !process) return null;
    const { pathname } = window.location;
    const redirection = `${LOGIN_ROUTE}?process=${process}&token=${token}&pathname=${pathname}`;
    return redirection;
  };

  useEffect(() => {
    const redirection = getRedirection();
    if (!redirection) return;
    const expTime = getExpirationTime(token);
    if (!expTime) {
      setUseLoginByToken(false);
      return;
    }
    logout(redirection);
  }, []);

  return (token && useLoginByToken && <Loader />) || children;
};

CheckTokenUrl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
