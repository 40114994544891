/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import styles from './useStyles';
import { convertirAMoneda } from '../../../utils/currency';
// import { AmortizationTables } from '../../AmortizationTables';
import { IMAGE_PRODUCT_DISABLED } from '../../../constants';

const Bank = ({
  // id,
  imageUrl,
  monthlyPaid,
  tasa,
  check,
  cat,
  product,
  loanType = '',
  year = null,
  plazo = null,
  amortization = [],
  showCat,
  showProduct,
  disabled,
}) => {
  const classes = styles();
  return (
    <div className={classes.container}>
      {disabled && (
        <div className={classes.containerDisabled}>
          <img src={IMAGE_PRODUCT_DISABLED} alt="product_disabled" className={classes.imageProductDisabled} />
        </div>
      )}
      <div className={classes.header}>
        <div className={classes.imageContent}>
          <div className={classes.imageContainer}>
            <img className={classes.image} src={imageUrl} alt={imageUrl} />
          </div>
        </div>
        {check || showProduct ? (
          <div className={classes.productContainer}>
            <Typography className={classes.productTitle}>
              Producto
            </Typography>
            <Typography className={classes.product}>
              {product}
            </Typography>
          </div>
        ) : ''}
      </div>
      <div className={classes.dataContainer}>
        <div className={classes.monthly}>
          <Typography className={classes.mensualidad}>
            Mensualidad
          </Typography>
          <Typography className={classes.monthlyPaid}>
            {convertirAMoneda(monthlyPaid)}
          </Typography>
        </div>
        <div className={classes.tasaContainer}>
          <Typography className={classes.tasaInteres}>
            Tasa de interés
          </Typography>
          <Typography className={classes.tasa}>
            {`${tasa}%`}
          </Typography>
        </div>
        {
            check || showCat ? (
              <div className={classes.catContainer}>
                <Typography className={classes.catInteres}>
                  CAT
                </Typography>
                <Typography className={classes.cat}>
                  {`${cat}%`}
                </Typography>
              </div>
            ) : ''
          }
        {
            plazo ? (
              <div className={classes.plazoContainer}>
                <Typography className={classes.plazo}>
                  {`Con el ${loanType} terminarás de pagar en `}
                  <b>{`${plazo} años `}</b>
                  {`en lugar de ${year} años`}
                </Typography>
              </div>
            ) : ''
          }
        {
           /* amortization.length ? (
              <AmortizationTables bank={id} amortization={amortization} />
            ) : '' */
          }
      </div>
    </div>
  );
};

Bank.propTypes = {
  // id: PropTypes.string,
  imageUrl: PropTypes.string,
  monthlyPaid: PropTypes.number,
  tasa: PropTypes.number,
  check: PropTypes.bool,
  cat: PropTypes.string,
  product: PropTypes.string,
  loanType: PropTypes.string,
  year: PropTypes.number,
  plazo: PropTypes.number,
  amortization: PropTypes.array,
  showCat: PropTypes.bool,
  showProduct: PropTypes.bool,
  disabled: PropTypes.bool,
};

Bank.defaultProps = {
  // id: '',
  imageUrl: '',
  monthlyPaid: 0,
  tasa: 0,
  check: false,
  cat: '',
  product: '',
  loanType: '',
  year: null,
  plazo: null,
  amortization: [],
  showCat: false,
  showProduct: false,
  disabled: false,
};

export default memo(Bank);
