import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
  },
  banksContainer: {
    margin: theme.spacing(2, 2),
    background: '#FFFFFF',
    borderRadius: '25px',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 4),
    },
    padding: `0 ${theme.spacing(2)}px`,
  },
  tagTitle: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: '2rem',
    lineHeight: '3.125rem',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      paddingTop: '50px',
      paddingBottom: '40px',
    },
  },
  tagSubtitle: {
    color: '#000',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: '1.5rem',
    lineHeight: '2.125rem',
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
      paddingTop: '100px',
      paddingBottom: '10px',
      textAlign: 'center',
    },
  },
  carouselContainer: {
    '& .slick-slide': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .slick-dots': {
      backgroundColor: '#fff',
      borderRadius: '10px',
      margin: '0',
    },
    '& .slick-prev': {
      left: '-10px',
    },
    '& .slick-next': {
      right: '-10px',
    },
    backgroundColor: '#FFFFFF',
    borderRadius: '25px',
  },
  imgContainer: {
    padding: theme.spacing(1),
  },
  imgBanks: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default useStyles;
