import { getProcess } from './auth';
import {
  COMPANY,
  COMPANY_TYPE_FINISH_MODAL,
  CONSULTA_BURO_NAME,
  SATWS_COMPLETED,
} from '../constants';

import * as processAPI from '../api/process';
import * as companyConfigsAPI from '../api/companyConfigs';

export const createUpdateTag = async (updateTag) => {
  const process = getProcess();
  const { tags = {} } = await processAPI.getProcessById(process);
  const body = {
    [CONSULTA_BURO_NAME]: { ...tags[CONSULTA_BURO_NAME], ...updateTag },
  };
  await processAPI.createTag({
    tagName: CONSULTA_BURO_NAME,
    active: true,
    body: { ...tags, ...body },
    processId: process,
  });
};

export const createUpdateTagGeneral = async (updateTag, TAG_NAME, processId = '', token = '') => {
  const process = processId || getProcess();
  const { tags = {} } = await processAPI.getProcessById(process, token);
  const body = {
    [TAG_NAME]: { ...tags[TAG_NAME], ...updateTag },
  };
  await processAPI.createTag({
    tagName: TAG_NAME,
    active: true,
    body: { ...tags, ...body },
    processId: process,
    token,
  });
};

export const addNextProcess = async (oldProcess, otherProcess) => {
  const item = { $set: { otherProcess } };
  await processAPI.updateProcess(item, oldProcess);
};

export const crateSatWsCompletedTag = async () => {
  const process = getProcess();
  const { tags = {} } = await processAPI.getProcessById(process);
  if (tags[SATWS_COMPLETED]?.completed) return false;
  const body = {
    [SATWS_COMPLETED]: { completed: true },
  };
  await processAPI.createTag({
    tagName: SATWS_COMPLETED,
    active: true,
    body: { ...tags, ...body },
    processId: process,
  });
  return true;
};

const updateDatosDocumentos = async (config) => {
  const {
    currentPage,
    completed, name,
  } = config;
  await createUpdateTagGeneral({ currentPage, completed }, name);
  // await createUpdateTagGeneral({ completed: true, fecha: nowDate() }, processTagName);
};

export const execFinishTagConfigDatosDocumentos = async (normalizedName) => {
  const configs = await companyConfigsAPI.getCompanyConfigByType(
    COMPANY,
    COMPANY_TYPE_FINISH_MODAL,
  );
  if (configs && Object.keys(configs).length) {
    const { payload = {} } = configs;
    const { finish = {} } = payload[normalizedName] || payload.none;
    const finishPage = finish.normal;
    if (finishPage?.config) await updateDatosDocumentos(finishPage?.config || {});
  }
};
