/* eslint-disable import/no-extraneous-dependencies */
import ReactGA from 'react-ga4';

const login = () => ReactGA.event('login', { method: 'local' });

const test = () => ReactGA.event({
  action: '',
  category: 'precalificacion',
  label: '',
  value: 0,
});

const visitaChiplo = () => ReactGA.event({
  action: 'visitaChiplo',
  category: 'precalificacion',
});

const cotizacion = () => ReactGA.event({
  action: 'cotizacion',
  category: 'precalificacion',
});

const cotizacionBancos = () => ReactGA.event({
  action: 'cotizacionBancos',
  category: 'precalificacion',
});

const crearCuenta = () => ReactGA.event({
  action: 'crearCuenta',
  category: 'precalificacion',
});

const consultaBuro = () => ReactGA.event({
  action: 'consultaBuro',
  category: 'precalificacion',
});

const perfilamientoCrediticio = () => ReactGA.event({
  action: 'perfilamientoCrediticio',
  category: 'precalificacion',
});

const matrizPerfilamiento = (label) => ReactGA.event({
  action: 'matrizPerfilamiento',
  category: 'precalificacion',
  label,
});

const eleccionProducto = (label) => ReactGA.event({
  action: 'eleccionProducto',
  category: 'precalificacion',
  label,
});

const cargaInicio = () => ReactGA.event({
  action: 'cargaInicio',
  category: 'precalificacion',
});

const cargaDocumentos = () => ReactGA.event({
  action: 'cargaDocumentos',
  category: 'precalificacion',
});

const cargaDatos = () => ReactGA.event({
  action: 'cargaDatos',
  category: 'precalificacion',
});

const descargaSolicitud = () => ReactGA.event({
  action: 'descargaSolicitud',
  category: 'precalificacion',
});

const solicitudFirmada = () => ReactGA.event({
  action: 'solicitudFirmada',
  category: 'precalificacion',
});

const botonWhatsapp = () => ReactGA.event({
  action: 'botonWhatsapp',
  category: 'contacto',
});

export {
  login,
  test,
  visitaChiplo,
  cotizacion,
  cotizacionBancos,
  crearCuenta,
  consultaBuro,
  perfilamientoCrediticio,
  matrizPerfilamiento,
  eleccionProducto,
  cargaInicio,
  cargaDocumentos,
  cargaDatos,
  descargaSolicitud,
  solicitudFirmada,
  botonWhatsapp,
};
