/* eslint-disable no-return-assign */
import { useEffect, useState, useMemo } from 'react';
import * as clientApi from '../../../api/clients';
import { COMPANY } from '../../../constants';
import { getGroupedData, convertStructure } from '../utils/convertData';

export default function useSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [clients, setClients] = useState([]);
  const [totalPages, setTotalPages] = useState(null);

  const groupedClients = useMemo(() => getGroupedData(clients), [clients, getGroupedData]);

  useEffect(() => {
    setClients([]);
  }, [query]);

  const getTotalPages = async () => {
    if (!totalPages) {
      const newToTalPages = await clientApi.getClientsAgentAssignedPageSize(COMPANY, 25);
      setTotalPages(newToTalPages);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError(false);
    try {
      await getTotalPages();
      const clientsData = await clientApi.getClientsAgentAssigned(COMPANY, query, pageNumber);
      const newClientsData = await convertStructure(clientsData);
      setClients((prev) => [...prev, ...newClientsData]);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [query, pageNumber]);

  return {
    loading,
    error,
    clients: groupedClients,
    totalPages,
    hasMore: pageNumber < totalPages,
  };
}
