import {
  CHIPLO_HEROKU_PRODUCTION_HOSTNAME,
  CHIPLO_HOSTNAME_PRODUCTION,
  CHIPLO_HOSTNAME_TEST,
  DOCUPASS_PORTAL_SITE_LOCAL_URL,
  DOCUPASS_PORTAL_SITE_PRODUCTION_URL,
  DOCUPASS_PORTAL_SITE_TEST_URL,
  LOCALHOST_NAME,
} from '../constants/commonUrls';

export const getFirstPathSegment = (pathString) => `/${pathString.split('/')[1]}`;

export const getURLToPortal = () => {
  switch (window.location.hostname) {
    case CHIPLO_HOSTNAME_PRODUCTION:
    case CHIPLO_HEROKU_PRODUCTION_HOSTNAME:
      return DOCUPASS_PORTAL_SITE_PRODUCTION_URL;
    case CHIPLO_HOSTNAME_TEST:
      return DOCUPASS_PORTAL_SITE_TEST_URL;
    case LOCALHOST_NAME:
    default:
      return DOCUPASS_PORTAL_SITE_LOCAL_URL;
  }
};
