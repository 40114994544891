/* eslint-disable max-len */

import * as companyConfigsApi from '../api/companyConfigs';
import * as flowAPI from '../api/flow';
import * as flowProcessAPI from '../api/flowProcess';
import * as processAPI from '../api/process';

import {
  COMPANY, TAGS_TYPE, FACTORAJE_MEGA_CLICK, FACTORAJE_EJERCER,
  FACTORAJE_PAGE_NAME,
} from '../constants';

import { getProcess } from './auth';
import { getStepByStepPosition, getStepData, storeStepData } from './stepper';

const normalizeText = (text) => text
  .normalize('NFD')
  .replace(/\p{Diacritic}/gu, '')
  .toLowerCase()
  .replace(/ /g, '');

const getMergedProcessTags = (processTags, configTags) => {
  const tagsArray = Object.keys(processTags)
    .map((key) => processTags[key]).filter(({ name }) => name);
  const tagNamesArray = configTags.map(({ name }) => name);
  const currentTagsArrayNames = tagsArray.map(({ name }) => name);
  const untrackedTags = tagsArray.filter(({ name }) => !tagNamesArray.includes(name));
  const tagsToUse = currentTagsArrayNames.map((name) => {
    if (untrackedTags?.length) {
      const tagTU = untrackedTags.find(({ name: untrackedName }) => untrackedName === name);
      if (tagTU) return tagTU;
    }
    return tagsArray.find(({ name: tagN }) => tagN === name);
  });
  return tagsToUse;
};

export const getTheCurrentStep = async (processId, stepName) => {
  const { flow: flowId } = await flowProcessAPI.getFlowProcessById(processId);
  const { tags = {} } = await processAPI.getProcessById(processId);
  const flowFound = await flowAPI.getFlowById(flowId);
  const normalizedName = normalizeText(flowFound.name);
  const companyConfigs = await companyConfigsApi.getAllCompanyConfigs(COMPANY);
  const { payload } = companyConfigs.find(({ type }) => type._id === TAGS_TYPE);
  const configTags = payload[normalizedName];
  const tagsToUse = getMergedProcessTags(tags, configTags);

  return tagsToUse.find(({ name }) => name === stepName) || {};
};

export const getDataProcess = async () => {
  const process = getProcess();
  const processObject = await processAPI.getProcessById(process);
  const { product: productProcess, tags } = processObject;
  const { _id = '' } = productProcess;
  return { productId: _id, tags };
};

export const getFlowTypeHandlerByOldProcess = async (processId) => {
  const { flow: flowId } = await flowProcessAPI.getFlowProcessById(processId);
  let flowFound = await flowAPI.getFlowById(flowId);
  let normalizedName = normalizeText(flowFound.name);
  if (normalizedName === FACTORAJE_MEGA_CLICK) {
    normalizedName = FACTORAJE_EJERCER;
    const flows = await flowAPI.getFlowsByCompany(COMPANY);
    flowFound = flows.find(({ name }) => normalizeText(name) === normalizedName);
  }
  return { flow: flowFound, normalizedFlowName: normalizedName };
};

export const getFlowTypeHandler = async (processId, token) => {
  const { flow: flowId } = await flowProcessAPI.getFlowProcessById(processId, token);
  let flowFound = await flowAPI.getFlowById(flowId, token);
  const normalizedName = normalizeText(flowFound.name);
  const flows = await flowAPI.getFlowsByCompany(COMPANY, token);
  flowFound = flows.find(({ name }) => normalizeText(name) === normalizedName);
  return { flow: flowFound, normalizedFlowName: normalizedName };
};

export const getFlowByProcess = async (processId) => {
  const { flow: flowId } = await flowProcessAPI.getFlowProcessById(processId);
  const flowFound = await flowAPI.getFlowById(flowId);
  return { flow: flowFound };
};

const createStepperTagsStructure = (processTags, configTags) => configTags.map(({
  label, completed, route, name,
  redirectPath, stepPosition,
}) => (
  {
    label: processTags[name]?.label || label,
    completed: processTags[name]?.completed || completed,
    route: processTags[name]?.route || route,
    name: processTags[name]?.name || name,
    redirectPath: processTags[name]?.redirectPath || redirectPath,
    stepPosition: processTags[name]?.stepPosition || stepPosition,
  }
));

export const getStepperTags = async (normalizedFlowName, process) => {
  const { tags = {} } = await processAPI.getProcessById(process);

  const companyConfigs = await companyConfigsApi.getAllCompanyConfigs(COMPANY);
  const { payload } = companyConfigs.find(({ type }) => type._id === TAGS_TYPE);
  const configTags = payload[normalizedFlowName];
  const tagsToUse = getMergedProcessTags(tags, configTags);
  const tagsStructured = createStepperTagsStructure(tags, tagsToUse);
  return { steps: tagsStructured, current: 0 };
};

export const getConfigs = async (configType) => {
  const companyConfigs = await companyConfigsApi.getAllCompanyConfigs(COMPANY);
  const { payload } = companyConfigs.find(({ type }) => type._id === configType);
  return payload;
};

export const getConfigTags = async (normalizedFlowName) => {
  const companyConfigs = await companyConfigsApi.getAllCompanyConfigs(COMPANY);
  const { payload } = companyConfigs.find(({ type }) => type._id === TAGS_TYPE);
  const tags = payload[normalizedFlowName];
  return tags;
};
// getFlowTypeHandler, createTags, getStepperTags,

export const convertConfigTagsToObject = (configTags) => configTags.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {});

export const getObjectTags = async (normalizedFlowName) => {
  const configTags = await getConfigTags(normalizedFlowName);
  const configTagObject = convertConfigTagsToObject(configTags);
  return configTagObject;
};

const completedTags = (configTags, completeTag) => {
  const { stepPosition = -1 } = configTags.find(({ name }) => name === completeTag) || {};
  if (stepPosition === -1) return configTags;
  return configTags.map((configTag) => (configTag.stepPosition <= stepPosition ? { ...configTag, completed: true } : configTag));
};

export const createTags = async (process, normalizedFlowName, completeTag, token) => {
  let configTags = await getConfigTags(normalizedFlowName);
  if (completeTag) configTags = completedTags(configTags, completeTag);
  const configTagObject = convertConfigTagsToObject(configTags);
  const { tags = {} } = await processAPI.getProcessById(process, token);
  const mergeTags = { ...configTagObject, ...tags };
  const body = mergeTags;
  await processAPI.createTag({
    active: true, body, processId: process, tagName: FACTORAJE_PAGE_NAME, token,
  });
  return body;
};

export const getActualStep = (stepperTags) => {
  const actualIndexStep = stepperTags.findIndex(({ completed }) => !completed);
  return { stepRoute: stepperTags[actualIndexStep].route, currentStep: actualIndexStep };
};

export const getTags = async (process, newNormalizedFlowName) => {
  const { steps } = await getStepperTags(newNormalizedFlowName, process);
  const actualStep = getActualStep(steps);
  return { steps, current: 0, actualStep };
};

export const getTagsStepsByLocalSteps = () => {
  const stepData = getStepData();
  const newTags = convertConfigTagsToObject(stepData.steps);
  return newTags;
};

export const getAndSaveLocalTagsInProcess = async (process, token) => {
  const localTags = getTagsStepsByLocalSteps();
  const { tags = {} } = await processAPI.getProcessById(process, token);
  const mergeTags = { ...localTags, ...tags };
  const body = mergeTags;
  await processAPI.createTag({
    active: true, body, processId: process, tagName: FACTORAJE_PAGE_NAME, token,
  });
  return body;
};

export const setCustomDefaultTagsInProcess = async (tagNameConfig, processId, token) => {
  const { tags = {} } = await processAPI.getProcessById(processId, token);
  const tagsToMerge = await getConfigTags(tagNameConfig);
  const configTagObject = convertConfigTagsToObject(tagsToMerge);
  const mergeTags = { ...configTagObject, ...tags };
  const body = mergeTags;
  await processAPI.createTag({
    active: true, body, processId, tagName: FACTORAJE_PAGE_NAME, token,
  });
  return body;
};

export const getPageTagStepsByLocalSteps = (tagName, stepPosition) => {
  const newTags = getTagsStepsByLocalSteps();
  const currentStepData = getStepByStepPosition(stepPosition);
  const { redirectPath } = currentStepData;
  const consultTag = newTags[tagName];
  return {
    tags: newTags, stepPosition, redirectPath, consultTag,
  };
};

export const updateLocalStepsByStep = (currentStepPosition) => {
  const stepsData = getStepData();
  const { steps } = stepsData;

  const newSteps = steps.map((step, index) => (
    index === currentStepPosition ? { ...step, completed: true } : step
  ));

  const newStepData = { stepRoute: steps[currentStepPosition + 1].route, currentStep: currentStepPosition + 1, steps: newSteps };

  storeStepData(newStepData);
  return newStepData;
};

export const updateCurrentPageOnLocalStep = (stepToUpdate, currentPage) => {
  const stepsData = getStepData();
  const { steps } = stepsData;
  const newSteps = steps.map((step, index) => (
    index === stepToUpdate ? { ...step, currentPage } : step
  ));

  const newStepData = { ...stepsData, steps: newSteps };
  storeStepData(newStepData);
};

export const findActualStepByHighestStepCompleted = (arraySteps) => {
  const highestStep = arraySteps.reduce((acc, curr) => {
    if (curr.completed && curr.stepPosition > acc.stepPosition) {
      return curr;
    }
    return acc;
  });
  const nextStepPosition = highestStep.stepPosition + 1;
  const currentStep = arraySteps.find((item) => item.stepPosition === nextStepPosition);
  return {
    stepRoute: currentStep.route,
    currentStep: currentStep.stepPosition,
  };
};

export const sortStepsArrayByStepPosition = (array) => array.sort((a, b) => a.stepPosition - b.stepPosition);

export const updateCurrentPagePerfilamientoTag = (tags, currentPage) => ({
  ...tags,
  perfilamiento: { ...tags?.perfilamiento, currentPage },
});

export const updateCompletedStepsByNewCurrentStep = (newCurrentStep) => {
  const stepsData = getStepData();
  const { steps } = stepsData;
  const newSteps = steps.map((step) => {
    const { stepPosition: stepPositionItem } = step;
    return {
      ...step,
      completed: stepPositionItem < newCurrentStep,
    };
  });
  const newStepsData = {
    steps: newSteps,
    currentStep: newCurrentStep,
    stepRoute: newSteps[newCurrentStep].route,
  };
  storeStepData(newStepsData);
};
