import { useState, useCallback, useMemo } from 'react';

export const STATES = {
  FORMALIZATION_MODAL: 'FORMALIZATION_MODAL',
  FORMALIZATION_MIFIEL: 'FORMALIZATION_MIFIEL',
  FORMALIZATION_ACCEPT_PRICE: 'FORMALIZATION_ACCEPT_PRICE',
  WAIT_INFORMATION: 'WAIT_INFORMATION',
  LOADER_PAGE: 'LOADER_PAGE',
};

const useStateMachine = (_currentState = STATES.LOADER_PAGE) => {
  const [currentState, setCurrentState] = useState(_currentState);

  const goToFormalizationModal = useCallback(() => {
    setCurrentState(STATES.FORMALIZATION_MODAL);
  }, []);

  const goToWaitInformation = useCallback(() => {
    setCurrentState(STATES.WAIT_INFORMATION);
  }, []);

  const goToLoaderPage = useCallback(() => {
    setCurrentState(STATES.LOADER_PAGE);
  }, []);

  const goToAcceptPricePage = useCallback(() => {
    setCurrentState(STATES.FORMALIZATION_ACCEPT_PRICE);
  }, []);

  const result = useMemo(
    () => (
      {
        STATES,
        currentState,
        goToFormalizationModal,
        goToLoaderPage,
        goToWaitInformation,
        goToAcceptPricePage,
      }
    ),
    [
      currentState,
      goToFormalizationModal,
      goToLoaderPage,
      goToWaitInformation,
      goToAcceptPricePage,
    ],
  );

  return result;
};

export default useStateMachine;
