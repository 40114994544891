import axios from 'axios';
import * as AuthUtils from '../utils/auth';

export const getBaseUrl = () => {
  switch (window.location.hostname) {
    case 'broker-production-464cd84fa52e.herokuapp.com':
    case 'www.chiplo.com':
      return 'https://fintecimal-nodos.herokuapp.com/api';
    case 'broker-test-464cd84fa52e.herokuapp.com':
      return 'https://fintecimal-nodos-test.herokuapp.com/api';
    default:
      // return 'https://fintecimal-nodos.herokuapp.com/api';
      // return 'https://fintecimal-nodos-test.herokuapp.com/api';
      // return 'http://localhost:1337/api';
      return process.env.REACT_APP_BASE_URL;
  }
};

export const createInstance = (params = { checkToken: false, token: '' }) => {
  const { checkToken, token } = params;
  let newToken = token || AuthUtils.getToken() || '';
  if (checkToken) {
    if (!newToken) {
      newToken = token;
      AuthUtils.storeAuth({ token });
    }
  }
  const authorizationHeader = newToken ? { Authorization: `Token ${newToken}` } : {};

  const axiosInstance = axios.create({
    baseURL: getBaseUrl(),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'RD2DAA4-W29MJGZ-PD7WPP6-8892M9D',
      ...authorizationHeader,
    },
    validateStatus: (status) => (
      status >= 200 && status < 300
    ),
  });

  return axiosInstance;
};

export const createInstanceNoToken = () => {
  const axiosInstance = axios.create({
    baseURL: getBaseUrl(),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'RD2DAA4-W29MJGZ-PD7WPP6-8892M9D',
    },
    validateStatus: (status) => (
      status >= 200 && status < 300
    ),
  });

  return axiosInstance;
};

export default createInstance();
