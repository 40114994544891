import { useState, useCallback, useMemo } from 'react';

const useEnabler = (initialValue = false) => {
  const [isEnabled, setIsEnabled] = useState(initialValue);

  const enable = useCallback(() => {
    setIsEnabled(true);
  }, []);

  const disable = useCallback(() => {
    setIsEnabled(false);
  }, []);

  const toggle = useCallback(() => {
    setIsEnabled(!isEnabled);
  }, [isEnabled]);

  const result = useMemo(
    () => (
      {
        isEnabled,
        enable,
        disable,
        toggle,
      }
    ),
    [
      isEnabled,
      enable,
      disable,
      toggle,
    ],
  );

  return result;
};

export default useEnabler;
