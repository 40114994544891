import { createInstance } from './config';

const BASE_URL = '/flow';

export const getFlowsByCompany = async (companyId, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.get(`${BASE_URL}/company/${companyId}`);
  return data;
};

export const getFlowById = async (id, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.get(`${BASE_URL}/${id}`);
  return data;
};
