/* eslint-disable import/no-extraneous-dependencies */
import cryptoJs from 'crypto-js';
import { DEVLOPMENT_ENV } from '../constants';

export const encryptData = (data) => {
  const {
    REACT_APP_SECRET_KEY, REACT_APP_ENVIRONMENT,
  } = process.env;
  if (REACT_APP_ENVIRONMENT === DEVLOPMENT_ENV) return data;
  const ciphertext = cryptoJs.AES.encrypt(data, REACT_APP_SECRET_KEY).toString();
  return ciphertext;
};

export const decryptData = (data) => {
  const {
    REACT_APP_SECRET_KEY, REACT_APP_ENVIRONMENT,
  } = process.env;
  if (REACT_APP_ENVIRONMENT === DEVLOPMENT_ENV) return data;
  const bytes = cryptoJs.AES.decrypt(data, REACT_APP_SECRET_KEY);
  const originalText = bytes.toString(cryptoJs.enc.Utf8);
  return originalText;
};
