import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import infoStepsJson from '../../../../../data/Landing.json';
import useStyles from './useStyles';
import { WhatsappButton } from '../../../../../components/WhatsappButton';

export const FooterInfoImages = () => {
  const {
    FooterImages: {
      title,
      body,
      image,
      buttonTitle,
      alt,
    },
  } = infoStepsJson;
  const classes = useStyles();
  return (
    <Box component="section" className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box
          className={clsx(classes.item, classes.itemBackground)}
        >
          <Box className={clsx(
            classes.innerItem,
            classes.marginContainer,
            classes.displayFlexbox,
          )}
          >
            <Typography variant="h2" component="h2" className={classes.title}>{title}</Typography>
            <Typography variant="body1" component="p" className={classes.body}>{body}</Typography>
            <Box className={classes.containerButton}>
              <WhatsappButton>{buttonTitle}</WhatsappButton>
            </Box>
          </Box>
        </Box>
        <Box className={classes.item}>
          <Box className={clsx(classes.innerItem, classes.imageContainer)}>
            <Box component="img" src={image} alt={alt} className={classes.image} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
