import { createInstance } from './config';

const BASE_URL = '/matrizPerfilamiento';

export const execMatrizPerfilamiento = async (body, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}`, body);
  return data;
};

export const sendMatrizPerfilamientoEmail = async ({
  leadId, company, body, token = '',
}) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}/company/${company}/lead/${leadId}/sendEmail`, body);
  return data;
};

export const execMatrizPerfilamientoMultiOptions = async (body, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}/multiOptions`, body);
  return data;
};
