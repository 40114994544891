import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
  },
  sliderContainer: {
    margin: theme.spacing(2, 2),
    background: '#FFFFFF',
    borderRadius: '25px',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 4),
    },
    padding: `0 ${theme.spacing(2)}px`,
  },
  title: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: '2rem',
    lineHeight: '3.125rem',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      paddingTop: '50px',
      paddingBottom: '40px',
    },
  },
  tagSubtitle: {
    color: '#000',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: '1.5rem',
    lineHeight: '2.125rem',
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
      paddingTop: '20px',
      paddingBottom: '10px',
      textAlign: 'center',
    },
  },
  carouselContainer: {
    '& .slick-slide': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .slick-dots': {
      backgroundColor: '#fff',
      borderRadius: '10px',
      margin: '0',
    },
    '& .slick-prev': {
      left: '-10px',
    },
    '& .slick-next': {
      right: '-10px',
    },
    '& .slick-track': {
      display: 'flex',
      alignItems: 'center',
    },
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(3)}px 0px`,
    },
  },
  imgContainer: {
    width: '85px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      height: '100px',
    },
    [theme.breakpoints.up('md')]: {
      width: '300px',
      height: '150px',
    },
  },
  img: {
    maxWidth: '80%',
    maxHeight: '80%',
    objectFit: 'contain',
  },
  carousel: {
    '& .alice-carousel__stage': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .alice-carousel__stage-item': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
