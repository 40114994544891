/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import {
  Container,
  Typography,
  Slider as SliderMaterial,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import useStyles from './useStyles';

import { currencyFormatter } from './formatter';

const DEFAULT_STEPS = 50000;

export const Slider = ({
  lowestValue,
  highestValue,
  defaultValue,
  name,
  onChange,
  alternClass,
}) => {
  const [value, setValue] = useState(defaultValue);

  const classes = useStyles();

  const valueFormatted = useMemo(
    () => currencyFormatter(value, '$0', 'MXN'),
    [value, currencyFormatter],
  );

  const sliderMarks = useMemo(() => [
    {
      _id: 1,
      key: 1,
      value: lowestValue,
      label: currencyFormatter(lowestValue, '$0', 'MXN'),
    },
    {
      _id: 2,
      key: 2,
      value: highestValue,
      label: currencyFormatter(highestValue, '$0', 'MXN'),
    },
  ], [lowestValue, highestValue, currencyFormatter]);

  const handleSliderValue = (event, newValue) => {
    setValue(newValue);
    onChange({ ...event, target: { ...event.target, name } }, newValue.toString());
  };

  useEffect(() => {

  }, [lowestValue, highestValue]);

  return (
    <Container disableGutters maxWidth={false} className={classes.container}>
      <Typography
        component="h3"
        className={alternClass ? classes.secSliderValue : classes.sliderValue}
      >
        {valueFormatted}
      </Typography>
      <Container
        component="div"
        disableGutters
        maxWidth={false}
        className={classes.sliderContainer}
      >
        <SliderMaterial
          value={value}
          name={name}
          onChange={handleSliderValue}
          defaultValue={defaultValue}
          aria-labelledby="discrete-slider-always"
          step={DEFAULT_STEPS}
          marks={sliderMarks}
          max={highestValue}
          min={lowestValue}
          classes={{
            markLabel: classes.sliderMarkLabel,
            thumbColorPrimary: alternClass ? classes.alternthumb : classes.thumb,
            track: alternClass ? classes.alterntrack : classes.track,
            rail: alternClass ? classes.alternrail : classes.rail,
          }}
        />
        <Box className={classes.marksContainer}>
          <Typography variant="body1" component="span" className={classes.markLabel}>{sliderMarks[0].label}</Typography>
          <Typography variant="body1" component="span" className={classes.markLabel}>{sliderMarks[1].label}</Typography>
        </Box>
      </Container>
    </Container>
  );
};

Slider.propTypes = {
  lowestValue: PropTypes.number,
  highestValue: PropTypes.number,
  defaultValue: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  alternClass: PropTypes.bool,
};

Slider.defaultProps = {
  lowestValue: 0,
  highestValue: 0,
  defaultValue: 0,
  name: '',
  onChange: () => { },
  alternClass: false,
};
