import { makeStyles } from '@material-ui/core/styles';

export const perfiladorInmobiliariaStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#FFF',
  },
  btn: {
    fontSize: '18px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000',
    backgroundColor: '#FFA500',
    borderRadius: '40px',
    padding: '20px 44px',
  },
}));
