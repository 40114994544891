/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Box, Button, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './useStyles';
import { useSimulator } from '../../../../hooks/useSimulator';

const FIRST_INPUT_ELEMENT = 'FIRST_INPUT_ELEMENT';
const SECOND_INPUT_ELEMENT = 'SECOND_INPUT_ELEMENT';
const THIRD_INPUT_ELEMENT = 'THIRD_INPUT_ELEMENT';

export const FRAME_CLASSES = {
  FIRST_INPUT_ELEMENT,
  SECOND_INPUT_ELEMENT,
  THIRD_INPUT_ELEMENT,
};

const FRAME = 'FRAME';
const NONE = 'NONE';

export const FRAME_TYPES = {
  FRAME,
  NONE,
};

export const isGreaterThanValue = (inputValue, limitValue, errorMessage) => {
  const isValid = inputValue > limitValue;
  return { isValid, errorMessage };
};
export const isValidPercentage = (iValue, precioValue, limitValue, errorMessage) => {
  const isValid = parseInt(((iValue / precioValue) * 100).toFixed(0), 10) >= limitValue;
  return { isValid, errorMessage };
};

export default function Simulator({
  initialData,
  tasas,
  TASA,
  onSubmit,
  children,
}) {
  const classes = styles();
  const {
    prestamoValue,
    percentage,
    monthlyPay,
    currentYear,
    values,
    handleChange,
  } = useSimulator({
    initialData,
    tasas,
    TASA,
  });
  return (
    <Container
      component="form"
      onSubmit={(event) => onSubmit({
        event,
        values,
        prestamoValue,
        percentage,
        monthlyPay,
        currentYear,
      })}
      method="post"
      autoComplete="off"
      className={classes.formContainer}
      disableGutters
      maxWidth={false}
    >
      {children({
        prestamoValue,
        percentage,
        monthlyPay,
        currentYear,
        values,
        handleChange,
      })}
    </Container>
  );
}

Simulator.propTypes = {
  initialData: PropTypes.object,
  tasas: PropTypes.object,
  TASA: PropTypes.number,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Simulator.defaultProps = {
  initialData: {},
  tasas: {},
  TASA: 0,
  onSubmit: () => { },
  children: '',
};

Simulator.Frame = function SimulatorFrame({
  children,
  classLabel,
  variant,
  frameClass,
}) {
  const classes = styles();
  const MAPPING_CLASSES = {
    FIRST_INPUT_ELEMENT: classes.firtInputElement,
    SECOND_INPUT_ELEMENT: classes.secondInputElement,
    THIRD_INPUT_ELEMENT: classes.thirdInputElement,
  };
  return (
    <>
      {
        (
          () => {
            switch (variant) {
              case FRAME:
                return (
                  <Box className={classLabel || MAPPING_CLASSES[frameClass] || ''}>
                    {children}
                  </Box>
                );
              case NONE:
              default:
                return <>{children}</>;
            }
          }
        )()
      }
    </>
  );
};

Simulator.Frame.propTypes = {
  frameClass: PropTypes.string,
  variant: PropTypes.string,
  classLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Simulator.Frame.defaultProps = {
  frameClass: '',
  variant: '',
  classLabel: '',
  children: '',
};

Simulator.InnerContainer = function SimulatorInnerContainer({ children, isVivienda }) {
  const classes = styles();
  return (
    <Box className={clsx(classes.innerContainer, {
      [classes.viviendaInnerContainer]: isVivienda,
    })}
    >
      {children}
    </Box>
  );
};

Simulator.InnerContainer.propTypes = {
  isVivienda: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Simulator.InnerContainer.defaultProps = {
  isVivienda: false,
  children: '',
};

Simulator.Button = function SimulatorButton({ buttonTitle }) {
  const classes = styles();
  return (
    <Box className={classes.containerButton}>
      <Button
        className={classes.button}
        variant="contained"
        type="submit"
      >
        {buttonTitle}
      </Button>
    </Box>
  );
};

Simulator.Button.propTypes = {
  buttonTitle: PropTypes.string,
};

Simulator.Button.defaultProps = {
  buttonTitle: '',
};
