/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import InputCURP from '../Input/InputCURP';
import InputCLABE from '../Input/InputCLABE';
import SeletBase from '../Input/SeletBase';
import InputRFC from '../Input/InputRFC';
import InputNumber from '../Input/InputNumber';
import InputEmail from '../Input/InputEmail';
import InputPhone from '../Input/InputPhone';
import InputRejected from '../Input/InputRejected';
import InputBaseDatePicker from '../Input/InputBaseDatePicker';
import InputCurrency from '../Input/InputCurrency';

const TYPES = {
  TEXT: 'text',
  RESPUESTA_CORTA: 'respuesta corta',
  TEXT_AREA: 'textarea',
  RESPUESTA_LARGA: 'respuesta larga',
  EMAIL: 'email',
  NUMBER: 'number',
  CANTIDAD: 'cantidad',
  NUMERO: 'número',
  PASSWORD: 'password',
  RFC: 'rfc',
  CLABE: 'clabe',
  CURP: 'curp',
  CURRENCY: 'currency',
  PHONE: 'phone',
  CELULAR: 'celular',
  SELECT: 'select',
  LISTA: 'lista',
  DATE_PICKER: 'datepicker',
  FECHA: 'fecha',
  AUTOCOMPLETE: 'autocomplete',
  ADDRESS: 'address',
  CHECKBOX: 'checkbox',
  LAST_DIGITS: 'lastdigits',
  INPUT_TABLE: 'Input table signers',
};

export const CustomField = ({ type, ...props }) => {
  switch (type) {
    case 'CURP':
      return <InputCURP {...props} />;
    case 'CLABE':
      return <InputCLABE {...props} />;
    case 'lista':
      return <SeletBase {...props} />;
    case 'RFC':
      return <InputRFC {...props} />;
    case 'cantidad':
    case 'number':
    case 'número':
      return <InputNumber {...props} />;
    case 'radio':
    case 'email':
      return <InputEmail {...props} />;
    case 'celular':
      return <InputPhone {...props} type={TYPES.TEXT} multiline />;
    case 'respuesta larga':
      return <InputRejected {...props} />;
    case 'fecha':
      return <InputBaseDatePicker {...props} />;
    case 'currency':
      return <InputCurrency {...props} />;
    case 'respuesta corta':
    default:
      return <InputRejected {...props} type={TYPES.TEXT} />;
  }
};

CustomField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.instanceOf(undefined),
  ]),
  handleChange: PropTypes.func,
};
