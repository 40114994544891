/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '../../Button';
import useStyles from './useStyles';
import InputRFC from '../InputRFC';

const InputArrayText = ({
  value,
  onChange,
  ...props
}) => {
  const [values, setValues] = useState([]);
  const classes = useStyles();

  const updateValues = (event, newValues) => {
    setValues(newValues);
    const vals = newValues.reduce((acc, curr) => ([
      ...acc, curr.value,
    ]), []);
    onChange(event, vals);
  };

  const onChangeValue = (event, newValue, index) => {
    const newValues = values.map((newVal, idx) => (index === idx ? { ...newVal, value: newValue } : newVal));
    updateValues(event, newValues);
  };

  const onDeleteValue = (event, index) => {
    const newValues = values.filter((newVal, idx) => index !== idx);
    updateValues(event, newValues);
  };

  const onAdd = () => {
    const newValue = {
      ...props,
      value: '',
    };
    setValues((oldVal) => [...oldVal, newValue]);
  };

  const setFieldValues = () => {
    if (value && Array.isArray(value)) {
      const newFields = value.map((val) => ({
        ...props,
        value: val,
      }));
      setValues(newFields);
    } else {
      onAdd();
    }
  };

  useEffect(() => {
    setFieldValues();
  }, []);
  return (
    <>
      {
        values.map((val, index) => (
          <div className={classes.containerInput}>
            <InputRFC
              {...val}
              onChange={(event, newValue) => onChangeValue(event, newValue, index)}
              endAdornment={(
                <IconButton
                  className={classes.iconButton}
                  onClick={(event) => onDeleteValue(event, index)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            />
          </div>
        ))
      }
      <div className={classes.buttonContainer}>
        <Button
          onClick={onAdd}
          className={classes.button}
          variant="outlined"
        >
          Agregar otro deudor
        </Button>
      </div>
    </>
  );
};

InputArrayText.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

InputArrayText.defaultProps = {
  value: [],
};

export default memo(InputArrayText);
