import { createInstance } from './config';

const BASE_URL = '/fieldValues';

// /api/fieldValues/process/:processId

export const getFieldValueByProcessField = async (process, field, showMessageError = false) => {
  try {
    const axios = createInstance();
    const { data } = await axios.get(`${BASE_URL}/process/${process}/field/${field}`, {
      params: {
        showMessageError,
      },
    });
    return data;
  } catch (e) {
    return null;
  }
};

export const getFieldValuesByProcess = async (process) => {
  try {
    const axios = createInstance();
    const { data } = await axios.get(`${BASE_URL}/process/${process}`);
    return data;
  } catch (e) {
    return null;
  }
};

export const saveFieldValue = async (body, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}`, body);
  return data;
};

export const createBatch = async (fieldValues) => {
  const axios = createInstance();
  const { data } = await axios.post(`${BASE_URL}/batch`, fieldValues);
  return data;
};
