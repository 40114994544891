import React, {
  memo, useCallback,
} from 'react';
import PropTypes from 'prop-types';

import InputRejected from '../InputRejected';
import useEnabler from '../../../hooks/useEnabler';

import curpIsValid from './validator';
import curpFormatter from './formatter';

const ERROR_MESSAGE_FORMAT = 'CURP incompleto o con formato incorrecto';

const InputCURP = ({
  value, onChange, isEmpty, ...props
}) => {
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value: _value } = target; // AAAA000000AAAA0000

    const newValue = curpFormatter(_value);

    if (curpIsValid(newValue)) {
      target.setCustomValidity('');
      disableError();
    } else {
      target.setCustomValidity(ERROR_MESSAGE_FORMAT);
      enableError();
    }

    onChange(event, newValue);
  }, [onChange, enableError, disableError]);

  return (
    <InputRejected
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isValid={curpIsValid(value)}
      value={curpFormatter(value)}
      onChange={handleOnChange}
      isEmpty={isEmpty}
      error={isError}
      helperText={isError ? ERROR_MESSAGE_FORMAT : ''}
    />
  );
};

InputCURP.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isEmpty: PropTypes.any,
};

InputCURP.defaultProps = {
  isEmpty: undefined,
};

export default memo(InputCURP);
