/* eslint-disable max-len */
import { useCallback, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { getAllCompanyConfigs } from '../api/companyConfigs';

import { productsData } from '../data';
import useStateMachine from '../containers/main/hooks/useStateMachine';
import {
  COMPANY,
  CONSULT_PAGES,
  CONSULT_PATRIMONIAL_RELATIONSHIP,
  CUSTOM_FLOW_CONFIG_TYPE,
  PRODUCT_ID_MEGA_ARRENDAMIENTO,
  PRODUCT_ID_MEGA_CREDITO_SIMPLE,
  PRODUCT_ID_MEGA_FACTORAJE,
  PRODUCT_ID_MEGA_CASH_CUSTOM,
  BURO_REJECTED,
  CRED_HIP_PRODUCT_ID,
} from '../constants';

export const useProduct = () => {
  const [isProductsLoaded, setIsProductsLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsId, setProductIds] = useState([]);

  const { STATES } = useStateMachine();

  const productStates = {
    [CRED_HIP_PRODUCT_ID]: {
      [STATES.CONSULT_CREDIT_SHAREHOLDER]: {
        REJECTED_FIELD: BURO_REJECTED,
        NORMAL_FLOW: CONSULT_PATRIMONIAL_RELATIONSHIP,
      },
    },
  };

  const productFinishFunctions = {
    [PRODUCT_ID_MEGA_FACTORAJE]: () => {},
    [PRODUCT_ID_MEGA_CREDITO_SIMPLE]: () => {},
    [PRODUCT_ID_MEGA_ARRENDAMIENTO]: () => {},
    [PRODUCT_ID_MEGA_CASH_CUSTOM]: () => {},
  };

  const finishFunctions = (_product) => productFinishFunctions[_product];

  const getNextCurrentPage = (_product, _currentState) => {
    const currentPage = productStates[_product][_currentState];
    return currentPage;
  };

  const getProductByRoute = (routeName) => {
    const product = productsData.find((_product) => _product.route === routeName) || productsData[0];
    return product;
  };

  const getNameProducts = useCallback(() => {
    const nameProducts = productsData
      .filter((_product) => _product.visible)
      .map((_product) => _product.name);
    setProducts(nameProducts);
  }, []);

  const getProductsId = useCallback(() => {
    const ids = productsData
      .filter((_product) => _product.visible)
      .map((_product) => _product._id);
    setProductIds(ids);
  }, []);

  const getFlowType = useCallback((product) => {
    const { flow = '' } = productsData.find(
      (_product) => _product.name === product,
    );
    if (!flow) {
      return productsData[0].flow;
    }
    return flow;
  }, []);

  const getConsultPageConfigByFlow = async (normalizedFlowName, consultPageName) => {
    const companyConfigs = await getAllCompanyConfigs(COMPANY);
    const { payload } = companyConfigs.find(({ type: { name = '' } = {} }) => name === CUSTOM_FLOW_CONFIG_TYPE);
    const allPagesConfig = payload[normalizedFlowName][CONSULT_PAGES];
    const pageConfig = allPagesConfig[consultPageName];
    return pageConfig;
  };

  if (!isProductsLoaded) {
    getNameProducts();
    getProductsId();
    setIsProductsLoaded(true);
  }

  const getDefaultProduct = () => productsData[0];

  return {
    products,
    productsId,
    getNameProducts,
    getFlowType,
    getNextCurrentPage,
    getConsultPageConfigByFlow,
    finishFunctions,
    getDefaultProduct,
    getProductByRoute,
  };
};
