import { useState, useCallback, useMemo } from 'react';
import {
  CREATE_CLIENT_PAGE,
  CREATE_CLIENT_BY_LINK_PAGE,
  CREATE_CLIENT_LOADER_PAGE,
} from '../../../../../constants';

export const STATES = {
  CREATE_CLIENT_PAGE,
  CREATE_CLIENT_BY_LINK_PAGE,
  CREATE_CLIENT_LOADER_PAGE,
};

const useStateMachine = (_currentState = STATES.CREATE_CLIENT_LOADER_PAGE) => {
  const [currentState, setCurrentState] = useState(_currentState);

  const goToClientPage = useCallback(() => {
    setCurrentState(STATES.CREATE_CLIENT_PAGE);
  }, []);
  const goToClientPageByLink = useCallback(() => {
    setCurrentState(STATES.CREATE_CLIENT_BY_LINK_PAGE);
  }, []);
  const goToLoaderPage = useCallback(() => {
    setCurrentState(STATES.CREATE_CLIENT_LOADER_PAGE);
  }, []);

  const result = useMemo(
    () => (
      {
        STATES,
        currentState,
        goToClientPage,
        goToClientPageByLink,
        goToLoaderPage,
      }
    ),
    [
      currentState,
      goToClientPage,
      goToClientPageByLink,
      goToLoaderPage,
    ],
  );

  return result;
};

export default useStateMachine;
