import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  checkBoxLabel: {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: '#7C839D',
  },
  containerCVV: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    width: '90px',
    marginLeft: '16px',
    textAlign: 'center',
    height: '40px',
  },
  hide: {
    visibility: 'hidden',
  },
}));
