import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import styles from './useStyles';
import { convertirAMoneda } from '../../../utils/currency';

export const CurrencyLabel = ({
  title,
  money,
  format,
}) => {
  const classes = styles();
  return (
    <Box>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Box className={classes.moneyFormat}>
        <Typography className={classes.money}>
          {convertirAMoneda(money)}
        </Typography>
        <Typography className={classes.currency}>
          {format}
        </Typography>
      </Box>
    </Box>
  );
};

CurrencyLabel.propTypes = {
  title: PropTypes.string.isRequired,
  money: PropTypes.number.isRequired,
  format: PropTypes.number.isRequired,
};
