import {
  Box, Typography,
} from '@material-ui/core';
import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import jwtDecode from 'jwt-decode';

import useStyles from './useStyles';

import { StepperContext } from '../../../../context/stepper';
import fields from '../../../../data/inputLoginEntity.json';
import Input from '../../../../components/Input';
import { AlertDialog, Button, Loader } from '../../../../components';
import {
  storeAuth, storeStepData, getLogoAndImageTemplate,
  getTokenAndRedirection,
  logOutMain,
  saveExtraDataAuth,
} from '../../../../utils';
import * as authAPI from '../../../../api/auth';
import * as userAPI from '../../../../api/users';
import {
  getFlowTypeHandler, getStepperTags,
} from '../../../../utils/tags';
import useLoading from '../../../../hooks/useLoading';
import { AuthContext } from '../../../../context/auth';
import ErrorMessage from '../../../../components/ErrorMessage';
import AlertSnack from '../../../../components/AlertSnack';
import useError from '../../../../hooks/useError';
import { ENTITY_CLIENTS_ROUTE, COMPANY, LOGIN_PHONE_ID } from '../../../../constants';
import { ThemeContext } from '../../../../context/Theme/themeContext';
import { useProduct } from '../../../../hooks/useProduct';
import { InformationDialog } from '../../../../components/InformationDialog';
import { getConfigs } from '../../../../utils/configs';
import { IMAGE_HEADER } from '../../../../constants/commonUrls';

export const LoginEntity = () => {
  const { initTimeoutToken } = useContext(AuthContext);
  const { initStepper } = useContext(StepperContext);
  const { loadTheme } = useContext(ThemeContext);
  const { products } = useProduct();
  const classes = useStyles();
  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();
  const [searchParams] = useSearchParams();
  const { product = '' } = useParams();

  const {
    logoUrl,
  } = useMemo(() => getLogoAndImageTemplate(product), []);

  const [formFields, setFormFields] = useState(fields);
  const [idRoles, setIdRoles] = useState(['']);

  const [showModal, setShowModal] = useState(false);
  const [showInformationDialog, setShowInformationDialog] = useState(false);
  const disable = useMemo(() => formFields.some((field) => !field.value), [formFields]);
  const navigate = useNavigate();

  const handleChange = (event, value) => {
    const { name } = event.target;
    // eslint-disable-next-line max-len
    const newFields = formFields.map((field) => (field.name === name ? { ...field, value } : field));
    setFormFields(newFields);
  };
  // jose+t02250823@docupass.co
  // T02250823

  const loginToken = async (token) => {
    const { validator: { email = '' } = {} } = jwtDecode(token);
    const { user } = await authAPI.loginByToken(email, token);
    storeAuth(user);
    return user;
  };

  const getActualStep = (stepperTags) => {
    const actualIndexStep = stepperTags.findIndex(({ completed }) => !completed);
    return { stepRoute: stepperTags[actualIndexStep].route, currentStep: actualIndexStep };
  };

  const setTags = async (process, normalizedFlowName) => {
    const stepperInitialization = await getStepperTags(normalizedFlowName, process);
    initStepper(stepperInitialization);
    const { steps } = stepperInitialization;
    const actualStep = getActualStep(steps);
    initStepper({ ...stepperInitialization, current: actualStep.currentStep });
    storeStepData({ ...actualStep, steps });
    return actualStep;
  };

  const onLogout = async () => {
    await logOutMain();
    await authAPI.logout();
  };

  const authUser = async (user) => {
    try {
      const { user: userAuth } = await userAPI.loginByEmail(user);
      return userAuth;
    } catch (error) {
      const { response: { data = '', status = 0 } = {} } = error;
      if (status === 401 && data === 'INVALID CREDENTIALS') {
        setShowModal(true);
      } else {
        showError(error.message, error.name || 'Error al autenticar, contacte a soporte');
      }
      return false;
    }
  };

  const onSubmit = async (userAuthenticated) => {
    try {
      await onLogout();
      startLoading();
      storeAuth(userAuthenticated);
      endLoading();
      initTimeoutToken();
      navigate(ENTITY_CLIENTS_ROUTE);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
    }
  };

  const loginByTokenFlow = async () => {
    try {
      const queryParams = getTokenAndRedirection(searchParams);
      if (!queryParams) return false;
      const { token, redirection, process } = queryParams;
      startLoading();
      await loginToken(token);
      const { normalizedFlowName } = await getFlowTypeHandler(process);
      await setTags(process, normalizedFlowName);
      saveExtraDataAuth({ process });
      await loadTheme(products[0]);
      initTimeoutToken();
      return navigate(redirection);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to loginByTokenFlow');
      return false;
    }
  };

  const verifyIsEntity = (userAuthenticated) => {
    const { organization: { roles = [] } = {} } = userAuthenticated;
    if (!roles.length) return false;
    const rolesIds = roles.map(({ _id }) => _id);
    const isEntity = idRoles.some((id) => rolesIds.includes(id));
    return isEntity;
  };

  const authenticate = async (event) => {
    event.preventDefault();
    startLoading();
    const email = formFields[0].value;
    const password = formFields[1].value;
    const userObject = {
      email,
      password,
      company: COMPANY,
      shouldCheckCompanyConfig: false,
    };
    const userAuthenticated = await authUser(userObject, email);
    const isEntity = verifyIsEntity(userAuthenticated);
    if (isEntity) await onSubmit(userAuthenticated);
    else setShowInformationDialog(true);
    endLoading();
  };

  const fetchAgentRolesId = async () => {
    const payloadConfig = await getConfigs(LOGIN_PHONE_ID);
    const { entityRoles } = payloadConfig;
    setIdRoles(entityRoles);
  };

  const fetchData = async () => {
    try {
      startLoading();
      await fetchAgentRolesId();
      endLoading();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to fetch  Data');
      endLoading();
    }
  };

  useEffect(() => {
    loginByTokenFlow();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <AlertSnack
        isOpen={isError}
        type="error"
        onClose={hideError}
      >
        <ErrorMessage
          errorType={errorType}
          errorMessage={errorMessage}
        />
      </AlertSnack>
      <AlertDialog
        onCancel={() => setShowModal(false)}
        onAccept={() => setShowModal(false)}
        title="Intentas acceder con un email que no está registrado"
        showModal={showModal}
        onCancelText="Acceder con otro email"
        onAcceptText="Registrarme"
      />
      <InformationDialog
        showModal={showInformationDialog}
        onSubmit={() => setShowInformationDialog(false)}
        buttonTitle="Entendido"
        descripcion="El email que estás ingresando con nosotros no tiene una cuenta asociada del tipo Inmobiliaria. Por favor ponte en contacto con soporte para que te ayuden a crear una cuenta."
      />
      <Loader open={isLoading} />
      <Box
        component="form"
        className={classes.formContainer}
        onSubmit={authenticate}
        method="post"
        autoComplete="off"
      >
        <Box>
          <Box className={classes.boxImage}>
            <img
              className={classes.imgLogo}
              src={logoUrl}
              alt="imgLogoMega"
            />
          </Box>
          <Box className={classes.imageContainer}>
            <img
              className={classes.image}
              src={IMAGE_HEADER}
              alt="imgLogoMega"
            />
          </Box>
        </Box>
        <Box className={classes.inputContainer}>
          {formFields.map((field) => (
            <Box
              className={classes.inputFieldContainer}
            >
              <Typography variant="h6" className={classes.labelInput}>
                {field.title}
              </Typography>
              <Input
                label={field.label}
                type={field.fieldType?.name}
                name={field.name}
                value={field.value || ''}
                required={Boolean(field?.config?.required)}
                disabled={field?.config?.disabled}
                hide={Boolean(field?.config?.hide)}
                isEmpty={field?.isEmpty}
                showPasswordValidity={field?.showPasswordValidity}
                errorMessage={field?.errorMessage}
                defaultValue={field?.config?.defaultValue}
                onChange={handleChange}
                extraInputClassName={classes.customPasswordInput}
              />
            </Box>
          ))}
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            disabled={disable}
            type="submit"
            className={classes.btnLogin}
            fullWidth
            classes={{
              outlined: classes.btnRoot,
            }}
          >
            Ingresar
          </Button>
        </Box>
      </Box>
      <Box />
    </>
  );
};
