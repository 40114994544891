/* eslint-disable max-len */
import React, {
  useEffect,
} from 'react';
import ReactGA from 'react-ga';

import useStateMachine from './hooks/useStateMachine';

import { Cotizacion } from './Cotizacion';
import { LoaderPage } from './LoaderPage';

import {
  BIENES_ARRENDAMIENTO,
  LOADER_PAGE,
  COTIZACION_CREDITO_HIPOTECARIO,
} from '../../../../constants';

export const CotizationPage = () => {
  const {
    STATES,
    currentState,
    goToCotization,
    goToLoaderPage,
  } = useStateMachine();

  const selectPage = async () => {
    const currentPage = BIENES_ARRENDAMIENTO;

    switch (currentPage) {
      case LOADER_PAGE:
        return goToLoaderPage();
      case COTIZACION_CREDITO_HIPOTECARIO:
      default:
        return goToCotization();
    }
  };

  useEffect(() => {
    selectPage();
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {
        (
          () => {
            switch (currentState) {
              case STATES.LOADER_PAGE:
                return (
                  <LoaderPage />
                );
              case STATES.COTIZACION_CREDITO_HIPOTECARIO:
              default:
                return (
                  <Cotizacion />
                );
            }
          }
        )()
      }
    </>
  );
};
