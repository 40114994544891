import { decryptData, encryptData } from '../utils/encryption';

const FIELD_VALUES_SECTIONS_KEY = btoa('FIELD_VALUES_SECTIONS_DATA');

export const storeFieldValuesSectionsData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(FIELD_VALUES_SECTIONS_KEY, encryptedData);
};

export const getFieldValuesSectionsData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(FIELD_VALUES_SECTIONS_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

export const removeFieldValuesSectionsData = () => {
  window.localStorage.removeItem(FIELD_VALUES_SECTIONS_KEY);
};
