/* eslint-disable max-len */
import {
  useEffect, useRef, useState,
} from 'react';

import * as clientAPI from '../../../api/clients';
import * as processAPI from '../../../api/process';
import * as fieldValueAPI from '../../../api/fieldValue';
import * as unprotectedAPI from '../../../api/unprotected';
import { execMatrizPerfilamiento, execMatrizPerfilamientoMultiOptions } from '../../../api/matrizPerfilamiento';

import {
  getCurrentStep, getEmail, getProcess, updateCurrentPageOnLocalStep, updateLocalStepsByStep,
} from '../../../utils';
import { getFlowName } from '../../../utils/flows';
import { getConfigs } from '../../../utils/configs';
import * as googleAnalytics from '../../../utils/googleAnalytics/events';

import { getLeadData, getLeadId } from '../../../utils/leads';
import { convertirAMoneda } from '../../../utils/currency';
import { getFieldValuesInFieldsFormat } from '../utils/fields';
import { saveManyFieldValues } from '../../../utils/api/fieldValues.utils';
import { getCreditStoredData, updateCreditDataByMatrizData } from '../../../utils/credit';
import { addProcessToFieldValues, getLocalFieldValueByFieldId } from '../../../utils/fieldValues';
import { checkAndSaveLeadSharedData, saveLeadSharedFieldValues, saveSharedFieldValues } from '../shared/CreateClientAccount';

import { useFieldAutoFill } from './useFieldAutoFill';
import { useProduct } from '../../../hooks/useProduct';

import {
  AFORO_FIELD_ID,
  COMPANY,
  ENGANCHE_FIELD_ID,
  GASTOS_ESCRITURA_FIELD_ID,
  IMPORTE_FIELD_ID,
  MATRIZ_EXECUTION_FIELDS_TYPE,
  PAGO_MENSUAL_FIELD_ID,
  PERFILAMIENTO_EMPTY,
  PERFILAMIENTO_EXIST,
  PERFILAMIENTO_FIELD_ID,
  PERFILAMIENTO_RECUPERACION_PRODUCTOS_TITLE,
  PLAZO_FIELD_ID,
  PLAZO_YEARS_FIELD_ID,
  PROFILING_COTIZATION,
  PROFILING_EMPTY_MATRIZ,
  PROPIEDAD_VALOR_FIELD_ID,
  VALOR_ESTADO_PROPIEDAD_FIELD_ID,
} from '../../../constants';
import { updateLeadWithTagSteps } from '../../../utils/api/leads.utils';
import { useFieldValues } from './useFieldValues';
import { objectHasProps } from '../../../utils/commons';

export const useMatrizProfilling = ({ company, fetchFieldsConfigsOnLoad = true }) => {
  const processId = getProcess();

  const [profilingFieldsIds, setProfilingFieldsIds] = useState([]);
  const [defaultProflilingFieldValues, setdefaultProfillingFieldValues] = useState([]);
  const { getDefaultProduct } = useProduct();
  const { fillValueFromFunctions } = useFieldAutoFill({ process: processId });
  const creditData = useRef(null);
  const {
    storeLocalFieldValues,
    formatFromFieldValueModelToUpload,
  } = useFieldValues({ company: COMPANY });

  const defaultProduct = getDefaultProduct();
  const flowName = getFlowName() || defaultProduct.name;

  const fetchMatrizFieldsConfigByFlowName = async (currentFlowName = flowName) => {
    const payload = await getConfigs(MATRIZ_EXECUTION_FIELDS_TYPE);

    const { profillingFields, defaultProfillingFieldValues = [] } = payload[currentFlowName];

    setProfilingFieldsIds(profillingFields);
    setdefaultProfillingFieldValues(defaultProfillingFieldValues);
    return { profillingFields, defaultProfillingFieldValues };
  };

  const fetchFields = async (matrizFieldsIds) => Promise.all(
    matrizFieldsIds.map(async (fieldId) => unprotectedAPI.getById(fieldId)),
  );

  const getCreditPropertyFieldValues = () => [
    {
      field: ENGANCHE_FIELD_ID,
      value: convertirAMoneda(creditData.current.enganche),
    },
    {
      field: PLAZO_YEARS_FIELD_ID,
      value: `${creditData.current.year} años`,
    },
    {
      field: VALOR_ESTADO_PROPIEDAD_FIELD_ID,
      value: convertirAMoneda(creditData.current.propietyValue),
    },
  ];

  const getMatrizFieldValuesFromStoredData = () => {
    const fieldValues = [
      {
        field: IMPORTE_FIELD_ID,
        value: convertirAMoneda(creditData.current.importe),
      },
      {
        field: PLAZO_FIELD_ID,
        value: `${creditData.current.meses} meses`,
      },
      {
        field: AFORO_FIELD_ID,
        value: `${creditData.current.aforo}`,
      },
      {
        field: PROPIEDAD_VALOR_FIELD_ID,
        value: convertirAMoneda(creditData.current.propietyValue),
      },
      {
        field: PAGO_MENSUAL_FIELD_ID,
        value: convertirAMoneda(creditData.current.monthlyPaid),
      },
      {
        field: GASTOS_ESCRITURA_FIELD_ID,
        value: convertirAMoneda(creditData.current.montoGastosEscritura),
      },
      ...getCreditPropertyFieldValues(),
    ];
    return fieldValues;
  };

  const formatFieldsData = async (fieldsData, storedFieldValues) => {
    const newFieldValues = await Promise.all(fieldsData.map(async (field) => {
      const { _id } = field;
      const storedFieldValue = storedFieldValues.find((fieldValue) => fieldValue?.field === _id);
      const localFieldValue = storedFieldValue || getLocalFieldValueByFieldId(_id);
      const sfieldValue = processId
        && (await fieldValueAPI.getFieldValueByProcessField(processId, _id));
      const finalFieldValue = localFieldValue?.value || (processId
        ? sfieldValue?.value
        : '') || '';
      return {
        ...field,
        value: finalFieldValue,
      };
    }));
    return newFieldValues;
  };

  const formatFieldValues = (fieldValues) => fieldValues
    .map(({
      _id, config, name, fieldType, value,
    }) => ({
      field: {
        _id,
        config,
        name,
        fieldType,
      },
      value,
    }));

  const formatToFieldValueModelStructure = (fieldValues) => fieldValues.map(
    ({
      field, name, fieldType, config = {}, value,
    }) => ({
      field: {
        _id: field, name, config, fieldType,
      },
      value,
    }),
  );

  const getCatAndTasaFieldValues = async (perfilamientoValue) => {
    const tasaFieldValues = [
      {
        field: {
          _id: PLAZO_FIELD_ID,
          name: 'plazo',
        },
        value: `${creditData.current.meses} meses`,
      },
      {
        field: {
          _id: PERFILAMIENTO_FIELD_ID,
          name: 'perfilamientoCrediticio',
        },
        value: perfilamientoValue,
      },
    ];
    const catAndTasaFieldValues = await processAPI.setTasaBroker('', company, tasaFieldValues);
    return catAndTasaFieldValues;
  };

  const savePerfilamientoValue = async (perfilamientoValue, process) => {
    await fieldValueAPI.saveFieldValue({
      field: PERFILAMIENTO_FIELD_ID,
      value: perfilamientoValue,
      process,
    });
  };

  const savePerfilamientoSharedFieldValues = async (values, processID) => {
    const email = getEmail();
    const { _id } = await clientAPI.getClientByCompanyAndEmail(company, email);
    const { value: mesesValue } = await fieldValueAPI.getFieldValueByProcessField(processID, '64b71fa1cbf8225d4573b07f');
    const mesesValueClean = mesesValue.split(' ')[0];
    const fields = [
      {
        value: values,
        _id: PERFILAMIENTO_FIELD_ID,
      },
      {
        value: `${mesesValueClean}`,
        _id: '64b71fa1cbf8225d4573b07f',
      },
    ];
    await saveSharedFieldValues(fields, _id);
  };

  const getCatTasaFieldValues = async (perfilamientoValue) => {
    const tasasFieldValues = await getCatAndTasaFieldValues(perfilamientoValue);
    return tasasFieldValues;
  };

  const getMatrizBody = async () => {
    const fields = await fetchFields(profilingFieldsIds);
    const storedFieldValues = getMatrizFieldValuesFromStoredData();
    const fieldsStructured = await formatFieldsData(fields, [
      ...storedFieldValues,
      ...defaultProflilingFieldValues,
    ]);
    const fieldValues = formatFieldValues(fieldsStructured);
    const newFieldValues = fillValueFromFunctions(fields, fieldValues);

    const formattedFieldValues = formatToFieldValueModelStructure(newFieldValues);

    const secondBatchResult = fillValueFromFunctions(
      fields,
      formattedFieldValues,
    );
    const thirdFieldsFormat = formatToFieldValueModelStructure(secondBatchResult);

    const thirdBatchResult = fillValueFromFunctions(
      fields,
      thirdFieldsFormat,
    );

    const fieldValuesResult = formatToFieldValueModelStructure(thirdBatchResult);
    return {
      plazo: `${creditData.current.meses} meses`,
      company,
      fieldValues: fieldValuesResult,
    };
  };

  const getCorrectMatrizProductsResult = (matrizResults) => {
    const { productsApplied = [], productsRecuperable = {} } = matrizResults;
    const products = productsApplied.length ? productsApplied : productsRecuperable.products;
    const message = productsApplied.length ? '' : productsRecuperable.message;
    const data = productsApplied.length ? null : productsRecuperable.data;
    const customTitle = productsApplied.length ? '' : PERFILAMIENTO_RECUPERACION_PRODUCTOS_TITLE;
    return {
      customTitle,
      message,
      products,
      data,
    };
  };

  const markRejectedProducts = (productsRejected) => productsRejected.map(
    (product) => ({ ...product, rejected: true }),
  );

  const getMatrizProductsStructured = (matrizResults) => {
    const {
      customTitle,
      products: approvedProducts,
      message,
      data,
    } = getCorrectMatrizProductsResult(matrizResults);
    const { productsRejected } = matrizResults;
    const newProductsRejected = markRejectedProducts(productsRejected);
    const products = [...approvedProducts, ...newProductsRejected];
    return {
      customTitle, products, message, data,
    };
  };

  const getMatrizProductStructuredNewFormat = (matrizResults) => {
    const {
      productsApplied = [],
      productsCompared,
      productsRecuperable,
      bestFinantialOption,
      bestInitialOutlay,
    } = matrizResults;
    const { bestProduct = {}, comparassion = [] } = productsCompared;
    const hasValidProducts = Object.keys(bestProduct).length > 0 || comparassion.length > 0;
    const validResult = !!(hasValidProducts && productsApplied.length);
    const data = validResult ? null : productsRecuperable.data;
    const message = validResult ? '' : productsRecuperable.message;
    const customTitle = validResult ? '' : PERFILAMIENTO_RECUPERACION_PRODUCTOS_TITLE;
    return {
      customTitle,
      message,
      bestProduct,
      comparassion,
      bestFinantialOption,
      bestInitialOutlay,
      data,
    };
  };

  // Formato anterior
  // eslint-disable-next-line no-unused-vars
  const checkMatrizStatus = (dataResult) => {
    const { productsApplied = [], productsRecuperable = {} } = dataResult;
    if (!productsApplied.length && !productsRecuperable?.products?.length) {
      return PERFILAMIENTO_EMPTY;
    }
    return PERFILAMIENTO_EXIST;
  };

  const isProductsResultEmpty = (dataResult) => {
    const { productsCompared = {}, productsRecuperable = {} } = dataResult;
    const hasBestProduct = productsCompared?.bestProduct
      && Object.keys(productsCompared?.bestProduct).length > 0;
    const hasRecuperableProducts = productsRecuperable?.products
      && productsRecuperable?.products.length > 0;
    return !(hasBestProduct || hasRecuperableProducts);
  };

  const checkMatrizStatusNewFormat = (dataResult) => {
    const isProductsEmpty = isProductsResultEmpty(dataResult);
    if (isProductsEmpty) return PERFILAMIENTO_EMPTY;
    return PERFILAMIENTO_EXIST;
  };

  const isPerfilamientoEmptyOrApplied = (perfilamientoCrediticioValues) => {
    const { productsCompared = [], productsRecuperable = {} } = perfilamientoCrediticioValues;

    if (isProductsResultEmpty(perfilamientoCrediticioValues)) return true;
    if (productsCompared && Object.keys(productsCompared?.bestProduct).length) {
      return productsCompared?.bestProduct?.applied;
    }
    const { products = [] } = productsRecuperable;
    return products.some(({ applied = false }) => applied);
  };

  const formatPerfilamientoFieldValue = (perfilamientoValues) => {
    const profFieldValue = {
      field: PERFILAMIENTO_FIELD_ID,
      name: 'perfilamientoCrediticio',
      value: perfilamientoValues,
    };
    return profFieldValue;
  };

  const execMatrizProfilling = async (body) => {
    const response = await execMatrizPerfilamiento(body);
    const responseMultiOptions = await execMatrizPerfilamientoMultiOptions(body);
    const matrizCompleted = { ...response, ...responseMultiOptions };
    const matrizStatus = checkMatrizStatusNewFormat(response);
    const { data = null } = getMatrizProductStructuredNewFormat(matrizCompleted);
    return {
      values: matrizCompleted,
      matrizStatus,
      newConditions: data,
    };
  };

  const fetchLocalCreditData = () => {
    creditData.current = getCreditStoredData();
  };

  const updateClientConditionsValues = async (newData, process = '') => {
    const leadId = getLeadId();
    updateCreditDataByMatrizData(newData);
    fetchLocalCreditData();
    const newConditionsValues = getMatrizFieldValuesFromStoredData();
    if (process) {
      const fieldValuesData = addProcessToFieldValues(newConditionsValues, process);
      await saveManyFieldValues(fieldValuesData);
      return;
    }
    if (!leadId) return;
    const sharedFieldValues = getFieldValuesInFieldsFormat(newConditionsValues);
    await saveLeadSharedFieldValues(sharedFieldValues, leadId);
  };

  const sortByCat = (banks) => banks.sort((bankA, bankB) => bankA.cat - bankB.cat);

  const updateCurrentPageByPerfilamiento = (currentPageToSet, moveNextStep = true, navigate = () => {}) => {
    const localStepPosition = getCurrentStep();
    if (!moveNextStep) return updateCurrentPageOnLocalStep(localStepPosition, currentPageToSet);
    const nextStep = localStepPosition + 1;
    const { steps: localSteps } = updateLocalStepsByStep(localStepPosition);
    updateCurrentPageOnLocalStep(nextStep, currentPageToSet);
    return navigate(localSteps[nextStep].route, 'inmobiliaria');
  };

  const handleOnPerfilamientoExists = async ({ leadData = null, navigate = () => {}, moveNextStep = true }) => {
    const currentPage = PROFILING_COTIZATION;
    googleAnalytics.perfilamientoCrediticio();
    if (leadData) await updateLeadWithTagSteps(leadData);
    updateCurrentPageByPerfilamiento(currentPage, moveNextStep, navigate);
  };

  const handlOnPerfilamientoEmpty = async ({ leadData = null, navigate = () => {}, moveNextStep = true }) => {
    const currentPage = PROFILING_EMPTY_MATRIZ;
    googleAnalytics.perfilamientoCrediticio();
    if (leadData) await updateLeadWithTagSteps(leadData);
    updateCurrentPageByPerfilamiento(currentPage, moveNextStep, navigate);
  };

  const onPerfilamientoEmpty = async (fieldValuesToSave = []) => {
    const leadData = getLeadData();
    const currentPage = PROFILING_EMPTY_MATRIZ;
    const localStepPosition = getCurrentStep();
    updateCurrentPageOnLocalStep(localStepPosition, currentPage);
    storeLocalFieldValues(fieldValuesToSave);
    if (objectHasProps(leadData)) await updateLeadWithTagSteps(leadData);
    window.location.reload();
  };

  const handleMatrizSubmission = async (saveFieldValue) => {
    const matrizBody = await getMatrizBody();
    const { fieldValues: matrizFieldValues } = matrizBody;
    const {
      values: matrizResult, matrizStatus, newConditions,
    } = await execMatrizProfilling(matrizBody);

    const perfilamientoFieldValue = formatPerfilamientoFieldValue(matrizResult);
    const formattedFieldValues = formatFromFieldValueModelToUpload(matrizFieldValues, true);
    const creditInfoFieldValues = getCreditPropertyFieldValues();
    const leadFieldValuesToSave = [
      ...formattedFieldValues,
      ...creditInfoFieldValues,
      perfilamientoFieldValue,
    ];
    const fieldValuesFormatted = getFieldValuesInFieldsFormat(leadFieldValuesToSave);

    await checkAndSaveLeadSharedData(fieldValuesFormatted);
    if (newConditions) {
      await updateClientConditionsValues(newConditions);
    }

    if (matrizStatus === PERFILAMIENTO_EXIST && matrizResult) {
      const catAndTasasFieldValues = await getCatTasaFieldValues(matrizResult);
      const catFieldsFormatted = getFieldValuesInFieldsFormat(catAndTasasFieldValues);
      await checkAndSaveLeadSharedData(catFieldsFormatted);
      storeLocalFieldValues([...leadFieldValuesToSave, ...catAndTasasFieldValues]);
      if (saveFieldValue) {
        const { value = '' } = leadFieldValuesToSave.find(({ field }) => field.toString() === PERFILAMIENTO_FIELD_ID) || {};
        if (value) await fieldValueAPI.saveFieldValue({ field: PERFILAMIENTO_FIELD_ID, value, process: processId });
      }
      updateCurrentPageByPerfilamiento(PROFILING_COTIZATION, false);
      window.location.reload();
    } else {
      await onPerfilamientoEmpty(leadFieldValuesToSave);
    }
  };

  useEffect(() => {
    fetchLocalCreditData();
  }, []);

  useEffect(() => {
    if (fetchFieldsConfigsOnLoad) {
      fetchMatrizFieldsConfigByFlowName();
    }
  }, []);

  return {
    execMatrizProfilling,
    fetchLocalCreditData,
    fetchMatrizFieldsConfigByFlowName,
    formatPerfilamientoFieldValue,
    getCatTasaFieldValues,
    getCorrectMatrizProductsResult,
    getMatrizProductsStructured,
    getMatrizProductStructuredNewFormat,
    getCreditPropertyFieldValues,
    getMatrizBody,
    isPerfilamientoEmptyOrApplied,
    savePerfilamientoValue,
    savePerfilamientoSharedFieldValues,
    sortByCat,
    updateClientConditionsValues,
    handleOnPerfilamientoExists,
    handlOnPerfilamientoEmpty,
    handleMatrizSubmission,
  };
};
