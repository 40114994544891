import { createInstance, createInstanceNoToken } from './config';

const BASE_URL = '/users';

export const create = async (user, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();

  const { data } = await axios.post('/users', user);
  return data;
};

export const generatePhoneCode = async (userData, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}/sendWhatsapp/verify/phone`, userData);
  return data;
};

export const verifyPhoneCode = async (phone, code, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}/verify/phone`, { phone, code });
  return data;
};

export const loginByPhoneCode = async (userData, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post('/loginPhone', userData);
  return data;
};

export const loginByEmail = async (userData, token) => {
  const { email, password } = userData;
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post('/login', { user: { email, password } });
  return data;
};

export const getUserByEmail = async (email, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.get(`${BASE_URL}/email/${email}`);
  return data;
};

export const getUserById = async (id) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/${id}`);
  return data;
};

export const phoneIsExist = async (user) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.post(`${BASE_URL}/verify/phoneExists`, user);
  return data;
};

export const verifyPhoneAlreadyExists = async (user, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}/verify/phoneExists`, user);
  return data;
};
