import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  checkBoxLabel: {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: '#7C839D',
  },
  container: {
    marginTop: '10vh',
    marginBottom: '14vh',
    maxWidth: '650px',
    [theme.breakpoints.down('md')]: {
      marginTop: '10%',
      marginBottom: '10%',
      paddingLeft: '5%',

    },
  },
  formGroup: {
    margin: '45px auto',
    paddingLeft: '49px',
  },
  containerCVV: {
    paddingLeft: '49px',
  },
  infoTitle: {
    color: theme.palette.secondary.main,
    maxWidth: '700px',
    fontWeight: 600,
    lineHeight: '1.25rem',
    fontSize: '1.125rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
      textAlign: 'left',
      margin: '0 auto',
      maxWidth: 'max-content',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
      textAlign: 'left',
      margin: '0 auto',
      maxWidth: 'max-content',
    },
  },
  customFieldsTitle: {
    textAlign: 'center',
  },
}));
