import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  label: {
    color: '#000000',
    fontFamily: 'Open sans, sans-serif',
    fontWeight: '700',
    fontSize: '1rem',
    display: 'block',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  },
  input: {
    borderRadius: 4,
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}));
