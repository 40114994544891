import * as flowAPI from '../../api/flow';
import * as flowProcessAPI from '../../api/flowProcess';
import { getFlowName } from '../flows';
import { getFlowTypeHandler } from '../tags';

export const getFlowByProcess = async (processId) => {
  const { flow: flowId } = await flowProcessAPI.getFlowProcessById(processId);
  const flowFound = await flowAPI.getFlowById(flowId);
  return flowFound;
};

export const getNormalizedFlowNameByProcess = async (processId = '') => {
  if (!processId) {
    return getFlowName();
  }
  const { normalizedFlowName } = await getFlowTypeHandler(processId);
  return normalizedFlowName;
};
