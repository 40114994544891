import { useState, useCallback, useMemo } from 'react';

const useError = (_isError = false, _errorMessage = '', _errorType = '') => {
  const [isError, setIsError] = useState(_isError);
  const [errorType, setErrorType] = useState(_errorType);
  const [errorMessage, setErrorMessage] = useState(_errorMessage);

  const enableError = useCallback(() => {
    setIsError(true);
  }, []);

  const disableError = useCallback(() => {
    setIsError(false);
  }, []);

  const showError = useCallback((message, type) => {
    enableError();
    if (message) setErrorMessage(message);
    if (type) setErrorType(type);
  }, [enableError]);

  const hideError = useCallback(() => {
    disableError();
  }, [disableError]);

  const result = useMemo(
    () => (
      {
        isError,
        errorType,
        errorMessage,
        enableError,
        disableError,
        showError,
        hideError,
      }
    ),
    [
      isError,
      errorMessage,
      errorType,
      enableError,
      disableError,
      showError,
      hideError,
    ],
  );

  return result;
};

export default useError;
