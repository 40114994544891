/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import {
  Container,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';

import { Button } from '../../../../components';

import useStyles from './useStyles';

export const ConsultLayout = ({
  children, data, submit, disabled, back, infoTitleClassName,
}) => {
  const {
    title, description, infoTitle, labelCheck,
  } = data;
  const [check, setCheck] = useState(false);

  const classes = useStyles();

  const handleChange = (event) => {
    const { target: { checked } } = event;
    setCheck(checked);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    submit();
  };

  return (
    <Container
      component="form"
      onSubmit={onSubmit}
      method="post"
      autoComplete="off"
      className={classes.container}
      disableGutters
      maxWidth={false}
    >
      {
        back ? <ArrowBackIosIcon className={classes.back} onClick={back} /> : ''
      }
      <Typography component="h2" className={classes.title}>{title}</Typography>
      <Typography component="p" className={clsx(classes.infoTitle, infoTitleClassName)}>{infoTitle}</Typography>

      {children}

      <Container
        className={classes.descriptionContainer}
        disableGutters
        maxWidth={false}
      >
        {
          Array.isArray(description) ? (
            description.map((desc, index) => (
              <Typography key={index} component="p" className={classes.description}>
                {desc}
              </Typography>
            ))
          )
            : (
              <Typography component="p" className={classes.description}>
                {description}
              </Typography>
            )
        }
      </Container>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          control={<Checkbox checked={check} onChange={handleChange} size="medium" defaultChecked />}
          label={labelCheck}
          classes={{
            label: classes.checkBoxLabel,
          }}
        />
      </FormGroup>
      <div
        className={classes.buttonContainer}
      >
        <Button
          className={classes.button}
          type="submit"
          disabled={disabled || !check}
        >
          Continuar
        </Button>
      </div>
    </Container>
  );
};

ConsultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    infoTitle: PropTypes.string.isRequired,
    labelCheck: PropTypes.string.isRequired,
  }).isRequired,
  submit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  back: PropTypes.func,
  infoTitleClassName: PropTypes.string,
};

ConsultLayout.defaultProps = {
  back: null,
  disabled: false,
  infoTitleClassName: '',
};
