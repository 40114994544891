import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { Box, Typography, Button } from '@material-ui/core';
import useStyles from './useStyles';
import infoStepsJson from '../../../../../data/Landing.json';

export const Header = () => {
  const { pathname } = useLocation();
  const {
    Header: {
      title,
      subTitle,
      buttonTitle,
      image,
      buttonRoute,
    },
  } = infoStepsJson;
  const classes = useStyles();
  return (
    <Box component="section" className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={clsx(classes.item, classes.marginContainer)}>
          <Box className={classes.innerItem}>
            <Typography variant="h2" component="h2" className={classes.title}>{title}</Typography>
            <Box className={classes.subtitleContainer}>
              <Typography variant="body1" component="p" className={classes.subTitle}>{subTitle}</Typography>
              <Button component={NavHashLink} className={classes.button} to={`${pathname}${buttonRoute}`}>{buttonTitle}</Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.item}>
          <Box className={clsx(classes.innerItem, classes.imageContainer)}>
            <Box component="img" src={image} alt={image} className={classes.image} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
