import { useState } from 'react';
import { executeAutoNodes, fetchAutoExeNodes } from '../../../../../utils/api/nodes.utils';

const useCreditProfilingCotization = () => {
  const [autoNodesForSendResults, setAutoNodesForSendResults] = useState([]);
  const [autoNodes, setAutoNodes] = useState([]);

  const fetchAutoNodesToNotifyResults = async (autoExecutableNodes) => {
    const fetchedNodes = await fetchAutoExeNodes(autoExecutableNodes);
    setAutoNodesForSendResults(fetchedNodes);
  };

  const startNotifyResults = async (checkSendEmail, _processId, token = '') => {
    if (!checkSendEmail) return;
    await executeAutoNodes(autoNodesForSendResults, _processId, token);
  };

  const fetchAutoNodes = async (autoExecutableNodes) => {
    const fetchedNodes = await fetchAutoExeNodes(autoExecutableNodes);
    setAutoNodes(fetchedNodes);
  };

  return {
    autoNodes,
    autoNodesForSendResults,
    fetchAutoNodesToNotifyResults,
    startNotifyResults,
    fetchAutoNodes,
  };
};

export default useCreditProfilingCotization;
