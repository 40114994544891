/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import { Close } from '@material-ui/icons';
import { Container, IconButton, Typography } from '@material-ui/core';
import useStyles from './useStyles';

export const SimpleModal = ({
  onCancel,
  showModal,
  title,
  children,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={showModal}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
    >
      <div className={classes.mainContainer}>
        <IconButton className={classes.IconButton} onClick={onCancel}>
          <Close className={classes.closeIcon} />
        </IconButton>
        <Typography
          variant="h1"
          className={classes.title}
        >
          {title}
        </Typography>
        <Container
          component="div"
          method="post"
          autoComplete="off"
          maxWidth={false}
          className={classes.form}
        >
          {children}
        </Container>
      </div>
    </Modal>
  );
};

SimpleModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
