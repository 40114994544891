import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  separatorHeader: {
    marginTop: '180px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '140px',
    },
  },
  separatorCreditSteps: {
    marginTop: '180px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '200px',
    },
  },
}));
