/* eslint-disable max-len */
import React, {
  useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { TabsContext } from '../../../../context/tabs/tabsContext';
import * as googleAnalytics from '../../../../utils/googleAnalytics/events';
import { storeCredit } from '../../../../utils/credit';
import { logOutMain } from '../../../../utils/logout';
import { logout } from '../../../../api/auth';
import { StepperContext } from '../../../../context/stepper';
import { useProduct } from '../../../../hooks/useProduct';
import { getActualStep, getConfigTags } from '../../../../utils/tags';
import { storeAgentId, storeStepData } from '../../../../utils';
import { ThemeContext } from '../../../../context/Theme/themeContext';
import { storeFlowData } from '../../../../utils/flows';
import { FLOW_INMOBILIARIA, FLOW_VIVIENDA } from '../../../../constants';
import useRoute from '../../../../hooks/useRoute';
import { ApoyoInfonavitSimulator } from '../InfonavitSimulators/ApoyoInfonavitSimulator';
import { CofinavitSimulator } from '../InfonavitSimulators/CofinavitSimulator';
import { FovisssteSimulator } from '../InfonavitSimulators/FovisssteSimulator';
import { TabPanel } from '../../../../components/TabPanel';
import { storeCofinanciamientoLocalFieldValue } from '../../../../utils/fieldValues';

export const CreditSimulator = ({
  startLoading, endLoading, agentId,
}) => {
  const {
    state: { tabs, active },
  } = useContext(TabsContext);
  const { getProductByRoute } = useProduct();
  const {
    navigateWithQueryParam,
    location: {
      pathname,
    },
  } = useRoute();
  const isVivienda = useMemo(() => [FLOW_VIVIENDA, FLOW_INMOBILIARIA].includes(pathname), [pathname]);

  const { initStepper } = useContext(StepperContext);
  const { loadTheme } = useContext(ThemeContext);

  const onLogout = async () => {
    await logOutMain();
    await logout();
  };

  const onSubmit = async ({
    storageData,
    event,
  }) => {
    event.preventDefault();
    event.stopPropagation();
    await onLogout();
    startLoading();
    storeCredit({ ...storageData, tipoPrestamoInfonavit: active });
    storeCofinanciamientoLocalFieldValue(active);
    storeAgentId(agentId);
    const productByRoute = getProductByRoute(pathname);
    const { name, firstRoute } = productByRoute;
    await loadTheme(name);
    const tags = await getConfigTags(name);
    const stepperObject = { steps: tags, current: 0 };
    initStepper(stepperObject);
    const { steps } = stepperObject;
    const actualStep = getActualStep(steps);
    storeFlowData(productByRoute);
    storeStepData({ ...actualStep, steps });
    googleAnalytics.cotizacion();
    navigateWithQueryParam(firstRoute, 'inmobiliaria');
    endLoading();
  };

  return (
    <>
      <TabPanel value={active} index={tabs[0].value}>
        <ApoyoInfonavitSimulator onSubmit={onSubmit} isVivienda={isVivienda} />
      </TabPanel>
      <TabPanel value={active} index={tabs[1].value}>
        <CofinavitSimulator onSubmit={onSubmit} isVivienda={isVivienda} />
      </TabPanel>
      <TabPanel value={active} index={tabs[2].value}>
        <FovisssteSimulator onSubmit={onSubmit} isVivienda={isVivienda} />
      </TabPanel>
    </>
  );
};

CreditSimulator.propTypes = {
  startLoading: PropTypes.func,
  endLoading: PropTypes.func,
  agentId: PropTypes.string,
};

CreditSimulator.defaultProps = {
  startLoading: () => { },
  endLoading: () => { },
  agentId: '',
};
