/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { scroller } from 'react-scroll';

import * as googleAnalytics from '../../../utils/googleAnalytics/events';
import { LandingLayout } from './layouts/LandingLayout';

import {
  Header, InfoTag,
} from './sections';
import { Loader, WhatsappFloat } from '../../../components';
import useLoading from '../../../hooks/useLoading';
import { CalCredit } from './sections/CalcCredit';
import useStyles from './useStyles';
import { InfoStepsComponent } from './sections/InfoStepsComponent';
import { FooterInfoImages } from './sections/FooterInfoImages';
import { FAQ } from './sections/FAQ';
import { HeaderBar } from '../components/HeaderBar';

const LandingVivienda = () => {
  const { isLoading, startLoading, endLoading } = useLoading();
  const classes = useStyles();
  const location = useLocation();

  const [agentId, setAgentId] = useState(null);

  useEffect(() => {
    googleAnalytics.visitaChiplo();
  }, []);

  const handleLoad = () => {
    const queryParams = new URLSearchParams(location.search);
    const agentIdParam = queryParams.get('agent');
    const currentURL = window.location.href;

    if (agentIdParam) {
      setAgentId(agentIdParam);
      queryParams.delete('agent');
      const id = location.hash.substring(1);

      if (id) {
        scroller.scrollTo(id, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
        const newURL = currentURL.replace(/(\?|&)agent=[^&]*/, '');
        window.history.replaceState({}, document.title, `${newURL}#${id}`);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <LandingLayout>
      <Loader open={isLoading} />
      <HeaderBar
        showLoginButton
        showSideBar
      />
      <Header />
      <CalCredit
        startLoading={startLoading}
        endLoading={endLoading}
        agentId={agentId}
        buttonTitle="Ver opciones de bancos"
      />
      <InfoStepsComponent />
      <InfoTag />
      <FAQ />
      <FooterInfoImages />
      <WhatsappFloat />
    </LandingLayout>
  );
};

export default LandingVivienda;
