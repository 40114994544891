import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  whatsappLogo: {

  },
  Float: {
    position: 'fixed',
    width: '60px',
    height: '60px',
    bottom: '50px',
    right: '15px',
    borderRadius: '50px',
    textAlign: 'center',
    fontSize: '30px',
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '40px',
    },
  },
  imageLogo: {
    width: '100%',
    height: 'auto',
  },
  myFloat: {
    marginTop: '16px',
  },
}));
