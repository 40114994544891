/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  gridContainer: {
    height: '100%',
  },
  gridItemLeft: {
    maxWidth: '600px',
    overflowY: 'auto',
    height: '100%',
    backgroundColor: '#E6F2FF',
    borderTopRightRadius: '195px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  innerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '90%',
  },
  information: {
    paddingTop: '15%',
  },
  title: {
    lineHeight: '24px',
    fontWeight: 700,
    fontSize: '2.5rem',
  },
  body: {
    lineHeight: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    color: '#707174',
    fontFamily: 'Montserrat, sans-serif',
    margin: '20px 0',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '360px',
    },
  },
  subtitle: {
    color: '#707174',
    lineHeight: '24px',
    paddingTop: '5%',
    paddingLeft: '15%',
    fontWeight: 600,
  },
  imgContainer: {
    width: '100%',
    maxWidth: '400px',
    maxHeight: '400px',
    paddingBottom: '8%',
  },
  imgMain: {
    width: '100%',
  },
  gridItemRight: {
    height: '100%',
  },
}));
