// /api/fields/company/:company/name/:fieldName
// /api/fields/company/:company/name/:fieldName
import { createInstance } from './config';

const BASE_URL = '/node';

export const getFieldsByNodeId = async (id) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/${id}/fields`);
  return data;
};

export const getNodeById = async (id, token) => {
  try {
    const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
    const { data } = await axios.get(`/node/${id}`);
    return data;
  } catch (err) {
    return null;
  }
};

export const autoExecuteNode = async (body, token) => {
  try {
    const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
    const { data } = await axios.post('/node/autoejecute', body);
    return data;
  } catch (err) {
    return null;
  }
};
