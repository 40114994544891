/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
} from '@material-ui/core';
import useStyles from './useStyles';

export const TabsChiplo = ({
  handleChange,
  tabs,
  initialValue,
}) => {
  const [valueTab, setValueTab] = useState(initialValue);
  const classes = useStyles();
  const onChange = (event, newValue) => {
    setValueTab(newValue);
    handleChange(newValue);
  };
  useEffect(() => {
    setValueTab(initialValue);
  }, [initialValue]);
  return (
    <Tabs
      value={valueTab}
      onChange={onChange}
      aria-label="wrapped label tabs"
      className={classes.tabs}
    >
      {
        tabs.map(({
          id,
          value,
          label,
        }) => (
          <Tab
            classes={{ root: classes.tab }}
            value={value}
            label={label}
            wrapped
            id={id}
            aria-controls={`wrapped-tabpanel-${id}`}
          />
        ))
      }
    </Tabs>
  );
};

TabsChiplo.propTypes = {
  tabs: PropTypes.array,
  handleChange: PropTypes.func,
  initialValue: PropTypes.string,
};

TabsChiplo.defaultProps = {
  tabs: [],
  handleChange: () => { },
  initialValue: '',
};
