import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ButtonMaterialUI from '@material-ui/core/Button';

import useStyles from './useStyles';

export const ButtonCredit = ({
  type,
  className,
  color,
  variant,
  fullWidth,
  disabled,
  onClick,
  children,
}) => {
  const classes = useStyles();

  return (
    <ButtonMaterialUI
      type={type}
      className={clsx(classes.button, className)}
      color={color}
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </ButtonMaterialUI>
  );
};

ButtonCredit.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  variant: PropTypes.oneOf(['contained', 'outlined']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

ButtonCredit.defaultProps = {
  type: 'button',
  className: '',
  color: 'primary',
  variant: 'contained',
  fullWidth: false,
  disabled: false,
  onClick: () => { },
  children: '',
};
