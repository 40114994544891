import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
  },
  sideBarContainer: {
    display: 'initial',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  container: {
    background: '#F7FAFD',
    height: '100%',
    width: '100%',
    padding: '0',
  },
  innerContainer: {
    maxWidth: '800px',
    width: '80%',
    margin: '0 auto',
  },
  gridContaienr: {
    height: '100%',
  },
  gridItem: {
    height: '100%',
  },
  gridLogo: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'initial',
    },
  },
  containerLogo: {
    display: 'initial',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    paddingTop: '8vh',
    paddingBottom: '4vh',
  },
  passwordContainer: {
    margin: '2rem 0',
  },
  passwordTitle: {
    color: theme.palette.primary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '600',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    margin: '1rem 0',
  },
  buttonContainer: {
    textAlign: 'center',
    margin: '2rem 0',
    marginBottom: '5px',
  },
  button: {
    fontSize: '1.25rem',
    padding: '0.75rem 2.5rem',
  },
  titleSection: {
    fontSize: '1.75rem',
    textAlign: 'center',
    marginTop: '0px',
    marginBottom: '20px',
    lineHeight: '2rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '20px',
      marginBottom: '56px',
    },
  },
  boxNotAccount: {
    textAlign: 'center',
  },
  notAccount: {
    paddingRight: '5px',
    color: '#A5A8AB',
    fontWeight: 600,
    fontSize: '1rem',
  },
  redirect: {
    fontWeight: 600,
    fontSize: '1rem',
    color: '#176BCD',
    cursor: 'pointer',
  },
}));
