import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './useStyles';

export const PageTitle = ({ title }) => {
  const classes = useStyles();

  return (

    <div className={classes.titleContainer}>
      <Typography
        variant="h1"
        className={classes.title}
      >
        {title}
      </Typography>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
