import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  textField: {
    overflow: 'hidden',
    backgroundColor: '#FFF',

    '& input': {
      width: '100%',
      maxWidth: '54px',

      height: '100vh',
      maxHeight: '64px',

      padding: '0px',

      fontSize: '34px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));
