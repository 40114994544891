import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    display: 'inline-block',
    color: '#2C1482',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '24px',
    marginBottom: '18px',
    marginTop: '18px',
  },
  containerCheckbox: {
    padding: '0px',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 20px',
    },
  },
  checkbox: {
    width: '30px',
    height: '30px',
    [theme.breakpoints.up('sm')]: {
      width: '20px',
      padding: '0px 5px',
    },
  },
  mainElement: {
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
  },
  firstElement: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
  },
  lastElement: {
    border: '1px solid transparent',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}));
