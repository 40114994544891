/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import { FOOTER_MENU_IMAGE } from '../../../../constants/commonUrls';

export const Sidebar = ({ open, toggleSidebar, options }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Drawer
      className={classes.sideDrawer}
      classes={{ paper: classes.paper }}
      open={open}
      anchor="left"
      onClose={toggleSidebar}
    >
      <Box className={classes.boxSidebar}>
        <Box className={classes.boxLogoMovil}>
          <Typography variant="h6" className={classes.itemLinkLogin}>
            Menú
          </Typography>
          <IconButton onClick={toggleSidebar} className={classes.btnCloseSidebar}>
            <Close />
          </IconButton>
        </Box>
        <Box className={classes.boxList}>
          {options.map((option) => (
            <IconButton key={option} onClick={option.action}>
              {option.icon}
              <Typography variant="h6" className={classes.itemLinkLogin}>
                {option.label}
              </Typography>
            </IconButton>
          ))}
        </Box>
        <Box className={classes.imageContainer}>
          <img
            src={FOOTER_MENU_IMAGE}
            alt="Logo"
            className={classes.imgLogo}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.node,
      action: PropTypes.func,
    }),
  ),
};

Sidebar.defaultProps = {
  toggleSidebar: () => { },
  options: [],
};
