import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { PerfiladorContext } from './perfiladorContext';
import {
  setCurrentInmobiliariaId,
  setPerfiladorGeneralConditions,
  setInmobiliariaConditions,
  selectPerfiladorInmobiliariaConditions,
  clearInmobiliariaConditions,
} from '../../store/perfilador';
import {
  getPerfiladorConditionsByCurrentInmobiliariaId,
  getPerfiladorCurrentInmobiliariaId,
  storeCurrentInmobiliariaId,
  storeInmobiliariaCreditConditions,
} from '../../storage';
import { objectHasProps } from '../../utils/commons';
import { setConditonsAlreadyEditted } from '../../utils/credit';

export const PerfiladorProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [_currentInmobiliariaId, _setCurrentInmobiliariaId] = useState(null);
  const currentInmobiliariaConditions = useSelector(selectPerfiladorInmobiliariaConditions);

  const setConditionsFromInmobiliariaData = ({ inmobiliariaId, creditConditions }) => {
    _setCurrentInmobiliariaId(inmobiliariaId);
    if (objectHasProps(creditConditions)) {
      dispatch(setInmobiliariaConditions({
        inmobiliariaId,
        creditConditions,
      }));
      dispatch(setCurrentInmobiliariaId(inmobiliariaId));
    }
  };

  const handleStorePerfiladorDataByInmobiliaria = () => {
    if (_currentInmobiliariaId && objectHasProps(currentInmobiliariaConditions)) {
      storeCurrentInmobiliariaId(_currentInmobiliariaId);
      const objConditions = {
        [_currentInmobiliariaId]: currentInmobiliariaConditions[_currentInmobiliariaId],
      };
      storeInmobiliariaCreditConditions(objConditions);
      setConditonsAlreadyEditted();
      return;
    }
    dispatch(clearInmobiliariaConditions());
  };

  const getAndSetConditionsByStoredInmobiliariaId = () => {
    const currentInmobiliariaId = getPerfiladorCurrentInmobiliariaId();
    const conditionsByInmobiliaria = getPerfiladorConditionsByCurrentInmobiliariaId();
    if (currentInmobiliariaId && objectHasProps(conditionsByInmobiliaria)) {
      dispatch(setInmobiliariaConditions({
        inmobiliariaId: currentInmobiliariaId,
        creditConditions: conditionsByInmobiliaria,
      }));
      dispatch(setCurrentInmobiliariaId(currentInmobiliariaId));
      _setCurrentInmobiliariaId(currentInmobiliariaId);
    } else {
      dispatch(clearInmobiliariaConditions());
    }
  };

  useEffect(() => {
    if (!_currentInmobiliariaId) {
      getAndSetConditionsByStoredInmobiliariaId();
    }

    return () => {
      dispatch(setCurrentInmobiliariaId(null));
      dispatch(setPerfiladorGeneralConditions(null));
    };
  }, [_currentInmobiliariaId]);

  const value = useMemo(() => ({
    currentInmobiliariaId: _currentInmobiliariaId,
    setConditionsFromInmobiliariaData,
    handleStorePerfiladorDataByInmobiliaria,
  }), [
    _currentInmobiliariaId,
    setConditionsFromInmobiliariaData,
    handleStorePerfiladorDataByInmobiliaria,
  ]);

  return (
    <PerfiladorContext.Provider value={value}>
      { children }
    </PerfiladorContext.Provider>
  );
};

PerfiladorProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
