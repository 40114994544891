import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingBottom: '80px',
    background: 'white',
    [theme.breakpoints.up('sm')]: {
      background: 'transparent',
      paddingBottom: '50px',
    },
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      margin: '0px 20px 0px 32px',
      width: 'calc(100% - 52px)',
    },
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      margin: '0px auto',
      maxWidth: '1600px',
      width: '100%',
    },
  },
  item: {
    flex: 1,
    width: '100%',
  },
  marginContainer: {
    margin: '0 auto',
    width: '95%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  innerItem: {
    color: 'white',
    height: '100%',
  },
  title: {
    color: '#06040A',
    fontSize: '36px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    letterSpacing: '0.36px',
    margin: '40px 0px 36px 0px',
    [theme.breakpoints.up('sm')]: {
      margin: '20px 0px 36px 0px',
      fontSize: '72px',
      letterSpacing: '0.72px',
    },
  },
  subtitleContainer: {
    paddingLeft: '32px',
    borderLeft: '6px solid #2C1482',
  },
  subTitle: {
    color: '#06040A',
    fontSize: '24px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    letterSpacing: '0.2px',
    lineHeight: '32px',
    margin: '24px 0px 24px 0px',
  },
  button: {
    fontSize: '22px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '500',
    letterSpacing: '0.2px',
    textTransform: 'none',
    lineHeight: '18px',
    color: '#FFF',
    background: '#2C1482',
    borderRadius: '40px',
    padding: '24px 40px',
    '&:hover': {
      background: '#2C1482',
      opacity: '0.6',
    },
  },
  imageContainer: {
    boxSizing: 'border-box',
    width: '95%',
    height: '100%',
    marginTop: '50px',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 40px)',
      marginTop: '0px',
      marginLeft: '40px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'inline-block',
    objectPosition: 'bottom',
  },
}));
