/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Checkbox } from '@material-ui/core';
import styles from './useStyles';
import Bank from '../Bank';
import { IMAGE_PRODUCT_DISABLED } from '../../../constants';

const BankCheckbox = ({
  imageUrl,
  id,
  monthlyPaid,
  tasa,
  firstElement,
  lastElement,
  checked,
  onCheck,
  cat,
  product,
  disabled,
  amortization,
  loanType,
  year,
  plazo,
}) => {
  const classes = styles();
  return (
    <div className={classes.container}>
      {disabled && (
        <div className={classes.containerDisabled}>
          <img src={IMAGE_PRODUCT_DISABLED} alt="product_disabled" className={classes.imageProductDisabled} />
        </div>
      )}
      <div className={classes.containerCheckbox}>
        <Checkbox
          className={classes.checkbox}
          checked={checked}
          onChange={onCheck}
          disabled={disabled}
        />
      </div>
      <div className={clsx(classes.mainElement, {
        [classes.firstElement]: firstElement,
        [classes.lastElement]: lastElement,
      })}
      >
        <Bank
          id={id}
          imageUrl={imageUrl}
          monthlyPaid={monthlyPaid}
          tasa={tasa}
          check
          cat={cat}
          product={product}
          amortization={amortization}
          loanType={loanType}
          year={year}
          plazo={plazo}
        />
      </div>
    </div>
  );
};

BankCheckbox.propTypes = {
  id: PropTypes.string,
  imageUrl: PropTypes.string,
  monthlyPaid: PropTypes.number,
  tasa: PropTypes.number,
  firstElement: PropTypes.bool,
  lastElement: PropTypes.bool,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  cat: PropTypes.string,
  product: PropTypes.string,
  disabled: PropTypes.bool,
  amortization: PropTypes.array,
  loanType: PropTypes.string,
  year: PropTypes.number,
  plazo: PropTypes.number,
};

BankCheckbox.defaultProps = {
  id: '',
  imageUrl: '',
  monthlyPaid: 0,
  tasa: 0,
  firstElement: false,
  lastElement: false,
  checked: false,
  onCheck: () => { },
  cat: '',
  product: '',
  disabled: false,
  amortization: [],
  loanType: '',
  year: null,
  plazo: null,
};

export default memo(BankCheckbox);
