/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './useStyles';
import CheckboxItem from './CheckboxItem';

export const CheckboxList = ({
  options,
  onChange,
  label,
  value,
  hide,
}) => {
  const classes = styles();
  return (
    <div className={hide ? classes.hide : ''}>
      {label ? <Typography variant="h2" className={classes.label}>{label}</Typography> : ''}
      <div className={classes.container}>
        {
          options.map(({
            name,
          }, index) => (
            <div key={name}>
              <CheckboxItem
                firstElement={index === 0}
                lastElement={index === (options.length - 1)}
                checked={value === name}
                onCheck={(e) => onChange(e, name)}
                label={name}
              />
            </div>
          ))
        }
      </div>
    </div>

  );
};

CheckboxList.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  hide: PropTypes.bool,
};

CheckboxList.defaultProps = {
  options: [],
  onChange: () => { },
  label: '',
  value: '',
  hide: false,
};
