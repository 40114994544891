import React, {
  memo, useCallback,
} from 'react';
import PropTypes from 'prop-types';

import InputRejected from '../InputRejected';
import useEnabler from '../../../hooks/useEnabler';

import emailIsValid from './validator';

const ERROR_MESSAGE_FORMAT = 'Email incompleto o con formato incorrecto';

const InputEmail = ({
  value, onChange, isEmpty, ...props
}) => {
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value: _value } = target; // AAAA000000AAAA0000

    if (emailIsValid(_value)) {
      target.setCustomValidity('');
      disableError();
    } else {
      target.setCustomValidity(ERROR_MESSAGE_FORMAT);
      enableError();
    }

    onChange(event, _value);
  }, [onChange, enableError, disableError]);

  return (
    <InputRejected
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isValid={emailIsValid(value)}
      value={value}
      onChange={handleOnChange}
      isEmpty={isEmpty}
      error={isError}
      helperText={isError ? ERROR_MESSAGE_FORMAT : ''}
    />
  );
};

InputEmail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isEmpty: PropTypes.any,
};

InputEmail.defaultProps = {
  isEmpty: undefined,
};

export default memo(InputEmail);
