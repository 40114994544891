import { Typography } from '@material-ui/core';

import { currencyFormatter, getMonthlyPaid } from '../utils';
import { calculateCreditoInfonavit } from '../utils/banksCalculations.utils';
import {
  getTipoPrestamoInfonavit,
  getSaldoSubcuentaInfonavit,
  getSueldoMensualBruto,
  getMontoCreditoInfonavit,
  getPropietyValue,
  getEnganche,
  getPlazo,
  saveExtraDataCredit,
  getImporte,
} from '../utils/credit';

import { replacingValuesOnString } from '../containers/main/utils/replacingValuesInString';

// eslint-disable-next-line no-unused-vars

const baseStrategy = {
  getBanksValues: ({
    banks, listBanks, tasas, year, importe,
  }) => banks.flows.map((banco) => {
    const product = listBanks[banco.bank];
    const { tasa } = tasas[banco.bank][product][year];
    return {
      imageUrl: banco.imageUrl,
      id: banco.id,
      tasa,
      monthlyPaid: getMonthlyPaid({
        importe,
        plazoMensual: year * 12,
        tasa: tasa * 0.01,
      }) || 0,
    };
  }),
  renderSubtitle: () => null,
  getInfoTitle: ({ infoTitle }) => replacingValuesOnString(infoTitle, {
    propietyValue: currencyFormatter(getPropietyValue()),
    enganche: currencyFormatter(getEnganche()),
    plazo: getPlazo(),
  }),
};

const creditoHipotecarioStrategy = {
  ...baseStrategy,
};

const viviendaStrategy = {
  ...baseStrategy,
};

const infonavitStrategy = {
  ...baseStrategy,
  getBanksValues: ({
    banks, listBanks, tasas, year, enganche, propietyValue,
  }) => banks.flows.map((banco) => {
    const product = listBanks[banco.bank];
    const { tasa } = tasas[banco.bank][product][year];
    const tipoPrestamoInfonavit = getTipoPrestamoInfonavit();
    const saldoSubcuentaInfonavit = getSaldoSubcuentaInfonavit() || 0;
    const montoCreditoInfonavit = getMontoCreditoInfonavit() || 0;
    const sueldoMensualBruto = getSueldoMensualBruto() || 0;

    const { pagoMensual: monthlyPaid, duracionPlazo, nuevoEnganche } = calculateCreditoInfonavit({
      propietyValue,
      tasa: tasa * 0.01,
      years: year,
      tipoPrestamoInfonavit,
      enganche,
      saldoSubcuentaInfonavit,
      montoCreditoInfonavit,
      sueldoMensualBruto,
    });
    saveExtraDataCredit({
      enganche: nuevoEnganche,
    });

    const plazo = (duracionPlazo !== null || undefined) && duracionPlazo < year
      ? duracionPlazo
      : null;

    return {
      imageUrl: banco.imageUrl,
      id: banco.id,
      tasa,
      monthlyPaid,
      loanType: tipoPrestamoInfonavit,
      year,
      plazo,
    };
  }),
  renderSubtitle: ({ classes, subtitle }) => {
    const tipoPrestamoInfonavit = getTipoPrestamoInfonavit();
    if (!subtitle || !Object.keys(subtitle).length) return null;
    const productSubtitle = subtitle[tipoPrestamoInfonavit];
    if (!productSubtitle) return null;
    const { text, subtitleLink } = productSubtitle;

    return (
      <div className={classes.subtContainer}>
        <Typography component="h2" className={classes.subtitle}>
          {`${text} `}
          {subtitleLink && (
            <a className={classes.subtitleLink} href={subtitleLink?.url} target="_blank" rel="noreferrer">
              {subtitleLink?.text}
            </a>
          )}
        </Typography>
      </div>
    );
  },
  getInfoTitle: ({ infoTitle }) => {
    const tipoPrestamoInfonavit = getTipoPrestamoInfonavit();
    const textInfoTitle = infoTitle[tipoPrestamoInfonavit];
    if (!textInfoTitle) return null;
    const engancheNumber = getEnganche();
    const engancheString = engancheNumber ? currencyFormatter(engancheNumber) : '$0.00';
    return replacingValuesOnString(textInfoTitle, {
      propietyValue: currencyFormatter(getPropietyValue()),
      importe: currencyFormatter(getImporte()),
      enganche: engancheString,
      plazo: getPlazo(),
    });
  },
};

const liquidezStrategy = {
  ...baseStrategy,
};

export {
  creditoHipotecarioStrategy,
  viviendaStrategy,
  infonavitStrategy,
  liquidezStrategy,
};
