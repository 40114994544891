/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import useStyles from './useStyles';

export const VideoPlayer = ({ url }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ReactPlayer
        url={url}
        className="react-player"
        width="100%"
        height="100%"
        controls
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
        }}
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string,
};

VideoPlayer.defaultProps = {
  url: '',
};
