// /api/fields/company/:company/name/:fieldName
// /api/fields/company/:company/name/:fieldName
import { createInstanceNoToken } from './config';

const BASE_URL = '/chiplo';

export const getInitialDataInClientsByLink = async (prospectoWebId, flowName) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.get(`${BASE_URL}/getaccountdata/prospectoWebId/${prospectoWebId}/flowName/${flowName}`);
  return data;
};

export const fetchRegisterFieldsFilledByLeadId = async (leadId, flow, company) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.get(`${BASE_URL}/getregisterfields/lead/${leadId}/flow/${flow}/company/${company}`);
  return data;
};

export const backLogger = async (body) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.post('/chiplo/logger', body);
  return data;
};
