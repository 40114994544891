import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    background: '#F7FAFD',
    height: 'calc(100% - 130px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
  },
}));
