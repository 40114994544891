import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    padding: theme.spacing(1.5, 3),
    fontSize: '1rem',
    fontWeight: 'bold',
    borderRadius: 50,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  },
  instagramButton: {
    backgroundColor: '#E1306C',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#C72E61',
    },
  },
  spotifyButton: {
    backgroundColor: '#1DB954',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1AA34A',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
