import { makeStyles } from '@material-ui/core/styles';

export const perfiladorLandingStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    paddingLeft: 'unset !important',
    paddingRight: 'unset !important',
  },
  tab: {
    fontSize: '14px',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    minHeight: '35px',
    padding: '5px 5px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '17px',
      lineHeight: '24px',
      minHeight: '40px',
    },
  },
  btn: {
    fontSize: '18px',
    fontFamily: 'Open Sans',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000',
    backgroundColor: '#FFA500',
    borderRadius: '5px',
    padding: '20px 44px',
    width: '100%',
    maxWidth: '414px',
  },
  gridContainer: {
    width: '100%',
    margin: 'unset',
    padding: 'unset',
  },
  gridLeft: {
    padding: 'unset !important',
  },
  gridRight: {
    border: '1px solid #E2DBDB',
    borderRadius: '10px',
    width: '100%',
    margin: 'unset',
    [theme.breakpoints.up('sm')]: {
      border: 'none',
      borderRadius: '0px',
      paddingLeft: '30px !important',
      paddingRight: '30px !important',
      borderLeft: '1px solid #7C839D',
    },
  },
  subtitleConditions: {
    color: '#313132',
    textAlign: 'center',
  },
  valueClassName: {
    color: '#313132',
  },
  titleClassName: {
    color: '#313132',
  },
  input: {
    marginBottom: '15px',
    width: 'calc(100% - 4px) !important',
    border: `2px solid ${theme.palette.primary.main} !important`,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px',
    },
  },
  title: {
    color: '#313132 !important',
    marginBottom: '15px',
    fontSize: '20px !important',
    fontWeight: '400',
    lineHeight: '24px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '10px !important',
    },
  },
}));
