/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const creditConditionsSlice = createSlice({
  name: 'creditConditions',
  initialState: {
    prestamoValue: 0,
    monthlyPay: 0,
    years: 0,
    values: {},
    engancheValue: 0,
  },
  reducers: {
    setPrestamoValue: (state, action) => {
      state.prestamoValue = action.payload;
    },
    setMonthlyPay: (state, action) => {
      state.monthlyPay = action.payload;
    },
    setYears: (state, action) => {
      state.years = action.payload;
    },
    setValues: (state, action) => {
      state.values = action.payload;
    },
    setEngancheValue: (state, action) => {
      state.engancheValue = action.payload;
    },
  },
});

export const {
  setPrestamoValue,
  setEngancheValue,
  setMonthlyPay,
  setYears,
  setValues,
} = creditConditionsSlice.actions;
