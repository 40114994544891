import { createInstance } from './config';

const BASE_URL = '/process';

export const generateCalculationsArrendamiento = async (process, companyId) => {
  const axios = createInstance();
  const { data } = await axios.post(`${BASE_URL}/${process}/company/${companyId}/generatecalculationsarrendamiento`, {});
  return data;
};

export const setTasaBroker = async (processId, companyId, fieldValuesData) => {
  const body = {
    processId,
    companyId,
    fieldValuesData,
  };
  const axios = createInstance();
  const { data } = await axios.post(`${BASE_URL}/brokertasacat`, body);
  return data;
};

export const generateCalculations = async (process, companyId) => {
  const axios = createInstance();
  const { data } = await axios.post(`${BASE_URL}/${process}/company/${companyId}/generatecalculations`, {});
  return data;
};

export const generateCalculationsDebtor = async (process, companyId, body) => {
  const axios = createInstance();
  const { data } = await axios.post(`${BASE_URL}/${process}/company/${companyId}/generatecalculationsdebtor`, body);
  return data;
};

export const getProcessByClient = async (client) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/client/${client}`);
  return data;
};

export const getProcessListCash = async (client) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/client/${client}/processlist/cash`);
  return data;
};

export const createProcess = async (body, companyId, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}/company/${companyId}/docupassProcess`, body);
  return data;
};

export const createTag = async ({
  tagName, active, body, processId, token = '',
}) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.post(`${BASE_URL}/${processId}/setTag/${tagName}/active/${active}`, body);
  return data;
};

export const getProcessById = async (id, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.get(`${BASE_URL}/${id}`);
  return data;
};

export const getDataDownload = async (processId, stepId) => {
  const axios = createInstance();
  const { data } = await axios.get(
    `/process/${processId}/step/${stepId}/normaldownload`,
  );
  return data;
};

export const updateProcess = async (item, processId, token) => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const { data } = await axios.put(`${BASE_URL}/${processId}`, item);
  return data;
};

export const updateModalViewFinish = async (process) => {
  const axios = await createInstance();
  const { data } = await axios.put(`${BASE_URL}/${process}`, { modalViewFinish: true });
  return data;
};

export const sendDocumentEmail = async (processId) => {
  const axios = await createInstance();
  const { data } = await axios.post(`${BASE_URL}/sendCarruselNodes/process/${processId}/notify`);
  return data;
};
