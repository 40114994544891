import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formContainer: {},
  viviendaInnerContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '750px',
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      margin: '0 auto',
      width: '95%',
      justifyContent: 'center',
      borderRadius: '20px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  },
  firtInputElement: {
    paddingLeft: '10px',
    paddingRight: '10px',
    flexBasis: '100%',
    [theme.breakpoints.up('md')]: {
      flexBasis: '0%',
      flex: 1,
      margin: '0px 10px 0px 10px',
      paddingTop: '20px',
      paddingBottom: '20px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  inputPrecio: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
    },
  },
  montos: {
    color: '#206BDF',
  },
  engancheInput: {
    width: '100%',
  },
  secondInputElement: {
    boxSizing: 'border-box',
    margin: '30px 0px 15px 0px',
    flexBasis: '50%',
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.up('md')]: {
      flexBasis: '0%',
      flex: 1,
      margin: '0px 10px 0px 10px',
      paddingTop: '20px',
      paddingBottom: '20px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  invalidate: {
    color: 'red',
  },
  thirdInputElement: {
    boxSizing: 'border-box',
    margin: '15px 0px 30px 0px',
    flexBasis: '50%',
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.up('md')]: {
      flexBasis: '0%',
      flex: 1,
      margin: '0px 10px 0px 10px',
      paddingTop: '20px',
      paddingBottom: '20px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  thirdInputElementLeft: {
    boxSizing: 'border-box',
    margin: '15px 0px 30px 0px',
    flexBasis: '50%',
    paddingLeft: '10px',
    [theme.breakpoints.up('md')]: {
      flexBasis: '0%',
      flex: 1,
      margin: '0px 10px 0px 10px',
    },
  },
  thirdInputElementRight: {
    boxSizing: 'border-box',
    margin: '15px 0px 30px 0px',
    flexBasis: '50%',
    paddingRight: '10px',
    [theme.breakpoints.up('md')]: {
      flexBasis: '0%',
      flex: 1,
      margin: '20px 20px 20px 20px',
    },
  },
  hidden: {
    display: 'none',
  },
  borderRight: {
    borderRight: '3px solid #dedeeb',
    [theme.breakpoints.up('md')]: {
      borderRight: '2px solid #dedeeb',
      borderLeft: '2px solid #dedeeb',
    },
  },
  inputElement: {
    width: '100%',
  },
  element: {
    flex: 1,
    padding: '0px 0px 0px 0px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.up('md')]: {
      marginTop: '10px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  containerButton: {
    textAlign: 'center',
  },
  button: {
    marginTop: '70px',
    marginBottom: '50px',
    fontSize: '18px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000',
    backgroundColor: '#FFA500',
    padding: '20px 44px',
    textTransform: 'capitalize',
    borderRadius: '40px',
    '&:hover': {
      backgroundColor: '#FFA500',
      opacity: '0.6',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#FFA500',
      marginTop: '70px',
      marginBottom: '70px',
    },
  },
}));
