import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import useStyles from './useStyles';
// import { DEFAULT_PLAZO_20 } from '../../../../constants';
import { selectPerfiladorengancheMinimoPercentage, selectPerfiladorPlazo } from '../../../../store/perfilador';

export const FooterTextPerfilador = () => {
  const classes = useStyles();
  const plazo = useSelector(selectPerfiladorPlazo);
  const engancheMinimoPercentage = useSelector(selectPerfiladorengancheMinimoPercentage);

  return (
    (plazo && engancheMinimoPercentage) ? (
      <Typography className={classes.footerText}>
        {`Estimación realizada con ${engancheMinimoPercentage}% de enganche y plazo de ${plazo}. Las condiciones finales serán definidas al autorizar el crédito.`}
      </Typography>
    ) : null
  );
};
