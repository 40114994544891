import { dispatchAdapter } from '../utils/adapter';

export const useFieldAutoFill = ({ process = '' }) => {
  const formatFieldValues = (fields, fieldValues) => {
    const formattedFieldValues = fields.map((field) => {
      const {
        _id, config, name, fieldType,
      } = field;
      const { value = '' } = fieldValues.find(({ field: { _id: fieldId = '' } }) => fieldId.toString() === _id.toString()) || {};
      const fieldValueObject = {
        name,
        field: _id,
        value: value || '',
        config,
        fieldType,
      };
      if (!process) return fieldValueObject;
      return {
        ...fieldValueObject,
        process,
      };
    });
    return formattedFieldValues;
  };

  const fillValueFromFunctions = (fields, fieldValuesArray) => {
    const fieldValues = formatFieldValues(fields, fieldValuesArray);
    const fieldValuesMap = fieldValues.reduce((acc, x) => {
      const { name } = x;
      acc[name] = x;
      return acc;
    }, {});

    const newFieldValues = fieldValues.map(
      (fieldValue) => dispatchAdapter(fieldValue, fieldValuesMap),
    );
    return newFieldValues;
  };

  return {
    fillValueFromFunctions,
  };
};
