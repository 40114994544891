import { createInstance } from './config';

/* eslint-disable-next-line */
export const signFile = async (file) => {
  const axiosConfig = createInstance();
  const response = await axiosConfig.post('/s3/signFile', { file });
  const { data } = response;
  return { ...data };
};

export const signFileFad = async (file) => {
  const axiosConfig = createInstance();
  const response = await axiosConfig.post('/s3/signFile/fad', { file });
  const { data } = response;
  return { ...data };
};

export const uploadToS3 = async (file, fileName) => {
  const axiosConfig = createInstance();
  const encodedFileName = encodeURIComponent(fileName);
  const formData = new FormData();
  formData.append('file', file);
  const options = {
    headers: {
      'Content-Type': undefined,
    },
  };
  const response = await axiosConfig.put(`/s3/uploadFile/fileName/${encodedFileName}`, formData, options);
  const { data } = response;
  return data;
};

export const getSignedUrl = async (url) => {
  try {
    const axiosConfig = createInstance();
    const response = await axiosConfig.post('/s3/getSignedUrlEncrypted', { url });
    const { data } = response;
    return data;
  } catch (err) {
    return '';
  }
};
