import { createInstance } from './config';

const BASE_URL = '/profileData';

export const create = async (profileData) => {
  const axios = await createInstance();
  const { data } = await axios.post(BASE_URL, profileData);
  return data;
};

export const createDefaultValues = async (defaultValues, token = '') => {
  const axios = await createInstance({ checkToken: true, token });
  const { data } = await axios.post(`${BASE_URL}/defaultValues`, defaultValues);
  return data;
};

export const getAllByProfileId = async (profileId) => {
  const axios = await createInstance();
  const { data } = await axios.get(`${BASE_URL}/profileId/${profileId}`);
  return data;
};

export const createAddress = async (profileId, processId, address) => {
  const axios = await createInstance();
  const url = `${BASE_URL}/profile/${profileId}/process/${processId}/address`;
  const { data } = await axios.post(url, { address });
  return data;
};
