import { DefaultTheme } from '../themes/DefaultTheme';
import logoUrl from '../assets/images/chiplo.svg';
import { IMAGE_HEADER } from '../constants/commonUrls';

export const getLogoAndImageTemplate = (product = '') => {
  switch (product) {
    default:
      return {
        logoUrl,
        mainImageUrl: IMAGE_HEADER,
        palette: DefaultTheme,
      };
  }
};
