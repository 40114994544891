import { createInstance } from './config';

const BASE_URL = '/timeline';

export const getTimelineByProcessId = async (processId, company) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/process/${processId}/company/${company}`);
  return data;
};

export const completeTimelineTagByTagName = async (processId, company, { tagName, tagBody = {} }, token = '') => {
  const axios = token ? createInstance({ checkToken: false, token }) : createInstance();
  const body = {
    tagName,
    tagBody,
  };
  const { data } = await axios.post(`${BASE_URL}/complete/process/${processId}/company/${company}`, body);
  return data;
};
