export const DOCUMENTO_COMPLETO_URL = 'https://fintecimal-common.s3.amazonaws.com/broker/DocumentoCompleto.png';
export const DOCUMENTO_EMPTY = 'https://fintecimal-common.s3.amazonaws.com/broker/DocumentEmpty.png';
export const CHIPLO_CASA_LOGO = 'https://fintecimal-common.s3.amazonaws.com/broker/chiploCasaLogo.png';
export const WHATS_ICON_LOGO = 'https://fintecimal-common.s3.amazonaws.com/broker/whatsapp-icon-logo.svg';
export const FOOTER_MENU_IMAGE = 'https://fintecimal-common.s3.amazonaws.com/broker/footer_menu_image.png';
export const IMAGE_HEADER = 'https://fintecimal-common.s3.amazonaws.com/broker/img_header.png';
export const DOCUPASS_PORTAL_SITE_PRODUCTION_URL = 'https://dashboard.docupass.co/portal';
export const DOCUPASS_PORTAL_SITE_TEST_URL = 'https://fintecimal-nodos-test.herokuapp.com/portal';
export const DOCUPASS_PORTAL_SITE_LOCAL_URL = 'http://localhost:1337/portal';
export const CHIPLO_HOSTNAME_PRODUCTION = 'www.chiplo.com';
export const CHIPLO_HEROKU_PRODUCTION_HOSTNAME = 'broker-production-464cd84fa52e.herokuapp.com';
export const CHIPLO_HOSTNAME_TEST = 'broker-test-8439af69dedb.herokuapp.com';
export const LOCALHOST_NAME = 'localhost';
