import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  FormControlLabel,
  Checkbox,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from './useStyles';

const ERROR_MESSAGE_EMPTY = 'Rellena este campo';

const InputCheckboxOld = ({
  id,
  name,
  label,
  value,
  disabled,
  className,
  required,
  onChange,
}) => {
  const inputRef = useRef(null);
  const classes = useStyles();

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { checked } = target;
    if (required && !checked) target.setCustomValidity(ERROR_MESSAGE_EMPTY);
    else target.setCustomValidity('');
    onChange(event, checked);
  }, [onChange, required]);

  useEffect(() => {
    if (!value && required) {
      inputRef.current.setCustomValidity(ERROR_MESSAGE_EMPTY);
    }
  }, [value, required]);

  return (
    <Container
      disableGutters
      maxWidth={false}
      className={classes.container}
    >
      <FormControlLabel
        id={id}
        inputRef={inputRef}
        name={name}
        onChange={handleOnChange}
        disabled={disabled}
        required={required}
        control={<Checkbox className={classes.Checkbox} size="medium" checked={value} />}
        label={label}
        className={className}
        classes={{
          label: classes.checkBoxLabel,
        }}
      />
    </Container>
  );
};

InputCheckboxOld.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

InputCheckboxOld.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  value: undefined,
  disabled: false,
  className: '',
  required: true,
  onChange: () => { },
};

export default memo(React.forwardRef(InputCheckboxOld));
