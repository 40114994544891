/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import { addMonths } from 'date-fns';
import {
  INFONAVIT_APOYO_INFONAVIT_TYPE,
  INFONAVIT_COFINAVIT_TYPE,
  INFONAVIT_FOVISSSTE_TYPE,
  INFONAVIT_TRADICIONAL_TYPE,
  MAX_ABONO_BIMESTRAL_INFONAVIT,
} from '../constants';

const calculateTasaMensual = (tasa) => tasa / 12;

const calculateMesesPlazoPrestamo = (loanTermInYears) => loanTermInYears * 12;

const calculatePagoMensual = (
  tasaMensual,
  plazoMeses,
  montoPrestamo,
) => (montoPrestamo * tasaMensual)
  / (1 - (1 + tasaMensual) ** -plazoMeses);

export const determineMontoPrestamo = (
  tipoPrestamoInfonavit,
  propietyValue,
  enganche,
  montoCreditoInfonavit,
  saldoSubcuentaInfonavit,
) => {
  let montoPrestamo;
  switch (tipoPrestamoInfonavit) {
    case INFONAVIT_TRADICIONAL_TYPE:
    case INFONAVIT_APOYO_INFONAVIT_TYPE:
      montoPrestamo = propietyValue - enganche;
      break;
    case INFONAVIT_COFINAVIT_TYPE:
      const totalInfonavit = montoCreditoInfonavit + saldoSubcuentaInfonavit;
      enganche = totalInfonavit >= propietyValue * 0.1
        ? 0
        : propietyValue * 0.1 - totalInfonavit;
      montoPrestamo = propietyValue - enganche - totalInfonavit;
      break;
    case INFONAVIT_FOVISSSTE_TYPE:
      enganche = saldoSubcuentaInfonavit >= propietyValue * 0.1
        ? 0
        : propietyValue * 0.1 - saldoSubcuentaInfonavit;
      montoPrestamo = propietyValue - enganche - saldoSubcuentaInfonavit;
      break;
    default:
      return null;
  }
  return { enganche, montoPrestamo };
};

const isLastPago = (saldo) => saldo < 0;

const fixLastPagoMenusal = (saldoInicial, saldoFinal, abonoACapital, pagoMensual) => {
  if (isLastPago(saldoFinal)) {
    if (saldoInicial < abonoACapital) return 0;
    if (saldoInicial < pagoMensual) return saldoInicial - abonoACapital;
  }
  return pagoMensual;
};

const fixLastAbonoACapital = (saldoInicial, saldoFinal, abonoACapital) => {
  if (isLastPago(saldoFinal)) {
    if (saldoInicial < abonoACapital) return saldoInicial;
  }
  return abonoACapital;
};

export const calculateAmortizacion = (
  tipoDeCalculo,
  fechaInicio,
  plazoMeses,
  pagoMensual,
  tasaMensual,
  montoPrestamo,
  sueldoMensualBruto,
) => {
  const amortizacion = [];
  let saldoInicial = montoPrestamo;

  // Suponiendo que las siguientes variables sean proporcionadas
  let numeroMeses = 0;
  if (tipoDeCalculo === INFONAVIT_APOYO_INFONAVIT_TYPE) {
    while (saldoInicial > 0) {
      numeroMeses += 1;
      const abonoBimestralInfonavit = (sueldoMensualBruto * 0.1) > MAX_ABONO_BIMESTRAL_INFONAVIT
        ? MAX_ABONO_BIMESTRAL_INFONAVIT
        : sueldoMensualBruto * 0.1;
      const abonoACapital = (numeroMeses % 2 === 0) ? abonoBimestralInfonavit : 0;
      const pagoInteres = saldoInicial * tasaMensual;
      const pagoACapital = pagoMensual - pagoInteres + abonoACapital;
      const saldoFinal = saldoInicial - pagoACapital;
      const fechaDePago = addMonths(fechaInicio, numeroMeses - 1);

      // Fix de texto para la tabla en ultima fila, no afecta el cálculo
      const pagoMensualFix = fixLastPagoMenusal(saldoInicial, saldoFinal, abonoACapital, pagoMensual);
      const abonoACapitalFix = fixLastAbonoACapital(saldoInicial, saldoFinal, abonoACapital);
      const saldoFinalFix = isLastPago(saldoFinal) ? 0 : saldoFinal;
      const pagoACapitalFix = isLastPago(saldoFinal) ? saldoInicial - pagoInteres : pagoACapital - abonoACapitalFix;

      amortizacion.push({
        fechaDePago: { name: 'fechaDePago', label: 'Fecha de Pago', value: fechaDePago.toISOString().slice(0, 10) },
        saldoInicial: { name: 'saldoInicial', label: 'Saldo Inicial', value: saldoInicial },
        pagoInteres: { name: 'pagoInteres', label: 'Pago Intéres', value: pagoInteres },
        pagoCapital: { name: 'pagoCapital', label: 'Pago Capital', value: pagoACapitalFix },
        pagoMensual: { name: 'pagoMensual', label: 'Pago Mensual', value: pagoMensualFix },
        abonoCapital: { name: 'abonoCapital', label: 'Abono Capital', value: abonoACapitalFix },
        saldoFinal: { name: 'saldoFinal', label: 'Saldo Final', value: saldoFinalFix },
      });

      saldoInicial = saldoFinal;
    }
  } else {
    for (let numMeses = 1; numMeses <= plazoMeses; numMeses++) {
      const pagoInteres = saldoInicial * tasaMensual;
      const pagoACapital = pagoMensual - pagoInteres;
      const saldoFinal = saldoInicial - pagoACapital;
      const fechaDePago = addMonths(fechaInicio, numMeses - 1);

      // Fix de texto para la tabla en ultima fila, no afecta el cálculo
      const pagoMensualFix = isLastPago(saldoFinal) ? saldoInicial : pagoMensual;
      const saldoFinalFix = isLastPago(saldoFinal) ? 0 : saldoFinal;
      const pagoACapitalFix = isLastPago(saldoFinal) ? saldoInicial - pagoInteres : pagoACapital;

      amortizacion.push({
        fechaDePago: { name: 'fechaDePago', label: 'Fecha de Pago', value: fechaDePago.toISOString().slice(0, 10) },
        saldoInicial: { name: 'saldoInicial', label: 'Saldo Inicial', value: saldoInicial },
        pagoInteres: { name: 'pagoInteres', label: 'Pago Interés', value: pagoInteres },
        pagoCapital: { name: 'pagoCapital', label: 'Pago Capital', value: pagoACapitalFix },
        pagoMensual: { name: 'pagoMensual', label: 'Pago Mensual', value: pagoMensualFix },
        saldoFinal: { name: 'saldoFinal', label: 'Saldo Final', value: saldoFinalFix },
      });

      saldoInicial = saldoFinal;
    }
  }

  const plazoYears = numeroMeses ? numeroMeses / 12 : plazoMeses / 12;

  const duracionPlazo = Number(plazoYears.toFixed(1));

  return {
    amortizacion,
    duracionPlazo,
  };
};

export const calculateCreditoInfonavit = ({
  propietyValue,
  tasa,
  years,
  fechaInicio = new Date(),
  tipoPrestamoInfonavit,
  enganche = 0,
  saldoSubcuentaInfonavit = 0,
  montoCreditoInfonavit = 0,
  sueldoMensualBruto = 0,
}) => {
  const tasaMensual = calculateTasaMensual(tasa);
  const plazoMeses = calculateMesesPlazoPrestamo(years);
  const { enganche: nuevoEnganche, montoPrestamo } = determineMontoPrestamo(
    tipoPrestamoInfonavit,
    propietyValue,
    enganche,
    montoCreditoInfonavit,
    saldoSubcuentaInfonavit,
  );
  const pagoMensual = calculatePagoMensual(
    tasaMensual,
    plazoMeses,
    montoPrestamo,
  );
  const { duracionPlazo, amortizacion } = calculateAmortizacion(
    tipoPrestamoInfonavit,
    fechaInicio,
    plazoMeses,
    pagoMensual,
    tasaMensual,
    montoPrestamo,
    sueldoMensualBruto,
  );

  return {
    nuevoEnganche,
    pagoMensual,
    amortizacion,
    duracionPlazo,
    montoPrestamo,
  };
};

// Example usage
// const { pagoMensual, amortizacion, duracionPlazo } = calculateCreditoInfonavit({
//   propietyValue: 1350000,
//   tasa: 0.1078,
//   years: 20,
//   tipoPrestamoInfonavit: INFONAVIT_COFINAVIT_TYPE,
//   enganche: 135000,
//   saldoSubcuentaInfonavit: 200000,
//   montoCreditoInfonavit: 250000,
//   sueldoMensualBruto: 70000,
// });
