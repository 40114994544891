import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    width: '100%',
  },
  mensualidad: {
    fontFamily: 'Montserrat, sans-serif',
    color: '#000F8F',
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    marginBottom: '40px',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  button: {
    padding: '12px 36px',
    fontFamily: 'Montserrat, sans-serif',
    color: '#FFF',
    fontWeight: '700',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    backgroundColor: '#176BCD',
    '&:hover': {
      backgroundColor: '#176BCD',
      opacity: '0.6',
    },
  },
  icon: {
    marginRight: '8px',
    fontSize: '2rem',
  },
}));
