export const PERFILAMIENTO_CREDITICIO = 'PERFILAMIENTO_CREDITICIO';
export const PERFILAMIENTO = 'PERFILAMIENTO';
export const PROFILING_MODAL = 'PROFILING_MODAL';
export const PROFILING_COTIZATION = 'PROFILING_COTIZATION';
export const PROFILING_UPLOADING = 'PROFILING_UPLOADING';
export const PROFILING_COMPLETED = 'PROFILING_COMPLETED';
export const PROFILING_EMPTY_MATRIZ = 'PROFILING_EMPTY_MATRIZ';
export const PERFILAMIENTO_EXIST = 'PERFILAMIENTO_EXIST';
export const PERFILAMIENTO_EMPTY = 'PERFILAMIENTO_EMPTY';
export const PERFILAMIENTO_RECUPERACION_PRODUCTOS_TITLE = 'No se encontraron productos hipotecarios para los montos indicados, pero podemos ofrerte un crédito por:';
export const PERFILAMIENTO_MODAL_COMPLETE = 'PERFILAMIENTO_MODAL_COMPLETE';

export const APPLICATION_COMPLETED = 'APPLICATION_COMPLETED';
export const MODAL_PROPIEDAD_EXTRA_OPTIONS = 'modalPropiedad';
export const BEST_FINANTIAL_OPTION = 'bestFinantialOption';
export const BEST_INITIAL_OUTLAY = 'bestInitialOutlay';
export const FIELDS_MODAL_EXTRA_OPTIONS = [
  {
    title: '¿Estas dispuesto a comprar una propiedad de menor valor?',
    type: 'modalPropiedad',
    fields: [
      'propiedadvalor',
    ],
    fieldsDefault: [],
  },
  {
    title: '¿Tienes algún conocido que puediera ser tu coacreditado?',
    type: 'modalCoacreditado',
    fields: [
      'relacionConCoacreditado',
      'employeeTypeCoacreditado',
      'ingresosTotalesCoacreditado',
    ],
    fieldsDefault: [
      {
        field: '64dabc22067071a24e24446d',
        name: 'relacionConCoacreditado',
        value: 'Uno Adicional',
        config: {},
      },
    ],
  },
];
