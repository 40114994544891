import {
  Box,
  Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import useStyles from './useStyles';
// import { convertirAMoneda } from '../../../utils/currency';

export const BanksTableComparative = ({ title, banksList }) => {
  const classes = useStyles();

  if (!banksList || banksList.length === 0) return null;

  const isWhiteCell = (index) => index % 2 !== 0;

  return (
    <div className={classes.container}>
      <Typography className={classes.titleContainer}>{title}</Typography>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableBody>
            {banksList.map(({
              producto, imageUrl, cat, bank,
            }, index) => (
              <TableRow className={clsx(classes.rowContainer, {
                [classes.rowWhiteColor]: isWhiteCell(index),
              })}
              >
                <TableCell
                  className={classes.cellContainer}
                >
                  <div className={classes.imageContent}>
                    <div className={classes.imageContainer}>
                      <img
                        className={classes.image}
                        src={imageUrl}
                        alt={imageUrl}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className={classes.cellContainer}>
                  <Box className={classes.propContainer}>
                    <Typography className={classes.propTitle}>
                      {bank}
                    </Typography>
                    <Typography className={classes.propSubtitle}>
                      {producto}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell className={classes.cellContainerCentered}>
                  <Box className={classes.propContainerCentered}>
                    <Typography className={classes.propTitle}>{`${cat}% CAT`}</Typography>
                  </Box>
                </TableCell>
                {/* <TableCell className={classes.cellContainer}>
                    <Typography className={classes.propValue}>
                      <span className={classes.difference}>{convertirAMoneda(diferencia)}</span>
                      {' '}
                      más caro que tu mejor opción
                    </Typography>
                  </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

BanksTableComparative.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  banksList: PropTypes.array,
};

BanksTableComparative.defaultProps = {
  title: '',
  banksList: [],
};
