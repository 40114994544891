import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
  },
  mensualidad: {
    fontFamily: 'Montserrat, sans-serif',
    color: '#000F8F',
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    marginBottom: '40px',
  },
  containerFields: {
    marginTop: '80px',
  },
  customFieldsTitle: {
    textAlign: 'center',
  },
}));
