/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '20px',
  },
  title: {
    color: '#2C1482',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '24px',
    textTransform: 'capitalize',
    margin: '0',
  },
}));
