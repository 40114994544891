import jwtDecode from 'jwt-decode';
import moment from 'moment';

export const getExpirationTime = (token) => {
  try {
    const { exp = 0 } = jwtDecode(token) || {};
    if (!exp) return 0;
    const expNormalized = exp * 1000;
    const diff = moment(expNormalized) - moment(Date.now());
    return diff < 0 ? 0 : diff;
  } catch (e) {
    return 0;
  }
};

export const getEmailFromToken = (token) => {
  const { validator: { email = '' } = {} } = jwtDecode(token);
  return email;
};
