import { INITIAL_TABS, UDATE_ACTIVE_TAB } from './constants';

export const tabsReducer = (state, action) => {
  switch (action.type) {
    case INITIAL_TABS:
      return {
        ...state,
        tabs: action.payload.tabs,
        active: action.payload.default,
      };
    case UDATE_ACTIVE_TAB:
      return {
        ...state,
        active: action.payload,
      };
    default:
      return { ...state };
  }
};
