import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    background: '#F7FAFD',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '4px',
    paddingRight: '4px',
    // overflowY: 'auto',
    backgroundColor: '#F7FAFD',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
  },
  fieldContainer: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'normal',
    position: 'relative',
    flexDirection: 'column',
  },
  fieldsContainer: {
    width: '100%',
    marginBottom: '10px',
  },
  input: {
    flexGrow: 1,
  },
  helpButton: {
    width: 'calc(100% - 50px)',
    alignSelf: 'flex-start',
  },
}));
