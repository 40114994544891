const getRFCValidator = (size = 0) => {
  if (size === 12) {
    return /[A-Z]{3}[0-9][0-9][0-1][0-9][0-3][0-9][A-Z0-9]{3}/;
  }
  return /[A-Z]{4}[0-9][0-9][0-1][0-9][0-3][0-9][A-Z0-9]{3}/;
};

const rfcIsValid = (value) => {
  const size = value.length;
  const validator = getRFCValidator(size);
  const MIN_SIZE = 10;
  const MAX_SIZE = 13;

  const sizeIsValid = size >= MIN_SIZE && size <= MAX_SIZE;
  const valueIsValid = validator.test(value);

  return sizeIsValid && valueIsValid;
};

export default rfcIsValid;
