import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({
  errorTypeLabel,
  errorType,
  errorMessageLabel,
  errorMessage,
}) => (
  <div>
    {
      errorType && (
        <div>
          <strong>
            { errorTypeLabel }
          </strong>
          <span>
            { errorType }
          </span>
        </div>
      )
    }
    <div>
      <strong>
        { errorMessageLabel }
      </strong>
      <span>
        { errorMessage }
      </span>
    </div>
  </div>
);

ErrorMessage.propTypes = {
  errorTypeLabel: PropTypes.string,
  errorType: PropTypes.string,
  errorMessageLabel: PropTypes.string,
  errorMessage: PropTypes.string,
};

ErrorMessage.defaultProps = {
  errorTypeLabel: 'Tipo de error: ',
  errorType: '',
  errorMessageLabel: 'Error: ',
  errorMessage: '',
};

export default memo(ErrorMessage);
