/* eslint-disable no-fallthrough */
/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { useSearchParams } from 'react-router-dom';
import useStateMachine from './hooks/useStateMachine';
import { LoaderPage } from './LoaderPage';
import { CreateClientByLink } from './CreateClientByLink';
import CreateClient from './CreateClient';
import { useCreateAccount } from '../../../../hooks/useCreateAccount';
import { getAndSetStrategyByName } from '../../../../utils/strategies.utils';

import { CREATE_ACCOUNT_STRATEGY } from '../../../../constants';

const CreateAccountPage = ({ productStrategy }) => {
  const { changeStrategy } = useCreateAccount();

  const [searchParams] = useSearchParams();
  const {
    STATES,
    currentState,
    goToClientPage,
    goToClientPageByLink,
  } = useStateMachine();
  const clientByLinkPage = () => {
    goToClientPageByLink();
  };
  const clientPage = () => {
    goToClientPage();
  };
  const selectPage = () => {
    const client = searchParams.get('client');
    if (client) {
      clientByLinkPage();
    } else {
      clientPage();
    }
  };
  useEffect(() => {
    selectPage();
  }, []);

  useEffect(() => {
    getAndSetStrategyByName(
      CREATE_ACCOUNT_STRATEGY,
      productStrategy,
      changeStrategy,
    );
  }, [productStrategy, changeStrategy]);

  return (
    <>
      {
        (
          () => {
            switch (currentState) {
              case STATES.CREATE_CLIENT_PAGE:
                return (
                  <CreateClient />
                );
              case STATES.CREATE_CLIENT_BY_LINK_PAGE:
                return (
                  <CreateClientByLink />
                );
              case STATES.CREATE_CLIENT_LOADER_PAGE:
              default:
                return (
                  <LoaderPage />
                );
            }
          }
        )()
      }
    </>

  );
};

export default CreateAccountPage;

CreateAccountPage.propTypes = {
  productStrategy: PropTypes.string,
};

CreateAccountPage.defaultProps = {
  productStrategy: '',
};
