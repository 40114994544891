import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    background: '#F7FAFD',
    width: '100%',
    padding: '8vh 0 2vh 0',
    overflowY: 'auto',
    backgroundColor: '#F7FAFD',
    height: 'calc(100% - 65px - 88px - 5px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 65px - 85.19px - 5px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 96.8px - 57px - 5px)',
    },
  },
  fullHeight: {
    height: '100%',
  },
  hide: {
    display: 'none',
  },
  containerTitles: {
    marginBottom: '4vh',
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  section: {
    marginTop: '2rem',
    marginBottom: '-2rem',
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '1.7rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  subTitle: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: 600,
    lineHeight: '1.25rem',
    fontSize: '1.125rem',
  },
  buttonContainer: {
    textAlign: 'center',
    margin: '2rem 0',
  },
  button: {
    fontSize: '1.25rem',
    padding: '0.75rem 2.5rem',
  },
}));
