import { decryptData, encryptData } from './encryption';

const CLIENT_DATA_KEY = btoa('CLIENT_DATA');

export const DEFAULT_CRYPTED_PASSWORD = '$2b$08$Qh2dVnEDjhv0YHh0quFvAuMvR8QVxc4MdLoy1zOxoQW1HF7fh5yg6';

export const storeClientData = async (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(CLIENT_DATA_KEY, encryptedData);
};

export const removeClientData = () => {
  window.localStorage.removeItem(CLIENT_DATA_KEY);
};

export const getLocalClientData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(CLIENT_DATA_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

const getItem = (key) => {
  try {
    const clientData = getLocalClientData();
    return clientData[key];
  } catch {
    return undefined;
  }
};

export const storeClientExtraData = (extraData) => {
  const clientData = getLocalClientData();
  storeClientData({
    ...clientData,
    ...extraData,
  });
};

export const getRegisterClientFields = () => {
  const registerFields = getItem('registerFields');
  return registerFields;
};

export const getSharedFieldValuesData = () => {
  const fields = getItem('sharedFieldValues');
  return fields;
};

export const getIsARecoverClient = () => getItem('isRecoverClient');

export const getTempEncriptedPassword = () => DEFAULT_CRYPTED_PASSWORD;

export const getTempDecriptedPassword = (cryptedPassword) => decryptData(cryptedPassword);
