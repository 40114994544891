import React, {
  memo, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import useEnabler from '../../../hooks/useEnabler';
import InputEmpty from '../InputEmpty';

const InputRejected = ({
  onChange,
  error,
  helperText,
  rejections,
  isValid,
  ...props
}) => {
  const {
    isEnabled: isRejected,
    enable: enableRejected,
    disable: disableRejected,
  } = useEnabler();

  useEffect(() => {
    if (rejections) {
      enableRejected();
    }
  }, [enableRejected, rejections]);

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value: _value } = target;
    if (rejections) disableRejected();
    onChange(event, _value);
  }, [onChange, disableRejected]);

  return (
    <InputEmpty
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isValid={isValid}
      onChange={handleOnChange}
      error={error || isRejected}
      helperText={isRejected ? `${rejections.reason}-${rejections.comments}` : helperText}
    />
  );
};

InputRejected.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  rejections: PropTypes.any,
  isValid: PropTypes.bool,
};

InputRejected.defaultProps = {
  error: false,
  helperText: '',
  rejections: null,
  isValid: true,
};

export default memo(InputRejected);
