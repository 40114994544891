import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';

import useStyles from './useStyles';
import { LandingContext } from '../../../../context/landing';
import { SocialButton } from '../../../../components';

export const SocialSection = () => {
  const classes = useStyles();
  const { landingData } = useContext(LandingContext);

  const {
    SocialSection: {
      show = false,
      title, body,
      logoLeft,
      logoRight,
      socialsButtons = [],
    } = {},
  } = landingData;

  if (!show) {
    return null;
  }
  // TODO: Refactorizar para usar LOGO y SocialButtonsList
  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.logoColumn}>
          <img
            src={logoLeft}
            alt="LogoIcon"
            className={classes.logoImage}
            draggable="false"
          />
        </Box>

        <Box className={classes.contentColumn}>
          <Box className={classes.logosContainer}>
            <img
              src={logoLeft}
              alt="LogoIcon"
              className={classes.logoImageMovil}
              draggable="false"
            />
            <img
              src={logoRight}
              alt="Logotipo-allied"
              className={classes.logoImageR}
              draggable="false"
            />
          </Box>
          <Typography className={classes.promoText}>
            {title || ''}
          </Typography>
          <Typography className={classes.bodyText}>
            {body || ''}
          </Typography>

          <Box className={classes.buttonsContainer}>
            {
              socialsButtons.map(({ type, text, url }) => (
                <SocialButton
                  type={type}
                  text={text}
                  url={url}
                />
              ))
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
