import PropTypes from 'prop-types';

import { CreateAccountContext } from './createAccountContext';
import { CreateStrategyProvider } from '../createStrategy';

export const CreateAccountProvider = CreateStrategyProvider(CreateAccountContext);

CreateAccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
