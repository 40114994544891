/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import * as nodeAPI from '../../api/node';
import * as googleAPIS from '../../api/googleApis';

import { COLONIA_FIELD_NAME, LIST_FIELDTYPE, RESPUESTA_CORTA_FIELDTYPE } from '../../constants';
import { isActionFillCPField } from '../fields';

export const executeAutoNodes = async (autoNodes, processID, token = '') => {
  if (Array.isArray(autoNodes) && autoNodes.length) {
    for (const autoNode of autoNodes) {
      await nodeAPI.autoExecuteNode({
        nodeConfig: autoNode,
        process: processID,
      }, token);
    }
  }
};

export const fetchAutoExeNodes = async (autoExecutableNodes) => {
  const fetchedNodes = await Promise.all(
    autoExecutableNodes.map((nodeAutoExe) => nodeAPI.getNodeById(nodeAutoExe)),
  );
  return fetchedNodes;
};

const coloniaHasResults = (colonia) => {
  if (colonia.length === 1 && colonia[0] !== '') return true;
  if (colonia.length > 1) return true;
  return false;
};

const fillAddresFieldsFromPostalCode = async (fields, codigoPostal) => {
  const {
    state,
    country,
    ciudad,
    municipio,
    colonia = [],
  } = await googleAPIS.getAddressData(codigoPostal);
  const address = {
    estado: state,
    pais: country,
    ciudad,
    municipio,
    colonia,
  };
  const coloniaOptions = colonia.reduce((acc, curr) => [...acc, { name: curr }], [{ name: '' }]);
  const newFields = fields.map((field) => {
    const { config: { matchFieldName = '' } = {} } = field;
    const fieldData = matchFieldName === COLONIA_FIELD_NAME && coloniaHasResults(colonia)
      ? {
        ...field,
        config: {
          ...field.config,
          options: coloniaOptions,
        },
        fieldType: LIST_FIELDTYPE,
      }
      : {
        ...field,
        fieldType: RESPUESTA_CORTA_FIELDTYPE,
      };
    const newValue = matchFieldName === COLONIA_FIELD_NAME ? '' : address[matchFieldName];
    return (address[matchFieldName]) ? {
      ...fieldData,
      value: newValue,
    } : { ...field };
  });
  return newFields;
};

export const fillNodesFieldsFromCustomActions = async (fieldEddited, fields) => {
  let newFields = fields;
  const { config: { matchFieldName = '' }, value: fieldValue } = fieldEddited;
  if (isActionFillCPField(matchFieldName, fieldValue)) {
    newFields = await fillAddresFieldsFromPostalCode(newFields, fieldValue);
  }
  return newFields;
};

export const isFieldToFillFromActions = (field) => {
  if (!field) return false;
  const { value = '', config: { matchFieldName = '' } } = field;
  if (!matchFieldName || !value) return false;
  if (isActionFillCPField(matchFieldName, value)) return true;
  return false;
};
