import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
  },
  formContainer: {
    height: 'initial',
    overflowY: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'initial',
    },
  },
  infoTitle: {
    marginTop: '40px',
    maxWidth: '600px',
    fontSize: '18px',
  },
  FactorajeDataContainer: {
    margin: '1rem auto 6rem auto',
    maxWidth: '900px',
    padding: '0 18px 0 18px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '900px',
      padding: '0',
    },
  },
  title: {
    fontWeight: '700',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: '#2C1482',
    fontFamily: 'Montserrat, sans-serif',
    padding: '0 16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      padding: '0 0px',
    },
  },
  subtContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '20px',
    paddingTop: '35px',
  },
  subtitle: {
    fontSize: '18px',
    lineHeight: '1.25rem',
    fontFamily: '"Montserrat", "sans-serif"',
    color: '#176BCD',
    fontWeight: '600',
    textAlign: 'center',
    maxWidth: '730px',
  },
  containerBanks: {
    maxWidth: '550px',
    width: '100%',
    margin: '0 auto',
    marginTop: '50px',
  },
  footerText: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '1.75rem',
    color: '#2C1482',
    maxWidth: '650px',
    margin: '0px auto 1rem auto',
    padding: '0px 16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      padding: '0px',
      margin: '2rem auto',
    },
  },
  componentTitleSimulator: {
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '1rem',
    color: '#2C1482',
    fontFamily: 'Montserrat, sans-serif',
    padding: '0 16px',
    textDecoration: 'underline',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      padding: '0 0px',
    },
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '25px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '40px',
    },
  },
  button: {
    background: '#FFA500',
    borderRadius: '60px',
    textTransform: 'none',
    fontWeight: 700,
    color: '#000',
    fontFamily: '"Montserrat", "sans-serif"',
    fontSize: '16px',
    lineHeight: '1.2rem',
    padding: '16px 40px',
    '&:hover': {
      background: '#FFA500',
      opacity: '0.6',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      padding: '16px 60px',
    },
  },
  buttonFooterTitle: {
    marginTop: '10px',
    color: '#176BCD',
    textAlign: 'center',
    fontFamily: '"Montserrat", "sans-serif"',
    fontSize: '14px',
    fontWeight: '600',
  },
  arrowContainer: {
    textAlign: 'center',
  },
  arrow: {
    color: '#2C1482',
  },
}));
