import { createInstance, createInstanceNoToken } from './config';

const BASE_URL = '/leads';

export const getLeadById = async (id) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.get(`${BASE_URL}/${id}`);
  return data;
};

export const createLead = async (body) => {
  const axios = createInstanceNoToken();
  const { data } = await axios.post(`${BASE_URL}`, body);
  return data;
};

export const notificationAnalystLead = async (company, lead) => {
  const axios = createInstance();
  const { data } = await axios.post(`${BASE_URL}/company/${company}/lead/${lead}/notifyAnalyst`);
  return data;
};

export const notifyLead = async (company, lead, body) => {
  const axios = createInstance();
  const { data } = await axios.post(`${BASE_URL}/company/${company}/lead/${lead}/notifyLead`, body);
  return data;
};

export const recoverLeadProcessSteps = async (leadId) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/${leadId}/recoverSteps`);
  return data;
};

export const updateLeadById = async (leadId, body) => {
  const axios = createInstance();
  const { data } = await axios.put(`${BASE_URL}/${leadId}`, body);
  return data;
};
