/* eslint-disable max-len */
import { getProcess } from './auth';
import * as flowProcessAPI from '../api/flowProcess';
import { normalizeText } from './commons';

export const finishStepByProcess = async (name) => {
  const processId = getProcess();
  const { _id: flowProcessId } = await flowProcessAPI.getFlowProcessByProcess(processId);
  const { steps: stepsConfigs } = await flowProcessAPI.getStepsByProcess(processId);
  const stepConfigIndex = stepsConfigs.findIndex(({ stepConfig }) => normalizeText(stepConfig.name) === name);
  if (stepConfigIndex === undefined) throw new Error('StepIndex is not found');
  await flowProcessAPI.finishStep(flowProcessId, stepsConfigs[stepConfigIndex]._id);
};

export const getActualStepFromTagsArray = (tagsArray) => {
  const tagFound = tagsArray.find(({ completed }) => !completed);
  const tagIndex = tagsArray.findIndex(({ completed }) => !completed);
  return { stepRoute: tagFound.route, currentStep: tagIndex };
};
