import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: '#f8fafd',
  },
  boxImage: {
    maxWidth: '120px',
    margin: '0 auto',
  },
  imgLogo: {
    paddingTop: '10%',
    width: '100%',
  },

  imageContainer: {
    margin: '0 auto',
    height: '250px',
    boxSizing: 'border-box',
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: '0 10px 0 0',
      maxWidth: '233px',
      height: '233px',
      display: 'initial',
    },
  },
  image: {
    height: '100%',
    objectFit: 'contain',
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
}));
