export const table = {
  label: 'Tabla',
  errorMessages: {
    validation: 'Tabla con formato no válido',
    empty: 'Es necesario se agregue información válida a la tabla.',
  },
  type: 'table',
};

export const text = {
  label: 'Texto',
  errorMessages: {
    validation: 'Texto con formato no válido',
    empty: 'El campo es requerido',
  },
  type: 'text',
};

export const generateErrorMessagesByLabel = (type, label = '') => ({
  validation: type.errorMessages.validation,
  empty: `El campo ${label} es requerido`,
});
