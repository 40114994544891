/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import useStyles from './useStyles';
import { ClientContext } from '../../../context/client/clientContext';
import { saveExtraDataCreditProcess } from '../../../utils/creditsProcess';
import { CHIPLO_CASA_LOGO } from '../../../constants/commonUrls';

export const TabPanelCredito = () => {
  const {
    state: {
      processes,
    },
    selectProcessClient,
  } = useContext(ClientContext);
  const navigate = useNavigate();
  const selectProcess = (_id) => {
    saveExtraDataCreditProcess({ currentProcess: _id });
    selectProcessClient(_id);
    navigate('/agente/credits/creditprocess');
  };
  const classes = useStyles();
  return (
    <>
      {
        processes.map(({
          date,
          name,
          banco,
          status,
          _id,
        }) => (
          <div
            className={classes.creditContainer}
            role="button"
            onClick={() => selectProcess(_id)}
            onKeyDown={() => selectProcess(_id)}
            tabIndex={0}
          >
            <div className={classes.imageContainer}>
              <img
                className={classes.image}
                alt="rectangle"
                src={CHIPLO_CASA_LOGO}
              />
            </div>
            <div className={classes.dataContainer}>
              <Typography
                variant="body1"
                className={classes.title}
              >
                {name}
              </Typography>
              {status !== 'Terminado' ? (
                <Typography
                  variant="body1"
                  className={classes.subtitle}
                >
                  Estatus: {status}
                </Typography>
              ) : ''}
              <Typography
                variant="body1"
                className={classes.subtitle}
              >
                Banco: {banco}
              </Typography>
              <Typography
                variant="body1"
                className={classes.subtitle}
              >
                Fecha de trámite: {date}
              </Typography>
            </div>
          </div>
        ))
      }
    </>
  );
};
