import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  conditionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0px',
    marginBottom: '20px',
  },
  conditionText: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
  ammount: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));
