import { useEffect, useState } from 'react';
import { getConsultPageConfigByFlow } from '../utils/commons';

/**
 * @typedef {Object} PageData
 * @property {string} title
 * @property {string} subtitle
 * @property {string} infoTitle
 * @property {string} componentTitle
 * @property {string} buttonTitle
 * @property {string} description
 * @property {Array} autoNodes
 * @property {string} finishType
 */

const DEFAULT_PAGE_DATA = {
  title: '',
  subtitle: '',
  infoTitle: '',
  componentTitle: '',
  buttonTitle: '',
  description: '',
  autoNodes: [],
  finishType: '',
};

/**
 * Custom hook to fetch page data based on flow and page name.
 *
 * @param {Object} params
 * @param {string} params.pageName - The name of the page.
 * @param {string} params.flowName - The name of the flow.
 * @param {boolean} [params.autoFetch=true] - Whether to automatically fetch data on mount.
 * @returns {{ pageData: PageData, fetchData: () => Promise<PageData> }}
 */
const useFetchPageData = ({
  pageName = '',
  flowName = '',
  autoFetch = true,
}) => {
  const [pageData, setPageData] = useState(DEFAULT_PAGE_DATA);

  /**
   * Fetch the page data based on flow and page name.
   *
   * @returns {Promise<PageData>} The fetched page data.
   */
  const fetchPageData = async () => {
    const pageConfig = await getConsultPageConfigByFlow(flowName, pageName, pageName);
    setPageData(pageConfig);
    return pageConfig;
  };

  useEffect(() => {
    if (!autoFetch) return;
    fetchPageData();
  }, []);

  return {
    pageData,
    setPageData,
    fetchPageData,
  };
};

export default useFetchPageData;
