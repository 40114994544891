/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  customPasswordInput: {
    borderColor: 'gray',
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    maxWidth: '370px',
    [theme.breakpoints.up('xl')]: {
      width: '80%',
      maxWidth: '600px',
    },
  },
  boxImage: {
    maxWidth: '82px',
    margin: '0 auto',
  },
  imgLogo: {
    paddingTop: '10%',
    width: '100%',
  },

  imageContainer: {
    margin: '0 auto',
    maxWidth: '233px',
    height: '233px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 10px 0 0',
      maxWidth: '233px',
      height: '233px',
    },
  },
  image: {
    height: '100%',
    objectFit: 'contain',
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },

  inputContainer: {
    maxWidth: '370px',
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      width: '80%',
      maxWidth: '700px',
    },
  },
  inputFieldContainer: {
    width: '100%',
  },
  labelInput: {
    lineHeight: '24px',
    paddingTop: '5%',
    paddingBottom: '5%',
    fontWeight: 700,
    fontSize: '1rem',
  },
  buttonContainer: {
    maxWidth: '370px',
    width: '100%',
    margin: '0 auto',
    marginTop: '36px',
    marginBottom: '10%',
    [theme.breakpoints.up('xl')]: {
      width: '80%',
      maxWidth: '700px',
    },
  },
  btnLogin: {
    fontSize: '1rem',
    padding: '0.75rem 5rem',
  },
  btnRoot: {
    borderRadius: '10px',
    boxShadow: '0px 10px 15px 0px rgba(224, 225, 225, 0.24)',
  },
  boxNotAccount: {
    textAlign: 'center',
  },
  notAccount: {
    paddingRight: '5px',
    color: '#A5A8AB',
    fontWeight: 600,
    fontSize: '1rem',
  },
  redirect: {
    fontWeight: 600,
    fontSize: '1rem',
    color: '#176BCD',
    cursor: 'pointer',
  },
  containerTitle: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: '"Montserrat", sans-serif ',
    fontWeight: '700',
    fontSize: '2rwweaeem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
}));
