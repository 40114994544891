import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
  },

  title: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: '2rem',
    lineHeight: '3.125rem',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      paddingTop: '50px',
      paddingBottom: '40px',
    },
  },
  button: {
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '8px',
    lineHeight: '1rem',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E2DBDB',
    borderRadius: '5px',
    color: '#444345',
    fontFamily: 'Montserrat, sans-serif',
    textTransform: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      lineHeight: '2rem',
      padding: '0 20px',
      maxWidth: '600px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
      lineHeight: '2.5rem',
    },
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
  imgContainer: {
    width: '105px',
    height: '50px', // Alto fijo del contenedor
    display: 'flex',
    justifyContent: 'center', // Centrar la imagen horizontalmente
    alignItems: 'center', // Centrar la imagen verticalmente
    margin: '0 auto', // Centrar el contenedor dentro del carrusel si es necesario
    overflow: 'hidden', // Asegura que la imagen no sobresalga del contenedor
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      height: '100px',
    },
    [theme.breakpoints.up('md')]: {
      width: '300px',
      height: '150px',
    },
  },
  img: {
    maxWidth: '80%', // Limitar el ancho de la imagen al 80% del contenedor
    maxHeight: '80%', // Limitar el alto de la imagen al 80% del contenedor
    objectFit: 'contain', // Asegura que la imagen mantenga su proporción
  },
}));

export default useStyles;
