/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';
import clsx from 'clsx';

import Input from '../../../../../../components/Input';
import DocumentButton from '../DocumentButton';

import useStyles from './useStyles';
import { Button } from '../../../../../../components';
import { HelpButton } from '../../../../../../components/HelpButton';

export const FormNodesDocuments = ({
  fields,
  buttonText,
  disabled,
  handleSubmit,
  onChange,
  isLoading,
  // eslint-disable-next-line no-unused-vars
  onDocumentEdit,
  onDocumentSelection,
  formRef,
}) => {
  const isDocumentCompleted = (value) => {
    const hasValue = Array.isArray(value) ? value.length : value;
    return !!hasValue;
  };
  const classes = useStyles();

  return (
    <Container
      component="form"
      onSubmit={handleSubmit}
      method="post"
      autoComplete="off"
      maxWidth={false}
      className={classes.container}
      ref={formRef}
    >
      <Box className={classes.fieldsContainer}>
        {fields.map((field, index) => (field?.fieldType?.name === 'document' ? (
          <div className={classes.fieldContainer}>
            <DocumentButton
              key={field.name}
              text={`${field.label} ${field?.config?.required ? '*' : ''}`}
              onClick={(event) => (onDocumentSelection(event, index, field))}
              isCompleted={isDocumentCompleted(field.value) && !isLoading}
              isLoading={isLoading}
              hide={!!field.config.hide}
              helpUrl={field.helpUrl}
              error={
                field.status?.payload?.rejections
                  && field.status?.payload?.rejections[0]
                }
            />
          </div>
        ) : (
          <div className={classes.fieldContainer}>
            <Input
              label={field.label}
              type={field?.fieldType?.name}
              name={field.name}
              value={field.value || ''}
              options={field.config.options ?? []}
              headers={field?.config?.data || []}
              required={Boolean(field?.config?.required)}
              onChange={(event, value) => onChange(event, value, index, field.name)}
              disabled={field?.config?.disabled}
              hide={Boolean(field?.config?.hide)}
              isEmpty={field?.isEmpty}
              errorMessage={field?.errorMessage}
              defaultValue={field?.config?.defaultValue}
              rejections={field?.status?.payload?.rejections
                      && field?.status?.payload?.rejections[0]}
              status={field?.status?.value}
              format={field?.config?.format}
              className={clsx(classes.input, {
                [classes.helpButton]: !!field?.config?.helpButton,
              })}
              multiUpload={field?.config?.multiUpload || false}
            />
            {
              field?.config?.helpButton && (
                <HelpButton key={field._id} helpData={field?.config?.helpData} />
              )
            }
          </div>
        )))}

      </Box>
      <div
        className={classes.buttonContainer}
      >
        <Button
          disabled={disabled}
          className={classes.button}
          type="submit"
        >
          {buttonText}
        </Button>
      </div>
    </Container>
  );
};

FormNodesDocuments.propTypes = {
  fields: PropTypes.array,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  onDocumentEdit: PropTypes.func,
  onDocumentSelection: PropTypes.func,
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

FormNodesDocuments.defaultProps = {
  fields: [],
  buttonText: 'Continuar',
  disabled: false,
  handleSubmit: () => { },
  onChange: () => { },
  isLoading: false,
  onDocumentEdit: () => { },
  onDocumentSelection: () => { },
  formRef: null,
};
