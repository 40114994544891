import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    height: '100%', // Ensures the component fills the available height
    backgroundColor: '#F0F0F0', // Replace with your desired background color
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    backgroundColor: '#F7FAFD',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 40px',
    },
    flex: 1,
  },
  innerContainer: {
    margin: '1rem auto 1rem auto',
    maxWidth: '600px',
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '0',
    },
  },
  optionsContainer: {
    width: '100%', // Asegurar que los elementos usen todo el ancho
  },
  accordionItem: {
    width: '100%', // Usar todo el ancho disponible
    marginBottom: '10px',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Alinear los textos y campos a la izquierda
    padding: '0px 25px 0px 25px',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 50px 0px 50px',
    },
  },
  title: {
    color: '#2C1482',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    margin: '20px 0',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  titleOption: {
    color: '#176BCD',
    fontSize: '1rem',
    lineHeight: '2rem',
    margin: '10px 0',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      lineHeight: '1rem',
    },
  },
  inputContainer: {
    width: '100%',
    marginBottom: '15px',
  },
  input: {
    width: '100%', // Ajustar input al ancho completo del contenedor
    marginTop: '5px',
    borderColor: '#B9D4FF',
  },
  button: {
    marginTop: '20px',
    background: '#FFA500',
    borderRadius: '60px',
    textTransform: 'none',
    fontWeight: 700,
    color: '#000',
    fontFamily: '"Montserrat", "sans-serif"',
    fontSize: '16px',
    lineHeight: '1.2rem',
    padding: '16px 40px',
    '&:hover': {
      background: '#FFA500',
      opacity: '0.6',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '20px',
      fontSize: '20px',
      padding: '16px 60px',
    },
  },
  buttonFooterTitle: {
    marginTop: '10px',
    marginBottom: '20px',
    color: '#176BCD',
    textAlign: 'center',
    fontFamily: '"Montserrat", "sans-serif"',
    fontSize: '14px',
    fontWeight: '600',
  },
  inputLabel: {
    color: '#000F8F',
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: '500',
    fontFamily: '"Lato", "sans-serif"',
  },
  labelInputs: {
    color: '#2C1482',
    fontSize: '1rem',
    fontFamily: '"Montserrat", "sans-serif"',
    fontWeight: '400',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 10px 20px 10px',
    },
  },
}));
