import React from 'react';
import PropTypes from 'prop-types';
import { Information } from '../../../../../components';
import useStyles from './useStyles';

export const CreditProfilingWait = ({
  titlePage, buttonText = 'Finalizar', onClick,
}) => {
  const classes = useStyles();
  return (
    <Information
      title={titlePage}
      onClick={onClick}
      buttonText={buttonText}
      customButtonClassName={classes.btn}
    />
  );
};

CreditProfilingWait.propTypes = {
  titlePage: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

CreditProfilingWait.defaultProps = {
  titlePage: '',
  buttonText: 'Finalizar',
  onClick: () => {},
};
