import {
  calcAmmountFromPercentage,
  calcYearsAndMonthsDifference,
  getAgeAndTerm, getAgeByBirthDate, getRPI, getTotalSumOfFields,
} from './fieldAutoFill';

import { sanitizeString } from './fields';

export const dispatchAdapter = (targetFieldValue, fieldValuesMap) => {
  const { config = {}, value: savedValue } = targetFieldValue;
  const { function: functionConfig = {} } = config;
  const {
    name,
    fieldName = '',
    fieldNameArray,
    wordsToExclude = [''],
    format: _format = '',
    includeYearsText = false,
    elementToExtract = '',
    fieldNamePercentage = '',
  } = functionConfig;

  if (!name) return { ...targetFieldValue, value: sanitizeString(savedValue) };
  let value = '';
  switch (name) {
    case 'getAgeByBirthDate':
      value = getAgeByBirthDate(_format, fieldName, fieldValuesMap, includeYearsText);
      break;
    case 'getAgeAndTerm':
      value = getAgeAndTerm(fieldNameArray, fieldValuesMap);
      break;
    case 'getRPI':
      value = getRPI(fieldNameArray, fieldValuesMap);
      break;
    case 'getSumValues':
      value = getTotalSumOfFields(fieldNameArray, wordsToExclude, fieldValuesMap);
      break;
    case 'calculateYearsMonthsDifference':
      value = calcYearsAndMonthsDifference(
        fieldName,
        fieldValuesMap,
        elementToExtract,
        includeYearsText,
      );
      break;
    case 'calculateAmmountFromPercentage':
      value = calcAmmountFromPercentage(fieldNamePercentage, fieldName, fieldValuesMap);
      break;
    default:
      value = savedValue || '';
  }
  return { ...targetFieldValue, value: sanitizeString(value) };
};
