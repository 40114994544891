/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
} from '@material-ui/core';

import styles from './useStyles';
import Input from '../../../../../components/Input';
import Simulator, {
  FRAME_CLASSES, FRAME_TYPES, isGreaterThanValue, isValidPercentage,
} from '../../Simulator';
import { INFONAVIT_COTIZACION_BUTTON_TEXT } from '../../../../../constants';
import { ApoyoInfonavitData } from '../../../data';

const {
  LIMIT_VALUE,
  TASA,
  INITIAL_VALUES,
  TASAS,
} = ApoyoInfonavitData;

export const ApoyoInfonavitSimulator = ({
  onSubmit,
  isVivienda,
  buttonTitle = INFONAVIT_COTIZACION_BUTTON_TEXT,
  initialValues = INITIAL_VALUES,
}) => {
  const classes = styles();

  const handleSubmit = ({
    prestamoValue,
    monthlyPay,
    currentYear,
    values,
    event,
  }) => {
    const storageData = {
      montoMensual: values.montoMensual.value,
      sueldoMensualBruto: values.montoMensual.value,
      importe: prestamoValue,
      meses: currentYear * 12,
      aforo: (prestamoValue / values.precio.value).toFixed(4),
      enganche: values.enganche.value,
      year: currentYear,
      plazo: `${currentYear} años`,
      mensualidad: monthlyPay,
      propietyValue: values.precio.value,
    };
    onSubmit({
      storageData,
      event,
    });
  };

  return (
    <Simulator
      initialData={initialValues}
      tasas={TASAS}
      TASA={TASA}
      onSubmit={handleSubmit}
    >
      {
        ({
          handleChange,
          values,
          percentage,
        }) => (
          <>
            <Simulator.InnerContainer isVivienda={isVivienda}>
              <Simulator.Frame variant={FRAME_TYPES.FRAME} frameClass={FRAME_CLASSES.FIRST_INPUT_ELEMENT}>
                <Input
                  label={values.precio.label}
                  type={values.precio.type}
                  name={values.precio.name}
                  value={values.precio.value}
                  required={values.precio.required}
                  isEmpty={values.precio.isEmpty}
                  validations={[
                    (inputValue) => isGreaterThanValue(inputValue, LIMIT_VALUE, 'Valor no puede ser menor a $1,200,000'),
                  ]}
                  onChange={handleChange}
                />
              </Simulator.Frame>
              <Simulator.Frame variant={FRAME_TYPES.FRAME} frameClass={FRAME_CLASSES.SECOND_INPUT_ELEMENT}>
                <Input
                  disabled={values.enganche.disabled}
                  label={values.enganche.label}
                  type={values.enganche.type}
                  name={values.enganche.name}
                  value={values.enganche.value}
                  required={values.enganche.required}
                  isEmpty={values.enganche.isEmpty}
                  onChange={handleChange}
                  error={values.enganche.error}
                  validations={[
                    (inputValue) => isValidPercentage(inputValue, values.precio.value, 5, 'Valor no puede ser menor a 5%'),
                    (inputValue) => isGreaterThanValue(values.precio.value, inputValue, 'Enganche no puede ser mayor al precio'),
                  ]}
                  endAdornment={(
                    <Typography
                      className={clsx(classes.enganche, {
                        [classes.invalidate]: percentage < 5,
                      })}
                    >
                      {`${percentage}%`}
                    </Typography>
                  )}
                />
              </Simulator.Frame>
              <Simulator.Frame variant={FRAME_TYPES.FRAME} frameClass={FRAME_CLASSES.SECOND_INPUT_ELEMENT}>
                <Input
                  label={values.year.label}
                  type={values.year.type}
                  name={values.year.name}
                  value={values.year.value}
                  required={values.year.required}
                  isEmpty={values.year.isEmpty}
                  onChange={handleChange}
                  fullWidth
                  options={values.year.options}
                />
              </Simulator.Frame>
              <Simulator.Frame variant={FRAME_TYPES.FRAME} frameClass={FRAME_CLASSES.FIRST_INPUT_ELEMENT}>
                <Input
                  classNameLabel={classes.blueColor}
                  label={values.montoMensual.label}
                  type={values.montoMensual.type}
                  name={values.montoMensual.name}
                  value={values.montoMensual.value}
                  required={values.montoMensual.required}
                  isEmpty={values.montoMensual.isEmpty}
                  onChange={handleChange}
                />
              </Simulator.Frame>
            </Simulator.InnerContainer>
            <Simulator.Button buttonTitle={buttonTitle} />
          </>
        )
      }
    </Simulator>
  );
};
ApoyoInfonavitSimulator.propTypes = {
  onSubmit: PropTypes.func,
  isVivienda: PropTypes.bool,
  buttonTitle: PropTypes.string,
  initialValues: PropTypes.object,
};

ApoyoInfonavitSimulator.defaultProps = {
  onSubmit: () => { },
  isVivienda: false,
  buttonTitle: INFONAVIT_COTIZACION_BUTTON_TEXT,
  initialValues: INITIAL_VALUES,
};
