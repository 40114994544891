/* eslint-disable react/forbid-prop-types */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  FormHelperText,
} from '@material-ui/core';

import useStyles from './style';
import useEnabler from '../../../hooks/useEnabler';

const ERROR_MESSAGE_EMPTY = 'Selecciona una opción';

const RadioGroupInput = ({
  label,
  value,
  onChange,
  required,
  options,
  disabled,
  defaultValue,
  isEmpty,
}) => {
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const classes = useStyles();
  const inputRef = useRef();

  const [mValue, setValue] = useState(value);

  const renderOptions = (listOptions) => {
    const items = listOptions.map((option) => (
      <FormControlLabel
        className={classes.optionLabel}
        value={option.name}
        control={<Radio className={classes.smallRadioButton} />}
        label={<Typography className={classes.option}>{option.name}</Typography>}
      />
    ));
    return items;
  };

  const mHandleChange = (event) => {
    const {
      target: { value: _value },
    } = event;
    const [input] = inputRef.current.getElementsByTagName('input');
    input.setCustomValidity('');
    disableError();
    onChange(event, _value);
    setValue(_value);
  };

  useEffect(() => {
    if (mValue !== value) {
      setValue(value);
    }
  }, [value]);

  const checkDisabled = useMemo(() => {
    if (options.length === 0) return true;
    return disabled;
  }, [options, disabled]);

  useEffect(() => {
    if (isEmpty) {
      const [input] = inputRef.current.getElementsByTagName('input');
      input.setCustomValidity(ERROR_MESSAGE_EMPTY);
      enableError();
    }
  }, [enableError, isEmpty]);

  const errorMessage = isError && (
    <FormHelperText className={classes.errorMessage}>{ERROR_MESSAGE_EMPTY}</FormHelperText>
  );

  return (
    <div>
      <FormControl
        className={classes.form}
        required={required}
        disabled={checkDisabled}
        ref={inputRef}
      >
        <FormLabel
          className={classes.label}
          classes={{
            asterisk: classes.asterisk,
          }}
        >
          {label}
        </FormLabel>
        <RadioGroup value={mValue} defaultValue={defaultValue} onChange={mHandleChange}>
          {renderOptions(options)}
        </RadioGroup>
        {errorMessage}

      </FormControl>
    </div>
  );
};

RadioGroupInput.defaultProps = {
  value: '',
  required: false,
  disabled: false,
  options: [],
  defaultValue: '',
  isEmpty: undefined,
};

RadioGroupInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  isEmpty: PropTypes.any,
};

export default RadioGroupInput;
