import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import * as companyConfigsAPI from '../../../../api/companyConfigs';
import useStyles from './useStyles';
import { Loader, MenuAppBar } from '../../../../components';
import { COMPANY } from '../../../../constants';
import useLoading from '../../../../hooks/useLoading';

export const COMPANY_TYPE = 'pagesData';

export const Legal = () => {
  const [pagesData, setPagesData] = useState({});
  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    link = '',
  } = pagesData;
  const classes = useStyles();
  const fetchData = async () => {
    startLoading();
    try {
      const companyConfigs = await companyConfigsAPI.getAllCompanyConfigs(COMPANY);
      const companyConfig = companyConfigs.find(({ type: { name = '' } = {} }) => name === COMPANY_TYPE);
      if (companyConfig && companyConfig.payload) {
        const {
          pagesData: pagesDataCompany,
        } = companyConfig.payload.creditohipotecario.createaccount;
        setPagesData(pagesDataCompany);
      }
      endLoading();
    } catch (e) {
      endLoading();
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <MenuAppBar>
      <Container
        component="main"
        className={classes.container}
        disableGutters
        maxWidth={false}
      >
        <Loader
          open={isLoading}
        />
        <iframe
          title="legal"
          className={classes.pdf}
          src={link}
        />
      </Container>
    </MenuAppBar>
  );
};
