import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sideDrawer: {
    width: 300,
  },
  paper: {
    width: '380px',
    maxWidth: '60%',
    backgroundColor: '#2C1482',
  },
  boxSidebar: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  boxLogoMovil: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px',
    justifyContent: 'space-between',
    marginTop: '15%',
  },
  boxList: {
    marginTop: '20%',
    marginLeft: '5%',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
  },
  imgLogo: {
    width: '100%',
    height: 'auto',
  },
  itemLinkLogin: {
    color: 'white',
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '1.125rem',
    marginLeft: theme.spacing(2),
    fontFamily: 'Open Sans',
  },
  btnCloseSidebar: {
    color: 'white',
    marginRight: theme.spacing(2),
  },
}));
