import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  extraInfo: {
    fontWeight: '400',
    fontFamily: '"Montserrat", sans-serif ',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#171D25',
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  label: {
    fontWeight: '600 !important',
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  stepLabelText: {
    visibility: 'hidden',
  },
  active: {
    color: '#FFA500 !important',
    borderColor: '#2C1482 !important',
    border: '0px',
  },
  completed: {
    borderColor: `${theme.palette.primary.main} !important`,
  },
  root: {
    color: 'white',
    borderColor: '#2C1482',
    border: '2px solid black',
    borderRadius: '50%',
  },
}));
