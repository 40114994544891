/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {},
  innerContainer: {
    margin: '0 auto',
    width: '95%',
  },
  tabPanelCredito: {
    marginTop: '60px',
  },
}));
