export const CREDITO_HIPOTECARIO_STRATEGY = 'creditoHipotecarioStrategy';
export const VIVIENDA_STRATEGY = 'viviendaStrategy';
export const INFONAVIT_STRATEGY = 'infonavitStrategy';
export const LIQUIDEZ_STRATEGY = 'liquididadStrategy';
export const INMOBILIARIA_STRATEGY = 'inmobiliariaStrategy';

export const CREDIT_PROFILING_STRATEGY = 'creditProfilingStrategy';
export const CREATE_ACCOUNT_STRATEGY = 'createAccountStrategy';
export const CREDIT_PROFILING_COTIZATION_STRATEGY = 'creditProfilingCotizationStrategy';
export const COTIZATION_PREVIEW_STRATEGY = 'previewCotizationStrategy';
