import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
    marginTop: '40px',
    textAlign: 'center',
    marginBottom: '26px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  gridContainer: {
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  input: {
    flexGrow: 1,
  },
  helpButton: {
    width: 'calc(100% - 50px)',
    alignSelf: 'flex-start',
  },

}));
