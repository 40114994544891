/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useContext,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box } from '@material-ui/core';
import * as googleAnalytics from '../../../../../utils/googleAnalytics/events';
import { StepperContext } from '../../../../../context/stepper';
import { NormalLayout } from '../../../layouts/NormalLayout';
import useError from '../../../../../hooks/useError';
import useLoading from '../../../../../hooks/useLoading';
import useStyles from './useStyles';
import { storeStepData } from '../../../../../utils/stepper';
import { CheckboxList } from '../../../../../components/CheckboxList';
import { getConsultPageConfigByFlow } from '../../../../../utils/commons';
import { COTIZATION, CREDITO_HIPOTECARIO } from '../../../../../constants';
import { getCredit, saveExtraDataCredit } from '../../../../../utils/credit';
import * as nodeAPI from '../../../../../api/node';
import { GroupFields } from '../../../../../components';
import { deleteLabelInFirstField } from '../../../utils/cotizationStructure';

export const Cotizacion = () => {
  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();
  const navigate = useNavigate();

  const { updateStateStepper, state: { steps, current } } = useContext(StepperContext);
  const [nodes, setNodes] = useState([]);

  const [pagesData, setPagesData] = useState({
    title: '',
    infoTitle: '',
    buttonTitle: '',
  });
  const { isLoading, startLoading, endLoading } = useLoading();
  const classes = useStyles();

  const changeHideChildrens = (field, fields) => {
    if (!field) return fields;
    const children = field?.config?.children || [];
    const updatedFields = fields.map((_field) => {
      if (children.includes(_field._id)) {
        const parentValue = field.value;
        const hide = !_field.config.parentValue.includes(parentValue);
        return { ..._field, config: { ..._field.config, hide, required: !hide ? _field?.config?.hideRequired : _field.config.required } };
      }
      return _field;
    });
    return updatedFields;
  };

  const onChangeCheckbox = (checked, bankName, position, fieldName) => {
    const newNodes = nodes.map((node) => {
      const newFields = node.fieldsData.map((field) => {
        if (fieldName !== field.name) return field;
        return { ...field, value: bankName };
      });
      const fieldEditted = newFields.find((field) => field.name === fieldName);
      const fieldsUnhide = changeHideChildrens(fieldEditted, newFields);
      return { ...node, fieldsData: fieldsUnhide };
    });
    setNodes(newNodes);
  };
  const isNotCompleted = useCallback(() => nodes.some((node) => {
    const { fieldsData = [] } = node;
    return fieldsData.some(({ config = {}, value = '' }) => {
      const { required = false, hide = false } = config;
      if (!hide && !required) return false;
      if (hide || !required) return false;
      return value === '' || value === null || !value.length;
    });
  }), [nodes]);

  const onSubmit = async () => {
    try {
      startLoading();
      if (isNotCompleted()) return;
      const clientPropertyStage = nodes[0].fieldsData[0].value;
      const clientHaveAgent = nodes[0].fieldsData[1].value || 'NoSelection';
      saveExtraDataCredit({ clientPropertyStage, clientHaveAgent });
      updateStateStepper(true);
      googleAnalytics.cotizacionBancos();
      endLoading();
    } catch (e) {
      showError(e.message);
      endLoading();
    }
  };

  const fetchNodesWithFields = async (idNodes) => {
    const nodesData = await Promise.all(idNodes.map(async (node, index) => {
      const nodeData = await nodeAPI.getNodeById(node);
      const { name = '' } = nodeData;
      const fields = await nodeAPI.getFieldsByNodeId(node);
      return { _id: node, fieldsData: fields, title: name };
    }));
    return nodesData;
  };

  const fetchData = async () => {
    try {
      startLoading();
      const pageConfigg = await getConsultPageConfigByFlow(CREDITO_HIPOTECARIO, COTIZATION, COTIZATION);
      const {
        title,
        subtitle,
        buttonTitle,
        nodes: checkboxesConfig,
      } = pageConfigg;
      const nodesWithFields = await fetchNodesWithFields(checkboxesConfig);
      const newNodes = deleteLabelInFirstField(nodesWithFields);
      setPagesData({
        title,
        infoTitle: subtitle,
        buttonTitle,
      });
      setNodes(newNodes);
      endLoading();
    } catch (e) {
      endLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (steps[current].completed) {
      storeStepData({ stepRoute: steps[current + 1].route, currentStep: current + 1, steps });
      navigate(steps[current + 1].route);
    }
  }, [steps, current]);
  return (
    <NormalLayout
      submit={onSubmit}
      isLoading={isLoading}
      isError={isError}
      hideError={hideError}
      errorType={errorType}
      errorMessage={errorMessage}
      data={pagesData}
      disabled={isNotCompleted()}
      classesNames={{
        infoTitle: classes.infoTitle,
      }}
    >
      <div className={classes.container}>
        <Container
          className={classes.FactorajeDataContainer}
          disableGutters
          maxWidth={false}
        >
          <Box className={classes.containerBanks}>
            {
              nodes.map(({ fieldsData, title: titleNode }, index) => (
                <Container
                  key={titleNode}
                  component="section"
                  maxWidth={false}
                  disableGutters
                  className={classes.containerFields}
                >
                  <GroupFields
                    fields={fieldsData}
                    position={index}
                    disabledSM
                    onChange={onChangeCheckbox}
                    customTitleClass={classes.customFieldsTitle}
                  />
                </Container>
              ))
            }
          </Box>
        </Container>
      </div>
    </NormalLayout>
  );
};
