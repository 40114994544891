/* eslint-disable object-curly-newline */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import * as processAPI from '../api/process';
import {
  BURO_CIEC_COMPLETED,
  BURO_MISSING,
  CIEC, CIEC_MISSING, PRE_APROVED, REJECTED_CIEC,
} from '../constants';

export const IVA = 0.16;
export const TASA_IVA = (1 + IVA);

export const getInformationCIEC = async (process) => {
  const { tags = {}, product = {} } = await processAPI.getProcessById(process);
  const consultedTag = tags[CIEC] || {};
  if (!consultedTag?.completed) return { title: CIEC_MISSING, tags, product, status: '' };
  const approvedTag = tags[PRE_APROVED] || {};
  const rejectedTag = tags[REJECTED_CIEC] || {};
  const currentStatusTag = approvedTag?.completed ? PRE_APROVED : (rejectedTag.completed ? REJECTED_CIEC : BURO_MISSING);
  if (currentStatusTag === BURO_MISSING) return { title: BURO_MISSING, tags, product, status: currentStatusTag };
  return { title: BURO_CIEC_COMPLETED, tags, product, status: currentStatusTag };
};

export const buildMegaBodydUpload = ({ rfc, action, process }) => ({
  action,
  process,
  data: {
    rfc,
  },
});

export const buildMegaBuroBodyUpload = ({ buro, action, process }) => ({
  action,
  process,
  data: {
    buro,
  },
});

// Importe*tasa/12*(1+tasa/12)^(plazos)/((1+tasa/12)^(plazos)-1)
export const getMonthlyPaid = ({ importe, plazoMensual, tasa }) => importe * tasa / 12 * (1 + tasa / 12) ** plazoMensual / ((1 + tasa / 12) ** plazoMensual - 1);

export const generateReferenceNumber = () => {
  const longitud = 25;
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const caracteresLength = caracteres.length;
  const referenciaArray = Array.from({ length: longitud }, () => caracteres.charAt(Math.floor(Math.random() * caracteresLength)));
  const numeroReferencia = referenciaArray.join('');
  return numeroReferencia;
};
