/* eslint-disable react/prop-types */
import React, { useCallback, forwardRef, memo } from 'react';

import TextField from '@material-ui/core/TextField';

import useStyles from './useStyles';

const Digit = (
  {
    value = '',
    onChange = () => { },
    onKeyDown = () => { },
    onKeyUp = () => { },
    onFocus = () => { },
    placeholder = '',
    required = false,
    disabled = false,
    digitIndex,
  },
  ref,
) => {
  const classes = useStyles();

  const handleOnChange = useCallback((event) => {
    const { value: word } = event.target;
    const cleanWord = word.trim();
    if (cleanWord.length > 1 && cleanWord.length === 6) {
      const newValues = cleanWord.split('');
      onChange(event, newValues);
      return;
    }
    const character = cleanWord.toString().substr(cleanWord.length - 1) || ' ';
    onChange(event, character);
  }, [onChange]);

  return (
    <TextField
      inputRef={ref}
      placeholder={placeholder}
      className={classes.textField}
      variant="outlined"
      type="text"
      inputProps={{
        style: { textAlign: 'center', width: '50px' },
        autoComplete: digitIndex === 0 ? 'one-time-code' : 'off',
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }}
      value={value}
      onChange={handleOnChange}
      onWheel={(event) => { event.preventDefault(); }}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
      required={required}
      disabled={disabled}
    />
  );
};

export default memo(forwardRef(Digit));
