import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './useStyles';

export const CheckBoxItem = ({
  checked,
  handleChange,
  text,
  classNames,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.containerCheck, {
      [classNames?.containerCheck]: classNames?.containerCheck,
    })}
    >
      <FormControlLabel
        control={(
          <Checkbox
            checked={checked}
            onChange={handleChange}
            name="checked"
            color="primary"
          />
    )}
        label={(
          <span className={clsx(
            classes.label,
            {
              [classNames?.label]: classNames?.label,
            },
          )}
          >
            {text}
          </span>
    )}
      />
    </div>
  );
};

CheckBoxItem.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  text: PropTypes.string,
  classNames: PropTypes.objectOf(PropTypes.string),
};

CheckBoxItem.defaultProps = {
  checked: false,
  handleChange: () => {},
  text: '',
  classNames: {},
};
