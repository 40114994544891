import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    background: '#FFF',
    paddingTop: '100px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
    },
  },
  title: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '600',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      fontSize: '4rem',
      lineHeight: '4.5rem',
    },
  },
  containerCredit: {
    width: '100%',
  },
  innerContatiner: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  subTitle: {
    marginTop: '20px',
    marginBottom: '50px',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '600',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
      fontSize: '2rem',
      marginBottom: '50px',
      lineHeight: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
      marginBottom: '70px',

    },
  },
}));
