import React, {
  memo, useCallback, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import useEnabler from '../../../hooks/useEnabler';

import InputRejected from '../InputRejected';
import { isValidPassword } from './validations';

const MINIMUM_LENGTH = 8;

const InputPassword = ({
  value,
  onChange,
  isEmpty,
  type,
  errorMessage,
  showPasswordValidity,
  ...props
}) => {
  const [messageError, setMessageError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const helperText = useMemo(() => {
    if (errorMessage) return errorMessage;
    return isError ? messageError : '';
  }, [isError, messageError, errorMessage]);

  const isActiveError = useMemo(() => !!errorMessage || isError, [isError, errorMessage]);

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value: _value } = target; // 3312139444
    const { valid, errorMessage: _errorMessage } = isValidPassword({
      oneUpperCaseLetter: true,
      value: _value,
      minimunLength: MINIMUM_LENGTH,
      oneDigit: true,
      showPasswordValidity,
    });
    if (valid) {
      target.setCustomValidity('');
      disableError();
    } else {
      target.setCustomValidity(_errorMessage);
      enableError();
    }
    setMessageError(_errorMessage);
    onChange(event, _value);
  }, [onChange, enableError, disableError]);

  const handleShowPassword = () => {
    setShowPassword((showPsswd) => !showPsswd);
  };
  const { valid: isValidPass } = useMemo(() => isValidPassword({
    oneUpperCaseLetter: true,
    value,
    minimunLength: MINIMUM_LENGTH,
    oneDigit: true,
    showPasswordValidity,
  }), [value]);

  return (
    <InputRejected
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isValid={isValidPass}
      value={value}
      type={showPassword ? 'text' : type}
      onChange={handleOnChange}
      isEmpty={isEmpty}
      error={isActiveError}
      endAdornment={(
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleShowPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      )}
      helperText={helperText}
    />
  );
};

InputPassword.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isEmpty: PropTypes.any,
  type: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  showPasswordValidity: PropTypes.bool,
};

InputPassword.defaultProps = {
  isEmpty: undefined,
  errorMessage: '',
  showPasswordValidity: true,
};

export default memo(InputPassword);
