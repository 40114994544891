import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import useStyles from './useStyles';

export const ALERT_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const VERTICAL_TYPES = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const HORIZONTAL_TYPES = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

const AlertSnack = ({
  isOpen,
  type,
  autoHideDuration,
  anchorOriginVertical,
  anchorOriginHorizontal,
  fullWidth,
  onClose,
  children,
  styles,
}) => {
  const classes = useStyles();

  const anchorOrigin = useMemo(() => ({
    vertical: anchorOriginVertical,
    horizontal: fullWidth ? HORIZONTAL_TYPES.CENTER : anchorOriginHorizontal,
  }), [anchorOriginVertical, anchorOriginHorizontal, fullWidth]);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      className={clsx({
        [classes.fullWidth]: fullWidth,
      })}
      onClose={onClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={type}
        style={styles}
        classes={{
          icon: classes.icon,
        }}
        className={clsx({
          [classes.fullWidth]: fullWidth,
        })}
      >
        { children }
      </Alert>
    </Snackbar>
  );
};

AlertSnack.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  autoHideDuration: PropTypes.number,
  anchorOriginVertical: PropTypes.oneOf(Object.values(VERTICAL_TYPES)),
  anchorOriginHorizontal: PropTypes.oneOf(Object.values(HORIZONTAL_TYPES)),
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.object,
};

AlertSnack.defaultProps = {
  isOpen: false,
  type: ALERT_TYPES.INFO,
  autoHideDuration: 6000,
  anchorOriginVertical: VERTICAL_TYPES.TOP,
  anchorOriginHorizontal: HORIZONTAL_TYPES.RIGHT,
  fullWidth: false,
  onClose: () => {},
  children: '',
  styles: {},
};

export default memo(AlertSnack);
