import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {},
  mainContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#f7fafd',
    border: '2px solid transparent',
    borderRadius: '15px',
    boxShadow: 24,
    width: '100%',
    maxWidth: '280px',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      maxWidth: '100%',
    },
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    padding: '0 16px',
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    lineHeight: '1.5rem',
    color: '#2C1482',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      marginBottom: '20px',
    },
  },
  radioGroup: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  textField: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  button: {
    backgroundColor: '#FFA000',
    fontSize: '0.85rem',
    fontWeight: 700,
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    lineHeight: '1.25rem',
    padding: '10px 24px',
    '&:hover': {
      backgroundColor: '#FF8C00',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '16px',
    marginBottom: '16px',
  },
  iconButton: {
    backgroundColor: '#D9D9D9',
  },
  closeIcon: {
    color: '#2C1482',
  },
  errorText: {
    textAlign: 'center',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.85rem',
    color: 'red',
    marginTop: '8px',
    marginBottom: '8px',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
}));
