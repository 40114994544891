import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {},
  containerIcon: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row-reverse',
  },
  containerInput: {
    marginTop: '1rem',
  },
  card: {
    boxShadow: 'none',
    position: 'relative',
    border: 'solid 1px #fbfbfb',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    '& > div:first-child > span:first-child': {
      fontSize: '1rem',
      letterSpacing: '0.2px',
      fontWeight: 'bold',
    },
    '& > div:last-child': {
      alignSelf: 'end',
      '& > button:first-child': {
        padding: '4px',
      },
    },
  },
  iconButton: {
    padding: '4px',
    '& > span > svg': {
      fontSize: '1.2rem',
    },
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '1rem',
  },
  button: {
    fontWeight: 700,
    color: '#000F8F',
    fontSize: '1rem',
    lineHeight: '1.2rem',
    padding: '12px 16px',
  },
}));
