import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';

export const ViviendaFormHeader = ({ classes, title, subtitle }) => (
  <Container
    component="div"
    maxWidth={false}
    className={classes.header}
  >
    {title ? <Typography className={classes.headerTitle}>{title}</Typography> : ''}
    {subtitle ? <Typography className={classes.headerSubTitle}>{subtitle}</Typography> : ''}
  </Container>
);

ViviendaFormHeader.propTypes = {
  classes: PropTypes.shape({
    header: PropTypes.string,
    headerTitle: PropTypes.string,
    headerSubTitle: PropTypes.string,
  }),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ViviendaFormHeader.defaultProps = {
  classes: {},
  title: '',
  subtitle: '',
};
