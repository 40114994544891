import React, {
  useState,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { LockOutlined } from '@material-ui/icons';

import useStyles from './useStyles';

import searchData from '../../../../data/inputSearch.json';
import { ListData } from '../../components/ListData';
import { HeaderBar } from '../../components/HeaderBar';
import Search from '../../../../components/Input/InputSearch';
import { ClientContext } from '../../../../context/client/clientContext';
import { storeCreditProcess } from '../../../../utils/creditsProcess';
import useSearch from '../../hooks/useSearch';
import { Loader } from '../../../../components';
import { logOutMain } from '../../../../utils';
import { logout } from '../../../../api/auth';
import { AGENTE_CLIENTS_CREATE_ROUTE, AGENTE_LOGIN_ROUTE, AGENTE_PERFILADOR_ROUTE } from '../../../../constants';
import { PageTitle } from '../../components/PageTitle';

// const url = 'http://localhost:3000/';

export const Clients = () => {
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const {
    clients,
    hasMore,
    loading,
  } = useSearch(query, pageNumber);
  const observer = useRef();
  const lastBookElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const {
    initClient,
  } = useContext(ClientContext);
  const navigate = useNavigate();
  const classes = useStyles();

  const onEnter = (event, currentValue) => {
    setQuery(currentValue);
    setPageNumber(1);
  };
  const onClickItem = (processes, title) => {
    storeCreditProcess({
      processes,
      title,
    });
    initClient({
      processes,
      title,
    });
    navigate('/agente/credits');
  };

  const sideBarOptions = [
    {
      label: 'Logout',
      icon: <LockOutlined style={{ color: 'white' }} />,
      action: async () => {
        await logout();
        await logOutMain();
        navigate(AGENTE_LOGIN_ROUTE);
      },
    },
  ];

  return (
    <div className={classes.container}>
      <Loader open={loading} />
      <div className={classes.innerContainer}>
        <HeaderBar
          title=""
          displayLogo
          options={[
            {
              label: 'Agregar cliente nuevo',
              id: 'sendLink',
              func: () => navigate(AGENTE_CLIENTS_CREATE_ROUTE),
            },
            {
              label: 'Perfilador',
              id: 'perfilador',
              func: () => navigate(AGENTE_PERFILADOR_ROUTE),
            },
          ]}
          showSideMenu
          sideBarOptions={sideBarOptions}
        />
        <PageTitle title="Mis clientes" />
        <div className={classes.searchContainer}>
          <Search
            onEnter={onEnter}
            placeholder={searchData.placeholder}
            value={searchData.value}
            disabled={loading}
            name={searchData.name}
          />
        </div>
        <div className={classes.clients}>
          {clients.map(({
            title,
            clients: clientsData,
          }) => (
            <ListData
              title={title}
              clients={clientsData}
              onClick={onClickItem}
              hasMore={hasMore}
              lastBookElementRef={lastBookElementRef}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
