/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-boolean-value */
import React, {
  useState, useEffect, useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import {
  Container,
} from '@material-ui/core';
import * as googleAnalytics from '../../../../../utils/googleAnalytics/events';
import { StepperContext } from '../../../../../context/stepper';
import { ConsultLayout } from '../../../layouts';
import * as fieldValueAPI from '../../../../../api/fieldValue';
// import * as clientAPI from '../../../../../api/clients';
import * as nodeAPI from '../../../../../api/node';
// import * as userAPI from '../../../../../api/users';
import useLoading from '../../../../../hooks/useLoading';

import { /* getAuth, getEmail, */ getProcess } from '../../../../../utils/auth';

import useStyles from './useStyles';
import { GroupFields, Loader } from '../../../../../components';
import {
  CONSULT_CREDIT_SHAREHOLDER,
  /* COMPANY, */
} from '../../../../../constants';

import ErrorMessage from '../../../../../components/ErrorMessage';
import AlertSnack from '../../../../../components/AlertSnack';
import useError from '../../../../../hooks/useError';

import { useProduct } from '../../../../../hooks/useProduct';
import { getFlowTypeHandler } from '../../../../../utils/tags';
import { createUpdateTagGeneral, storeStepData } from '../../../../../utils';
import { findFields } from '../../../../../utils/commons';
import {
  extractAddressFromPostalCode,
  getNewNodes,
  obtenerFechaDeNacimiento,
} from '../../../../../utils/fields';
import { nowDate } from '../../../../../components/Input/InputBaseDatePicker/formatter';
// import { SendCodeDialog } from '../../../../../components/SendCodeDialog';
// import { InformationDialog } from '../../../../../components/InformationDialog';

/* const INITIAL_ERROR = {
  isError: false,
  errorMessage: 'La verificacionestamal',
}; */

export const ConsultCreditShareholder = ({
  back, createUpdateTag,
}) => {
  const { updateStateStepper, state: { steps, current } } = useContext(StepperContext);
  const navigate = useNavigate();
  // const [showInformationDialog, setShowInformationDialog] = useState(false);
  // const [isErrorPhoneValue, setIsErrorPhoneValue] = useState(INITIAL_ERROR);
  // const [showSendCodeDialog, setShowSendCodeDialog] = useState(false);
  // const [phone, setPhone] = useState('');
  const { isLoading, startLoading, endLoading } = useLoading();

  const [nodesWithFieldValues, setNodesWithFieldValues] = useState([]);

  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();

  const [pageConfig, setPageConfig] = useState({
    title: '',
    infoTitle: '',
    subTitle: '',
    nodes: [],
    labelCheck: '',
    description: [],
  });

  const { getConsultPageConfigByFlow } = useProduct();

  const classes = useStyles();

  const filteredNodes = useMemo(() => getNewNodes(nodesWithFieldValues), [nodesWithFieldValues]);
  const changeHideChildrens = (field, fields) => {
    const children = field.config.children || [];
    const updatedFields = fields.map((_field) => {
      if (children.includes(_field._id)) {
        const parentValue = field.value;
        const hide = _field.config.parentValue !== parentValue;
        return { ..._field, config: { ..._field.config, hide } };
      }
      return _field;
    });
    return updatedFields;
  };

  const setCPFilled = async (name, value, newNodes) => {
    if (name === 'cp' && value && value.length === 5) {
      const [{ value: codigoPostal }] = findFields([name], newNodes);
      try {
        startLoading();
        const newNN = await extractAddressFromPostalCode(codigoPostal, newNodes);
        setNodesWithFieldValues(newNN);
        endLoading();
      } catch (e) {
        setNodesWithFieldValues(newNodes);
        endLoading();
      }
    } else {
      setNodesWithFieldValues(newNodes);
    }
  };

  const setDateFilled = async (name, value, newNodes) => {
    if (name === 'rfcField' && value) {
      const [{ value: dateValue }] = findFields([name], newNodes);
      try {
        startLoading();
        const newNN = obtenerFechaDeNacimiento(dateValue, newNodes);
        setNodesWithFieldValues(newNN);
        endLoading();
      } catch (e) {
        setNodesWithFieldValues(newNodes);
        endLoading();
      }
    } else {
      setNodesWithFieldValues(newNodes);
    }
  };

  const handleChange = (event, value, position) => {
    const { name } = event.target;
    const newNodes = nodesWithFieldValues.map((node, index) => {
      if (index !== position) return node;
      const newFields = node.fields.map(
        (field) => (field.name === name ? { ...field, value } : field),
      );
      const fieldEditted = newFields.find((field) => field.name === name);
      const fieldsUnhide = changeHideChildrens(fieldEditted, newFields);
      return { ...node, fields: fieldsUnhide };
    });
    setCPFilled(name, value, newNodes);
    setDateFilled(name, value, newNodes);
  };

  const transformDataToUpload = (_process) => {
    const newFields = nodesWithFieldValues.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).map(({
      _id, value,
    }) => ({
      field: _id,
      value,
      process: _process,
    }));
    return {
      fields: newFields,
    };
  };

  const saveFieldValues = (dataToUpload) => dataToUpload.fields.map(
    (field) => fieldValueAPI.saveFieldValue(field),
  );

  const submitForm = async () => {
    try {
      startLoading();
      const processID = getProcess();
      const dataToUpload = transformDataToUpload(processID);
      await Promise.all(saveFieldValues(dataToUpload));
      await createUpdateTag();
      googleAnalytics.consultaBuro();
      await createUpdateTagGeneral({
        completed: true,
        fecha: nowDate(),
        status: 'Aprobado',
      }, 'consultaburo');
      updateStateStepper(true);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
    }
  };

  const getFieldValues = async (fields) => {
    const theProcess = getProcess();
    const theFieldValues = await Promise.all(fields.map(async (field) => {
      const fieldValue = await fieldValueAPI.getFieldValueByProcessField(theProcess, field._id);
      return { ...field, value: fieldValue.value || '' };
    }));
    return theFieldValues;
  };

  const fetchNodesWithFields = async (idNodes) => {
    const nodesData = await Promise.all(idNodes.map(async (node, index) => {
      const nodeData = await nodeAPI.getNodeById(node);
      const { name = '' } = nodeData;
      const title = index === 0 ? '' : name;
      const fields = await nodeAPI.getFieldsByNodeId(node);
      return { _id: node, fieldsData: fields, title };
    }));
    return nodesData;
  };

  const convert1Darray = (fields) => fields.reduce((acc, curr) => {
    const { fieldsData } = curr;
    return [...acc, ...fieldsData];
  }, []);

  const isNotCompleted = () => nodesWithFieldValues.some((node) => {
    const { fields = [] } = node;
    return fields.some(({ config = {}, value = '' }) => {
      const { required = false, hide = false } = config;
      if (!hide && !required) return false;
      if (hide || !required) return false;
      return value === '' || value === null || !value.length;
    });
  });

  const formatNodesWithFieldValues = (_nodes, _fieldValues) => {
    const newNodes = _nodes.map((node) => {
      const newFields = node.fieldsData.map((_field) => {
        const { name: fieldValueName } = _field;
        const {
          _id, fieldCatalog = {}, config, value = '', fieldType,
        } = _fieldValues.find(({ name }) => name === fieldValueName);
        return {
          ..._field, _id, fieldCatalog, config, value, fieldType,
        };
      });
      return { ...node, fields: newFields };
    });
    return newNodes;
  };

  /* const backPhone = () => {
    setShowSendCodeDialog(false);
  };

  const setPhoneValue = async () => {
    const email = getEmail();
    const client = await clientAPI.getClientByCompanyAndEmail(COMPANY, email);
    const { phone: phoneClient } = client;
    setPhone(phoneClient);
  }; */

  /* const verifyCode = async (event, code) => {
    try {
      // validacion del  codigo
      event.preventDefault();
      startLoading();
      setShowSendCodeDialog(false);

      const { verified } = await userAPI.verifyPhoneCode(phone, code);
      if (verified) {
        await submitForm();
      } else {
        setShowSendCodeDialog(true);
        setIsErrorPhoneValue({
          isError: true,
          errorMessage: 'Error al verificar el numero',
        });
      }
      endLoading();
    } catch (e) {
      console.log({ e });
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
    }
  }; */

  /* const checkPhoneAlreadyExists = async (user) => {
    const existsResponse = await userAPI.verifyPhoneAlreadyExists(user);
    const { exists = false } = existsResponse;
    return exists;
  }; */

  /* const generateCode = async (user) => {
    const { whatsappSended } = await userAPI.generatePhoneCode(user);
    setShowSendCodeDialog(true);
    return whatsappSended;
  }; */

  /* const sendCode = async () => {
    startLoading();
    const userObject = {
      company: COMPANY,
      phone,
      shouldCheckCompanyConfig: false,
      model: 'client',
      origin: 'Buro',
    };

    const exists = await checkPhoneAlreadyExists(userObject);
    if (!exists) {
      setShowInformationDialog(true);
      endLoading();
      return;
    }
    await generateCode(userObject, phone);
    endLoading();
  }; */

  const fetchData = async () => {
    startLoading();
    try {
      const theProcess = getProcess();
      // await setPhoneValue();
      const { normalizedFlowName } = await getFlowTypeHandler(theProcess);
      const _pageConfig = await getConsultPageConfigByFlow(
        normalizedFlowName,
        CONSULT_CREDIT_SHAREHOLDER,
      );
      const { nodes: _nodes } = _pageConfig;
      const nodesWithFields = await fetchNodesWithFields(_nodes);
      const fieldsFlat = convert1Darray(nodesWithFields);

      const fieldsWithValues = await getFieldValues(fieldsFlat);
      const newNodes = formatNodesWithFieldValues(nodesWithFields, fieldsWithValues);
      setNodesWithFieldValues(newNodes);
      setPageConfig(_pageConfig);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to get the data');
      console.log(e);
    } finally {
      endLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (steps[current].completed) {
      storeStepData({ stepRoute: steps[current + 1].route, currentStep: current + 1, steps });
      navigate(steps[current + 1].route);
    }
  }, [steps, current]);

  return (
    <>
      <AlertSnack isOpen={isError} type="error" onClose={hideError}>
        <ErrorMessage errorType={errorType} errorMessage={errorMessage} />
      </AlertSnack>
      {/*
      <SendCodeDialog
        onCancel={() => setShowSendCodeDialog(false)}
        backPhone={backPhone}
        onSubmit={verifyCode}
        phone={phone}
        showModal={showSendCodeDialog}
        error={isErrorPhoneValue}
        title="Autorizo consulta de Buró de crédito"
        buttonText="Autorizar"
      />
      <InformationDialog
        showModal={showInformationDialog}
        onSubmit={() => setShowInformationDialog(false)}
        buttonTitle="OK"
        descripcion="El celular que estás ingresando con nosotros no tiene una cuenta asociada. Por favor ingresa a Chiplo.com y crea un proceso nuevo."
      />
      */}
      <Loader open={isLoading} />
      <ConsultLayout
        data={pageConfig}
        submit={submitForm}
        back={back}
        infoTitleClassName={classes.infoTitle}
        disabled={isNotCompleted()}
      >
        <Container
          component="section"
          maxWidth="md"
          className={classes.container}
        >
          {
            filteredNodes.map(({ fields, title: titleNode }, index) => (
              <Container
                key={titleNode}
                component="section"
                maxWidth={false}
                disableGutters
                className={classes.containerFields}
              >
                <GroupFields
                  fields={fields}
                  title={titleNode}
                  position={index}
                  onChange={handleChange}
                  customTitleClass={classes.customFieldsTitle}
                />
              </Container>
            ))
          }
        </Container>
      </ConsultLayout>
    </>
  );
};

ConsultCreditShareholder.propTypes = {
  createUpdateTag: PropTypes.func.isRequired,
  back: PropTypes.func,
};

ConsultCreditShareholder.defaultProps = {
  back: () => { },
};
