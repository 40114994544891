import React, { useEffect } from 'react';

import * as googleAnalytics from '../../../../utils/googleAnalytics/events';
import { LandingLayout } from '../../../landing/layouts/LandingLayout';
import { HeaderBar } from '../../../landing/components/HeaderBar';
import { FooterInfoImages } from '../../../../components/FooterInfoImages';
import { FAQ } from '../../../../components/FAQ';
import useStyles from './useStyles';
import useLoading from '../../../../hooks/useLoading';

import { LandingInfonavitLiquidez } from '../../../../data';
import { BankLabels } from '../../../../components/BankLabels';
import { Header } from '../../../../components/Header';
import { Loader } from '../../../../components';
import { InfoSteps } from '../../../../components/InfoSteps';
import { CalCredit } from '../../sections';

export const LiquidezLanding = () => {
  const { isLoading } = useLoading();
  const classes = useStyles();
  const {
    InfoStepsData,
    faqData,
    HeaderData,
    BankLabelsData,
  } = LandingInfonavitLiquidez;
  const { title, questions } = faqData;
  useEffect(() => {
    googleAnalytics.visitaChiplo();
  }, []);

  return (
    <LandingLayout>
      <Loader open={isLoading} />
      <HeaderBar
        showLoginButton
        showSideBar
      />
      <Header
        title={HeaderData.title}
        subTitle={HeaderData.subTitle}
        buttonTitle={HeaderData.buttonTitle}
        image={HeaderData.image}
        buttonRoute={HeaderData.buttonRoute}
      />
      <InfoSteps
        data={InfoStepsData}
      />
      <CalCredit
        buttonTitle="Ver opciones de bancos"
      />
      <BankLabels title={BankLabelsData.title} images={BankLabelsData.images} />
      <div className={classes.container}>
        <FAQ questions={questions} title={title} />
      </div>
      <FooterInfoImages />
    </LandingLayout>
  );
};
