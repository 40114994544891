import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
  },
  infoTitle: {
    maxWidth: '550px',
  },
  FactorajeDataContainer: {
    margin: '1rem auto 6rem auto',
    maxWidth: '900px',
    padding: '0 18px 0 18px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '900px',
      padding: '0',
    },
  },
  title: {
    fontWeight: '700',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: '#2C1482',
    fontFamily: 'Montserrat, sans-serif',
    padding: '0 16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      padding: '0 0px',
    },
  },
  containerBanks: {
    maxWidth: '550px',
    width: '100%',
    margin: '0 auto',
    marginTop: '50px',
  },
}));
