import { decryptData, encryptData } from '../utils/encryption';

const FIELD_VALUES_KEY = btoa('FIELD_VALUES_DATA');

export const storeFieldValuesData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(FIELD_VALUES_KEY, encryptedData);
};

export const removeFieldValuesData = () => {
  window.localStorage.removeItem(FIELD_VALUES_KEY);
};

export const getLocalFieldValuesData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(FIELD_VALUES_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return [];
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};
