import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
  },
  boxRoundedContainer: {
    margin: theme.spacing(2, 2),
    background: '#FFFFFF',
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 4),
    },
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    },
  },
  title: {
    color: '#000',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: '20px',
    lineHeight: '30px',
    paddingTop: '5px',
    paddingBottom: '5px',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      fontSize: '25px',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
}));

export default useStyles;
