import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setStrategyFlowName, setStrategyLoaded } from '../store/flowStrategy';
import { FlowStrategyContext } from '../context/flowStrategy/flowStrategyContext';

export const useFlowStrategy = () => {
  const dispatch = useDispatch();
  const { strategy, changeStrategy } = useContext(FlowStrategyContext);
  const { strategyFlowName, strategyLoaded } = useSelector((state) => state.flowStrategy);

  const changeStrategyFlowName = (newStrategyFlowName) => {
    dispatch(setStrategyFlowName(newStrategyFlowName));
  };

  const changeStrategyLoaded = (isLoaded = false) => {
    dispatch(setStrategyLoaded(isLoaded));
  };

  const setFlowStrategy = (newStrategyFlowName, newStrategy) => {
    changeStrategy(newStrategy);
    changeStrategyFlowName(newStrategyFlowName);
    changeStrategyLoaded(true);
  };

  const selectAndSetStrategyByFlowName = (strategies) => {
    const newStrategy = strategies[strategyFlowName] || Object.keys(strategies)[0];
    setFlowStrategy(strategyFlowName, newStrategy);
  };

  return {
    strategy,
    strategyFlowName,
    strategyLoaded,
    setFlowStrategy,
    changeStrategyLoaded,
    changeStrategyFlowName,
    selectAndSetStrategyByFlowName,
  };
};
