import { numberFormatter } from '../InputNumber/formatter';

export const limitPhoneNumber = (value) => (
  value.replace(' ', '').substring(0, 10)
);

export const removePhoneFormat = (value) => (
  // eslint-disable-next-line no-useless-escape
  value.replace(/[{()}]/g, '')
);

export const formatPhone = (value) => {
  if (value.length > 2) {
    const newValue = `(${value.substr(0, 2)})${value.substr(2, value.length - 1)}`;
    return newValue;
  }
  return value;
};

export const phoneFormatter = (value) => {
  let newValue = value ? value.toString() : '';

  newValue = removePhoneFormat(newValue);
  newValue = numberFormatter(newValue, false);
  newValue = formatPhone(newValue);

  return newValue;
};

export default phoneFormatter;
