import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '95%',
    margin: '0 auto',
  },
  title: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '3.125rem',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      paddingTop: '50px',
      paddingBottom: '40px',
    },
  },
}));
