/* eslint-disable react/jsx-no-useless-fragment */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import DocumentIcon from '@material-ui/icons/InsertDriveFile';
// import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';

import Text, { COLORS } from '../../../../../../components/Text';

import useStyles from './useStyles';

const STUB_FN = () => {};

const DocumentButton = ({
  text,
  loadingText,
  enableOnClick,
  onClick,
  enableOnDelete,
  onDelete,
  isLoading,
  isCompleted,
  disabled,
  hide,
  // helpUrl,
  error,
}) => {
  const classes = useStyles();

  return (
    <>
      { !hide && (
        <ListItem
          ContainerComponent="div"
          button={enableOnClick}
          onClick={enableOnClick ? onClick : STUB_FN}
          className={clsx(
            classes.listItem,
            {
              [classes.listItemCompleted]: isCompleted,
              [classes.error]: error && !isLoading,
            },
          )}
          disabled={disabled}
        >

          <ListItemAvatar
            className={classes.listItemAvatar}
          >
            {
            isLoading
              ? (
                <CircularProgress
                  size={24}
                />
              )
              : (
                <DocumentIcon
                  className={clsx(
                    classes.icon,
                    {
                      [classes.iconError]: error,
                      [classes.iconCompleted]: isCompleted,
                    },
                  )}
                />
              )
          }
          </ListItemAvatar>

          <div>
            <Text
              color={isLoading || isCompleted ? COLORS.INITIAL : COLORS.SECONDARY}
            >
              { text }
            </Text>
            {
            (isLoading || error) && (
              <Text
                color={isLoading ? COLORS.PRIMARY : COLORS.ERROR}
              >
                <small>
                  { isLoading ? loadingText : `${error?.reason} - ${error?.comments}` }
                </small>
              </Text>
            )
          }
          </div>

          <ListItemSecondaryAction>
            {
            enableOnDelete && (
              <IconButton
                aria-label="delete"
                className={classes.iconButton}
                onClick={onDelete}
                disabled={disabled}
              >
                <CloseIcon className={clsx(
                  classes.icon,
                  classes.iconDelete,
                )}
                />
              </IconButton>
            )
          }
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </>
  );
};

DocumentButton.propTypes = {
  text: PropTypes.string,
  loadingText: PropTypes.string,
  enableOnClick: PropTypes.bool,
  onClick: PropTypes.func,
  enableOnDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  isCompleted: PropTypes.bool,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  // helpUrl: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
};

DocumentButton.defaultProps = {
  text: '',
  loadingText: 'subiendo',
  enableOnClick: true,
  onClick: () => {},
  enableOnDelete: false,
  onDelete: () => {},
  isLoading: false,
  isCompleted: false,
  disabled: false,
  hide: false,
  // helpUrl: '',
  error: null,
};

export default memo(DocumentButton);
