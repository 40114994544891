export const CONSULT_CREDIT_BUSSINESS = 'CONSULT_CREDIT_BUSSINESS';
export const CONSULT_CREDIT_SHAREHOLDER = 'CONSULT_CREDIT_SHAREHOLDER';
export const SET_DATA_SHAREHOLDER = 'SET_DATA_SHAREHOLDER';
export const SET_DATA_LEGAL_REPRESENTATIVES = 'SET_DATA_LEGAL_REPRESENTATIVES';
export const WAIT_INFORMATION = 'WAIT_INFORMATION';
export const LOADER_PAGE = 'LOADER_PAGE';
export const CONSULT_INVOICE_BUSSINESS = 'CONSULT_INVOICE_BUSSINESS';
export const CONSULT_PATRIMONIAL_RELATIONSHIP = 'CONSULT_PATRIMONIAL_RELATIONSHIP';
export const CONSULT_PAGES = 'CONSULT_PAGES';
export const UPLOAD_PDF = 'UPLOAD_PDF';
export const WAIT_CONSULT = 'WAIT_CONSULT';
export const BURO_REJECTED = 'BURO_REJECTED';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_BY_LINK = 'CREATE_ACCOUNT_BY_LINK';
export const AGENT_CREATE_CLIENT_ACCOUNT = 'AGENT_CREATE_CLIENT_ACCOUNT';
export const FINISH_TYPE_MATRIZ_VIVIENDA = 'FINISH_TYPE_MATRIZ_VIVIENDA';
export const CREDIT_PROFILING_COTIZATION = 'CREDIT_PROFILING_COTIZATION';
export const COTIZACION_ACCOUNT_INMOBILIARIA = 'COTIZACION_ACCOUNT_INMOBILIARIA';
export const COTIZACION_PROFILING_RECUPERACION = 'COTIZACION_PROFILING_RECUPERACION';
export const FINISH_TYPE_CREDIT_PROFILLING_VIVIENDA = 'FINISH_TYPE_CREDIT_PROFILLING_VIVIENDA';
export const NORMAL_FINISH_TYPE = 'NORMAL';
