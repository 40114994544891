/* eslint-disable react/forbid-prop-types */

import React, {
  memo, useCallback, useMemo, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Box } from '@material-ui/core';
import { optionsFormatter } from './formatter';
import useStyles from './useStyles';
import useEnabler from '../../../hooks/useEnabler';

const ERROR_MESSAGE_EMPTY = 'Rellena este campo';

const SelectSimple = ({
  id,
  name,
  label,
  value,
  required,
  options,
  isEmpty,
  disabled,
  className,
  fullWidth,
  onChange,
  rejections,
  defaultValue,
  hide,
}) => {
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();

  const {
    isEnabled: isRejected,
    enable: enableRejected,
    disable: disableRejected,
  } = useEnabler();

  useEffect(() => {
    if (rejections) {
      enableRejected();
    }
  }, [enableRejected, rejections]);
  const inputRef = useRef();

  useEffect(() => {
    if (isEmpty) {
      const [input] = inputRef.current.getElementsByTagName('input');
      input.setCustomValidity(ERROR_MESSAGE_EMPTY);
      enableError();
    }
  }, [enableError, isEmpty]);

  const classes = useStyles();

  const optionsEdited = useMemo(() => {
    const newOptions = [];

    if (label) {
      newOptions.push({
        id: 'parent_label',
        name: label,
        value: '',
        disabled: true,
      });
    }

    optionsFormatter(options, newOptions);

    return newOptions;
  }, [options, label]);

  const handleOnChange = useCallback((event) => {
    const { value: _value } = event.target;
    if (rejections) disableRejected();
    const [input] = inputRef.current.getElementsByTagName('input');
    input.setCustomValidity('');
    disableError();
    onChange(event, _value);
  }, [onChange]);

  let errorMessage = isError && <FormHelperText>{ERROR_MESSAGE_EMPTY}</FormHelperText>;
  errorMessage = isRejected ? (
    <FormHelperText>
      {`${rejections.reason}-${rejections.comments}`}
    </FormHelperText>
  ) : errorMessage;

  return (
    <Box>
      <InputLabel className={classes.InputLabel}>
        {`${label}${required ? ' *' : ''}`}
      </InputLabel>
      <FormControl
        className={clsx(className, {
          [classes.hide]: hide,
        })}
        fullWidth={fullWidth}
        variant="filled"
        disabled={disabled}
        ref={inputRef}
        error={isError || isRejected}
      >
        <Select
          id={id}
          name={name}
          value={value || defaultValue}
          defaultValue={defaultValue}
          disableUnderline
          onChange={handleOnChange}
          className={classes.Select}
          inputProps={{
            classes: {
              root: clsx(classes.InputPropsRoot, {
                [classes.error]: isError,
              }),
              disabled: classes.InputPropsDisabled,
            },
          }}
        >
          {
            optionsEdited.map((option) => (
              <MenuItem
                key={option.id}
                value={option.value}
                disabled={option.disabled}
              >
                {option.name}
              </MenuItem>
            ))
          }
        </Select>
        {errorMessage}
      </FormControl>
    </Box>
  );
};

SelectSimple.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  options: PropTypes.array,
  isEmpty: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  rejections: PropTypes.any,
  defaultValue: PropTypes.string,
  hide: PropTypes.bool,
};

SelectSimple.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  value: undefined,
  required: false,
  options: [],
  isEmpty: undefined,
  disabled: false,
  className: '',
  fullWidth: true,
  onChange: () => { },
  rejections: null,
  defaultValue: '',
  hide: false,
};

export default memo(SelectSimple);
