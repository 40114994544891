import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  main: {
    width: '100%',
    // height: 'calc(100% - 65px - 88px - 5px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '600px',
    paddingBottom: '40px',
  },
}));
