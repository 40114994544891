import { INIT_STEPPER, UPDATE_CURRENT_STEP, UPDATE_STATE_STEPPER } from './constants';

export const stepperReducer = (state, action) => {
  switch (action.type) {
    case INIT_STEPPER:
      return {
        ...state,
        steps: [...action.payload.steps],
        current: action.payload.current,
      };
    case UPDATE_CURRENT_STEP:
      return {
        ...state,
        current: action.payload,
      };
    case UPDATE_STATE_STEPPER:
      return {
        ...state,
        steps: state.steps.map((step, index) => (
          index === state.current ? { ...step, completed: action.payload } : step
        )),
      };
    default:
      return { ...state };
  }
};

/*

*/
