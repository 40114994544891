import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  sectionContainer: {
    paddingBottom: '36px',
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainer: {
    paddingLeft: '30px',
    paddingRight: '30px',
    width: '100%',
    maxWidth: '1400px',
    alignSelf: 'center',
  },
  Title: {
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: '3rem',
    color: '#171D25',
    marginTop: '70px',
    marginBottom: '80px',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
      lineHeight: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      marginBottom: '48px',
      marginTop: '27px',
    },
  },
  stepTitle: {
    color: '#171D25',
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '1.375rem',
    lineHeight: '1.875rem',
    marginBottom: '36px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  },
  stepSubtitle: {
    maxWidth: '450px',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '30px',
    paddingBottom: '40px',
    height: '130px',
    fontFamily: 'Open Sans',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      maxWidth: '325px',
    },
    [theme.breakpoints.down('xs')]: {},
  },
  Box: {
    maxWidth: '365px',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  Img: {
    width: '85%',
    alignSelf: 'center',
  },
}));
