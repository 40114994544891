/* eslint-disable max-len */
import React, {
  useContext, useMemo,

} from 'react';
import PropTypes from 'prop-types';
import {
  Container,
} from '@material-ui/core';

import * as googleAnalytics from '../../utils/googleAnalytics/events';
import styles from './useStyles';

import { storeCredit } from '../../utils/credit';
import { logOutMain } from '../../utils/logout';
import { logout } from '../../api/auth';
import { StepperContext } from '../../context/stepper';
import { useProduct } from '../../hooks/useProduct';
import { getConfigTags } from '../../utils/tags';
import { storeAuth, storeStepData } from '../../utils';
import { ThemeContext } from '../../context/Theme/themeContext';
import { storeFlowData } from '../../utils/flows';
import { CREDITO_HIPOTECARIO_DESEMBOLSO_INITIAL_VALUES, FLOW_INMOBILIARIA, FLOW_VIVIENDA } from '../../constants';
import useRoute from '../../hooks/useRoute';
import { CreditConditionsSimulator } from '../CreditConditionsSimulator';

export const CreditSimulator = ({
  startLoading, endLoading, agentId, buttonTitle,
}) => {
  const { getProductByRoute } = useProduct();
  const {
    navigateWithQueryParam,
    location,
  } = useRoute();
  const classes = styles();

  const { pathname } = location;
  const isVivienda = useMemo(() => [FLOW_VIVIENDA, FLOW_INMOBILIARIA].includes(pathname), [pathname]);

  const { initStepper } = useContext(StepperContext);
  const { loadTheme } = useContext(ThemeContext);

  const getActualStep = (stepperTags) => {
    const actualIndexStep = stepperTags.findIndex(({ completed }) => !completed);
    return { stepRoute: stepperTags[actualIndexStep].route, currentStep: actualIndexStep };
  };

  const onLogout = async () => {
    await logOutMain();
    await logout();
  };
  /*
  Aforomo maximo = prestamo/valor de la casa

  prestamos = valor de la casa - enganche
  */

  const storeAgentId = () => {
    if (agentId) {
      storeAuth({ agentId });
    }
  };

  const onSubmit = async ({ storageData, event }) => {
    const {
      importe,
      meses,
      aforo,
      enganche,
      year,
      plazo,
      mensualidad,
      propietyValue,
    } = storageData;
    event.preventDefault();
    event.stopPropagation();
    await onLogout();
    startLoading();
    storeCredit({
      importe,
      meses,
      aforo,
      enganche,
      year,
      plazo,
      mensualidad,
      propietyValue,
    });
    storeAgentId();
    const productByRoute = getProductByRoute(pathname);
    const { name, firstRoute } = productByRoute;
    await loadTheme(name);
    const tags = await getConfigTags(name);
    const stepperObject = { steps: tags, current: 0 };
    initStepper(stepperObject);
    const { steps } = stepperObject;
    const actualStep = getActualStep(steps);
    storeFlowData(productByRoute);
    storeStepData({ ...actualStep, steps });
    googleAnalytics.cotizacion();
    navigateWithQueryParam(firstRoute, 'inmobiliaria');
    endLoading();
  };

  return (
    <Container
      autoComplete="off"
      className={classes.formContainer}
      disableGutters
      maxWidth={false}
    >
      <CreditConditionsSimulator
        onSubmit={onSubmit}
        initialValues={CREDITO_HIPOTECARIO_DESEMBOLSO_INITIAL_VALUES}
        buttonTitle={buttonTitle}
        isVivienda={isVivienda}
        useDesembolsoMode
        hidePlazo
      />
    </Container>
  );
};

CreditSimulator.propTypes = {
  startLoading: PropTypes.func,
  endLoading: PropTypes.func,
  agentId: PropTypes.string,
  buttonTitle: PropTypes.string,
};

CreditSimulator.defaultProps = {
  startLoading: () => { },
  endLoading: () => { },
  agentId: '',
  buttonTitle: '',
};
