import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import SpotifyIcon from '@material-ui/icons/LibraryMusic';

import useStyles from './useStyles';

const SOCIAL_TYPE_INSTAGRAM = 'instagram';
const SOCIAL_TYPE_SPOTIFY = 'spotify';

export const SocialButton = ({ type, text, url }) => {
  const classes = useStyles();

  switch (type) {
    case SOCIAL_TYPE_INSTAGRAM:
      return (
        <Button
          variant="contained"
          className={`${classes.button} ${classes.instagramButton}`}
          href={url}
          target="_blank"
        >
          <InstagramIcon className={classes.buttonIcon} />
          {text}
        </Button>
      );
    case SOCIAL_TYPE_SPOTIFY:
      return (
        <Button
          variant="contained"
          className={`${classes.button} ${classes.spotifyButton}`}
          href={url}
          target="_blank"
        >
          <SpotifyIcon className={classes.buttonIcon} />
          {text}
        </Button>
      );
    default:
      return null;
  }
};

SocialButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
};

SocialButton.defaultProps = {
  type: '',
  text: '',
  url: '',
};
