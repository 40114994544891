import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {

    display: 'flex',
    justifyContent: 'center',
    paddingTop: '15px',
    paddingLeft: '15px',
    paddingRight: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '30px',
    },
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerContainer: {
    width: '96%',
  },
  childContainer: {
    maxWidth: '800px',
    width: '95%',
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  boxImage: {
    maxWidth: '100px',
    marginBottom: '10px',
  },
  imgLogo: {
    width: '100%',
  },
}));
