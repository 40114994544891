/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Stepper as StepperMaterial,
  Step,
  StepLabel,
  StepConnector,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import { CustomStepContent } from './CustomStepContent';

export const StepperVertical = ({ steps, activeStep, connectorClass }) => {
  const classes = useStyles();
  return (
    <StepperMaterial
      activeStep={activeStep}
      connector={<StepConnector className={connectorClass} />}
      orientation="vertical"
      className={classes.stepper}
    >
      {
        steps.map(({
          label,
          completed,
          extraInfo,
        }) => (
          <Step key={label} completed={completed}>
            <StepLabel
              classes={{
                label: classes.label,
              }}
              StepIconProps={{
                classes: {
                  text: classes.stepLabelText,
                  active: classes.active,
                  root: classes.root,
                  completed: classes.completed,
                },
              }}
            >
              {label}
            </StepLabel>
            <CustomStepContent>
              {
                extraInfo.map((info) => (
                  <Typography component="p" className={classes.extraInfo}>{info}</Typography>
                ))
              }
            </CustomStepContent>
          </Step>
        ))
      }
    </StepperMaterial>
  );
};

StepperVertical.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  connectorClass: PropTypes.string,
};

StepperVertical.defaultProps = {
  connectorClass: '',
};
