/* eslint-disable react/forbid-prop-types */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFlowStrategy } from '../../hooks/useFlowStrategy';

export const FlowStrategyContainer = ({ flowName, strategies, children }) => {
  const { setFlowStrategy } = useFlowStrategy();

  useEffect(() => {
    const defaultStrategy = Object.keys(strategies)[0];
    const strategy = strategies[flowName] || defaultStrategy;
    setFlowStrategy(flowName, strategy);
  }, []);

  return children;
};

FlowStrategyContainer.propTypes = {
  flowName: PropTypes.string.isRequired,
  strategies: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
