/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import useStyles from './useStyles';

const InputBase = ({
  id,
  name,
  label,
  value,
  type,
  disabled,
  className,
  fullWidth,
  multiline,
  startAdornment,
  endAdornment,
  onChange,
  onKeyDown,
  error,
  helperText,
  hide,
  extraInputClassName,
  required,
  hideLabel,
  makeRequired,
}, ref) => {
  const classes = useStyles();

  const handleOnChange = useCallback((event) => {
    const { value: _value } = event.target;
    onChange(event, _value);
  }, [onChange]);

  const handleOnWheel = useCallback((event) => {
    if (type === 'number') {
      event.target.blur();
      event.stopPropagation();
      return false;
    }
    return true;
  }, [type]);

  return (
    <TextField
      hiddenLabel={hide}
      id={id}
      name={name}
      label={label}
      value={value}
      type={type}
      inputRef={ref}
      disabled={disabled}
      className={clsx(classes.textField, className)}
      fullWidth={fullWidth}
      variant="filled"
      required={required}
      multiline={multiline}
      hidden
      InputLabelProps={{
        classes: {
          root: clsx(classes.InputLabelPropsRoot, {
            [classes.delete]: hideLabel,
          }),
          focused: classes.InputLabelPropsFocused,
        },
      }}
      InputProps={{
        classes: {
          root: clsx(classes.InputPropsRoot, extraInputClassName, {
            [classes.hide]: hide,
          }),
          focused: classes.InputPropsFocused,
          input: clsx(
            classes.InputPropsInput,
            {
              [classes.InputPropsInputTextArea]: multiline,
              [classes.InputPropsHiddenLabel]: hideLabel,
            },
          ),
          disabled: classes.InputPropsDisabled,
          error: classes.InputPropsError,
        },
        disableUnderline: true,
        startAdornment: (
          startAdornment
            ? (
              <InputAdornment position="start">
                { startAdornment }
              </InputAdornment>
            )
            : ''
        ),
        endAdornment: (
          endAdornment
            ? (
              <InputAdornment position="end">
                { endAdornment }
              </InputAdornment>
            )
            : ''
        ),
      }}
      onChange={handleOnChange}
      onKeyDown={onKeyDown}
      onWheel={handleOnWheel}
      error={error}
      helperText={!hide && helperText}
    />
  );
};

InputBase.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  extraInputClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  startAdornment: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  endAdornment: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  hide: PropTypes.bool,
  makeRequired: PropTypes.bool,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

InputBase.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  value: '',
  type: 'text',
  disabled: false,
  className: '',
  extraInputClassName: '',
  fullWidth: true,
  multiline: false,
  startAdornment: '',
  endAdornment: '',
  onChange: () => {},
  onKeyDown: () => {},
  error: false,
  helperText: '',
  hide: false,
  makeRequired: false,
  required: false,
  hideLabel: false,
};

export default memo(React.forwardRef(InputBase));
