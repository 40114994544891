import React, {
  useEffect, useContext,
  useState,
} from 'react';

import useStateMachine from './hooks/useStateMachine';

import { StepperContext } from '../../../../context/stepper';
import { LoaderPage } from './LoaderPage';
import {
  APPLICATION_NAME,
  PROFILING_MODAL,
  STEP_INFORMACION_DEL_CLIENTE,
  PERFILAMIENTO_MODAL_COMPLETE,
  NORMALIZED_STEP_INFORMACION_DEL_CLIENTE,
  APPLICATION_COMPLETED,
  PROFILING_COMPLETED,
} from '../../../../constants';

import {
  getCurrentStep,
  getProcess,
  getPageTagStepsByLocalSteps,
  getTheCurrentStep,
  createUpdateTagGeneral,
  execFinishTagConfigDatosDocumentos,
  getFlowTypeHandler,
} from '../../../../utils';

import * as processAPI from '../../../../api/process';
import useRoute from '../../../../hooks/useRoute';

import { StepConfigCompletedChecker } from '../../../../components/StepConfigCompletedChecker';
import { nowDate } from '../../../../components/Input/InputBaseDatePicker/formatter';
import * as googleAnalytics from '../../../../utils/googleAnalytics/events';
import { finishStepByProcess } from '../../../../utils/steps';
import { ModalNodosChiplo } from '../CreditProfilingPage/CreditProfilingModalChiplo';
import { CreditProfilingWait } from '../CreditProfilingPage/CreditProfilingWait';

export const ApplicationPage = () => {
  const [title, setTitle] = useState('');
  const { updateCurrentStep } = useContext(StepperContext);
  const {
    navigateWithQueryParam,
  } = useRoute();
  const {
    STATES,
    currentState,
    goToProfilingModal,
    goToLoaderPage,
    goToProfilingCompleted,
  } = useStateMachine();

  const finishInformacionCliente = async () => {
    const processId = getProcess();
    const { normalizedFlowName } = await getFlowTypeHandler(processId);
    await execFinishTagConfigDatosDocumentos(normalizedFlowName);
    await finishStepByProcess(NORMALIZED_STEP_INFORMACION_DEL_CLIENTE);
    setTitle('Gracias, información recibida. Analizamos tu solicitud de inmediato y regresamos contigo con los avances. Cualquier duda puedes escribirnos al 3335063714.');
    return goToProfilingCompleted();
  };

  const completeUploadTagGeneral = () => {
    // TODO: Remover estas tags obsoletas
    createUpdateTagGeneral(
      {
        completed: true,
        fecha: nowDate(),
      },
      'solicitudfirmada',
    );
    googleAnalytics.solicitudFirmada();
    window.location.reload();
  };

  const getProcessSteps = async () => {
    const processId = getProcess();
    const localStepPosition = getCurrentStep();

    if (!processId) {
      return getPageTagStepsByLocalSteps(
        APPLICATION_NAME,
        localStepPosition,
      );
    }
    const process = await processAPI.getProcessById(processId);
    const { tags = {} } = process;
    const { redirectPath, stepPosition } = await getTheCurrentStep(
      processId,
      APPLICATION_NAME,
    );
    const consultTag = tags[APPLICATION_NAME];
    return {
      tags, redirectPath, stepPosition, consultTag, process,
    };
  };

  const selectPage = async () => {
    const {
      redirectPath, stepPosition, consultTag, process = {},
    } = await getProcessSteps();
    const { currentPage = STATES.PROFILING_MODAL, completed } = consultTag;
    if (completed) return navigateWithQueryParam(redirectPath, 'inmobiliaria');
    updateCurrentStep(stepPosition);
    switch (currentPage) {
      case PROFILING_MODAL:
        const { modalViewFinish = false } = process;
        if (modalViewFinish) {
          setTitle('Tu expediente está en proceso de revisión. Una vez revisado nos pondremos en contacto. ');
          return goToProfilingCompleted();
        }
        return goToProfilingModal();
      case PERFILAMIENTO_MODAL_COMPLETE:
        setTitle('Tu expediente está en proceso de revisión. Una vez revisado nos pondremos en contacto. ');
        return goToProfilingCompleted();

      case APPLICATION_COMPLETED:
        setTitle('Gracias, información recibida. Analizamos tu solicitud de inmediato y regresamos contigo con los avances. Cualquier duda puedes escribirnos al 3335063714.');
        return goToProfilingCompleted();
      case PROFILING_COMPLETED:
        setTitle('Gracias, información recibida. Analizamos tu solicitud de inmediato y regresamos contigo con los avances. Cualquier duda puedes escribirnos al 3335063714.');
        return goToProfilingCompleted();

      default:
        return goToLoaderPage();
    }
  };

  useEffect(() => {
    selectPage();
  }, []);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {
        (
          () => {
            switch (currentState) {
              case STATES.PROFILING_MODAL:
                return (
                  <StepConfigCompletedChecker
                    tagName={APPLICATION_NAME}
                    stepConfigName={STEP_INFORMACION_DEL_CLIENTE}
                    updateTag={{ currentPage: PROFILING_COMPLETED }}
                    onStepConfigCompleted={completeUploadTagGeneral}
                  >
                    <ModalNodosChiplo
                      onFinish={finishInformacionCliente}
                    />
                  </StepConfigCompletedChecker>
                );
              case STATES.PROFILING_COMPLETED:
                return (
                  <CreditProfilingWait titlePage={title} />
                );
              case STATES.LOADER_PAGE:
              default:
                return (
                  <LoaderPage />
                );
            }
          }
        )()
      }
    </>
  );
};
