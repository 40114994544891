import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import useStyles from './useStyles';

export const LandingLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <Container
      maxWidth={false}
      disableGutters
      component="main"
      className={classes.container}
    >
      {children}
    </Container>
  );
};

LandingLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
