import { createInstance } from './config';

const BASE_URL = '/entities';

export const getEntityById = async (id) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/${id}`);
  return data;
};

export const getEntitiesLandingData = async (companyId) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/company/${companyId}/landing`);
  return data;
};

export const getAgentsListByCompanyAndEntityId = async (companyId, entityId) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/company/${companyId}/entity/${entityId}/agents`);
  return data;
};

export const getAgentDataById = async (companyId, agentId) => {
  const axios = createInstance();
  const { data } = await axios.get(`${BASE_URL}/company/${companyId}/agents/${agentId}`);
  return data;
};
