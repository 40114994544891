import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
  },
  creditContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '25px 0',
  },
  imageContainer: {
    maxWidth: '120px',
    height: '66px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 10px 0 0',
      maxWidth: '150px',
      height: '80px',
    },
  },
  image: {
    height: '100%',
    objectFit: 'contain',
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  dataContainer: {
    paddingLeft: '12px',
  },
  title: {
    fontFamily: 'Open Sans',
    color: '#303030',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    opacity: '0.9',
  },
  subtitle: {
    fontFamily: 'Open Sans',
    color: '#303030',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    opacity: '0.6',
  },
}));
