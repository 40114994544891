import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  digitContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  fullWidthTextField: {
    '& input': {
      fontFamily: 'monospace',
      letterSpacing: '1ch',
      border: 'none',
      backgroundColor: '#f3f3f3',
      '&:focus': {
        backgroundColor: '#d3d3d3',
      },
    },
    '& input::placeholder': {
      letterSpacing: '0',
    },
    background: 'linear-gradient(to left, #f3f3f3 1ch, transparent 1ch) 0 100% / 1ch 2px repeat-x',
    '&:focus-within': {
      background: 'linear-gradient(to left, #d3d3d3 1ch, transparent 1ch) 0 100% / 1ch 2px repeat-x',
    },
  },
}));
