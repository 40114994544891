import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputEmpty from '../InputEmpty';
import { isInputEmpty, isNumber, validateRegex } from '../../../utils';

const InputPercentage = ({
  value,
  onChange,
  required,
  error,
  errorMessage,
  disabled,
  ...props
}) => {
  const [mValue, setValue] = useState(value);

  const onClear = () => {
    setValue('');
    onChange('');
  };

  const isValid = (data) => {
    if (isInputEmpty(data) && !required) return true;
    return (
      isInputEmpty(data)
      || (isNumber(data) && validateRegex(data, /^\d+\.?(\d{1,2})?$/))
    );
  };

  const formatPercentage = (data) => `${data}%`;

  const formatNumber = (data) => data.replace(/[,%]/g, '');

  const deleting = (eventValue) => {
    let deletingValue = eventValue;
    if (eventValue.length < mValue.length) {
      deletingValue = eventValue.slice(0, -1);
    }
    return formatNumber(deletingValue);
  };

  const monChange = (event) => {
    const {
      target: { value: eventValue },
    } = event;
    const formattedNumber = deleting(eventValue);
    if (isValid(formattedNumber)) {
      setValue(formatPercentage(formattedNumber));
      onChange(event, formatPercentage(formattedNumber));
      if (formattedNumber.length === 0) onClear();
    }
  };

  useEffect(() => {
    if (!value) return;

    const formattedNumber = formatNumber(value);
    if (isValid(formattedNumber)) {
      setValue(formatPercentage(formattedNumber));
    }
  }, [value]);

  return (
    <InputEmpty
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={mValue}
      onChange={monChange}
      error={error}
      onClear={onClear}
      errorMessage={errorMessage}
      required={required}
    />
  );
};

InputPercentage.defaultProps = {
  value: '',
  onChange: () => {},
  required: false,
  disabled: false,
  error: false,
  errorMessage: '',
};

InputPercentage.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default InputPercentage;
