/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDropzone } from 'react-dropzone';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import useStyles from './useStyles';
import { uploadFileToS3 } from '../../../api/uploadFileToS3';
import { DOCUMENTO_COMPLETO_URL, DOCUMENTO_EMPTY } from '../../../constants/commonUrls';

const LOADER = 'LOADER';
const DOCUMENT_EMPTY = 'DOCUMENT_EMPTY';
const DOCUMENT_COMPLETE = 'DOCUMENT_COMPLETE';
const DOCUMENT_ERROR = 'DOCUMENT_ERROR';

const InputUploadDocument = ({
  multiple,
  accept,
  onDrop,
  disabled,
  label,
  value,
  name,
  disableCompleteIcon,
}) => {
  const [currentIcon, setCurrentIcon] = useState(DOCUMENT_EMPTY);
  const classes = useStyles();

  const onDropChange = async (acceptedFiles, rejectedFiles) => {
    try {
      setCurrentIcon(LOADER);
      if (acceptedFiles.length) {
        const urls = await Promise.all(acceptedFiles.map(async (file) => {
          const { url } = await uploadFileToS3(file);
          return url;
        }));
        if (disableCompleteIcon) {
          setCurrentIcon(DOCUMENT_EMPTY);
        } else {
          setCurrentIcon(DOCUMENT_COMPLETE);
        }
        const event = { target: { name } };
        onDrop(event, urls);
      }
      if (rejectedFiles.length) {
        setCurrentIcon(DOCUMENT_EMPTY);
      }
    } catch (e) {
      setCurrentIcon(DOCUMENT_EMPTY);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ multiple, accept, onDrop: onDropChange });

  const ICON = useCallback(() => {
    switch (currentIcon) {
      case LOADER:
        return <CircularProgress />;
      case DOCUMENT_COMPLETE:
        return <img alt="completado" src={DOCUMENTO_COMPLETO_URL} />;
      case DOCUMENT_EMPTY:
      case DOCUMENT_ERROR:
      default:
        return <img alt="vacio" src={DOCUMENTO_EMPTY} />;
    }
  }, [currentIcon]);
  const rootProps = getRootProps({ className: classes.dropZone });
  const inputProps = getInputProps();

  useEffect(() => {
    if (value) setCurrentIcon(DOCUMENT_COMPLETE);
    else setCurrentIcon(DOCUMENT_EMPTY);
  }, [value]);

  return (
    <div className={classes.dropZoneContainer}>
      <Paper {...rootProps} style={{ border: currentIcon === DOCUMENT_ERROR ? '2px dashed #f44336' : '2px dashed #4C5C68' }}>
        <input {...inputProps} disabled={disabled || currentIcon === DOCUMENT_COMPLETE} />
        <div className={classes.iconContainer}>
          {ICON()}
        </div>
        {label ? (
          <div className={classes.containerTexts}>
            <Typography variant="body1" className={classes.label}>
              {label}
            </Typography>
            {
              currentIcon === LOADER ? (
                <Typography variant="body2" className={classes.subTitle}>
                  Subiendo
                </Typography>
              ) : ''
            }
          </div>
        ) : ''}
      </Paper>
    </div>
  );
};

InputUploadDocument.propTypes = {
  multiple: PropTypes.bool,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onDrop: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  isIneEditor: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  disableCompleteIcon: PropTypes.bool,
};

InputUploadDocument.defaultProps = {
  multiple: false,
  accept: '',
  onDrop: () => { },
  disabled: false,
  label: '',
  isIneEditor: false,
  value: '',
  name: '',
  disableCompleteIcon: false,
};

export default InputUploadDocument;
