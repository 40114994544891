/* eslint-disable no-unused-vars */
import React, {
  useEffect, useContext, useState,
  useCallback,
} from 'react';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import useStateMachine from '../../hooks/useStateMachine';

import * as processAPI from '../../../../api/process';
import {
  CONSULTA_BURO_NAME,
  CONSULT_CREDIT_BUSSINESS,
  CONSULT_CREDIT_SHAREHOLDER,
  PRODUCT_ID_MEGA_CASH_CUSTOM,
  BURO_REJECTED,
  BURO_MISSING,
  REJECTED_CIEC,
  CRED_HIP_PRODUCT_ID,
} from '../../../../constants';

import { ConsultCreditShareholder } from './ConsultCreditShareholder';
import { StepperContext } from '../../../../context/stepper';
import { LoaderPage } from './LoaderPage';
import { getInformationCIEC, getProcess, getTheCurrentStep } from '../../../../utils';
import { Wait } from './Wait';
import { BuroRejected } from './BuroRejected';
import { useProduct } from '../../../../hooks/useProduct';
import { storeProductData } from '../../../../utils/product';

export const ConsultPage = () => {
  const navigate = useNavigate();
  const { updateCurrentStep, updateStateStepper } = useContext(StepperContext);
  const { getDefaultProduct, getFlowType } = useProduct();
  const currentProduct = getDefaultProduct();
  const [productId, setProductId] = useState(currentProduct._id);

  const {
    STATES,
    currentState,
    goToConsultCreditBussiness,
    goToConsultCreditShareholder,
    goToSetDataShareholder,
    goToWaitInformation,
    goToConsultInvoiceBussiness,
    goToConsultPatrimonialRelationShip,
    goToUploadPdf,
    goToWaitConsult,
    goToConsultBuroRejected,
    goToSetDataLegalRepresentatives,
  } = useStateMachine();

  const productGoToFunctions = {
    [CRED_HIP_PRODUCT_ID]: {
      [STATES.CONSULT_CREDIT_SHAREHOLDER]: {
        REJECTED_FIELD: goToConsultBuroRejected,
        NORMAL_FLOW: goToConsultPatrimonialRelationShip,
      },
    },
    [PRODUCT_ID_MEGA_CASH_CUSTOM]: {
      [STATES.SET_DATA_SHAREHOLDER]: goToUploadPdf,
      [STATES.UPLOAD_PDF]: goToWaitConsult,
    },
  };

  const createUpdateTag = useCallback((updateTag) => {
    const process = getProcess();
    return async () => {
      const { tags = {} } = await processAPI.getProcessById(process);
      const body = {
        [CONSULTA_BURO_NAME]: { ...tags[CONSULTA_BURO_NAME], ...updateTag },
      };
      await processAPI.createTag({
        tagName: CONSULTA_BURO_NAME,
        active: true,
        body: { ...tags, ...body },
        processId: process,
      });
    };
  }, []);

  const getCurrentPage = (currentPage, status) => (
    [BURO_MISSING, REJECTED_CIEC].includes(status) ? BURO_REJECTED : currentPage);

  const selectPage = async () => {
    storeProductData(currentProduct);
    const process = getProcess();
    const { tags = {}, product = {}, status } = await getInformationCIEC(process);
    const { redirectPath, stepPosition } = await getTheCurrentStep(process, CONSULTA_BURO_NAME);
    setProductId(product._id || '');
    const consultTag = tags[CONSULTA_BURO_NAME] || {};
    const { currentPage = CONSULT_CREDIT_BUSSINESS, completed } = consultTag;
    if (completed) return navigate(redirectPath);
    const newCurrentPage = getCurrentPage(currentPage, status);
    updateCurrentStep(stepPosition);
    switch (newCurrentPage) {
      case CONSULT_CREDIT_SHAREHOLDER:
      default:
        return goToConsultCreditShareholder();
    }
  };

  useEffect(() => {
    selectPage();
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {
        (
          () => {
            switch (currentState) {
              case STATES.CONSULT_CREDIT_SHAREHOLDER:
              default:
                return (
                  <ConsultCreditShareholder
                    createUpdateTag={createUpdateTag({ completed: true })}
                    goTo={productGoToFunctions[productId][STATES.CONSULT_CREDIT_SHAREHOLDER]}
                    product={productId}
                  />
                );
            }
          }
        )()
      }
    </>
  );
};
