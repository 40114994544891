import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from '@material-ui/core';

export const ViviendaFormSubmitButton = ({ classes, buttonTitle, disabled }) => (
  <Container
    className={classes.buttonContainer}
  >
    <Button
      className={classes.button}
      type="submit"
      disabled={disabled}
    >
      {buttonTitle}
    </Button>
  </Container>
);

ViviendaFormSubmitButton.propTypes = {
  classes: PropTypes.shape({
    buttonContainer: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }),
  buttonTitle: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ViviendaFormSubmitButton.defaultProps = {
  classes: {
    buttonContainer: '',
    button: '',
  },
  disabled: false,
};
