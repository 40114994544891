/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box, Typography,
} from '@material-ui/core';

import styles from './useStyles';
import Input from '../Input';
import {
  convertirANumero,
} from '../../utils/currency';
import {
  INPUT_SIMULATOR_MINIMUN_ENGANCHE_DECIMAL,
  INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
  MINIMUM_CREDIT_VALUE,
} from '../../constants';
import { getValueMinimumErrorMessage } from '../../utils/simulator.utils';

export const InputSimulator = ({ title, values, setValues }) => {
  const classes = styles();

  const percentage = useMemo(() => {
    const precio = values.precio.value;
    const enganche = values.enganche.value;
    if (!precio || !enganche || (typeof precio !== 'number' || typeof enganche !== 'number')) return 0;
    return parseInt(((enganche / precio) * 100).toFixed(0), 10);
  }, [values]);

  const isGreaterThanValue = (inputValue, limitValue, errorMessage) => {
    const isValid = inputValue > limitValue;
    return { isValid, errorMessage };
  };

  const isValidPercentage = (iValue, precio, limitValue, errorMessage) => {
    const isValid = parseInt(((iValue / precio) * 100).toFixed(0), 10) >= limitValue;
    return { isValid, errorMessage };
  };

  const handleChange = (event, value) => {
    const { target } = event;
    const { name } = target;
    if (name === 'precio') {
      const convertedValue = convertirANumero(value);
      const newEnganche = (convertedValue * INPUT_SIMULATOR_MINIMUN_ENGANCHE_DECIMAL)?.toFixed(2);

      setValues({
        ...values,
        [name]: {
          ...values[name],
          value: convertedValue,
          isEmpty: !value,
        },
        enganche: {
          ...values.enganche,
          value: convertirANumero(newEnganche),
          isEmpty: !newEnganche,
        },
      });
    } else {
      setValues({
        ...values,
        [name]: {
          ...values[name],
          value: name !== 'year' ? convertirANumero(value) : value,
          isEmpty: !value,
          ...(name === 'enganche' ? { focus: true } : {}),
        },
      });
    }
  };

  return (
    <Box className={clsx(classes.innerContainer)}>
      <Typography component="h3" className={clsx(classes.title)}>{title}</Typography>
      <Box className={classes.firtInputElement}>
        <Input
          className={classes.inputPrecio}
          label={values.precio.label}
          required={values.precio.required}
          type={values.precio.type}
          name={values.precio.name}
          value={values.precio.value}
          isEmpty={values.precio.isEmpty}
          validations={[
            (inputValue) => isGreaterThanValue(inputValue, MINIMUM_CREDIT_VALUE, getValueMinimumErrorMessage()),
          ]}
          onChange={handleChange}
        />
      </Box>
      <Box className={classes.precioContainer}>
        <Input
          disabled={values.enganche.disabled}
          className={classes.engancheInput}
          label={values.enganche.label}
          required={values.enganche.required}
          type={values.enganche.type}
          name={values.enganche.name}
          value={values.enganche.value}
          isEmpty={values.enganche.isEmpty}
          onChange={handleChange}
          error={values.enganche.error}
          validations={[
            (inputValue) => isValidPercentage(inputValue, values.precio.value, INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE, `Valor no puede ser menor a ${INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE}%`),
            (inputValue) => isGreaterThanValue(values.precio.value, inputValue, 'Enganche no puede ser mayor al precio'),
          ]}
          endAdornment={(
            <Typography
              className={clsx(classes.enganche, {
                [classes.invalidate]: percentage < INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
              })}
            >
              {`${percentage}%`}
            </Typography>
          )}
        />
      </Box>
      {/* <Box className={classes.yearContainer}>
          <Input
          className={classes.inputBorder}
          label={values.year.label}
          required={values.year.required}
          type={values.year.type}
          name={values.year.name}
          value={values.year.value}
          isEmpty={values.year.isEmpty}
          onChange={handleChange}
          fullWidth
          options={values.year.options}
        />
        </Box> */ }
    </Box>
  );
};

InputSimulator.propTypes = {
  title: PropTypes.string,
  values: PropTypes.object,
  setValues: PropTypes.func,
};

InputSimulator.defaultProps = {
  title: '',
  values: {},
  setValues: () => { },
};
