import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  innerContainer: {
    maxWidth: '600px',
    margin: '0 auto',
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  customTitleClass: {
    textAlign: 'center',
  },
  title: {
    maxWidth: '1100px',
    marginBottom: '70px',
    fontSize: '32px',
  },
}));
