/* eslint-disable react/forbid-prop-types */

import React, {
  memo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { normalizeText } from 'normalize-text';

import InpuAutocomplete from '../InpuAutocomplete';

import useDidUpdate from '../../../hooks/useDidUpdate';
import useDebounce from '../../../hooks/useDebounce';
import useLoading from '../../../hooks/useLoading';

import * as GoogleApis from '../../../api/googleApis';
import * as ProfileDataApi from '../../../api/profileData';

import { getProcess, getProfile } from '../../../utils/auth';

const InputAddress = ({
  id,
  name,
  label,
  value,
  required,
  disabled,
  className,
  fullWidth,
  onChange,
}) => {
  const [valueInput, setValueInput] = useState('');
  const [options, setOptions] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const debounceValueInput = useDebounce(valueInput);
  const {
    startLoading,
    endLoading,
    isLoading,
  } = useLoading();

  const updateAddress = useCallback(async (addressName) => {
    const processId = await getProcess();
    const profileId = await getProfile();
    const address = addresses.find((option) => (
      option.formatted_address === addressName
    )) || [];
    const data = await ProfileDataApi.createAddress(profileId, processId, address);
    return data;
  }, [addresses]);

  const onChangeAutocomplete = useCallback((event, newValue) => {
    if (!process.env.STORYBOOK) updateAddress(newValue);
    onChange(event, newValue);
  }, [onChange, updateAddress]);

  const onChangeInput = useCallback((event) => {
    setValueInput(event.target.value);
  }, []);

  const fetchOptions = async (query) => {
    try {
      startLoading();
      const _addresses = await GoogleApis.getPlaces(normalizeText(query));

      const _options = _addresses.map((option) => (
        option.formatted_address
      ));

      setOptions(_options);
      setAddresses(_addresses);
    } catch (e) { /* no problem */ } finally {
      endLoading();
    }
  };

  useDidUpdate(() => {
    fetchOptions(debounceValueInput);
  }, [debounceValueInput]);

  return (
    <InpuAutocomplete
      id={id}
      name={name}
      label={label}
      value={value}
      valueInput={valueInput}
      options={options}
      required={required}
      disabled={disabled}
      loading={isLoading}
      className={className}
      fullWidth={fullWidth}
      onChange={onChangeAutocomplete}
      onChangeInput={onChangeInput}
      filterSelectedOptions={false}
    />
  );
};

InputAddress.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
};

InputAddress.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  value: undefined,
  required: false,
  disabled: false,
  className: '',
  fullWidth: true,
  onChange: () => {},
};

export default memo(InputAddress);
