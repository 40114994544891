import { isNumber } from './validator';

export const formatNumber = (value) => {
  let newValiue = value;

  while (newValiue.length > 0) {
    if (isNumber(newValiue)) return newValiue;
    newValiue = newValiue.slice(0, newValiue.length - 1);
  }

  return '';
};

export const removeDecimals = (value) => {
  const [newValue] = value.split('.');
  return newValue;
};

export const formatDecimals = (value) => {
  let newValue = value.split('.');
  newValue = newValue.splice(0, 2).join('.'); // + newValue.join(''); VARIANT
  return newValue;
};

export const numberFormatter = (value, decimals = true) => {
  let newValue = value ? value.toString() : '';

  newValue = formatNumber(newValue);
  if (decimals) newValue = formatDecimals(newValue);
  else newValue = removeDecimals(newValue);

  return newValue;
};

export default numberFormatter;
