import moment from 'moment';

const dateFormatter = (date = '', format = '', locale = '') => {
  moment.locale(locale);
  let formattedDate = null;
  if (moment(date, format).isValid()) formattedDate = moment(date, format);
  else if (moment(date).isValid()) formattedDate = moment(date);
  return formattedDate;
};

export const nowDate = (format = 'L', locale = 'es') => {
  moment.locale(locale);
  return moment().format(format);
};

export default dateFormatter;
