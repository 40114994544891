import { createSelector } from 'reselect';

// Selectores
export const selectPerfiladorInputValue = (state) => state.perfilador.inputValue;
export const selectPerfiladorInputNumber = (state) => state.perfilador.inputNumber;
export const selectPerfiladorResultado = (state) => state.perfilador.resultado;
export const selectPerfiladorPlazo = (state) => state.perfilador.plazo;
export const selectPerfiladorPlazoNumber = (state) => state.perfilador.plazoNumber;
export const selectPerfiladorEnganche = (state) => state.perfilador.engancheValue;
export const selectPerfiladorPercentageEnganche = (state) => state.perfilador.percentageEnganche;
export const selectPerfiladorEngancheNumber = (state) => state.perfilador.engancheNumber;
export const selectPerfiladorInitialDataValues = (state) => state.perfilador.initialDataValues;
export const selectPerfiladorTasaNumber = (state) => state.perfilador.tasaNumber;
export const selectPerfiladorengancheMinimoPercentage = (
  state,
) => state.perfilador.engancheMinimoPercentage;
export const selectPerfiladorGastosEscrituraPercentage = (
  state,
) => state.perfilador.gastosEscrituraPercentage;
export const selectPerfiladorInmobiliariaConditions = (
  state,
) => state.perfilador.inmobiliariasConditions;

export const selectPerfiladorCurrentInmobiliariaId = (
  state,
) => state.perfilador.currentInmobiliariaId;

export const selectPICByCurrentInmobiliariaId = createSelector(
  [selectPerfiladorInmobiliariaConditions],
  (inmobiliariasConditions) => inmobiliariasConditions[selectPerfiladorCurrentInmobiliariaId],
);
// Selector memoizado
export const selectPerfiladorFormattedResultado = createSelector(
  [selectPerfiladorResultado],
  (resultado) => ({
    ...resultado,
    formattedIngresosRecomendados: new Intl.NumberFormat('es-MX').format(resultado.ingresosRecomendados),
  }),
);
