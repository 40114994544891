/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as companyConfigsAPI from '../../api/companyConfigs';
import useStyles from './useStyles';
import { COMPANY } from '../../constants';

export const COMPANY_TYPE = 'pagesData';

export const TermsAndConditions = ({
  handleChange,
  checked,
  product,
}) => {
  const [pagesData, setPagesData] = useState({});
  const classes = useStyles();
  const {
    terms = '',
  } = pagesData;
  const fetchData = async () => {
    const companyConfigs = await companyConfigsAPI.getAllCompanyConfigs(COMPANY);
    const companyConfig = companyConfigs.find(({ type: { name = '' } = {} }) => name === COMPANY_TYPE);
    if (companyConfig && companyConfig.payload) {
      const {
        pagesData: pagesDataCompany,
      } = companyConfig.payload[product.productName][product.page];
      setPagesData(pagesDataCompany);
    }
    return {};
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className={classes.container}>
      <FormControlLabel
        control={(
          <Checkbox
            checked={checked}
            onChange={handleChange}
            name="checked"
            color="primary"
          />
        )}
        label={(
          <span className={classes.terms}>
            Acepto
            <span onClick={() => window.open('/legal', '_blank')} className={classes.companyLink}>{` ${terms}`}</span>
          </span>
        )}
      />
    </div>
  );
};

TermsAndConditions.propTypes = {
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
  product: PropTypes.shape({
    productName: PropTypes.string,
    page: PropTypes.string,
  }),
};

TermsAndConditions.defaultProps = {
  handleChange: () => { },
  checked: false,
  product: {
    productName: '',
    page: '',
  },
};

export default TermsAndConditions;
