import moment from 'moment';
import 'moment/locale/es';

const LOCALE = 'es';
moment.locale(LOCALE);

export const cleanMonth = (rawMonth) => parseInt(rawMonth.split(' ')[0], 10);

export const convertDateToFormat = (date, format) => moment(date).format(format);

export const sortByDateDescending = (array) => {
  const resultArray = array.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB - dateA;
  });
  return resultArray;
};
