import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { LockOutlined, Menu, QuestionAnswer } from '@material-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import useStyles from './useStyles';
import { Button } from '../../../../components';
import { Sidebar } from '../Sidebar';
import logoUrl from '../../../../assets/images/chiplo.svg';
import { APP_MAIN_ROUTE, FAQ_ROUTE, LOGIN_ROUTE } from '../../../../constants';

export const HeaderBar = ({
  showLoginButton,
  showSideBar,
  navigateOnLogoClick,
  customLogoClickRoute,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const toggleSidebar = () => {
    setOpen(!open);
  };
  const sideBarOptions = [
    showLoginButton && {
      label: 'Ingresar',
      type: 'element',
      icon: <LockOutlined style={{ color: 'white' }} />,
      action: async () => {
        navigate(LOGIN_ROUTE);
      },
    },
    {
      label: 'Cotizar',
      type: 'navHashLink',
      icon: <LockOutlined style={{ color: 'white' }} />,
      route: `${pathname}#cotizador`,
      action: async () => {},
    },
    {
      label: 'Preguntas frecuentes',
      type: 'element',
      icon: <QuestionAnswer style={{ color: 'white' }} />,
      action: async () => {
        navigate(FAQ_ROUTE);
      },
    },
  ];

  const onLogoClick = () => {
    if (!navigateOnLogoClick) return;
    const routeToRedirect = customLogoClickRoute || APP_MAIN_ROUTE;
    navigate(routeToRedirect);
  };

  const renderLoginButton = () => (
    <Button
      button
      onClick={() => {
        navigate(LOGIN_ROUTE);
      }}
      variant="outlined"
      className={classes.btnSign}
    >
      Ingresar
    </Button>
  );
  return (
    <AppBar
      position="static"
      className={classes.appBar}
    >
      <Toolbar>
        <Box
          className={clsx(
            classes.boxLogo,
            {
              [classes.boxLogoNavigate]: navigateOnLogoClick,
            },
          )}
          onClick={onLogoClick}
        >
          <img
            className={classes.imgLogo}
            src={logoUrl}
            alt="imgLogo"
          />
        </Box>
        <Box className={classes.boxItems}>
          {showSideBar && (
            <Box className={classes.btnSidebar}>
              <IconButton onClick={toggleSidebar}>
                <Menu style={{ fontSize: '2.5rem' }} />
              </IconButton>
            </Box>
          )}

          <Box className={classes.boxButtons}>
            {showLoginButton && renderLoginButton()}
          </Box>
        </Box>
        <Sidebar
          open={open}
          toggleSidebar={toggleSidebar}
          options={sideBarOptions}
        />
      </Toolbar>
    </AppBar>
  );
};

HeaderBar.propTypes = {
  showLoginButton: PropTypes.bool,
  showSideBar: PropTypes.bool,
  navigateOnLogoClick: PropTypes.bool,
  customLogoClickRoute: PropTypes.string,
};

HeaderBar.defaultProps = {
  showLoginButton: false,
  showSideBar: false,
  navigateOnLogoClick: () => { },
  customLogoClickRoute: '',
};
