/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { TabsContext } from '../../context/tabs/tabsContext';

import { getPerfiladorActiveTab } from '../../storage';

import { PerfiladorHeaderTabs } from './components/PerfiladorHeaderTabs';
import { PerfiladorBodyTabs } from './components/PerfiladorBodyTabs';
import useStyles from './useStyles';

const data = [
  {
    label: 'Precio de inmueble',
    value: 'PRECIO_INMUEBLE',
    disabled: false,
  },
  {
    label: 'Ingresos mensuales',
    value: 'INGRESOS_MENSUALES',
    disabled: false,
  },
];

export const Perfilador = ({
  buttonTitle,
  onSubmit,
  handleChangeActiveTab,
  showFooter,
  variant,
}) => {
  const { initTabs } = useContext(TabsContext);
  const classes = useStyles();

  useEffect(() => {
    const activeTab = getPerfiladorActiveTab();
    initTabs({
      tabs: data,
      default: activeTab || data[0].value,
      variant,
    });
  }, []);

  return (
    <Box className={classes.containerPerfilador}>
      <PerfiladorHeaderTabs
        handleChangeActiveTab={handleChangeActiveTab}
        showFooter={showFooter}
      />
      <PerfiladorBodyTabs
        onSubmit={onSubmit}
        buttonTitle={buttonTitle}
        showFooter={showFooter}
      />
    </Box>
  );
};

Perfilador.propTypes = {
  buttonTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  handleChangeActiveTab: PropTypes.func,
  showFooter: PropTypes.bool,
  variant: PropTypes.string,
};

Perfilador.defaultProps = {
  buttonTitle: '',
  showFooter: false,
  onSubmit: () => {},
  handleChangeActiveTab: () => {},
  variant: '',
};
