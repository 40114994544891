/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const flowStrategySlice = createSlice({
  name: 'flowStrategy',
  initialState: {
    strategyFlowName: 'creditohipotecario',
    strategyLoaded: false,
  },
  reducers: {
    setStrategyLoaded: (state, action) => {
      state.strategyLoaded = action.payload;
    },
    setStrategyFlowName: (state, action) => {
      state.strategyFlowName = action.payload;
    },
  },
});

export const {
  setStrategyLoaded,
  setStrategyFlowName,
} = flowStrategySlice.actions;
