import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './useStyles';

export const LabelInfo = ({ label }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography
        variant="h2"
        component="h2"
        className={classes.title}
      >
        {label}
      </Typography>
    </div>
  );
};

LabelInfo.propTypes = {
  label: PropTypes.string,
};

LabelInfo.defaultProps = {
  label: '',
};
