import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import useStyles from './useStyles';

export const BoxRounded = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.boxRoundedContainer}>
      <Typography className={classes.title} component="h3">
        {title}
      </Typography>
      {children}
    </Box>
  );
};

BoxRounded.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

BoxRounded.defaultProps = {
  title: '',
  children: null,
};
