/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {
  useState, useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container,
} from '@material-ui/core';
import * as fieldValueAPI from '../../../../../api/fieldValue';
import * as googleAnalytics from '../../../../../utils/googleAnalytics/events';
import * as nodeAPI from '../../../../../api/node';
import * as flowProcessApi from '../../../../../api/flowProcess';
import useLoading from '../../../../../hooks/useLoading';
import { finishStepByProcess } from '../../../../../utils/steps';

import { getProcess } from '../../../../../utils/auth';

import useStyles from './useStyles';
import { GroupFields, Loader } from '../../../../../components';
import ErrorMessage from '../../../../../components/ErrorMessage';
import AlertSnack from '../../../../../components/AlertSnack';
import useError from '../../../../../hooks/useError';
import { getFlowTypeHandler } from '../../../../../utils/tags';
import {
  COMPANY,
  CUSTOM_FLOW_CONFIG_TYPE,
  PERFILAMIENTO_CREDITICIO,
  PROFILING_UPLOADING,
} from '../../../../../constants';
import { getAllCompanyConfigs } from '../../../../../api/companyConfigs';
import { FormLayout } from '../../../layouts/FormLayout';
import { DownloadButton } from '../../../../../components/DownloadButton';
import { createUpdateTagGeneral } from '../../../../../utils';
import { nowDate } from '../../../../../components/Input/InputBaseDatePicker/formatter';

const getConsultPageConfigByFlow = async (normalizedFlowName, consultPageName) => {
  const companyConfigs = await getAllCompanyConfigs(COMPANY);
  const { payload } = companyConfigs.find(({ type: { name = '' } = {} }) => name === CUSTOM_FLOW_CONFIG_TYPE);
  const allPagesConfig = payload[normalizedFlowName][PERFILAMIENTO_CREDITICIO];
  const pageConfig = allPagesConfig[consultPageName];
  return pageConfig;
};

export const CreditProfilingUpload = ({ goTo, createUpdateTag }) => {
  const [nodeAutoExe, setNodeAutoExe] = useState(null);
  const [stepId, setStepId] = useState(null);
  const [stepConfigId, setStepConfigId] = useState(null);
  const [title, setTitle] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [nodesWithFieldValues, setNodesWithFieldValues] = useState([]);
  const { isLoading, startLoading, endLoading } = useLoading();
  const {
    isError,
    errorType,
    errorMessage,
    showError,
    hideError,
  } = useError();

  const classes = useStyles();

  const compareValue = (value, parentValue) => {
    if (Array.isArray(value)) {
      const optionsChequed = value.filter(({ checked }) => checked);
      const valueNames = optionsChequed.map(({ name }) => name);
      return valueNames.includes(parentValue);
    }
    return parentValue === value;
  };

  const filteredNewNodes = useMemo(() => {
    if (!nodesWithFieldValues || !nodesWithFieldValues.length) return [];
    const onlyFields = nodesWithFieldValues.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []);
    const fieldsCheckbox = onlyFields.filter(({ fieldType: { name = '' } = {} }) => name === 'lista' || name === 'checkbox');
    let nodesToReturn = nodesWithFieldValues;
    if (fieldsCheckbox.length) {
      const idNodes = fieldsCheckbox.reduce((acc, { config = {}, value = '' }) => {
        const { childrenNodes = [], defaultValue = '' } = config;
        const valueToCompare = value || defaultValue;
        const docsToCheck = nodesWithFieldValues.filter(({ _id }) => childrenNodes.includes(_id));
        const docsToShow = docsToCheck
          .filter(({ config: { parentValue = '' } = {} }) => compareValue(valueToCompare, parentValue))
          .map(({ _id }) => _id);
        return [...acc, ...docsToShow];
      }, []);
      nodesToReturn = nodesWithFieldValues.filter(({ _id, config = {} }) => {
        if (config?.parentValue) {
          return idNodes.includes(_id);
        }
        return true;
      });
    }
    const newNodes = nodesToReturn.map((node) => {
      const { fields } = node;
      const filteredFields = fields.filter(({ config: { hide = false } }) => hide === false);
      return { ...node, fields: filteredFields };
    });
    return newNodes;
  }, [nodesWithFieldValues]);

  const getUnChekedNodesToEmptyValues = () => nodesWithFieldValues.map((node) => {
    if (!node?.config?.parentValue) return node;
    const foundNode = filteredNewNodes.find(({ _id }) => _id === node._id) || null;
    if (foundNode) return node;
    const { fields } = node;
    const newFields = fields.map((field) => ({ ...field, value: '' }));
    return { ...node, fields: newFields };
  });

  const transformDataToUpload = (_process, filterNodes) => {
    const newFields = filterNodes.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).map(({
      _id, value,
    }) => ({
      field: _id,
      value,
      process: _process,
    }));
    return {
      fields: newFields,
    };
  };

  const saveFieldValues = (dataToUpload) => dataToUpload.fields.map(
    (field) => fieldValueAPI.saveFieldValue(field),
  );

  const submitForm = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      startLoading();
      const processID = getProcess();
      const filterNodes = getUnChekedNodesToEmptyValues();
      const dataToUpload = transformDataToUpload(processID, filterNodes);
      await Promise.all(saveFieldValues(dataToUpload));
      await createUpdateTag();
      await createUpdateTagGeneral({
        completed: true,
        fecha: nowDate(),
      }, 'solicitudfirmada');
      await finishStepByProcess('informaciondelcliente');
      googleAnalytics.solicitudFirmada();
      goTo();
      endLoading();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
    }
  };

  const isNotCompleted = () => filteredNewNodes.some((node) => {
    const { fields = [] } = node;
    return fields.some(({ config = {}, value = '' }) => {
      const { required = false, hide = false } = config;
      if (!hide && !required) return false;
      if (hide || !required) return false;
      if (Array.isArray(value)) {
        return !value.some(({ checked }) => checked);
      }
      return value === '' || value === null || !value.length;
    });
  });

  const getFieldValues = async (fields) => {
    const theProcess = getProcess();
    const theFieldValues = await Promise.all(fields.map(async (field) => {
      const fieldValue = await fieldValueAPI.getFieldValueByProcessField(theProcess, field._id);
      return { ...field, value: fieldValue.value || '' };
    }));
    return theFieldValues;
  };

  const fetchNodesWithFields = async (idNodes) => {
    const changeNames = {
      'Solicitud de Credito': 'Carga los documentos firmados',
    };
    const nodesData = await Promise.all(idNodes.map(async (node) => {
      const nodeData = await nodeAPI.getNodeById(node);
      const { name = '', config } = nodeData;
      const nodeTitle = changeNames[name] || name;
      const fields = await nodeAPI.getFieldsByNodeId(node);
      return {
        _id: node, fieldsData: fields, name: nodeTitle, config,
      };
    }));
    return nodesData;
  };

  const convert1Darray = (fields) => fields.reduce((acc, curr) => {
    const { fieldsData } = curr;
    return [...acc, ...fieldsData];
  }, []);

  const changeHideChildrens = (field, fields) => {
    if (!field) return fields;
    const children = field?.config?.children || [];
    const updatedFields = fields.map((_field) => {
      if (children.includes(_field._id)) {
        const parentValue = field.value;
        const hide = _field.config.parentValue !== parentValue;
        return { ..._field, config: { ..._field.config, hide } };
      }
      return _field;
    });
    return updatedFields;
  };

  const handleChange = (event, value, position, nameEvent) => {
    const { name } = event.target;
    const newName = nameEvent || name;
    const newNodes = nodesWithFieldValues.map((node) => {
      const newFields = node.fields.map(
        (field) => (field.name === newName ? { ...field, value } : field),
      );
      const fieldEditted = newFields.find((field) => field.name === newName);
      const fieldsUnhide = changeHideChildrens(fieldEditted, newFields);
      return { ...node, fields: fieldsUnhide };
    });
    setNodesWithFieldValues(newNodes);
  };

  const formatNodesWithFieldValues = (_nodes, _fieldValues) => {
    const newNodes = _nodes.map((node) => {
      const newFields = node.fieldsData.map((_field) => {
        const { name: fieldValueName } = _field;
        const {
          _id, fieldCatalog = {}, config, value = '', fieldType,
        } = _fieldValues.find(({ name }) => name === fieldValueName);
        return {
          ..._field, _id, fieldCatalog, config, value, fieldType,
        };
      });
      return { ...node, fields: newFields };
    });
    return newNodes;
  };
  const setNodo = async (nodeAutoejecutable) => {
    const nodeBatch = await nodeAPI.getNodeById(nodeAutoejecutable[0]);
    const nodeDigitalTemplate = await nodeAPI.getNodeById(nodeAutoejecutable[1]);
    return [nodeBatch, nodeDigitalTemplate];
  };
  const setNodosAndStep = async () => {
    const process = getProcess();
    const activeSteps = await flowProcessApi.getStepsActiveByProcess(process);
    const activeStepconfig = activeSteps.steps.find(({ stepConfig }) => stepConfig.name === 'Información del Cliente'); // aki
    const { stepConfig = {}, _id } = activeStepconfig;
    const {
      config: { nodeAutoejecutable = [] } = {},
    } = stepConfig;
    const nodes = await setNodo(nodeAutoejecutable);
    return { stepConfig: activeStepconfig._id, nodes, step: _id };
  };

  const fetchData = async () => {
    startLoading();
    try {
      const theProcess = getProcess();
      const { step, nodes, stepConfig } = await setNodosAndStep();
      const { normalizedFlowName } = await getFlowTypeHandler(theProcess);
      const _pageConfig = await getConsultPageConfigByFlow(
        normalizedFlowName,
        PROFILING_UPLOADING,
      );
      const { nodes: idNodes, title: newTitle } = _pageConfig;
      setTitle(newTitle);
      const nodesWithFields = await fetchNodesWithFields(idNodes);
      const fieldsFlat = convert1Darray(nodesWithFields);
      const fieldsWithValues = await getFieldValues(fieldsFlat);
      const newNodes = formatNodesWithFieldValues(nodesWithFields, fieldsWithValues);
      setNodesWithFieldValues(newNodes);
      setStepConfigId(stepConfig);
      setStepId(step);
      setNodeAutoExe(nodes);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to get the data');
      console.log(e);
    } finally {
      endLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <AlertSnack isOpen={isError} type="error" onClose={hideError}>
        <ErrorMessage errorType={errorType} errorMessage={errorMessage} />
      </AlertSnack>
      <Loader open={isLoading || loadingButton} />
      <FormLayout
        submit={submitForm}
        title={title}
        disabled={isNotCompleted()}
      >
        <Container
          component="section"
          maxWidth="md"
          className={classes.container}
        >
          {stepId && nodeAutoExe ? (
            <DownloadButton
              title="Descarga y firma los siguientes documentos"
              buttonTitle="Descargar documentos para firma"
              setIsLoading={setLoadingButton}
              nodeAutoExe={nodeAutoExe}
              stepId={stepConfigId}
            />
          ) : ''}
          {
            filteredNewNodes.map(({ fields, name: titleNode }, index) => (
              <Container
                key={titleNode}
                component="section"
                maxWidth={false}
                disableGutters
                className={classes.containerFields}
              >
                <GroupFields
                  fields={fields}
                  title={titleNode}
                  position={index}
                  onChange={handleChange}
                  customTitleClass={classes.customFieldsTitle}
                />
              </Container>
            ))
          }
        </Container>
      </FormLayout>
    </>
  );
};

CreditProfilingUpload.propTypes = {
  goTo: PropTypes.func.isRequired,
  createUpdateTag: PropTypes.func.isRequired,
};
