import { DefaultTheme } from '../themes/DefaultTheme';

const THEME_KEY = btoa('THEME_DATA');

export const storeThemeData = (data) => {
  window.localStorage.setItem(THEME_KEY, JSON.stringify(data));
};

export const getThemeData = () => {
  const jsonData = window.localStorage.getItem(THEME_KEY);
  return JSON.parse(jsonData);
};

export const removeThemeData = () => {
  window.localStorage.removeItem(THEME_KEY);
};

export const getItem = (key) => {
  try {
    const themeData = getThemeData();
    return themeData[key];
  } catch {
    return undefined;
  }
};

export const getLogo = () => {
  const logo = getItem('logo');
  return logo;
};

export const getTheme = () => {
  const theme = getItem('theme');
  return theme;
};

export const init = () => {
  const theme = getTheme();
  const logo = getLogo();
  if (!logo || !theme) return { theme: DefaultTheme, logo: '', disableLogo: false };
  return { logo, theme: DefaultTheme };
};
