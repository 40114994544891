import { formatMoney } from '../../../utils/currency';
import { numberFormatter } from '../InputNumber/formatter';

const removeMoneyFormat = (value) => (
  // eslint-disable-next-line no-useless-escape
  value.replace(/[\,$]/g, '')
);

export const currencyFormatter = (value, decimals = true) => {
  let newValue = value ? value.toString() : '';

  newValue = removeMoneyFormat(newValue);
  newValue = numberFormatter(newValue, decimals);
  newValue = formatMoney(newValue);

  return newValue;
};

export const removeCurrencyFormatter = (value, decimals = true) => {
  let newValue = value ? value.toString() : '';

  newValue = removeMoneyFormat(newValue);
  newValue = numberFormatter(newValue, decimals);

  return newValue;
};
