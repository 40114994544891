import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
  },
  container: {
    backgroundColor: '#FFF',
  },
  btn: {
    fontSize: '18px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000',
    backgroundColor: '#FFA500',
    borderRadius: '40px',
    padding: '20px 44px',
  },
  title: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '600',
    fontSize: '2rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      fontSize: '4rem',
      lineHeight: '4.5rem',
    },
  },
  titleNoLogo: {
    paddingTop: '40px',
  },
  subTitle: {
    paddingTop: '20px',
    paddingBottom: '50px',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '600',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '30px',
      fontSize: '2rem',
      paddingBottom: '30px',
      lineHeight: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '50px',
      paddingBottom: '70px',

    },
  },
  textsContainer: {
    backgroundColor: '#FFF',
  },
}));
