import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const CreateStrategyProvider = (Context) => {
  const StrategyProvider = ({ children }) => {
    const [strategy, setStrategy] = useState(null);
    const [strategyLoaded, setStrategyLoaded] = useState(false);

    const changeStrategy = (newStrategy) => {
      setStrategy(newStrategy);
      setStrategyLoaded(true);
    };

    const value = useMemo(
      () => ({ strategy, strategyLoaded, changeStrategy }),
      [strategy, strategyLoaded, changeStrategy],
    );

    return (
      <Context.Provider value={value}>
        {children}
      </Context.Provider>
    );
  };
  StrategyProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  return StrategyProvider;
};
