import { decryptData, encryptData } from '../utils/encryption';

const PERFILADOR_DATA_KEY = btoa('PERFILADOR_DATA');

export const storePerfiladorLocalData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(PERFILADOR_DATA_KEY, encryptedData);
};

export const removePerfiladorLocalData = () => {
  window.localStorage.removeItem(PERFILADOR_DATA_KEY);
};

export const getPerfiladorLocalData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(PERFILADOR_DATA_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

const getItem = (key) => {
  try {
    const perfiladorData = getPerfiladorLocalData();
    return perfiladorData[key];
  } catch {
    return undefined;
  }
};

export const getPerfiladorPropietyValue = () => getItem('clientPropietyValue');

export const getPerfiladorClientIngresos = () => getItem('ingresos');

export const getPerfiladorClientValuePropNumber = () => getItem('valuePropNumber');

export const getPerfiladorActiveTab = () => getItem('activeTab');

export const getPerfiladorCurrentInmobiliariaId = () => getItem('currentInmobiliariaId');

export const getPerfiladorInmobiliariasConditions = () => {
  const inmobiliariasConditions = getItem('inmobiliariasConditions');
  return inmobiliariasConditions;
};

export const getPerfiladorConditionsByInmobiliariaId = (inmobiliariaId) => {
  const inmobiliariasConditions = getPerfiladorInmobiliariasConditions() || {};
  return inmobiliariasConditions[inmobiliariaId] || {};
};

export const getPerfiladorConditionsByCurrentInmobiliariaId = () => {
  const currentInmobiliariaId = getPerfiladorCurrentInmobiliariaId();
  if (!currentInmobiliariaId) return null;
  return getPerfiladorConditionsByInmobiliariaId(currentInmobiliariaId);
};

export const storeExtraPerfiladorData = (data) => {
  const perfiladorData = getPerfiladorLocalData();
  const newPerfiladorData = { ...perfiladorData, ...data };
  storePerfiladorLocalData(newPerfiladorData);
};

export const storeInmobiliariaCreditConditions = (data) => {
  const { inmobiliariasConditions = { } } = getPerfiladorInmobiliariasConditions() || {};
  const newCurrentData = {
    inmobiliariasConditions: {
      ...inmobiliariasConditions,
      ...data,
    },
  };
  storeExtraPerfiladorData(newCurrentData);
};

export const storeCurrentInmobiliariaId = (currentInmobiliariaId) => {
  storeExtraPerfiladorData({ currentInmobiliariaId });
};
