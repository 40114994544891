import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth: '600px',
    margin: '0 auto',
  },
  form: {
    background: '#F7FAFD',
    width: '100%',
    padding: '2vh 0 2vh 0',
    overflowY: 'auto',
    backgroundColor: '#F7FAFD',
    height: 'calc(100% - 65px - 88px - 5px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 65px - 85.19px - 5px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 96.8px - 57px - 5px)',
    },
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'normal',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));
