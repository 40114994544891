import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    background: 'linear-gradient(122deg, #2D1583 2.78%, rgba(50, 24, 141, 0.90) 36.46%, rgba(100, 61, 238, 0.85) 98.15%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
  },
  title: {
    fontSize: '30px',
    letterSpacing: '0.30px',
    color: '#FFF',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    textAlign: 'center',
    marginTop: '0px',
    marginBottom: '0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      letterSpacing: '0.36px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '42px',
      letterSpacing: '0.42px',
    },
  },
}));
