/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import { Close } from '@material-ui/icons';
import {
  Container,
  IconButton,
  Typography,
  Radio, RadioGroup, FormControlLabel, Button,
} from '@material-ui/core';
import useStyles from './useStyles';
import Input from '../Input';

export const ModalChangeOptions = ({
  type,
  options,
  onCancel,
  showModal,
  onSubmit,
}) => {
  const classes = useStyles();
  const [radioValue, setRadioValue] = useState('');
  const [fieldValues, setFieldValues] = useState({});
  const [fieldsDefault, setFieldsDefault] = useState({});
  const [actualType, setActualType] = useState('');
  const [title, setTitle] = useState('');
  const [error, setError] = useState(false);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if (error) {
      setError(false);
    }
  };

  const handleOnChange = (event, value, fieldName) => {
    const newFieldValues = fieldValues.map((field) => {
      if (field.name === fieldName) return { ...field, value };
      return field;
    });
    setFieldValues(newFieldValues);
  };

  const handleSubmit = () => {
    if (radioValue === '') {
      setError(true);
    } else {
      onSubmit(fieldValues, radioValue, fieldsDefault);
    }
  };

  const closeModal = () => {
    onCancel();
    setRadioValue('');
    setFieldValues([]);
    setFieldsDefault([]);
  };

  useEffect(() => {
    if (actualType !== type) setRadioValue('');
    if (options.length && type) {
      const option = options.find((opt) => opt.type === type);
      setActualType(type);
      setTitle(option.title);
      setFieldValues(option.fields);
      setFieldsDefault(option.fieldsDefault);
    }
  }, [options, type]);

  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
    >
      <div className={classes.mainContainer}>
        <IconButton className={classes.iconButton} onClick={closeModal}>
          <Close className={classes.closeIcon} />
        </IconButton>
        <Typography
          variant="h1"
          className={classes.title}
        >
          {title}
        </Typography>
        <Container
          component="div"
          method="post"
          autoComplete="off"
          maxWidth={false}
          className={classes.form}
        >
          {error && (
          <Typography className={classes.errorText}>
            Por favor, selecciona una opción.
          </Typography>
          )}
          <RadioGroup
            row
            className={classes.radioGroup}
            value={radioValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
            <FormControlLabel value="si" control={<Radio color="primary" />} label="Si" />
          </RadioGroup>
          {radioValue === 'si' && fieldValues && fieldValues.map((field) => (
            <Input
              label={field?.label}
              type={field?.fieldType?.name}
              name={field?.name}
              value={field?.value || ''}
              options={field?.config?.options ?? []}
              required
              onChange={(event, value) => handleOnChange(event, value, field.name)}
              isEmpty={field?.isEmpty}
              errorMessage={field?.errorMessage}
              className={classes.textField}
            />
          ))}
          <div className={classes.buttonContainer}>
            <Button variant="contained" className={classes.button} onClick={handleSubmit}>
              Continuar
            </Button>
          </div>
        </Container>
      </div>
    </Modal>
  );
};

ModalChangeOptions.propTypes = {
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
