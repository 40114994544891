/* eslint-disable import/prefer-default-export */
import { UPDATE_LOGO, UPDATE_THEME, LOAD_THEME } from './constants';

export const themeReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case UPDATE_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case LOAD_THEME:
      return { ...action.payload };
    default:
      return { ...state };
  }
};
