/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
import {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import * as fieldValueAPI from '../../api/fieldValue';
import * as processAPI from '../../api/process';

import { getProcess } from '../../utils';
import {
  convertirAMoneda,
} from '../../utils/currency';
import {
  getAforo, getEnganche, getImporte,
  getYear,
  getMensualidad, getMeses, getPropietyValue, storeCredit, getCreditObjectUnformatted, fillValuesOnInitialValuesFromCreditObject, getCreditValuesObject, getCredit,
  getMontoGastosEscritura,
  setConditonsAlreadyEditted,
} from '../../utils/credit';

import {
  AFORO_FIELD_ID,
  COMPANY,
  ENGANCHE_FIELD_ID,
  GASTOS_ESCRITURA_FIELD_ID,
  IMPORTE_FIELD_ID,
  PAGO_MENSUAL_FIELD_ID,
  PERFILAMIENTO_EXIST,
  PLAZO_FIELD_ID,
  PROFILING_COTIZATION,
  PROFILING_EMPTY_MATRIZ,
  PROPIEDAD_VALOR_FIELD_ID,

} from '../../constants';

import { useMatrizProfilling } from '../../containers/main/hooks/useMatrizProfilling';

import { useFieldValues } from '../../containers/main/hooks/useFieldValues';
import { useFlowStrategy } from '../../hooks/useFlowStrategy';

export const Simulator = ({
  startLoading,
  endLoading,
  onSubmitClick,
  shouldExecMatriz,
}) => {
  const { strategy: simulatorStrategy } = useFlowStrategy();

  const initialValues = simulatorStrategy.getInitialValues();
  const [values, setValues] = useState(initialValues);

  const {
    getMatrizBody,
    getCreditPropertyFieldValues,
    fetchLocalCreditData,
    fetchMatrizFieldsConfigByFlowName,
    handleMatrizSubmission,
    execMatrizProfilling,
    updateClientConditionsValues,
    savePerfilamientoValue,
  } = useMatrizProfilling({ company: COMPANY, fetchFieldsConfigsOnLoad: false, useDesembolsoMode: true });

  const { formatFromFieldValueModelToUpload } = useFieldValues({ company: COMPANY });

  const saveCreditValues = async () => {
    const process = getProcess();
    const importe = getImporte();
    const meses = getMeses();
    const aforo = getAforo();
    const propietyValue = getPropietyValue();
    const monthlyPaid = getMensualidad();
    const enganche = getEnganche();
    const year = getYear();
    const montoGastosEscritura = getMontoGastosEscritura();
    await Promise.all([
      fieldValueAPI.saveFieldValue({
        field: ENGANCHE_FIELD_ID,
        value: convertirAMoneda(enganche),
        process,
      }),
      fieldValueAPI.saveFieldValue({
        field: PLAZO_FIELD_ID,
        value: `${year} años`,
        process,
      }),
      fieldValueAPI.saveFieldValue({
        field: PROPIEDAD_VALOR_FIELD_ID,
        value: convertirAMoneda(propietyValue),
        process,
      }),
      fieldValueAPI.saveFieldValue({
        field: PAGO_MENSUAL_FIELD_ID,
        value: convertirAMoneda(monthlyPaid),
        process,
      }),
      fieldValueAPI.saveFieldValue({
        field: IMPORTE_FIELD_ID,
        value: convertirAMoneda(importe),
        process,
      }),
      fieldValueAPI.saveFieldValue({
        field: PLAZO_FIELD_ID,
        value: `${meses} meses`,
        process,
      }),
      fieldValueAPI.saveFieldValue({
        field: AFORO_FIELD_ID,
        value: `${aforo}`,
        process,
      }),
      fieldValueAPI.saveFieldValue({
        field: GASTOS_ESCRITURA_FIELD_ID,
        value: convertirAMoneda(montoGastosEscritura),
        process,
      }),
    ]);
  };

  const saveProcessCreditFieldValues = async (matrizFieldValues, processId) => {
    const formattedFieldValues = formatFromFieldValueModelToUpload(matrizFieldValues, true);
    const creditInfoFieldValues = getCreditPropertyFieldValues();
    const newFieldValuesToSave = [...formattedFieldValues, ...creditInfoFieldValues];

    const fieldValuesFormatted = newFieldValuesToSave.map((fieldValue) => ({
      ...fieldValue,
      process: processId,
    }));

    await Promise.all(fieldValuesFormatted.map(async (fieldValue) => fieldValueAPI.saveFieldValue(fieldValue)));
  };

  const onSubmit = async ({ event, storageData }) => {
    try {
      const process = getProcess();
      event.preventDefault();
      event.stopPropagation();
      startLoading();
      const oldCreditData = getCredit();
      storeCredit({
        ...oldCreditData,
        ...storageData,
      });
      setConditonsAlreadyEditted();
      fetchLocalCreditData();
      if (!shouldExecMatriz) return onSubmitClick();
      if (!process || process === undefined) return handleMatrizSubmission();

      await saveCreditValues();
      const matrizBody = await getMatrizBody();
      const { fieldValues: matrizFieldValues } = matrizBody;
      await saveProcessCreditFieldValues(matrizFieldValues, process);
      const { values: matrizResult, matrizStatus, newConditions } = await execMatrizProfilling(matrizBody);
      await savePerfilamientoValue(matrizResult, process);
      if (newConditions) {
        await updateClientConditionsValues(newConditions, process);
      }
      if (matrizStatus === PERFILAMIENTO_EXIST && matrizResult) {
        await onSubmitClick({ currentPage: PROFILING_COTIZATION })();
        await processAPI.setTasaBroker(process, COMPANY);
      } else {
        await onSubmitClick({ currentPage: PROFILING_EMPTY_MATRIZ })();
      }
      window.location.reload();
      endLoading();
    } catch (e) {
      endLoading();
    }
  };
  const fetchData = async () => {
    startLoading();
    try {
      await fetchMatrizFieldsConfigByFlowName();
      const creditFormattedValues = await simulatorStrategy.getCreditValues();
      const creditObjectUnformatted = getCreditObjectUnformatted(creditFormattedValues);
      const creditObject = getCreditValuesObject(creditObjectUnformatted);
      const newValues = fillValuesOnInitialValuesFromCreditObject(values, creditObject);
      setValues(newValues);
      endLoading();
    } catch (e) {
      console.log(e);
      endLoading();
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return simulatorStrategy.renderSimulator({ initialValues: values, onSubmit });
};

Simulator.propTypes = {
  startLoading: PropTypes.func,
  endLoading: PropTypes.func,
  onSubmitClick: PropTypes.func,
  autoNodes: PropTypes.array,
  shouldExecMatriz: PropTypes.bool,
};

Simulator.defaultProps = {
  startLoading: () => { },
  endLoading: () => { },
  onSubmitClick: () => { },
  autoNodes: [],
  shouldExecMatriz: false,
};
