import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import useStyles from './useStyles';

const CustomToolbar = (props) => {
  const {
    date,
    isLandscape,
    openView,
    setOpenView,
    title,
  } = props;
  const classes = useStyles();

  const handleChangeViewClick = (view) => () => {
    setOpenView(view);
  };

  return (
    <PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
      <Box className={classes.toolbarContainer}>
        <Typography variant="body1" component="span" className={classes.toolbarTitle}>Cambiar año:</Typography>
        <ToolbarButton
          onClick={handleChangeViewClick('year')}
          variant="h6"
          label={date.format('YYYY')}
          selected={openView === 'year'}
          className={classes.toolbarButton}
        />
      </Box>
      <ToolbarButton
        onClick={handleChangeViewClick('date')}
        variant="h4"
        selected={openView === 'date'}
        label={date.format('ll')}
      />
    </PickerToolbar>
  );
};

CustomToolbar.propTypes = {
  date: PropTypes.string,
  isLandscape: PropTypes.bool,
  openView: PropTypes.bool,
  setOpenView: PropTypes.func,
  title: PropTypes.string,
};

CustomToolbar.defaultProps = {
  date: '',
  isLandscape: false,
  openView: true,
  setOpenView: () => { },
  title: '',
};

export default CustomToolbar;
